import ApplicationInternalStatus from "./ApplicationInternalStatus";

const ApplicationStage = {
    ALL: -1,
    APPLICATION: 1,
    RECRUITMENT: 2,
    HR: 3,
};

export default ApplicationStage;

const ApplicationStageStrings = {
    APPLICATION: 'Application',
    RECRUITMENT: 'Recruitment',
    HR: 'HR',
};

export function getApplicationStage(application) {

    if(application.applicationInternalStatus < ApplicationInternalStatus.APPLICANT_FILING_DATA_COMPLETED) {
        return ApplicationStageStrings.APPLICATION;
    }
    else if(application.applicationInternalStatus >= ApplicationInternalStatus.APPLICANT_FILING_DATA_COMPLETED
        && application.applicationInternalStatus < ApplicationInternalStatus.CONTRACT_FILLED) {
        return ApplicationStageStrings.RECRUITMENT;
    }

    return ApplicationStageStrings.HR;
}

export function getApplicationStages() {
    return [
        {
            name: 'Application',
            value: ApplicationStage.APPLICATION
        },
        {
            name: 'Recruitment',
            value: ApplicationStage.RECRUITMENT
        },
        {
            name: 'HR',
            value: ApplicationStage.HR
        }
    ]
}