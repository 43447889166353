import React from 'react';
import strings from '../../../localization';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {getError, hasError} from "../../../functions/Validation";
import SelectControl from "../../controls/SelectControl";


function getRoles(data, roles) {
    if (!data.division) {
        return roles;
    }

    let result = [];

    for (let role of roles) {
        if (role.division && role.division.id == data.division.id) {
            result.push(role);
        }
    }


    return result;
}

function haveRolesSelect(data, roles) {
    if (!data.division) {
        return true;
    }

    return getRoles(data, roles).length > 0;
}

function getRecruiters(data, recruiters, userDivisions) {
    if (!data.division) {
        return recruiters;
    }

    let result = [];

    for (let rec of recruiters) {

        const userDivision = userDivisions.find(x => x.user && x.user.id === rec.id && x.division && x.division.id === data.division.id)

        if (!userDivision || !userDivision.visibleToCandidate || userDivision.deleted) {
            continue;
        }

        rec.name = rec.firstName + " " + rec.lastName;
        result.push(rec);
    }


    return result;
}

const AddCandidateForm = ({
                              onSubmit,
                              onChange,
                              errors,
                              data,
                              keyPress,
                              divisions,
                              recruiters,
                              userDivisions,
                              jobRoles
                          }) => (
    <form id="add-candidate-form" onSubmit={onSubmit} action="#">
        <TextField
            label={strings.addCandidate.firstName}
            error={hasError(errors, 'firstName')}
            helperText={getError(errors, 'firstName')}
            fullWidth
            name='firstName'
            onChange={onChange}
            onKeyPress={keyPress}
            value={data.firstName}
            variant='outlined'
            margin="dense"
        />
        <TextField
            label={strings.addCandidate.lastName}
            error={hasError(errors, 'lastName')}
            helperText={getError(errors, 'lastName')}
            fullWidth
            name='lastName'
            onChange={onChange}
            onKeyPress={keyPress}
            margin="dense"
            value={data.lastName}
            variant='outlined'
        />
        <TextField
            label={strings.addCandidate.email}
            error={hasError(errors, 'email')}
            helperText={getError(errors, 'email')}
            fullWidth
            name='email'
            onChange={onChange}
            onKeyPress={keyPress}
            margin="dense"
            value={data.email}
            variant='outlined'
        />
        <div className={'select-container' + (hasError(errors, 'division') ? ' error' : '')}>
            <SelectControl
                error={hasError(errors, 'division')}
                helperText={getError(errors, 'division')}
                fullWidth
                autoFocus
                name='division'
                onChange={onChange}
                margin="normal"
                value={data.division}
                options={divisions}
                nameKey={'name'}
                valueKey={'id'}
                selected={data.division}
                isSearchable={false}
                placeholder={strings.jobDetails.division}
            />
            {
                hasError(errors, 'division') ?
                    <p className='select-error'>{strings.validation.RequiredErrorMessage}</p> : ""
            }
        </div>
        <div className={'select-container' + (hasError(errors, 'recruiter') ? ' error' : '')}>
            <SelectControl
                error={hasError(errors, 'recruiter')}
                helperText={getError(errors, 'recruiter')}
                fullWidth
                autoFocus
                name='recruiter'
                onChange={onChange}
                margin="normal"
                value={data.recruiter}
                options={getRecruiters(data, recruiters, userDivisions)}
                nameKey={'name'}
                valueKey={'id'}
                selected={data.recruiter}
                isDisabled={!data.division}
                isSearchable={false}
                placeholder={strings.jobDetails.recruiter}
            />
            {
                hasError(errors, 'recruiter') ?
                    <p className='select-error'>{strings.validation.RequiredErrorMessage}</p> : ""
            }
        </div>
        {
            haveRolesSelect(data, jobRoles) &&
            <div className={'select-container' + (hasError(errors, 'jobRole') ? ' error' : '')}>
                <SelectControl
                    error={hasError(errors, 'jobRole')}
                    helperText={getError(errors, 'jobRole')}
                    fullWidth
                    autoFocus
                    name='jobRole'
                    onChange={onChange}
                    margin="normal"
                    value={data.jobRole}
                    options={getRoles(data, jobRoles)}
                    nameKey={'name'}
                    valueKey={'id'}
                    selected={data.jobRole}
                    isDisabled={!data.division}
                    isSearchable={false}
                    placeholder={strings.jobDetails.job}
                />
                {
                    hasError(errors, 'jobRole') ?
                        <p className='select-error'>{strings.validation.RequiredErrorMessage}</p> : ""
                }
            </div>
        }
        {
            !haveRolesSelect(data, jobRoles) &&
            <TextField
                className={'job-role-input'}
                label={strings.jobDetails.job}
                error={hasError(errors, 'jobRoleText')}
                helperText={getError(errors, 'jobRoleText')}
                fullWidth
                name='jobRoleText'
                onChange={onChange}
                onKeyPress={keyPress}
                margin="dense"
                value={data.jobRoleText}
                variant='outlined'
            />
        }

        <div className='submit-container'>
            <Button variant="contained" className={'add-candidate-btn'} color="primary" onClick={onSubmit}>
                {strings.addCandidate.addCandidate}
            </Button>
        </div>
    </form>
);

export default AddCandidateForm;