import React, {Component} from 'react';
import {Dialog, Divider, Slide} from "@material-ui/core/";
import CloseIcon from '@material-ui/icons/Close';
import {Button, IconButton, TextField} from "@material-ui/core";
import strings from "../localization";
import FormComponent from './FormComponent';
import WithdrawalEmailOptions from "../constants/WithdrawalEmailOptions";

class WithdrawConfirmationModal extends FormComponent {

    constructor(props) {
        super(props);

        this.state = {
            application: props.application ? props.application : {},
            data: {},
            emailOption: ''
        }
    }

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="left" ref={ref} {...props} />;
    });

    send() {
        this.props.toggleModal();

        this.props.apply(this.props.application ? this.props.application.id : -1,
            this.state.emailOption);
    }

    setEmailOption(emailOption){
        this.setState({
            emailOption
        });
    }

    render() {

        return (
            <Dialog
                onClose={this.props.toggleModal}
                aria-labelledby="customized-dialog-title"
                open={this.props.open}
                TransitionComponent={this.Transition}
                className={'withdraw-modal'}
            >
                <div className='form-modal-header' onClose={this.props.toggleModal}>
                    <div>
                        <h1>{this.props.title}</h1>
                        <IconButton className={'close-button-wrapper'} onClick={this.props.toggleModal}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <Divider />
                </div>
                <div className={'withdraw-content'}>
                    <div className={'options'}>
                        <Button variant="contained" className={this.state.emailOption === WithdrawalEmailOptions.Candidate_withdrawal ? 'selected' : ''} color="primary" onClick={() => this.setEmailOption(WithdrawalEmailOptions.Candidate_withdrawal)}>
                            { strings.candidateProfiles.candidateWithdrawal }
                        </Button>
                        <Button variant="contained" className={this.state.emailOption === WithdrawalEmailOptions.Role_no_longer_available ? 'selected' : ''} color="primary" onClick={() => this.setEmailOption(WithdrawalEmailOptions.Role_no_longer_available)}>
                            { strings.candidateProfiles.roleNoLongerAvailable }
                        </Button>
                    </div>
                    <div className={'send-button'}>
                        <Button variant="contained" color="primary" onClick={() => this.send()}>
                            { strings.candidateProfiles.send }
                        </Button>
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default WithdrawConfirmationModal;