import ContractTypeMode from "../constants/ContractTypes";
import {findDataValue} from "./DropdownUtil";
import Territories, {getTerritoriesString} from "../constants/TerritoriesOfWork";
import ContractTypes from "../constants/ContractTypes";
import Validators from "../constants/ValidatorTypes";

export function getOfferValidationList(data, offer = null) {

    let result = {};

    let fields = ['workPattern', 'rateOfPay', 'startDate', 'continuousStartDate', 'hoursPerWeek',
     'annualSalary', 'holidayEntitlement', /*'additionalBenefits',*/ 'carAllowance', 'carAllowancePerMonth', 'unpaidBreak'
     , 'rateOfPayPerHalfDay', 'contractedHoursPerMonth', 'currency']


    for(let field of fields) {
        if(checkCondition(data, field, offer)) {
            result[field] = [{ type: Validators.REQUIRED }];
        }    
    } 
    
    return result;
}


export function checkCondition(data, name, offer = null){

    if(data && data.contractType){
        if(name === 'region'){
            return !(data.contractType === ContractTypeMode.Payroll_52_Group_UK
                || data.contractType === ContractTypeMode.VAS_Casual_UK
            );
        }else if(name === 'workPattern'){
            return !(data.contractType === ContractTypeMode.VAS_Casual_UK
                || data.contractType === ContractTypeMode.Salaried_Operative_ROI
            );
        }else if(name === 'rateOfPay'){
            return !(data.contractType === ContractTypeMode.Payroll_52_Group_UK
                || data.contractType === ContractTypeMode.Salaried_Operative_UK
                || data.contractType === ContractTypeMode.Salaried_Operative_ROI
            );
        }else if(name === 'startDate'){
            return !(data.contractType === ContractTypeMode.Ad_hoc_Casual_services_agreement_ROI
                || data.contractType === ContractTypeMode.Ad_hoc_Casual_services_agreement_UK
                || data.contractType === ContractTypeMode.VAS_Casual_UK
            );
        }else if(name === 'continuousStartDate'){
            return (data.contractType === ContractTypeMode.Payroll_52_Group_UK);
        }else if(name === 'hoursPerWeek'){
            return !(data.contractType === ContractTypeMode.Ad_hoc_Casual_services_agreement_ROI
                || data.contractType === ContractTypeMode.Ad_hoc_Casual_services_agreement_UK
                || data.contractType === ContractTypeMode.VAS_Casual_UK
                || data.contractType === ContractTypeMode.Salaried_Operative_ROI
                || data.contractType === ContractTypeMode.Hourly_Contracted_pay_UK
            );
        }else if(name === 'annualSalary'){
            return !(data.contractType === ContractTypeMode.Ad_hoc_Casual_services_agreement_UK
                || data.contractType === ContractTypes.Ad_hoc_Casual_services_agreement_ROI
                || data.contractType === ContractTypeMode.VAS_Casual_UK
            );
        }else if(name === 'holidayEntitlement'){
            return (data.contractType === ContractTypeMode.Salaried_Operative_UK
            );
        }else if(name === 'additionalBenefits'){
            return (data.contractType === ContractTypeMode.Payroll_52_Group_UK);
        } else if (name == 'carAllowanceSelect' || name == 'carAllowancePerMonthSelect') {
            return (data.contractType === ContractTypeMode.Payroll_52_Group_UK);
        } else if(name === 'carAllowance'){
            if(data.contractType !== ContractTypeMode.Payroll_52_Group_UK) {
                return false;
            }

            let isValue = offer && offer.carAllowance && offer.carAllowance.value != undefined;

            if(isValue) {
                return offer.carAllowance.value;
            }

            if(offer == null) {
                return false;
            }

            return offer.carAllowance;

        }else if(name === 'bonus'){
            return (data.contractType === ContractTypeMode.Payroll_52_Group_UK);
        }else if(name === 'commission'){
            return (data.contractType === ContractTypeMode.Payroll_52_Group_UK);
        }else if(name === 'carAllowancePerMonth'){

            if(data.contractType !== ContractTypeMode.Payroll_52_Group_UK) {
                return false;
            }

            let isValue = offer && offer.carAllowance && offer.carAllowance.value != undefined;

            if(isValue) {
                return offer.carAllowance.value;
            }

            if(offer == null) {
                return false;
            }

            return offer.carAllowance;
            
        }else if(name === 'unpaidBreak'){
            return (data.contractType === ContractTypeMode.Salaried_Operative_UK);
        }else if(name === 'rateOfPayPerHalfDay'){
            return (data.contractType === ContractTypeMode.VAS_Casual_UK);
        }else if(name === 'contractedHoursPerMonth'){
            return (data.contractType === ContractTypeMode.Salaried_Operative_ROI
            || data.contractType === ContractTypeMode.Hourly_Contracted_pay_UK
            );
        }else if(name === 'currency') {
            return true;
        }
    }
}

export function checkTerritoryOfWorkConditions(data, territoryOfWork){
    if(data && territoryOfWork){
        return findDataValue(data, 'territoryOfWork') === territoryOfWork;
    }
}

export function checkConditionsForValidation(data){
    
    if(data.territoryOfWork){
        if(findDataValue(data, 'territoryOfWork') === getTerritoriesString(Territories.UK)){
            if(data.nationality !== null && findDataValue(data, 'nationality') !== 'British'){
                return true;
            }
        } else if (findDataValue(data, 'territoryOfWork') === getTerritoriesString(Territories.IRELAND)){
            if(data.nationality !== null && !checkNationalityIDVTVerification(findDataValue(data, 'nationality'))) {
                return true;
            }
        } else {
            return false;
        }
    }
}

export function checkUniformConditions(data){
    switch(data.contractType)
    {
        case ContractTypes.Ad_hoc_Casual_services_agreement_UK: return true;
        case ContractTypes.Ad_hoc_Casual_services_agreement_ROI: return true;
        case ContractTypes.VAS_Casual_UK: return true;
        case ContractTypes.Hourly_Contracted_pay_UK: return true;
    }

    return false;
}

export function checkNationalityIDVTVerification(nationality) {
    if(nationality === 'British' || nationality === 'Irish' || nationality === 'Northern Irish' || 
        nationality === 'English' || nationality === 'Welsh' || nationality === 'Scottish') {
        return true;
    }
    return false;
}