import React, {createRef} from 'react'
import TablePage from "../../common/TablePage";
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import {withSnackbar} from "notistack";
import PageState from '../../constants/PageState';
import {
    Button,
    Drawer,
    TableBody,
    TableCell,
    TableHead,
    TableRow, TextField
} from "@material-ui/core";
import DrawerWrapper from "../../common/DrawerWrapper";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ViewSendModal from "../../common/ViewSendModal";
import MakeOfferModal from "../../common/MakeOfferModal";
import { getCodebooks } from '../../services/CodebookService';
import { getApplications } from '../../services/AddCandidateService';
import { getContractTypesString} from '../../constants/ContractTypes';
import {makeOffer, sendOffer} from "../../services/OfferService";
import {getHealthcareTypes} from "../../constants/HealthcareType";
import ApplicationInternalStatus from '../../constants/ApplicationInternalStatus';
import SearchIcon from "@material-ui/icons/Search";
import { getOfferValidationList } from '../../util/FieldsConditionUtil';
import { hasErrors, validate } from '../../functions/Validation';
import ApplicationView from '../../constants/ApplicationView';
import HRApproveModal from "../../common/HRApproveModal";
import HRApprovalStatus from "../../constants/HRApprovalStatus";

class ConditionalOffers extends TablePage {

    validationList = {};

    tableDescription = [
        { key: 'id', label: strings.candidateProfiles.name, transform: 'renderName', class: 'column-fix' },
        { key: 'division', label: strings.candidateProfiles.division, transform: 'renderDivision' },
        { key: 'region', label: strings.candidateProfiles.region, transform: 'renderRegion' },
        { key: 'id', label: strings.candidateProfiles.contractType, transform: 'renderContractType' },
        { key: 'id', label: strings.conditionalOffers.changeStatus, transform: 'renderChangeStatusButton'},
        { key: null, label: strings.conditionalOffers.hrApproval, transform: 'renderHRApprovalButton'},
        { key: 'id', label: strings.conditionalOffers.send, transform: 'renderSendButton'},
        { key: 'recruiter', label: strings.conditionalOffers.offeringManager, transform: 'renderOfferingManager' }
    ];

    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : {},
            showSearch: true,
            tableData: [],
            pageState: PageState.View,
            openSendModal: false,
            openMakeOfferModal: false,
            openHRApprove: false,
            total: 100,
            errors: {},
            offerSent: false,
            healthcare: getHealthcareTypes(),
            contractTypeConfigurations: []
        };

        this.toggleSendModal = this.toggleSendModal.bind(this);
        this.openSendModal = this.openSendModal.bind(this);
        this.sendModalRef = createRef();
        this.toggleMakeOffer = this.toggleMakeOffer.bind(this);
        this.openMakeOfferModal = this.openMakeOfferModal.bind(this);
        this.sendOffer = this.sendOffer.bind(this);
        this.toggleHRApprove = this.toggleHRApprove.bind(this);
        this.makeOfferModalRef = createRef();
    }

    findApplication(id) {

        for(let item of this.state.tableData) {
            if(item.id == id) {
                return item;
            }
        }

        return null;
    }

    renderName(id){

        let application = this.findApplication(id);

        if(!application) {
            return '';
        }

        return <div>
            <div>
                {this.renderCandidateImage()}
            </div>
            <div>
                {application.forename + ' ' + application.surname}
            </div>
        </div>
    }

    renderContractType(id){

        let application = this.findApplication(id);

        // return (
        //     <div>
        //         <SelectControl
        //             fullWidth
        //             autoFocus
        //             name='contractType'
        //             onChange={ (event) => this.changeDataStatus(event, id) }
        //             margin="normal"
        //             options={ getContractTypes() }
        //             nameKey={ 'name' }
        //             valueKey={ 'value' }
        //             selected={ application ? application.contractType : undefined }
        //         />
        //     </div>
        // );

        if(application.contractType){
            return getContractTypesString(application.contractType);
        }

        return null;
    }

    renderDivision(item) {
        if (!item) {
            return '';
        }

        return item.name;
    }

    renderRegion(item) {
        if (!item) {
            return '';
        }

        return item.name;
    }

    fetchData() {

        if(!this.state.filter) {
            return;
        }

        this.setState({
             lockTable: true
        });

        getCodebooks().then(response => {
            if(!response.ok) {
                return;
            }

            this.setState({
                titles: response.data.titles,
                genders : response.data.genders,
                divisions: response.data.divisions,
                regions: response.data.regions,
                recruiters: response.data.recruiters,
                jobRoles: response.data.jobRoles,
                travelPreferences: response.data.travelPreferances,
                nationalities: response.data.nationalities,
                idDocumentTypes: response.data.idDocumentTypes,
                proofAddressDocumentTypes: response.data.proofAddressDocumentTypes,
                sessionTypes: response.data.sessionTypes,
                territoryOfWorks: response.data.territoryOfWorks,
                workPatterns: response.data.workPaterns,
                contractTypeConfigurations: response.data.contractTypeConfigurations
            });
        });

        if(this.state.filter) {
            this.state.filter.applicationInternalStatus = ApplicationInternalStatus.ALL;
            this.state.filter.view = ApplicationView.CONDITIONAL_OFFERS;
            this.state.filter.search = this.state.searchData.search;
            this.state.filter.page = this.state.searchData.page - 1;
            this.state.filter.perPage = this.state.searchData.perPage;
        }

        getApplications(this.state.filter).then(response => {
            if(!response.ok) {
                return;
            }

            this.setState({
                tableData: response.data.entities ? response.data.entities : [],
                total: response.data.total,
                lockTable: false
            });
        });
    }

    componentDidMount() {
        this.props.setApplicationId(-1);

        this.state.filter = this.props.filter ? this.props.filter.filterData : {
            division: -1,
            region: -1,
            location: -1,
            recruiter: -1,
            jobRole: -1,
            status: -1,
            contractType: -1,
            search: '',
            page: 1,
            perPage: 30,
            dateFrom: null,
            dateTo: null,
            stage: -1
        };

        this.fetchData();
    }

    componentWillReceiveProps(props) {
        this.state.filter = props.filter ? props.filter.filterData : {
            division: -1,
            region: -1,
            location: -1,
            recruiter: -1,
            jobRole: -1,
            status: -1,
            contractType: -1,
            search: '',
            page: 1,
            perPage: 30,
            dateFrom: null,
            dateTo: null,
            stage: -1
        };
        this.fetchData();
    }

    toggleSendModal(id){

        this.state.selectedApplication = id;

        this.setState({
            openSendModal: !this.state.openSendModal
        });
    }

    toggleMakeOffer(id){

        this.state.selectedApplication = id;

        this.setState({
            openMakeOfferModal: !this.state.openMakeOfferModal
        });

        if(!this.state.openMakeOfferModal) {
            this.fetchData();
        }
    }

    toggleHRApprove(id){

        this.state.selectedApplication = id;

        this.setState({
            openHRApprove: !this.state.openHRApprove
        });

        if(!this.state.openHRApprove) {
            this.fetchData();
        }
    }

    openSendModal() {
        if (this.sendModalRef.current) {
            this.sendModalRef.current.open();
        }
    };

    openMakeOfferModal() {
        if (this.makeOfferModalRef.current) {
            this.makeOfferModalRef.current.open();
        }
    };

    renderCandidateImage(){
        return(
            <div className={'candidate-profile-image'}>
                {
                    this.state.tableData.image
                        ?
                        <img src={'/images/background.jpg'} alt={'logo'} />
                        :
                        <img src={'/images/user.png'} alt={'logo'} />
                }
            </div>
        );
    }

    sendOffer(id){
    
        if(!id) {
            return;
        }

        this.toggleSendModal();

        makeOffer(id).then(response => {

            this.fetchData();

        });
    }

    viewSendModal(){
        return (
            <ViewSendModal
                toggleModal={this.toggleSendModal}
                open={this.state.openSendModal}
                title={strings.conditionalOffers.title}
                openDialog={this.openSendModal}
                deleteFile={this.deleteFile}
                onChange={this.onChange}
                applicationId={this.state.selectedApplication}
                sendOffer={this.sendOffer}
            />
        );
    }

    viewMakeOfferModal(){

        let application = this.findApplication(this.state.selectedApplication);

        return (
            <MakeOfferModal
                toggleModal={this.toggleMakeOffer}
                open={this.state.openMakeOfferModal}
                title={strings.makeOffer.title}
                openDialog={this.openMakeOfferModal}
                deleteFile={this.deleteFile}
                contractType={this.state.data ? this.state.data.contractType : undefined}
                workPatterns={this.state.workPatterns}
                healthcare={this.state.healthcare}
                application={ application }
            />
        );
    }

    hrApproveModal() {

        let application = this.findApplication(this.state.selectedApplication);

        return (
            <HRApproveModal
                toggleModal={this.toggleHRApprove}
                open={this.state.openHRApprove}
                title={'HR Approval'}
                openDialog={this.toggleHRApprove}
                application={ application }
            />
        )
    }

    getPageHeader() {
        return <h1>{ strings.conditionalOffers.conditionalOffers }</h1>;
    }

    changeDataStatus(event, id) {

        let application = this.findApplication(id);
        
        let index = this.state.tableData.indexOf(application);

        if(index == -1) {
            return;
        }

        let data = this.state.tableData;
        data[index][event.target.name] = event.target.value.value ? event.target.value.value : event.target.value;

        this.setState({
            tableData: data
        });
    }

    renderOfferingManager(recruiter){

        if(!recruiter) {
            return;
        }

        return recruiter.firstName + ' ' + recruiter.lastName;
    }

    renderChangeStatusButton(id){

        let application = this.findApplication(id);

        return (
            <div className='change-status-button'>
                <Button 
                disabled={application.applicationInternalStatus != ApplicationInternalStatus.NEED_CONDITIONAL_OFFER}
                variant="contained" color="primary" onClick={() => this.toggleMakeOffer(id)}>
                    { strings.conditionalOffers.makeOffer }
                </Button>
            </div>
        );
    }

    doContractTypeNeedHRApproval(contractType) {

        for(let config of this.state.contractTypeConfigurations) {
            if(config.contractType === contractType && config.needHRApproval) {

                return true
            }
        }
        return false;
    }

    renderHRApprovalButton(item) {
        let application = this.findApplication(item.id);

        if(!this.doContractTypeNeedHRApproval(application.contractType)) {
            return '';
        }

        return (
            <div className='hr-approval-button'>
                <Button
                    disabled={application.applicationInternalStatus != ApplicationInternalStatus.NEED_CONDITIONAL_OFFER}
                    variant="contained" color="primary" onClick={() => this.toggleHRApprove(item.id)}>
                    { strings.conditionalOffers.hrApproval }
                </Button>
            </div>
        );
    }

    renderSendButton(id){

        let application = this.findApplication(id);

        this.validationList = getOfferValidationList(application);

        let errors = validate(application.offer, this.validationList);
        return (
            <div>
                {
                    application.applicationInternalStatus == ApplicationInternalStatus.NEED_CONDITIONAL_OFFER
                    ?
                        <div className='send-button'>
                            <Button variant="contained" disabled={ hasErrors(errors) || (application.offer.hrApprovalStatus !== HRApprovalStatus.APPROVED && this.doContractTypeNeedHRApproval(application.contractType))  } color="primary" onClick={() => this.toggleSendModal(id)}>
                                { strings.conditionalOffers.send }
                            </Button>
                        </div>
                    :
                        <div className='sent-button'>
                            <Button variant="contained" disabled color="primary">
                                { strings.conditionalOffers.sent }
                            </Button>
                        </div>
                }
            </div>
        );
    }

    renderTableHeader() {

        let result = [];

        for(let item of this.tableDescription) {

            result.push(
                <TableCell key={ 'table-header-' + result.length } >
                    { item.label }
                </TableCell>
            )
        }

        return (
            <TableHead className='table-header'>
                <TableRow>
                    { result }
                </TableRow>
            </TableHead>
        );
    }

    renderTableRow(data = []) {

        let result = [];

        for(let item of data) {

            let className = 'table-row';

            if(this.isRowSelected(item)) {
                className += ' selected';
            }

            result.push(
                <TableRow key={ 'table-row-' + result.length } className={ className } onClick={ () => this.selectRow(item) }>
                    { this.renderTableRowData(item) }
                    {/*{ this.renderRowMenu(result.length, item) }*/}
                </TableRow>
            )
        }

        return (
            <TableBody>
                { result }
            </TableBody>
        );
    }

    renderRowMenu(index, item) {

        let ariaOwns = 'action-menu-' + index;

        return(
            <TableCell>


            </TableCell>
        );
    }

    render() {
        let sendModal = this.viewSendModal();
        let makeOfferModal = this.viewMakeOfferModal();
        let hrApprovalModal = this.hrApproveModal();

        return (
            <div>
                <Grid id='conditional-offers-table-page'>
                    { this.renderDialog(strings.table.confirmDelete, 'To subscribe to this website, please enter your email address here. We will send\n' +
                        'updates occasionally.', this.cancelDelete, this.delete) }
                    <div className='header'>
                        { this.getPageHeader() }
                        <div className='filter-controls'>

                            {
                                this.state.showSearch &&
                                <div className={'filter-wrapper'}>
                                    <TextField
                                        label={ strings.table.search }
                                        type="search"
                                        name='search'
                                        value={ this.state.searchData.search }
                                        onChange={ this.searchChanged }
                                        variant={'outlined'}
                                    />
                                    <SearchIcon/>
                                </div>
                            }

                            {
                                this.state.showAdd &&
                                this.renderTableControls()
                            }
                        </div>
                    </div>
                    <Paper className={'table-wrapper'} md={12}>
                        { this.renderTable(this.state.tableData) }
                    </Paper>

                    <Drawer id='drawer' anchor='right' open={  this.showDrawer() } onClose={ () => this.setPageState(PageState.View) } >
                        <DrawerWrapper onBack={ () => this.setPageState(PageState.View) }>
                            { this.renderDrawerContent() }
                        </DrawerWrapper>
                    </Drawer>
                </Grid>
                {sendModal}
                {makeOfferModal}
                {hrApprovalModal}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen,
        setApplicationId: Actions.setApplicationId
    }, dispatch);
}

function mapStateToProps({ menuReducers, filterReducers, authReducers, siteDataReducers })
{
    return { menu: menuReducers, filter: filterReducers, user: authReducers.user, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(ConditionalOffers)));