import React from 'react';
import Home from './pages/Home';
import Error from "./pages/Error";
import Forbidden from "./pages/Forbidden";
import NotFound from "./pages/NotFound";
import { Route } from 'react-router-dom';
import { isUserLoggedIn } from "./base/OAuth";
import Login from "./pages/user/Login";
import Divisions from "./pages/user/Divisions";
import UserList from "./pages/admin/users/UserList";
import AddCandidate from './pages/admin/users/AddCandidate';
import AddCandidatePassword from './pages/user/AddCandidatePassword';
import CandidateProfile from './pages/candidateProfiles/CandidateProfiles';
import ConditionalOffers from "./pages/conditionalOffers/ConditionalOffers";
import CandidateApplications from "./pages/candidateApplications/CandidateApplications";
import Application from './pages/application/Application';
import ViewCandidates from './pages/viewCandidates/ViewCandidates';
import Contract from "./pages/contract/Contract";
import TermsAndConditions from "./pages/termsAndConditions/TermsAndConditions";
import ResetPassword from "./pages/user/ResetPassword";
import ResetPasswordRequest from './pages/user/ResetPasswordRequest';
import SystemSettings from "./pages/SystemSettings";
import SelfieGallery from "./pages/selfieGallery/SelfieGallery";
import AdminAlerts from './pages/adminAlerts/AdminAlerts';

let ROUTES = {
    Home: {
        path: '/',
        component: <Home/>,
        auth: true
    },
    Error: {
        path: '/error',
        component: <Error/>,
        auth: false
    },
    Forbidden: {
        path: '/forbidden',
        component: <Forbidden/>,
        auth: false
    },
    NotFound: {
        path: '/not-found',
        component: <NotFound/>,
        auth: false
    },
    Login: {
        path: '/login',
        component: <Login/>,
        auth: false
    },
    Divisions: {
        path: '/divisions',
        component: <Divisions/>,
        auth: false
    },
    UserList: {
        path: '/users',
        component: <UserList showFilter={ false }/>,
        auth: true
    },
    AddCandidate: {
        path: '/add-candidate',
        component: <AddCandidate/>,
        auth: true
    },
    AddCandidatePassword: {
        path: '/add-candidate-password',
        component: <AddCandidatePassword/>,
        auth: false
    },
    CandidateProfile: {
        path: '/candidate-profiles',
        component: <CandidateProfile/>,
        auth: true
    },
    ConditionalOffers: {
        path: '/conditional-offers',
        component: <ConditionalOffers/>,
        auth: true
    },
    CandidateApplications: {
        path: '/candidate-applications',
        component: <CandidateApplications/>,
        auth: true
    },
    Application: {
        path: '/application',
        component: <Application/>,
        auth: true
    },
    ViewCandidates: {
        path: '/view-candidate',
        component: <ViewCandidates/>,
        auth: true 
    },
    AdminAlerts: {
        path: '/admin-alerts',
        component: <AdminAlerts/>,
        auth: true 
    },
    SelfieGallery: {
        path: '/selfie-gallery',
        component: <SelfieGallery/>,
        auth: true
    },
    Contract: {
        path: '/contract',
        component: <Contract/>,
        auth: true
    },
    TermsAndConditions: {
        path: '/terms-and-conditions',
        component: <TermsAndConditions/>,
        auth: true
    },
    SystemSettings: {
        path: '/system-settings',
        component: <SystemSettings/>,
        auth: true
    },
    ResetPassword: {
        path: '/reset-password',
        component: <ResetPassword/>,
        auth: false
    },
    ResetPasswordRequest: 
    {
        path: '/reset-password-request',
        component: <ResetPasswordRequest/>,
        auth: false
    }
};

export default ROUTES;

function getRoute(path) {

    for(const [key, value] of Object.entries(ROUTES)) {

        if(value.path === path) {
            return value;
        }
    }

    return null;
}

export function checkPath(path) {

    let pathObject = getRoute(path);

    if(!pathObject) {
        return true;
    }

    if(pathObject.auth) {
        return !isUserLoggedIn();
    }

    return false;
}

export function getRoutes() {

    let result = [];

    for(const [key, value] of Object.entries(ROUTES)) {

        result.push(
            <Route key={ 'route-' + result.length } exact path={ value.path } render={() => (
                value.component
            )}/>
        )
    }

    return result;
}