import {makeParametersList, request} from "../base/HTTP";
import HttpMethod from "../constants/HttpMethod";
import CONFIG from '../config';


export async function getContract(id) {
    return await request('/api/contract/' + id, {}, HttpMethod.GET);
}

export async function declineContract(id) {
    return await request('/api/contract/decline/' + id, {}, HttpMethod.PUT);
}

export async function updateContract(data) {
    return await request('/api/contract', transformData(data), HttpMethod.PUT);
}

export async function changeContractMedicalConditionStatus(id, status) {
    return await request('/api/contract/medical-condition/' + id + '/' + status, {}, HttpMethod.PUT);
}

export async function changeContractCriminalRecordStatus(id, status) {
    return await request('/api/contract/criminal-record/' + id + '/' + status, {}, HttpMethod.PUT);
}

function transformData(data) {
    const confDiv = CONFIG.currentDivision;
    let currDivision;
    switch (confDiv) {
        case 'ss':
            currDivision = 0;
          break;
        case 'st':
            currDivision = 1;
          break;
        case 'sc':
            currDivision = 2;
          break;
        default:
            currDivision = 1;
          break;
      }
    return {
        ...data,
        otherWorkHours: parseFloat(data.otherWorkHours),
        currentDivision: currDivision
    }
}
