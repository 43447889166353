import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings({

    en: {

        systemSettings: {
            IDVTOverride: 'IDVT Override'
        },

        selfieGallery : {
            selfieGallery: 'Selfie Gallery',
            acceptSelfie: 'Accept Selfie',
            rejectSelfie: 'Reject Selfie',
            selfieView: 'Selfie view'
        },

        viewCandidates: {
            withdraw: 'Withdraw',
            archived: 'Archived',
            firstName: 'First name',
            lastName: 'Last name',
            email: 'Email',
            phone: 'Phone',
            created: 'Created',
            lastUpdate: 'Last update',
            stage: 'Stage',
            viewCandidates: 'View Candidates',
            resend: 'Resend',
            errorResendEmail: 'Error sending email',
            emailResend: 'Email sent',
            status: 'Status',
            reset: 'Reset',
            resetMessage: 'Are you sure you want to continue to reset the candidate\'s contract?',
            resetCandidate: 'Reset candidate'
        },
        adminAlerts: {
            textToScan: 'Text To Scan',
            alert: 'Alert',
            active: 'Active',
            createdDate: 'CreatedDate',
            errorSavingAdminAlert: 'Error saving candidate',
            adminalertSave: 'Admin Alert Saved',
            save: 'Admin Alert saved',
            viewadminalert: 'View Admin Alert'
        },

        navigation: {
            viewCandidate: 'View Candidate',
            adminAlert: 'Admin Alerts',
            selfieGallery: 'Selfie Gallery',
            recruitment: 'Recruitment',
            hr: 'HR',
            idvtOverride: 'IDVT Override'
        },

        uploadFileError: 'File too big. Please upload a file smaller than 10MB.',
        uploadFileErrorFileType: 'Only the following file types can be uploaded: *.jpg, *.jpeg, *.bmp, *.doc, *.docx, *.png, *.pdf, *.tiff, *.tif',

        application: {
            errorSavingApplican: 'Error saving candidate',
            candidatSaved: 'Candidate saved',
            gdprModalTitle: 'GDPR Confirm',
            gdprMessage: 'You have indicated that you disagree with the GDPR disclaimer and withdraw your application. Are you sure?',
            errorFinishingApplication: 'Error finishing application',
            success: 'Application finished',
            save: 'Application saved',
            yotiValidation: {
                title: 'Verify Your Name & Date of Birth',
                message: <p>We have picked up that the detail provided does not match.<br />Please verify your details</p>,
                allowOverride: 'Allow override',
                doNotOverride: 'Do not override',
                firstName: 'First Name',
                surname: 'Surname',
                dateOfBirth: 'Date of Birth'
            },
            yotiUnableValidation: {
                title: 'Unable to process application',
                message: <p>Unfortunalety, you will not be able to process your<br/>application.</p>,
                returnToManualProcess: 'Return to manual process',
                close: 'Close'
            }
        },

        home: {
            application: 'Application'
        },

        menu: {
            Home: 'Home',
            Products: 'Products',
            Services: 'Services',
            OnlineGoods: 'Online Goods',
            EmeraldDragon: 'Emerald Dragon',
            Features: 'Features'

        },

        table: {
            actions: 'Actions',
            view: 'View',
            edit: 'Edit',
            delete: 'Delete',
            confirmDelete: 'Confirm delete',
            no: 'No',
            yes: 'Yes',
            search: 'Search'
        },

        header: {
            lock: 'Lock',
            logout: 'Logout'
        },

        filter: {
            search: 'Search'
        },

        validation: {
            RequiredErrorMessage: 'required',
            MinLengthErrorMessage: 'Minimal length is ',
            MaxLengthErrorMessage: 'Maximal length is ',
            EmailErrorMessage: 'Please enter valid email',
            PasswordErrorMessage: 'Password must contain at least 6 letters, one upper case, one lower case and one number.',
            UserExistsErrorMessage: 'User with this email address already exists',
            OldPasswordDidNotMatch: 'Old password did not match',
            PasswordsNotEqual: 'Passwords do not match',
            notNumber: 'Not number'
        },

        notFound: {
            notFound: 'Not found!',
            dashboard: 'Dashboard'
        },

        forbidden: {
            forbidden: 'Forbidden!',
            dashboard: 'Dashboard'
        },

        error: {
            error: 'Error!',
            dashboard: 'Dashboard'
        },

        login: {
            email: 'Email',
            password: 'Password',
            login: 'Login',
            wrongCredentials: 'Wrong Credentials',
            forgotPassword: 'Forgot your password? ',
            now: ' now',
            privacyPolicy: 'Privacy Policy',
            termsAndConditions: 'Terms & Conditions',
            cookies: 'Cookies'
        },

        addCandidate: {
            email: 'Email',
            firstName: 'First Name',
            lastName: 'Last Name',
            addCandidate: 'Add Candidate',
            wrongCredentials: 'Wrong Credentials',
            success: 'Successful',
            role: 'Role'
        },

        addCandidatePassword:{
            password: 'Password',
            confirmPassword: 'Confirm Password',
            addPassword: 'Add Password',
            candidate: 'Candidate Name'
        },

        lock: {
            password: 'Password',
            login: 'Login',
            wrongCredentials: 'Wrong Credentials',
            unlock: 'Unlock'
        },

        userList: {
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'Email',
            isDeleted: 'Is deleted',
            dateCreated: 'Date Created',
            pageTitle: 'Users',
            enabled: 'Enabled',
            userDelete: 'User deleted',
            userRestored: 'User restored'
        },

        userForm: {
            email: 'Email',
            firstName: 'First name',
            lastName: 'Last name',
            ok: 'Ok',
            cancel: 'Cancel'
        },

        addUser: {
            pageTitle: 'Add user',
            errorAddClub: 'Error adding user',
            clubAdded: 'User added',
            errorAddingUser: 'E-mail address already in use',
            userAdded: 'User added'
        },

        resetPassword: {
            email: 'Email',
            resetPassword: 'Reset your password',
            password: 'Password',
            passwordRepeat: 'Password repeat',
            resetPasswordSend: 'Password reset link sent'
        },

        candidateProfile:{
            withdrawConfirm: <p>You are about to <b>WITHDRAW</b> your application. Are you sure you wish to <b>WITHDRAW</b>?</p>,
            withdraw: 'My circumstances have changed. Please withdraw my application',
            back: 'Back',
            update: 'Update',
            additionalInfoRequired: 'Additional info required',
            candidateProfile: 'Candidate Profile',
            personalDetails: 'Personal Details',
            addressDetails: 'Address Details',
            emergencyContact: 'Emergency Contact',
            jobDetails: 'Job Details',
            documents: 'Documents',
            ukNationals: 'Territory of Work',
            rightToWork: 'Right To Work',
            rightToWorkNonUK: 'Right To Work (RTW)',
            gdprDisclaimer: 'GDPR Disclaimer',
            immigrationControl: 'Immigration Control',
            submitApplication: 'Submit Application',
            errorUpdateStatuse: 'Error update statuse',
            statusUpdated: 'Status updated',
            save: 'Save for later'
        },

        contract: {
            rehabilitation: 'Rehabilitation of Offenders',
            signatureError: 'Please sign contract',
            contractDeclined: 'Contract declined',
            contract: 'Contract',
            personalDetails: 'Personal Details',
            bankDetails: 'Bank Details',
            employment: 'Employment',
            uniform: 'Uniform',
            medicalDeclaration: 'Medical Declaration',
            workingTimeElection: 'Working Time Election',
            changesInInformation: 'Changes In Information',
            termsAndConditions: 'Acceptance of T&Cs',
            digitalSignature: 'Digital Signature',
            errorSavingContract: 'Error saving contract',
            contractSaved: 'Contract saved',
            thankYou: 'Thank you for accepting the terms & conditions offered to you.',
            message: 'Your application has now be passed to our Human Resources team for final review, we will be in contact in due course.'
        },

        bankDetails: {
            bic: 'BIC number',
            informationRestrictedToHRonly: 'Information restricted to HR only',
            title: 'Bank Details',
            bankName: 'Bank Name',
            bankAccountNumber: 'Bank Account Number',
            accountHolderName: 'Account Holder Name',
            bankSortCode: 'Bank Sort Code',
            iban: 'IBAN',
            proofOfAccountDetails: 'Proof Of Account Details',
            uploadFiles: 'Upload Files',
            bankDetailsConfirmation: 'Bank Detail Confirmation',
            bankDetailsMessage: 'Please confirm that you have checked the above entered bank details and accept that you understand the company accepts no liability for mis-directed payments if you input your bank details incorrectly.\n'
        },

        employment: {
            employment: 'Employment',
            employmentQuestion: 'Is this your only employment?',
            yes: 'Yes, this is my only employment',
            no: 'No, this is not my only employment',
            previousEmployment: 'Previous Employment',
            previousEmploymentQuestion: 'Have you previously worked for Retail & Asset Solutions?',
            previousYes: 'Yes, I have worked for the company',
            previousNo: 'No, I have never worked for the company',
            employmentNoQuestion: 'If no, please give details of other hours worked',
            employmentDocument: 'If available, please upload your P45',
            uploadFiles: 'Upload files',
            numberOfHoursPlaceholder: 'Please enter number of hours',
            previousEmploymentComment: 'If Yes, please give details and reason for leaving',
            enterText: 'Enter text'
        },

        uniform: {
            uniform: 'Uniform',
            uniformQuestion: 'We use a third-party supplier to provide our uniform. Please confirm your approval for your home address to be provided to them in order for your uniform to be sent by them directly.\n',
            yes: 'Yes, I provide my approval',
            no: 'No, I do not provide my approval'
        },

        medicalDeclaration: {
            rehabilitationNotAllowed: 'Information restricted to HR only',
            medicalDeclarationNotAllowed: 'Information restricted to HR only',
            medicalDeclaration: 'Medical Declaration',
            medicalDeclarationQuestion: 'Do you have any medical conditions that you wish to declare, which may require the company to consider making reasonable adjustments?\n',
            medicalDeclarationYes: 'If Yes, please give details of medical conditions',
            enterText: 'Enter text',
            rehabilitation: 'Rehabilitation of Offenders Act 1974',
            rehabilitationQuestion: 'In relation to the Rehabilitation of Offenders Act 1974 have you ever been convicted or found guilty by a Court of any offence in any country which are unspent (not including parking & speeding)\n',
            rehabilitationYes: 'If Yes, please give details'
        },

        workingTimeElection: {
            workingTimeElection: 'Working Time Election',
            workingTimeElectionQuestion: 'Please tick one of the boxes below and sign and date this form. We are unable to offer you any work until we have received this form. You may terminate this agreement by giving three months\' written notice at any time.\n',
            workingTimeElectionYes: 'I wish to opt-out:',
            workingTimeElectionNo: 'I do not wish to opt-out:',
            workingTimeElectionLabel: 'of the 48-hour working week restriction under the Working Time Regulations 1998.'
        },

        changesInInformation: {
            changesInInformation: 'Changes in Information',
            changesInInformationQuestion: 'It is your responsibility to keep the Company informed of any changes to this information.',
            yes: 'Yes, I understand the requirement',
            no: 'No, I do not understand the requirement'
        },

        termsAndConditions: {
            termsAndConditions: 'Terms & Conditions',
            selectTerms: 'Select Terms & Conditions to read and accept terms',
            mainTitle: 'Terms of Reference for the Provision of Casual Worker Services\n',
            accept: 'Accept',
            decline: 'Decline',
            buttonLabel: 'the T&C of employment as outline above',
            termsAndConditionsOfEmployment: 'Terms & Conditions of Employment',
            acceptMessage: 'You are about to ACCEPT the T&Cs. Are you sure you wish to ACCEPT them?',
            declineMessage: 'You are about to DECLINE the T&Cs. Are you sure you wish to DECLINE them?',
            acceptTerms: 'Yes, I ACCEPT the Terms & Conditions',
            declineTerms: 'No, I DECLINE the Terms & Conditions'
        },

        digitalSignature: {
            digitalSignature: 'Digital Signature',
            clear: 'Clear',
            accept: 'Accept and Sign',
            digitalSignatureMessage: 'By signing this document with an electronic signature, I agree that such signature will be as valid as handwritten signatures. \n'
        },

        candidateProfiles: {
            automaticCheck: 'Automatic Check',
            GDPRNo: 'We are sorry that you are unable to accept the GDPR Disclaimer. We will now update your profile to indicate that you no longer wish to pursue this opportunity and change your application status to withdrawn.',
            name: 'Name',
            candidateProfiles: 'Candidate Profiles',
            firstName: 'First Name',
            surname: 'Surname',
            division: 'Division',
            region: 'Region',
            recruiterName: 'Recruiter Name',
            role: 'Role',
            applicationDate: 'Application Date',
            sessionType: 'Session Type',
            sessionLocation: 'Session Location',
            shareCodeNo: 'Share Code No.',
            IDVTComments: 'IDVT Comments',
            upload: 'Upload',
            uploadFiles: 'Upload Files',
            docViewer: 'View Documents',
            historyViewer: 'History Documents',
            viewApplicant: 'View Applicant',
            viewDocuments: 'View Documents',
            rtwStatus: 'RTW Status',
            contractType: 'Contract Type',
            applicationStatus: 'Application Status',
            status: 'Status',
            confirm: 'Confirm',
            confirmTitle: 'Verify copy of Right to Work Documents',
            confirmMessageAcceptPhysical: 'To the best of my knowledge, I can confirm these are true and accurate copies of the candidate\'s original documents and I have had visibility of these original documents',
            confirmMessageAcceptVirtual: 'You are about to ACCEPT this applicants Right to Work documents are you sure you wish to ACCEPT the documents',
            confirmMessageReject: 'You are about to REJECT this applicants Right to Work documents are you sure you wish to REJECT the documents',
            thankYou: 'Thank you',
            message: 'Your application has been submitted for review by the Recruitment and HR team.',
            addInfoRequired: 'Additional Information Required',
            addInfoRequiredMessage: 'Additional information is required to be updated by the candidate. Please enter items required to be updated or uploaded.\n' +
                '\n' +
                'The application will be reset and you will receive a notification once the candidate has updated their profile to review. \n',
            send: 'Send',
            withdrawMessage: 'Please select email to be sent to candidate',
            candidateWithdrawal: 'Candidate Withdrawal',
            roleNoLongerAvailable: 'Role no longer available'
        },

        conditionalOffers: {
            hrApproval: 'HR approval',
            conditionalOffers: 'Conditional Offers',
            firstName: 'First Name',
            surname: 'Surname',
            division: 'Division',
            region: 'Region',
            contractType: 'Contract Type',
            changeStatus: 'Change Status',
            makeOffer: 'Make Offer',
            send: 'Send Offer',
            sent: 'Offer Sent',
            offeringManager: 'Offering Manager',
            title: 'Send Offer',
            sendOfferMessage: 'You are about to SEND OFFER to this applicant. Are the approvals in place and can you verify that the candidate met during the recruitment process closely resembles the candidate\'s photograph/s within their Right to Work documents?\n'
        },

        makeOffer: {
            jobRole: 'Job role',
            currency: 'Currency',
            commission: 'Commission',
            bonus: 'Bonus',
            title: 'Make Offer',
            firstName: 'First Name',
            surname: 'Surname',
            division: 'Division',
            region: 'Region',
            contractType: 'Contract Type',
            startDate: 'Start Date',
            continuousStartDate: 'Continuous Start Date',
            workPattern: 'Work Pattern',
            rateOfPay: 'Rate Of Pay',
            annualSalary: 'Annual Salary',
            hoursPerWeek: 'Hours per week',
            carAllowance: 'Car Allowance',
            holidayEntitlement: 'Holiday Entitlement',
            mileageRate: 'Mileage Rate',
            contractedHoursPerMonth: 'Contracted Hours Per Week',
            additionalBenefits: 'Additional Benefits',
            unpaidBreak: 'Unpaid Break',
            carAllowancePerMonth: 'Car Allowance Per Month',
            pension: 'Pension',
            rateOfPayPerHalfDay: 'Rate Of Pay Per Half Day',
            healthcare: 'Healthcare',
            pleaseSelect: 'Please select',
            done: 'Save'
        },

        hrManager: {
            visaLimit: 'Visa limit',
            update: 'Update',
            visaType: 'Visa type',
            visaNumber: 'Visa number',
            commission: 'Commission',
            bonus: 'Bonus',
            status: 'Status',
            contractedHoursPerMonth: 'Contracted hours per week',
            contract: 'Contract',
            errorUpdatingStatus: 'Error updating status',
            statusUpdated: 'Status updated',
            applicationConfirmed: 'Document check changed',
            errorConfirmApplication: 'Error confirm application',
            candidateApplications: 'Candidate Applications',
            firstName: 'First Name',
            lastName: 'Last Name',
            division: 'Division',
            region: 'Region',
            recruiterName: 'Recruiter Name',
            role: 'Role',
            applicationDate: 'Application Date',
            sessionType: 'Session Type',
            viewRTW: 'View RTW',
            viewOffer: 'View Offer',
            viewApplicant: 'View Applicant',
            pastEmploymentDeclaration: "E'ee past employment declaration",
            docCheck: 'Doc Check Done',
            payrollID: 'Payroll ID No.',
            criminalRecord: 'Criminal record declared',
            healthCondition: 'Health condition declared',
            applicationStatus: 'Application Status',
            comments: 'Comments',
            enterComments: 'Enter comments',
            done: 'Done',
            confirm: 'Confirm',
            fullViewRTW: 'View Right to Work',
            niNumber: 'NI Number',
            proofOfIdentity: 'Proof Of Identity',
            proofOfAddress: 'Proof Of Address',
            documentType: 'Document Type',
            nationality: 'Nationality',
            view: 'View',
            contractType: 'Contract Type',
            workPattern: 'Work Pattern',
            hoursPerWeek: 'Hours per week',
            healthcare: 'Healthcare',
            surname: 'Surname',
            startDate: 'Start Date',
            annualSalary: 'Annual Salary',
            carAllowance: 'Car Allowance',
            rateOfPayPerHalfDay: 'Rate Of Pay Per Half Day',
            holidayEntitlement: 'Holiday Entitlement',
            pension: 'Pension',
            rateOfPay: 'Rate of Pay',
            continuousStartDate: 'Continuous Start Date',
            mileageRate: 'Mileage Rate',
            carAllowancePerMonth: 'Car Allowance Per Month',
            unpaidBreak: 'Unpaid Break',
            additionalBenefits: 'Additional Benefits',
            edit: 'Edit Candidate Profile',
            title: 'Title',
            gender: 'Gender',
            dateOfBirth: 'Date of Birth',
            email: 'E-mail Address',
            firstLineAddress: '1st Line Address',
            secondLineAddress: '2nd Line Address',
            townCity: 'Town / City',
            postcode: 'Postcode',
            primaryContact: 'Primary Contact No.',
            secondaryContact: 'Secondary Contact No.',
            emergencyContact: 'Emergency Contact No.',
            jobRole: 'Job Role / Job Title',
            sessionLocation: 'Session Location',
            travelPreference: 'Travel Preference',
            candidateRejection: 'Are you sure you want to reject a candidate',
            rejectionReasons: 'Enter reason for rejecting a candidate',
            rejectMessage: 'You are about to REJECT a candidate. Are you sure you wish to REJECT a candidate?'
        },

        personalDetails:{
            personalDetails: 'Personal Details',
            title: 'Title',
            forename: 'First Name and middle name/s exactly as they appear on your Right To Work Documents',
            surname: 'Surname exactly as it appears on your Right To Work Documents',
            gender: 'Gender',
            email: 'E-mail Address',
            secondaryEmail: 'Secondary E-mail Address',
            dateOfBirth: 'Date of Birth',
            dateOfBirthPlaceholder: 'DD/MM/YYYY',
            country: 'Country'
        },

        addressDetails: {
            postcode: 'Postcode',
            firstLineAddress: '1st Line Address',
            secondLineAddress: '2nd Line Address',
            townCity: 'Town / City',
            firstPhoneContact: 'Phone Contact Mobile',
            secondPhoneContact: 'Phone Contact Landline',
            mobileLandline: ''
        },

        emergencyContact: {
            emergencyFullName: 'Full Name',
            emergencyRelationshipToYou: 'Relationship to you',
            emergencyFirstPhoneContact: 'Phone Contact Mobile',
            emergencySecondPhoneContact: 'Phone Contact Landline',
            mobileLandline: '',
            emergencyEmail: 'E-mail Address'
        },

        jobDetails: {
            bringBuddy: <p style={{margin: 0}}>If you were referred to RAS by an existing colleague,<br/> please add their name</p>,
            division: 'Division',
            region: 'Region',
            recruiter: 'Recruiter Name',
            job: 'Job Role / Job Title',
            sessionType: 'Session Type',
            sessionLocation: 'Session Location',
            travelPreference: 'Travel Preference',
            minibusName: 'Minibus Name',
            pickupPointOne: 'Pickup Point',
            pickupPointTwo: 'Pickup Point',
            placeOfWork: 'Place of work'
        },

        documents: {
            passportPhoto: 'Passport Style Photo',
            uploadFiles : 'Upload files',
            uploadDocuments: 'Upload Documents',
            dragAndDrop: 'Drag & Drop to upload files',
            browse: 'Browse my computer',
            done: 'Done',
            myAttachments: 'My Attachments',
            documentType: 'Document Type',
            viewDocuments: 'View Documents'
        },

        histories: {
            viewHistories: 'View Histories'
        },

        ukNationals: {
            proofOfPPS: 'Proof of PPS',
            territoryOfWork: 'Territory Of Work - UK or Ireland',
            nINo: 'NI Number',
            ppsNumber: 'PPS Number',
            dateOfBirth: 'Date Of Birth',
            proofOfIdentity: 'Proof Of Identity',
            proofOfAddress: 'Proof of Address',
            proofOfNI: 'Proof of NI',
            nationality: 'Nationality',
            fullUKDrivingLicence: 'Full UK Driving Licence',
            roiDrivingLicence: 'ROI Driving Licence',
            uploadFiles : 'Upload files',
            uploadSelfie : 'Upload selfie',
            ukOrRoiCitizen: 'Are you a UK or ROI Citizen?',
            roiCitizen: 'Are you a ROI citizen?',
            inDatePassport: 'Do you have an in-date Passport?',
            yotiValidationMessageFailed: 'You have not passed YOTI verification, continue manual ID process.'
        },

        rightToWork: {
            forUK: 'FOR UK',
            forIreland: 'FOR IRELAND',
            ukShareCode: 'Share Code',
            visaDocuments: 'Visa Documents',
            ukCitizenship: 'RTW Supporting Documents',
            documentUpload: 'Document Upload',
            uploadFiles : 'Upload files',
            irExpireDate: 'Visa Expiry Date',
            irExpireDatePlaceholder :'DD/MM/YYYY'
        },

        gdprDisclaimer: {
            gdprDisclaimer: 'You acknowledge that we and/or our third-party representatives may utilise your personal data in furtherance of this scheme. You appreciate that we may collect, process, and retain data securely, on paper or electronically, in support of your potential participation. You confirm our right to such data for legitimate purposes when obtained in a lawful, fair, and transparent manner. You are aware that your personal data is retained confidentially, with strictly restricted access and only for as long as necessary. We do not retain personal data that is incorrect or no longer required for a relevant purpose. You may advise us of any personal data you believe we possess which no longer fulfils an appropriate purpose. Should we believe we still have a legitimate interest in maintaining such data we will advise you. You may raise any remaining concerns with the appropriate supervisory authority, normally the Office of the Information Commissioner in the UK.',
            yes: 'Yes, I understand and agree',
            no: 'No, I disagree and withdraw my application',
            agree: "You agreed, you can submit the application",
            disagree: "You disagreed, you are unable to submit the application"
        },

        immigrationControl: {
            yes: 'YES',
            no: 'NO',
            yesOption: 'If Yes, please provide date when you remain in the UK until',
            immigrationControlQuestion: 'Are you subject to immigration control or are there any restrictions on the length of your stay in the UK?',
            immigrationControlDate: 'Select Date'
        },

        filters: {
            status: 'RTW Status',
            status_all: 'Application Status',
            position: 'Position',
            location: 'Location',
            rtwCheck: 'RTW Check',
            division: 'Division',
            region: 'Region',
            role: 'Role',
            contractType: 'Contract Type',
            recruiter: 'Recruiter',
            clear: 'Clear',
            search: 'Search candidates',
            stage: 'Stage',
            from: 'From',
            to: 'To',
            archived: 'Archived',
            medicalConditionStatus: 'Medical condition',
            criminalRecordStatus: 'Criminal record',
            selfieStatus: 'Status'
        }
    }
});

export default strings;
