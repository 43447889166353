import HoursPerWeek from "./HoursPerWeek";

const Hours = {
    0: 0,
    16: 16,
    20: 20,
    22: 22,
    22.5: 22.5,
    24: 24,
    25: 25,
    28: 28,
    32: 32,
    37.5: 37.5,
    40: 40,
    45: 45,
    48: 48
}

export default Hours;

export function getHours() {
    return [
        {
            value: Hours["0"],
            name: '0'
        },
        {
            value: Hours["16"],
            name: '16'
        },
        {
            value: Hours["20"],
            name: '20'
        },
        {
            value: Hours["22"],
            name: '22'
        },
        {
            value: Hours["22.5"],
            name: '22.5'
        },
        {
            value: Hours["24"],
            name: '24'
        },
        {
            value: Hours["25"],
            name: '25'
        },
        {
            value: Hours["28"],
            name: '28'
        },
        {
            value: Hours["32"],
            name: '32'
        },
        {
            value: Hours["37.5"],
            name: '37.5'
        },
        {
            value: Hours["40"],
            name: '40'
        },
        {
            value: Hours["45"],
            name: '45'
        },
        {
            value: Hours["48"],
            name: '48'
        },
    ]
}