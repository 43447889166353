import React, {Component} from 'react';
import {Dialog, DialogContent, DialogTitle, Divider, Slide} from "@material-ui/core/";
import CloseIcon from '@material-ui/icons/Close';
import {Button, IconButton, LinearProgress, Paper, TextField} from "@material-ui/core";
import strings from "../localization";
import {bindActionCreators} from "redux";
import * as Actions from "../actions/Actions";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import UserType from "../constants/UserType";
import {transformDate} from "../util/DateUtil";
import {findDataValue} from "../util/DropdownUtil";
import { editAdminAlert, getAdminAlert } from '../services/AdminAlertService';
import Page from './Page';
import ApplicationModes from '../constants/ApplicationMode';
import Checkbox from '@material-ui/core/Checkbox';


class ViewAdminAlertsModal extends Page {

    constructor(props) {
        super(props);

        this.state = {
            application: {},
            data: {},
            files: [],
            showProgressBar: false,
            isActive: true
        }
       
    }

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="left" ref={ref} {...props} />;
    });

    componentDidMount() {
        if(!this.props.application) {
            return;
        }

        this.fetchApplication(this.props.application.id);
    }

    componentWillReceiveProps(props) {
        if(props.open) {
           
        }

        if(!props.application) {
            return;
        }

        this.fetchApplication(props.application.id);
    }
    fetchApplication(id) {
        getAdminAlert(id).then(response => {
            if(!response || !response.ok) 
            {
                return;
            }

            this.setState({
                application: response.data,
                data: response.data,
                files: []
            });
        });
    }

    handleCartsButtonClick(response) {
        if (!response || !response.ok) {
            this.props.enqueueSnackbar(strings.adminAlerts.errorSavingAdminAlert, { variant: 'error' });
            return;
        }

        this.props.enqueueSnackbar(strings.adminAlerts.save, { variant: 'success' });
        return;
    }

    edit() {

        this.props.setReturnUrl(window.location.pathname);
        
        this.props.history.push({
            pathname: '/adminalert',
            state: { applicationId: this.props.id, returnUrl: window.location.pathname }
        })
    }
    update() {
        editAdminAlert(this.state.data, ApplicationModes.SAVE).then(response => {

            if (!response || !response.ok) {
                this.props.enqueueSnackbar(strings.adminAlerts.errorSavingAdminAlert, { variant: 'error' });
                return;
            }
            this.props.enqueueSnackbar(strings.adminAlerts.adminalertSave, { variant: 'success' });
            this.props.toggleModal();
            window.location.reload(false);
        });
    }
    updateActiveProperty = (event) => {
        // Create a new object using the spread operator to copy existing properties
        const newData = { ...this.state.data };
    
        // Update the 'active' property in the new object
        newData.active = event == true ? 1 : 0; // for example, toggle the boolean value
    
        // Set the updated object back in the state
        this.setState({
          data: newData,
        });
      };
    render() {
        if(!this.props.application) {
            return '';
        }
        return (
            <Dialog
                onClose={() => this.props.toggleModal(this.props.id)}
                aria-labelledby="customized-dialog-title"
                open={this.props.open}
                TransitionComponent={this.Transition}
                className={'view-applicant-modal view-admin-alert-modal'}
            >
                <div className='form-modal-header' onClose={() => this.props.toggleModal(this.props.application.id)}>
                    <div>
                        <h1>{this.props.title}</h1>
                        <IconButton className={'close-button-wrapper'} onClick={this.props.toggleModal}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <Divider />
                    {
                        this.state.showProgressBar &&
                        <LinearProgress/>
                    }
                    <div className={'view-applicant-wrapper'}>
                        <div className={'content-wrapper'}>
                            { !this.state.showProgressBar }
                            <div className={'left-wrapper'}>
                                <div className={'left'}>
                                    <label>{strings.adminAlerts.textToScan}</label>
                                    <div className={'inner-left'}>
                                        <TextField
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                            name='textToScan'
                                            onChange={ this.changeData }
                                            margin="dense"
                                            value={ this.state.data.textToScan }
                                            variant='outlined'
                                            disabled={ !(this.props.auth.user.userType == UserType.HR_MANAGER ||
                                                this.props.auth.user.userType == UserType.HR_RECRUITMENT) }
                                        />
                                    </div>
                                </div>
                               
                                <div className={'left'}>
                                    <label>{strings.adminAlerts.alert}</label>
                                    <div className={'inner-left'}>
                                    <TextField
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                            name='alert'
                                            onChange={ this.changeData }
                                            margin="dense"
                                            value={ this.state.data.alert }
                                            variant='outlined'
                                            disabled={ !(this.props.auth.user.userType == UserType.HR_MANAGER ||
                                                this.props.auth.user.userType == UserType.HR_RECRUITMENT) }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={'right-wrapper'}>
                                 <div className={'right'}>
                                    <label>{strings.adminAlerts.active}</label>
                                    <div className={'inner-right'}>
                                         <Checkbox
                                          checked={this.state.data.active == 1}
                                          onChange={e => {this.updateActiveProperty(e.target.checked) }}
                                          inputProps={{
                                          'aria-label': 'secondary checkbox',
                                            }}
                                        />
                                        </div>
                                </div>
                                <div className={'right'}>
                                    <label>{strings.adminAlerts.createdDate}</label>
                                    <div className={'inner-right'}>
                                    {this.props.application ? transformDate(this.props.application.createdDate) : ''}
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div className={'buttons'}>
                            {
                                <div>
                                    <Button className={'done-button'} variant="contained" color="primary" style={{ marginLeft: '10px' }}
                                     onClick={ () => this.update() }
                                     disabled={ !(this.props.auth.user.userType == UserType.HR_MANAGER ||
                                        this.props.auth.user.userType == UserType.HR_RECRUITMENT) }>
                                        { strings.hrManager.update }
                                    </Button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen,
        setReturnUrl: Actions.setReturnUrl
    }, dispatch);
}

function mapStateToProps({ menuReducers, filterReducers, authReducers })
{
    return { menu: menuReducers, filter: filterReducers, auth: authReducers};
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewAdminAlertsModal)));
