const CriminalRecordStatus = {
    ALL: -1,
    NON_DECLARED: 1,
    SELF_DECLARED: 2,
    CRD_UNSUITABLE: 3,
    HR_APPROVED: 4
};

export function getCriminalRecordStatuses() {
    return [
        {
            value: CriminalRecordStatus.NON_DECLARED,
            name: 'Non Declared'
        },
        {
            value: CriminalRecordStatus.SELF_DECLARED,
            name: 'Self Declared'
        },
        {
            value: CriminalRecordStatus.CRD_UNSUITABLE,
            name: 'CRD Unsuitable'
        },
        {
            value: CriminalRecordStatus.HR_APPROVED,
            name: 'HR Approved'
        }
    ]
}

export function getAllCriminalRecordStatuses() {
    return [
        {
            value: CriminalRecordStatus.ALL,
            name: 'All'
        },
        {
            value: CriminalRecordStatus.NON_DECLARED,
            name: 'Non Declared'
        },
        {
            value: CriminalRecordStatus.SELF_DECLARED,
            name: 'Self Declared'
        },
        {
            value: CriminalRecordStatus.CRD_UNSUITABLE,
            name: 'CRD Unsuitable'
        },
        {
            value: CriminalRecordStatus.HR_APPROVED,
            name: 'HR Approved'
        }
    ]
}

export default CriminalRecordStatus;