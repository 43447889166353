import React from 'react'
import CONFIG from "../../config";
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import Page from "../../common/Page";

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

class Divisions extends Page {

    constructor(props) {
        
        super(props);
        this.props.changeFullScreen(true);
        this.state = {
            data: {},
            errors: {},
            redirectUrl: props.location.state ? props.location.state.redirectUrl : '/'
        };

    }

    componentDidMount() {
        if(this.props.auth.user) {
            this.props.history.push('/');
        }
    }

    render() {

        return (

            <div id='Divisions'>
      <div className="DivisionsMain" style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      
      <div className="grid-container">
      <h1>Please select your division.</h1>
        <div className="dcard stk">
          <a href={CONFIG.divisonStockTakingURL + '/login?div=st'}>
            <img src="./images/stdiv.svg" className="sharedspace" alt="Retail Logo" />
            <img src="./images/Pharmacy.svg" className="sharedspace" alt="Pharmacy Logo" />
          </a>
        </div>
        <div className="dcard ss">
          <a href={CONFIG.divisonStoreSupportURL + '/login?div=ss'}>
            <img src="./images/ssdiv.svg" className="divisionallogo" alt="SS Logo" />
          </a>
        </div>
        <div className="dcard sci">
          <a href={CONFIG.divisonSupplyChainURL + '/login?div=sc'}>
            <img src="./images/scdiv.svg" className="divisionallogo" alt="SCI Logo" />
          </a>
        </div>
      </div>
      
    </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen,
        changeMenuState: Actions.changeMenuState
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, auth: authReducers };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Divisions));