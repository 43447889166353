import React from 'react';
import strings from "../../../localization";
import {getError, hasError} from "../../../functions/Validation";
import {Button, TextField, Divider } from "@material-ui/core";
import DatePickerControl from '../../controls/DatePickerControl';
import Grid from '@material-ui/core/Grid';
import {findDataValue} from "../../../util/DropdownUtil";
import UploadSection from '../../../constants/UploadSection';
import CustomFileList from '../../../common/CustomFileList';
import Territories, {getTerritoriesString} from "../../../constants/TerritoriesOfWork";


const RightToWorkForm = ({
                      onChange,
                      errors,
                      data,
                      onModalOpen,
                      onDelete,
                      ukVisaDoc,
                      ukCitizenshipDoc,
                      disable
                  }) => {

    return (
        <form id='right-to-work-form'>
            <Grid container spacing={9} className={'grid-wrapper'}>
                <Grid item xl={4} lg={5} md={12}>
                    {
                        (findDataValue(data, 'territoryOfWork') === 'UK') &&
                        <div className={ 'select-container' + (hasError(errors, 'ukShareCode') ? ' error' : '') }>
                            <label>{ strings.rightToWork.ukShareCode }</label>
                            <div className='tooltip-control-container'>
                                <TextField
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={ hasError(errors, 'ukShareCode') }
                                    helperText={ getError(errors, 'ukShareCode') }
                                    fullWidth
                                    name='ukShareCode'
                                    onChange={ onChange }
                                    value={ data.ukShareCode }
                                    margin="dense"
                                    variant='outlined'
                                    disabled={ disable }
                                    placeholder={findDataValue(data, 'territoryOfWork') === getTerritoriesString(Territories.UK) ? '9XY-6GY-2S2' : ''}
                                />
                                <div className={'tooltip right'}>
                                    <img src={'/images/info.png'} />
                                    <span className="tooltip-content">
                                        <p>The Share Code should be in the following format:</p>
                                        <ul>
                                            <li>9XY-6GY-2S2 (capital letters)</li>
                                        </ul>
                                        <p>Please enter "MANUAL-SC" as a Share Code, to be directed to the Manual ID Process</p>
                                    </span>
                                </div>
                            </div>
                        </div>
                    }
                    <div className={ 'select-container-upload' }>
                        <label>{ strings.rightToWork.visaDocuments }</label>
                        <div className="open-modal-button">
                            <Button disabled={ disable } variant="contained" color="primary" onClick={() => {onModalOpen(UploadSection.Visa_Documents)}}>
                                { strings.documents.uploadFiles }
                            </Button>
                            <div className={'tooltip right'}>
                                <img src={'/images/info.png'} />
                                <span className="tooltip-content">
                                    <p>Documents to upload for your Visa:</p>
                                    <ul>
                                        <li>Visa</li>
                                        <li>Overseas Passport with cover.</li>
                                    </ul>
                                    
                                    <p>Please ensure that the documents are clear and legible. If you are uploading documents with photos, use a clear image of your face. If we are not able to review documents due to the quality of the uploads, this might delay your application.</p>
                                </span>
                            </div>
                        </div>
                    </div>
                    {
                        ukVisaDoc.length > 0 &&
                        <CustomFileList onDelete={onDelete} files={ukVisaDoc} disable={ disable } uploadSection={ UploadSection.Visa_Documents } />
                    }
                    <Divider />
                    <div className={ 'select-container-upload' }>
                        <label>{ strings.rightToWork.ukCitizenship }</label>
                        <div className="open-modal-button">
                            <Button disabled={ disable } variant="contained" color="primary" onClick={() => {onModalOpen(UploadSection.UK_Citizenship_Documents)}}>
                                { strings.documents.uploadFiles }
                            </Button>
                            <div className={'tooltip right'}>
                                <img src={'/images/info.png'} />
                                <span className="tooltip-content">
                                    <p>Documents you can upload as your Right to Work documents and additional requirements:</p>
                                    <ul>
                                        <li>Passport: Require copy of cover as well.</li>
                                        <li>National Identity Card: Require copies of front and back.</li>
                                        <li>Permanent Residence: In your current Overseas Passport with cover.</li>
                                        <li>Biometric Immigration Document: Require front and back, and Overseas Passport with cover</li>
                                        <li>Immigration Status Document: Only if it has your visa on it. Require front and back of your Visa, Overseas Passport with cover</li>
                                        <li>Long / Short Birth Certificate</li>
                                        <li>Adoption Certificate</li>
                                        <li>Certificate of Registrationv</li>
                                        <li>Certificate of Naturalisation</li>
                                        <li>Residence Card: Require front and back, Overseas Passport with cover</li>
                                        <li>Certificate of Application / Home Office Letter: Must have reference number or case ID on the document, and Overseas Passport with cover.</li>
                                        <li>Application Registration Card: Require front and back, Overseas Passport with cover.</li>
                                        <li>Positive Verification Document: Require Overseas Passport with cover</li>
                                        
                                    </ul>
                                    <p>Please ensure that the documents are clear and legible. If we are not able to review documents due to the quality of the uploads, this might delay your application.</p>
                                </span>
                            </div>
                        </div>
                    </div>
                    {
                        ukCitizenshipDoc.length > 0 &&
                        <CustomFileList onDelete={onDelete} files={ukCitizenshipDoc} disable={ disable } uploadSection={ UploadSection.UK_Citizenship_Documents } />
                    }
                    <Divider style={{ marginBottom: '20px' }} />
                </Grid>

                <Grid item xl={4} lg={5} md={12}>
                    <div className={ 'hidden' }>
                        <TextField
                            fullWidth
                            margin="dense"
                            variant='outlined'
                        />
                    </div>
                    <div className={ 'select-container' + (hasError(errors, 'irExpireDate') ? ' error' : '') } id='irExpireDate'>
                        <label>{ strings.rightToWork.irExpireDate }</label>
                        <DatePickerControl
                            hasError={ hasError(errors, 'irExpireDate') }
                            error={ getError(errors, 'irExpireDate') }
                            date={data.irExpireDate}
                            name={'irExpireDate'}
                            placeholder={ strings.rightToWork.irExpireDatePlaceholder }
                            onChange={ onChange }
                            dateFormat='dd/MM/yyyy'
                            disabled={ disable }
                            withPortal={true}
                        />
                    </div>
                </Grid>
            </Grid>
        </form>
    );
}

export default RightToWorkForm;