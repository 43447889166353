import React from 'react';
import strings from "../../../localization";
import {getError, hasError} from "../../../functions/Validation";
import {Divider, TextField} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import SelectControl from "../../controls/SelectControl";

const EmergencyContactForm = ({
                                  onSubmit,
                                  onCancel,
                                  relationshipTypes,
                                  onChange,
                                  errors,
                                  data,
                                  disable
                              }) => {
    return (
        <form id='emergency-contact-form'>
            <Grid container spacing={9} className={'grid-wrapper'}>
                <Grid item xl={4} lg={5} md={12}>
                    <div className={'select-container' + (hasError(errors, 'emergencyFullName') ? ' error' : '')}>
                        <label>{strings.emergencyContact.emergencyFullName}</label>
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={hasError(errors, 'emergencyFullName')}
                            helperText={getError(errors, 'emergencyFullName')}
                            fullWidth
                            name='emergencyFullName'
                            onChange={onChange}
                            value={data.emergencyFullName}
                            margin="dense"
                            variant='outlined'
                            disabled={disable}
                        />
                    </div>
                    <div
                        className={'select-container' + (hasError(errors, 'emergencyFirstPhoneContact') ? ' error' : '')}>
                        <label>{strings.emergencyContact.emergencyFirstPhoneContact} {strings.emergencyContact.mobileLandline}</label>
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={hasError(errors, 'emergencyFirstPhoneContact')}
                            helperText={getError(errors, 'emergencyFirstPhoneContact')}
                            fullWidth
                            name='emergencyFirstPhoneContact'
                            onChange={onChange}
                            value={data.emergencyFirstPhoneContact}
                            margin="dense"
                            variant='outlined'
                            disabled={disable}
                        />
                    </div>
                    <div className={'select-container' + (hasError(errors, 'emergencyEmail') ? ' error' : '')}>
                        <label>{strings.emergencyContact.emergencyEmail}</label>
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={hasError(errors, 'emergencyEmail')}
                            helperText={getError(errors, 'emergencyEmail')}
                            fullWidth
                            name='emergencyEmail'
                            onChange={onChange}
                            value={data.emergencyEmail}
                            margin="dense"
                            variant='outlined'
                            disabled={disable}
                        />
                    </div>
                </Grid>

                <Grid item xl={4} lg={5} md={12}>
                    <div
                        className={'select-container' + (hasError(errors, 'emergencyRelationshipToYou') ? ' error' : '')}>
                        <label>{strings.emergencyContact.emergencyRelationshipToYou}</label>
                        <SelectControl
                            error={hasError(errors, 'emergencyRelationshipToYou')}
                            helperText={getError(errors, 'emergencyRelationshipToYou')}
                            fullWidth
                            autoFocus
                            name='emergencyRelationshipToYou'
                            onChange={onChange}
                            margin="normal"
                            value={data.emergencyRelationshipToYou}
                            options={relationshipTypes}
                            nameKey={'name'}
                            valueKey={'id'}
                            selected={relationshipTypes.find(x => x.name === data.emergencyRelationshipToYou?.name || x.name === data.emergencyRelationshipToYou)}
                            isDisabled={disable}
                            isSearchable={false}
                        />
                        {hasError(errors, 'emergencyRelationshipToYou') ?
                            <p className='select-error'>{strings.validation.RequiredErrorMessage}</p> : ""}
                    </div>
                    <div
                        className={'select-container' + (hasError(errors, 'emergencySecondPhoneContact') ? ' error' : '')}>
                        <label>{strings.emergencyContact.emergencySecondPhoneContact} {strings.emergencyContact.mobileLandline}</label>
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={hasError(errors, 'emergencySecondPhoneContact')}
                            helperText={getError(errors, 'emergencySecondPhoneContact')}
                            fullWidth
                            name='emergencySecondPhoneContact'
                            onChange={onChange}
                            value={data.emergencySecondPhoneContact}
                            margin="dense"
                            variant='outlined'
                            disabled={disable}
                        />
                    </div>
                </Grid>
            </Grid>
            <Divider/>
        </form>
    )
};

export default EmergencyContactForm;