import React from 'react'

import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import { useLocation } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import Page from "../../common/Page";

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import LoginForm from "../../components/forms/user/LoginForm";
import Validators from "../../constants/ValidatorTypes";
import {login} from "../../base/OAuth";
import {HashLink as Link} from "react-router-hash-link";
import MenuState from "../../constants/MenuState";
import {changeMenuState} from "../../actions/Actions";
import CONFIG from '../../config';
import { useHistory } from 'react-router-dom';

class Login extends Page {
    static divParam =''; 
    validationList = {
        email: [ {type: Validators.EMAIL } ],
        password: [ {type: Validators.REQUIRED } ]
    };

    constructor(props) {
        super(props);

        this.state = {
            data: {},
            errors: {},
            redirectUrl: props.location.state ? props.location.state.redirectUrl : '/'
        };

        this.props.changeFullScreen(true);

        this.keyPress = this.keyPress.bind(this);
    }
    
    handleQueryParameters = () => {
        const location = this.props.location;
        const queryParams = new URLSearchParams(location.search);
        this.divParam = CONFIG.currentDivision +'div';
      };
    componentDidMount() {
        this.handleQueryParameters();
        if(this.props.auth.user) {
            this.props.history.push('/');
        }
    }

    keyPress(event) {

        if(event.key == 'Enter') {
            this.login()
        }
    }

    login() {

        if(!this.validate()) {
            return;
        }

        login(this.state.data.email, this.state.data.password).then(response => {

            if(!response.ok) {

                this.setError('email', strings.login.wrongCredentials);
                return;
            }
            response.data.div = this.divParam;
            this.props.login(response.data);

            if(window.innerWidth <= 768) {
                this.props.changeMenuState(MenuState.SHORT);
            }
            
            this.props.history.push({
                pathname: '/'
            })
        });
    }

    render() {

        return (

            <div id='login'>
                <div className='logo'>
                    <img src={'/images/' + this.divParam +'.svg'} alt={'logo'} />
                </div>
                <Grid container spacing={9} className={'grid-wrapper'}>
                    <Grid item md={5} className={"login-image"}>
                        <img src={'/images/' + this.divParam +'.jpg'} alt={'logo'} />
                    </Grid>
                    <Grid item md={5} className={'login-form-wrapper ' + this.divParam }>
                        <Paper className='paper'>
                            <div className={'login-logo'}>
                                <img src={'/images/RAS-Hex.png'} alt={'logo'} />
                            </div>
                            <LoginForm onSubmit={ () => this.login() } onChange={ this.changeData }
                                    keyPress={ this.keyPress }
                                    data={ this.state.data } errors={ this.state.errors }
                            />
                        </Paper>
                    </Grid>
                </Grid>

                <div className={'wrapper'}>
                    <a href={'https://asginfo.retailassetsolutions.com/'} className={'privacy-policy'}>{ strings.login.privacyPolicy }</a>
                    <a href={'https://asginfo.retailassetsolutions.com/TermsAndConditions'} className={'terms-and-conditions'}>{ strings.login.termsAndConditions }</a>
                    <a href={'https://asginfo.retailassetsolutions.com/Cookies'} className={'cookies'}>{ strings.login.cookies }</a>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen,
        changeMenuState: Actions.changeMenuState,
        login: Actions.login
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, auth: authReducers };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));