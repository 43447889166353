import React, {Component} from 'react'
import {Button, Tooltip} from "@material-ui/core";
import ApplicationFileStatus from "../constants/ApplicationFileStatus";
import strings from "../localization";
import {changeFileStatus} from "../services/FileUpload";
import {updateRTWProcess, updateRTWStatus} from "../services/AddCandidateService";
import RTWProcess from "../constants/RTWProcess";
import ViewSelfieModal from "../common/ViewSelfieModal";

class SelfieCart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : []
        }
        this.toggleViewSelfieModal = this.toggleViewSelfieModal.bind(this);
    }

    renderSelfie(item) {
        let className = 'candidate-selfie';

        if(item.applicationFileStatus === ApplicationFileStatus.REJECTED){
            className = 'candidate-selfie rejected';
        }

        return (
            <div className='candidate-selfie-container'>
                <div className={className} style={{ backgroundImage: 'url(data:image/png;base64,' + item.content + ')' }} />
                <Button className='candidate-selfie-expand-button'>
                    <img src={'/images/expand-icon.jpg'} className='candidate-selfie-expand' alt='expand' onClick={() => this.toggleViewSelfieModal(item.content)} />
                </Button>
            </div>
        )
    }

    viewSelfieModalRender(selfie) {
        return  <ViewSelfieModal
            toggleModal={this.toggleViewSelfieModal}
            open={this.state.openSelfieView}
            selfie={selfie}
            title={strings.selfieGallery.selfieView}
        />
    }

    toggleViewSelfieModal(content){

        this.setState({
            selfie: content
        });

        this.setState({
            openSelfieView: !this.state.openSelfieView
        });
    }

    renderUserInfo(name, surname, email) {
        return (
            <div className='candidate-cart-name-email'>
                <span className='label'>{name} {surname}</span>
                <Tooltip title={email}>
                    <span className='label'>{email}</span>
                </Tooltip>
            </div> )
    }

    onClickButton(item, status) {
        changeFileStatus(item.id, status).then(response => {
            if(status === ApplicationFileStatus.REJECTED) {
                updateRTWStatus({id: item.application.id, status: 0}).then(() => {
                    updateRTWProcess({id: item.application.id, Process: RTWProcess.MANUAL})
                });
            }
            this.setState({
                data: {...this.state.data, applicationFileStatus: status}
            });
            this.props.onClick(response);
        });
    }
    renderButtons(item) {
        return (
            <div className='cadidate-cart-buttons-container' >
                <Button variant="contained" className='accept-button' disabled={item.applicationFileStatus != ApplicationFileStatus.ON_HOLD} onClick={ () => this.onClickButton(item, ApplicationFileStatus.ACCEPTED) }>
                    { strings.selfieGallery.acceptSelfie }
                </Button>
                <Button variant="contained" className='reject-button' disabled={item.applicationFileStatus != ApplicationFileStatus.ON_HOLD} onClick={ () => this.onClickButton(item, ApplicationFileStatus.REJECTED) }>
                    { strings.selfieGallery.rejectSelfie }
                </Button>
            </div>
        )
    }

    render() {
        let selfieView = this.viewSelfieModalRender(this.state.selfie);
        return (
            <div className={this.props.className ? this.props.className : 'candidate-cart'}>
                { this.renderSelfie(this.state.data) }
                { !this.props.hideNameAndEmail && this.renderUserInfo(this.state.data.application.forename, this.state.data.application.surname, this.state.data.application.email)}
                { this.renderButtons(this.state.data) }
                { selfieView }
            </div>
        )
    }
}

export default SelfieCart;
