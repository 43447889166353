const Permission = {
    CANDIDATE_PROFILE: 1,
    CONDITIONAL_OFFER: 2,
    ADD_CANDIDATE: 3,
    USER_APPLICATION: 4,
    APPLICATIONS: 5,
    CONTRACT: 6,
    VIEW_CANDIDATES: 7,
    GO_BACK: 8,
    MEDICAL_DECLARATION_VIEW: 9,
    REHABILITATION: 10,
    BANK_DETAILS: 11,
    SELFIE_GALLERY: 12,
    ADMIN_ALERT: 13,
};

export default Permission;