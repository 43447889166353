
const Currencies = {
    GBR: 1,
    EUR: 2
};

export default Currencies;

export function getCurrenciesList() {
    return [
        {
            value: Currencies.GBR,
            name: '£'
        },
        {
            value: Currencies.EUR,
            name: '€'
        }
    ]
}