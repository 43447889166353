import React from 'react';
import strings from "../../../localization";
import {getError, hasError} from "../../../functions/Validation";
import {Button, TextField, Divider, Chip, Tooltip} from "@material-ui/core";
import SelectControl from '../../controls/SelectControl';
import Grid from '@material-ui/core/Grid';
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloseIcon from "@material-ui/icons/Close";
import DocumentType, { getDocumentTypes } from "../../../constants/DocumentType";
import ApplicationInternalStatus from '../../../constants/ApplicationInternalStatus';
import UploadSection from "../../../constants/UploadSection";
import CustomFileList from '../../../common/CustomFileList';

function renderEmploymentFiles(files, data, onChange, onDelete, proofAddressDocumentTypes){
    let result = [];

    // if(files){
        for(let file of files) {

            let shortName = file ? file.name : '';

            if(shortName.length > 22) {
                shortName = shortName.substring(0, 22) + '...';
            }

            result.push(
                <div className={'uploaded-file-chip'}>
                    <Tooltip title={ file ? file.name : '' } >
                        <Chip
                            icon={<AttachFileIcon />}
                            label={ shortName }
                            clickable
                            color="success"
                            className={'chip-uploaded-file'}
                            onDelete={() => {onDelete(file.id)}}
                            deleteIcon={<CloseIcon className={'close-icon'} />}
                        />    
                    </Tooltip>
                    <SelectControl
                        placeholder={ strings.documents.documentType }
                        fullWidth
                        autoFocus
                        margin="normal"
                        value={ data.proofAddressDocumentType }
                        options={ proofAddressDocumentTypes }
                        nameKey={ 'name' }
                        valueKey={ 'id' }
                        selected={ data.proofAddressDocumentType }
                        isSearchable={false}
                    />
                </div>
            )
        // }
    }

    return result;
}

const EmploymentForm = ({
                             onSubmit,
                             onCancel,
                             onChange,
                             errors,
                             data,
                             onDelete,
                             titles,
                             genders,
                             disabled,
                             proofOfEmployment,
                             handleToggle,
                             onModalOpen
                         }) => (

    <form id='employment-form'>
        <Grid container spacing={9} className={'grid-wrapper-empl'}>
            <Grid item md={10} className={'margin-center'}>
                <div className={'question'}>{ strings.employment.employmentQuestion }</div>
            </Grid>
            <Grid item md={9} className={'switch-wrapper margin-center'}>
                <label className="switch" onClick={handleToggle}>
                    <input checked={data.isOnlyEmployment} disabled={ disabled } type="checkbox" id="togBtn" name={'isOnlyEmployment'}/>
                    <div className="slider round">
                        <span className="on">YES</span>
                        <span className="off">NO</span>
                    </div>
                </label>
                {
                    data.isOnlyEmployment
                        ?
                        <div className={'label-wrapper'}>
                            <label className={'employment-answer'}>{strings.employment.yes}</label>  
                        </div>
                        :
                        <div className={'label-wrapper'}>
                            <label className={'employment-answer'}>{strings.employment.no}</label>
                        </div>
                }
            </Grid>
            {
                !data.isOnlyEmployment
                    ?
                <div className={'employment-declaration-show'}>
                    <Grid container spacing={9} className={'grid-wrapper'}>
                        <Grid item xl={4} lg={10} md={12}>
                            <div className={ 'select-container' + (hasError(errors, 'otherWorkHours') ? ' error' : '') }>
                                <label>{ strings.employment.employmentNoQuestion }</label>
                                <TextField
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={ hasError(errors, 'otherWorkHours') }
                                    helperText={ getError(errors, 'otherWorkHours') }
                                    placeholder={ strings.employment.numberOfHoursPlaceholder }
                                    fullWidth
                                    name='otherWorkHours'
                                    onChange={ onChange }
                                    margin="dense"
                                    value={ data.otherWorkHours }
                                    variant='outlined'
                                    disabled={ disabled }
                                />
                            </div>
                        </Grid>
                        <Grid item xl={4} lg={10} md={12}>
                        <div className={ 'select-container-upload' }>
                            <label>{ strings.employment.employmentDocument }</label>
                            <div className="open-modal-button">
                                <Button variant="contained" color="primary" disabled={ disabled } onClick={() => {onModalOpen(UploadSection.Contract_Employment)}}>
                                    { strings.employment.uploadFiles }
                                </Button>
                                <div className={'tooltip right'}>
                                    <img src={'/images/info.png'} />
                                    <span className="tooltip-content">
                                        <p>You can upload a copy of your P45 or P60.</p>
                                        <p>Please ensure that the documents are clear and legible. If we are not able to review documents due to the quality of the uploads, this might delay your application.</p>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <Divider />
                        
                        <CustomFileList onDelete={onDelete} files={proofOfEmployment} disable={ disabled }
                        uploadSection={ UploadSection.Contract_Employment } />
                        
                    </Grid>
                    </Grid>
                </div>
                    :
                ""
            }
        </Grid>

        <h1>{ strings.employment.previousEmployment }</h1>
        <Grid container spacing={9} className={'grid-wrapper-empl'}>
            <Grid item md={10} className='margin-center'>
                <div className={'question'}>{ strings.employment.previousEmploymentQuestion }</div>
            </Grid>
            <Grid item md={9} className={'switch-wrapper margin-center'}>
                <label className="switch" onClick={handleToggle}>
                    <input checked={data.havePreviousWorked} disabled={ disabled } type="checkbox" id="togBtn" name={'havePreviousWorked'}/>
                    <div className="slider round">
                        <span className="on">YES</span>
                        <span className="off">NO</span>
                    </div>
                </label>
                {
                    data.havePreviousWorked
                        ?
                        <div className={'label-wrapper'}>
                            <label className={'employment-answer'}>{strings.employment.previousYes}</label>
                        </div>
                        :
                        <div className={'label-wrapper'}>
                            <label className={'employment-answer'}>{strings.employment.previousNo}</label>
                        </div>
                }
            </Grid>
            {
                data.havePreviousWorked &&
                <Grid item lg={9} md={12}>
                    <div className={'previous-employment-comment'}>
                        <label>{ strings.employment.previousEmploymentComment }</label>
                        <TextField
                            placeholder={strings.employment.enterText}
                            multiline
                            rows={2}
                            rowsMax={4}
                            variant={'outlined'}
                            onChange={onChange}
                            name={'previousWorkedLeavingReason'}
                            value={ data ? data.previousWorkedLeavingReason : '' }
                            disabled={ disabled }
                        />
                    </div>
                </Grid>
            }

        </Grid>
        <Divider />
    </form>
);

export default EmploymentForm;