import React, { Component } from 'react';
import { Chip, Dialog, DialogContent, Divider, Slide, Tooltip } from "@material-ui/core/";
import CloseIcon from '@material-ui/icons/Close';
import { Button, IconButton } from "@material-ui/core";
import strings from "../localization";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import SelectControl from '../components/controls/SelectControl';
import { getDocumentTypes } from '../constants/DocumentType';
import { changeFileType } from '../services/FileUpload';

class FileUploadModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: {},
            files: this.props.uploadedFile ? this.props.uploadedFile : []
        }

        this.onChange = this.onChange.bind(this);
    }

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="left" ref={ref} {...props} />;
    });

    componentWillReceiveProps(props) {
        this.setState({
            files: this.props.uploadedFile ? this.props.uploadedFile : []
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.uploadedFile !== this.props.uploadedFile) {
            this.setState({
                files: this.props.uploadedFile ? this.props.uploadedFile : []
            });
        }
    }

    findFile(id) {

        for (let file of this.state.files) {
            if (file.id == id) {
                return file;
            }
        }

        return null;
    }

    onChange(event, id) {

        let file = this.findFile(id);

        if (!file) {
            return;
        }

        let index = this.state.files.indexOf(file);

        if (index < 0) {
            return;
        }

        changeFileType(id, event.target.value.value).then(response => {

            if (!response || !response.ok) {
                return;
            }

            let files = this.state.files;
            files[index].documentType = event.target.value;

            this.setState({
                files: files
            });
        });
    }

    renderFiles(files) {

        let result = [];

        let options = getDocumentTypes(this.props.uploadSection)

        for (let file of files) {

            let shortName = file ? file.name : '';

            if(shortName.length > 22) {
                shortName = shortName.substring(0, 22) + '...';
            }

            result.push(
                <div className={'uploaded-file-chip'} key={file.id}>
                    <Tooltip title={file ? file.name : ''}>
                        <Chip
                            icon={<AttachFileIcon />}
                            label={shortName}
                            clickable
                            color="success"
                            className={'chip-uploaded-file'}
                        />
                    </Tooltip>

                    <SelectControl
                        placeholder={strings.documents.documentType}
                        fullWidth
                        autoFocus
                        name='documentType'
                        onChange={(event) => this.onChange(event, file.id)}
                        margin="normal"
                        options={options}
                        nameKey={'name'}
                        valueKey={'value'}
                        selected={file.documentType}
                        isSearchable={false}
                    />
                </div>
            )
        }

        return result;
    }

    render() {
        return (
            <Dialog
                onClose={this.props.toggleModal}
                aria-labelledby="customized-dialog-title"
                open={this.props.open}
                TransitionComponent={this.Transition}
                className={'form-modal'}
            >
                <div className='form-modal-header' onClose={this.props.toggleModal}>
                    <div>
                        <h1>{this.props.title}</h1>
                        <IconButton className={'close-button-wrapper'} onClick={this.props.toggleModal}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <Divider />
                </div>
                <DialogContent dividers>
                    {this.props.content}
                </DialogContent>
                <Divider />
                <div className={'browse-button'}>
                    <Button variant="contained" color="primary" onClick={this.props.openDialog}>
                        {strings.documents.browse}
                    </Button>
                </div>
                <h3 className={'file-upload-modal-h3'}>{strings.documents.myAttachments}</h3>
                <Divider />
                {
                    this.state.files ?
                        Object.keys(this.state.files).length > 0 &&
                        <div className={'uploaded-files-chip-wrapper'}>
                            {this.renderFiles(this.state.files)}
                        </div>
                        :
                        ''
                }
                <div className={'done-button'}>
                    <Button variant="contained" color="primary" onClick={this.props.toggleModal}>
                        {strings.documents.done}
                    </Button>
                </div>
            </Dialog>
        );
    }
}

export default FileUploadModal;