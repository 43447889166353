import React from 'react';
import strings from "../../../localization";
import {getError, hasError} from "../../../functions/Validation";
import {Button, TextField, Divider} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import DocumentType from "../../../constants/DocumentType";
import ApplicationInternalStatus from '../../../constants/ApplicationInternalStatus';
import {findDataValue} from "../../../util/DropdownUtil";
import UploadSection from '../../../constants/UploadSection';
import CustomFileList from '../../../common/CustomFileList';
import {checkPermission} from "../../../util/SecurityUtil";
import Permission from "../../../constants/Permission";

const BankDetailsForm = ({
                             onChange,
                             errors,
                             data,
                             onDelete,
                             proofOfAccountDetails,
                             onModalOpen,
                             handleToggle,
                             disabled,
                             user
                         }) => (

    <form id='bank-details-form'>
        <Grid container spacing={9} className={'grid-wrapper'}>

            {
                checkPermission(user.userType, Permission.BANK_DETAILS) &&
                <React.Fragment>
                    <Grid item xl={4} lg={5} md={12}>
                        <div className={'select-container' + (hasError(errors, 'bankName') ? ' error' : '')}>
                            <label>{strings.bankDetails.bankName} {data.applicationInterlanState}</label>
                            <TextField
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={hasError(errors, 'bankName')}
                                helperText={getError(errors, 'bankName')}
                                fullWidth
                                name='bankName'
                                onChange={onChange}
                                margin="dense"
                                value={data.bankName}
                                variant='outlined'
                                disabled={disabled}
                            />
                        </div>
                        <div className={'select-container' + (hasError(errors, 'accountHolderName') ? ' error' : '')}>
                            <label>{strings.bankDetails.accountHolderName}</label>
                            <TextField
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={hasError(errors, 'accountHolderName')}
                                helperText={getError(errors, 'accountHolderName')}
                                fullWidth
                                name='accountHolderName'
                                onChange={onChange}
                                margin="dense"
                                value={data.accountHolderName}
                                variant='outlined'
                                disabled={disabled}
                            />
                        </div>
                        <div className={'select-container-upload'}>
                            <label>{strings.bankDetails.proofOfAccountDetails}</label>
                            <div className="open-modal-button">
                                <Button variant="contained" color="primary" disabled={disabled} onClick={() => {
                                    onModalOpen(UploadSection.Proof_of_Account_Details)
                                }}>
                                    {strings.bankDetails.uploadFiles}
                                </Button>
                                <div className={'tooltip right'}>
                                    <img src={'/images/info.png'}/>
                                    <span className="tooltip-content">
                                <p>Documents you can upload as proof of account details:</p>
                                <ul>
                                    <li>Bank Statement</li>
                                    <li>Bank Confirmation Letter</li>
                                </ul>

                                <p>Please ensure that the documents are clear and legible. If we are not able to review documents due to the quality of the uploads, this might delay your application.</p>
                            </span>
                                </div>
                            </div>
                        </div>

                        <Divider style={{marginBottom: '20px'}}/>
                        <CustomFileList onDelete={onDelete} files={proofOfAccountDetails} disable={disabled}
                                        uploadSection={UploadSection.Proof_of_Account_Details}/>
                    </Grid>

                    <Grid item xl={4} lg={5} md={12}>
                        {
                            findDataValue(data, 'territoryOfWork') === 'UK'
                                ?
                                <div>

                                    <div
                                        className={'select-container' + (hasError(errors, 'bankAccountNumber') ? ' error' : '')}>
                                        <label>{strings.bankDetails.bankAccountNumber}</label>
                                        <TextField
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={hasError(errors, 'bankAccountNumber')}
                                            helperText={getError(errors, 'bankAccountNumber')}
                                            fullWidth
                                            name='bankAccountNumber'
                                            onChange={onChange}
                                            margin="dense"
                                            value={data.bankAccountNumber}
                                            variant='outlined'
                                            disabled={disabled}
                                        />
                                    </div>
                                    <div className={'select-container' + (hasError(errors, 'bankShortCode') ? ' error' : '')}>
                                        <label>{strings.bankDetails.bankSortCode}</label>
                                        <TextField
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={hasError(errors, 'bankShortCode')}
                                            helperText={getError(errors, 'bankShortCode')}
                                            fullWidth
                                            name='bankShortCode'
                                            onChange={onChange}
                                            margin="dense"
                                            value={data.bankShortCode}
                                            variant='outlined'
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                                :
                                <div>
                                    <div className={'select-container' + (hasError(errors, 'iban') ? ' error' : '')}>
                                        <label>{strings.bankDetails.iban}</label>
                                        <TextField
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={hasError(errors, 'iban')}
                                            helperText={getError(errors, 'iban')}
                                            fullWidth
                                            name='iban'
                                            onChange={onChange}
                                            margin="dense"
                                            value={data.iban}
                                            variant='outlined'
                                            disabled={disabled}
                                        />
                                    </div>
                                    <div className={'select-container' + (hasError(errors, 'bic') ? ' error' : '')}>
                                        <label>{strings.bankDetails.bic}</label>
                                        <TextField
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={hasError(errors, 'bic')}
                                            helperText={getError(errors, 'bic')}
                                            fullWidth
                                            name='bic'
                                            onChange={onChange}
                                            margin="dense"
                                            value={data.bic}
                                            variant='outlined'
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>

                        }
                    </Grid>

                    <Grid item md={10}>
                        <div className={'message-wrapper'}>
                            <h4>{strings.bankDetails.bankDetailsConfirmation}</h4>
                            <div className={'message'}>{strings.bankDetails.bankDetailsMessage}</div>
                        </div>

                        <label className="switch" onClick={handleToggle}>
                            <input checked={data.bankDetailConfirmation} disabled={disabled} type="checkbox" id="togBtn"
                                   name={'bankDetailConfirmation'}/>
                            <div className="slider round">
                                <span className="on">YES</span>
                                <span className="off">NO</span>
                            </div>
                        </label>
                        {

                            hasError(errors, 'bankDetailConfirmation') &&
                            <p className={'error'}>
                                {getError(errors, 'bankDetailConfirmation')}
                            </p>
                        }

                    </Grid>
                </React.Fragment>
            }

            {
                !checkPermission(user.userType, Permission.BANK_DETAILS) &&
                <Grid item md={10}>
                    <div className={'question'}>{ strings.bankDetails.informationRestrictedToHRonly }</div>
                </Grid>
            }
        </Grid>
        <Divider/>
    </form>
);

export default BankDetailsForm;