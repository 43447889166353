import React, {createRef} from 'react'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Page from "../../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {withSnackbar} from "notistack";
import Validators from '../../constants/ValidatorTypes';
import history from '../../history';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import strings from "../../localization";
import {Button} from "@material-ui/core";
import TermsAndConditionsModal from "../../common/TermsAndConditionsModal";
import TermsAndConditionsStatus from "../../constants/TermsAndConditionsStatus";

class TermsAndConditions extends Page {

    validationList = {}

    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : {},
            errors: {},
            openTermsAndConditions: false,
            termsAndConditions: ''
        };

        this.props.changeFullScreen(false);
        this.goToContract = this.goToContract.bind(this);
        this.termsAndConditionsModalRef = createRef();
        this.toggleTermsAndConditionsModal = this.toggleTermsAndConditionsModal.bind(this);
        this.openTermsAndConditionsDialog = this.openTermsAndConditionsDialog.bind(this);
    }

    goToContract(){
        this.props.close()
    }

    openTermsAndConditionsModal(result) {
        this.setState({
            openTermsAndConditions: true,
            termsAndConditions: result
        });
    }

    toggleTermsAndConditionsModal(result, type) {
        this.setState({
            openTermsAndConditions: !this.state.openTermsAndConditions,
        });

        this.props.submit(result, type);
    }

    openTermsAndConditionsDialog() {
        if (this.termsAndConditionsModalRef.current) {
            this.termsAndConditionsModalRef.current.open();
        }
    };

    viewTermsAndConditionsModal(){
        return (
            <TermsAndConditionsModal
                toggleModal={this.toggleTermsAndConditionsModal}
                open={this.state.openTermsAndConditions}
                title={strings.termsAndConditions.termsAndConditions}
                openDialog={this.openTermsAndConditionsDialog}
                termsAndConditions={this.state.termsAndConditions}
            />
        );
    }

    render() {
        let termsAndConditionsModal = this.viewTermsAndConditionsModal();

        return (
            <div className={'terms-and-conditions'}>
                <Grid container spacing={9}>
                    <Grid item md={12}>
                        <Paper className='paper'>
                            <div className={'header-wrapper'}>
                                <ArrowBackIcon onClick={this.goToContract}/>
                                <h1>{strings.termsAndConditions.termsAndConditions}</h1>
                            </div>
                            {
                                this.props.contract && this.props.contract.contractContent &&
                                <div className={'content-wrapper'} dangerouslySetInnerHTML={{__html: this.props.contract.contractContent }}>
                                
                                </div>
                            }
                            
                            <div className={'content-wrapper'}>
                                
                                <div className='submit-container'>
                                    <Button className={'accept'} variant="contained" color="primary" disabled={ this.props.disabled } onClick={() => this.openTermsAndConditionsModal(TermsAndConditionsStatus.ACCEPT)} >
                                        <div>{ strings.termsAndConditions.accept }</div>
                                        <p>{ strings.termsAndConditions.buttonLabel }</p>
                                    </Button>
                                    <Button className={'decline'} variant="contained" color="primary" disabled={ this.props.disabled } onClick={() => this.openTermsAndConditionsModal(TermsAndConditionsStatus.DECLINE)}>
                                        <div>{ strings.termsAndConditions.decline }</div>
                                        <p>{ strings.termsAndConditions.buttonLabel }</p>
                                    </Button>
                                </div>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
                {termsAndConditionsModal}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen,
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers })
{
    return { menu: menuReducers, user: authReducers.user, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions)));
