
const VisaType = {
    SettlementRefugee: 1,
    LimitedLeaveToRemain: 2,
    StudentVisa: 3,
    IndefiniteLeaveToRemain: 4,
    LeaveToEnter: 5
};

export default VisaType;

export function getVisaTypes() {
    const visaTypes = [
        {
            value: VisaType.SettlementRefugee,
            name: 'Settlement/Refugee'
        },
        {
            value: VisaType.LimitedLeaveToRemain,
            name: 'Limited Leave to Remain'
        },
        {
            value: VisaType.StudentVisa,
            name: 'Student Visa'
        },
        {
            value: VisaType.IndefiniteLeaveToRemain,
            name: 'Indefinite Leave to Remain'
        },
        {
            value: VisaType.LeaveToEnter,
            name: 'Leave to Enter'
        }
    ];

    return visaTypes;
}