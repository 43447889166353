import React, {Component} from 'react';
import {Dialog, Divider, Slide} from "@material-ui/core/";
import CloseIcon from '@material-ui/icons/Close';
import {Button, IconButton, TextField} from "@material-ui/core";
import strings from "../localization";
import FormComponent from './FormComponent';

class AddInfoRequiredModal extends FormComponent {

    constructor(props) {
        super(props);

        this.state = {
            application: props.application ? props.application : {},
            data: {
                additionalInfoRequired: props.application ? props.application.additionalInfoRequired : ''
            }
        }
    }

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="left" ref={ref} {...props} />;
    });

    componentWillReceiveProps(props) {
        this.setState({
            data: {
                additionalInfoRequired: props.application ? props.application.additionalInfoRequired : ''
            }
        })
    }

    send() {

        this.props.toggleModal();

        this.props.apply(this.props.application ? this.props.application.id : -1,
            this.state.data.additionalInfoRequired);
    }


    render() {

        return (
            <Dialog
                onClose={this.props.toggleModal}
                aria-labelledby="customized-dialog-title"
                open={this.props.open}
                TransitionComponent={this.Transition}
                className={'add-info-req-modal'}
            >
                <div className='form-modal-header' onClose={this.props.toggleModal}>
                    <div>
                        <h1>{this.props.title}</h1>
                        <IconButton className={'close-button-wrapper'} onClick={this.props.toggleModal}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <Divider />
                    <div className={'add-info-req-wrapper'}>
                        <div className={'add-info-req-message'}>
                            {strings.candidateProfiles.addInfoRequiredMessage}
                        </div>
                        <div className={'add-info-req-content'}>
                            <TextField
                                placeholder={strings.hrManager.enterComments}
                                multiline
                                rows={2}
                                rowsMax={4}
                                variant={'outlined'}
                                onChange={this.changeData}
                                name={'additionalInfoRequired'}
                                value={ this.state.data ? this.state.data.additionalInfoRequired : '' }
                            />
                        </div>
                        <div className='add-info-req-btn'>
                            <Button variant="contained" color="primary" onClick={() => this.send()}>
                                { strings.candidateProfiles.send }
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default AddInfoRequiredModal;