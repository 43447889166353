import React from 'react';
import strings from '../../../localization';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {getError, hasError} from "../../../functions/Validation";

const AddCandidatePasswordForm = ({
    onSubmit,
    onChange,
    errors,
    data,
    keyPress
}) => (
    <form id="add-candidate-password-form" onSubmit={ onSubmit } action = "#">
        <TextField
            label={ strings.addCandidatePassword.candidate }
            fullWidth
            name='candidateName'
            disabled
            margin="normal"
            value={ data.candidateName }
            variant='outlined'
        />
        <TextField
            label={ strings.addCandidatePassword.password }
            error={ hasError(errors, 'password') }
            helperText={ getError(errors, 'password') }
            fullWidth
            name='password'
            type='password'
            onChange={ onChange }
            onKeyPress={ keyPress }
            margin="normal"
            value={ data.password }
            variant='outlined'
        />
        <TextField
            label={ strings.addCandidatePassword.confirmPassword }
            error={ hasError(errors, 'confirmPassword') }
            helperText={ getError(errors, 'confirmPassword') }
            fullWidth
            name='confirmPassword'
            type='password'
            onChange={ onChange }
            onKeyPress={ keyPress }
            margin="normal"
            value={ data.confirmPassword }
            variant='outlined'
        />
        <div className='submit-container'>
            <Button variant="contained" color="primary" onClick={ onSubmit }>
                { strings.addCandidatePassword.addPassword }
            </Button>
        </div>
    </form>
);

export default AddCandidatePasswordForm;