import React from 'react';
import strings from "../../../localization";
import {Button, Divider, Switch} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import ConfirmMode, {getConfirmModesString} from "../../../constants/ConfirmMode";

const GDRPDisclaimerForm = ({
                      onSubmit,
                      onCancel,
                      onChange,
                      errors,
                      data,
                      handleToggle,
                      disable
                  }) => {
return(
    <form id='gdpr-disclaimer-form'>
        <Grid container spacing={9} className={'grid-wrapper'}>
            <Grid item md={10}>
                <div className='submit-container'>
                    <p className={'disclaimer-text'}>
                        {strings.gdprDisclaimer.gdprDisclaimer}
                    </p>
                </div>
            </Grid>
            <Grid item md={9} className={'switch-wrapper'}>
                <label className="switch" onClick={ handleToggle }>
                    <input checked={data.gdprDisclaimerAnswer} disabled={ disable } type="checkbox" id="togBtn" name={'gdprDisclaimerAnswer'}/>
                    <div className="slider round">
                        <span className="on">YES</span>
                        <span className="off">NO</span>
                    </div>
                </label>

                {
                    // data.gdprDisclaimerAnswer !== undefined ?
                    data.gdprDisclaimerAnswer
                        ?
                        <div className={'label-wrapper'}>
                            <label className={'gdpr-answer'}>{strings.gdprDisclaimer.yes}</label>
                        </div>
                        :
                        <div className={'label-wrapper'}>
                            <label className={'gdpr-answer'}>{strings.gdprDisclaimer.no}</label>
                        </div>
                    // :
                    // ''
                }
            </Grid>
        </Grid>
    </form>
)};

export default GDRPDisclaimerForm;