import React, {Component} from 'react';
import {Dialog, Divider, Slide, Tooltip} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from "@material-ui/core";
import {dateTimeToString} from "../util/DateUtil";


class ViewHistoryModal extends Component {

    constructor(props) {
        super(props);
    }

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="left" ref={ref} {...props} />;
    });

    renderHistories(){
        let result = [];

        for(let item of this.props.histories){

            result.push(
                <div className={'file-info'}>
                    <div className={'file-info-border'}></div>
                    <div className={'file-info-content'}>
                        <div className='data'>
                            <div className={'file-name'}>
                                { item.title }
                            </div>
                            <div className={'file-data'}>
                                - { item.description }
                            </div>
                            <div className={'file-data'}>
                                {dateTimeToString(item.dateCreated)}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return result;
    }

    render() {
        return (
            <Dialog
                onClose={() => this.props.toggleModal(-1)}
                aria-labelledby="customized-dialog-title"
                open={this.props.open}
                TransitionComponent={this.Transition}
                className={'view-documents-modal'}
            >
                <div className='form-modal-header' onClose={() => this.props.toggleModal(-1)}>
                    <div>
                        <h1 className='documen-view-title'>
                            {this.props.title}

                        </h1>
                        <IconButton className={'close-button-wrapper'} onClick={() => this.props.toggleModal(-1)}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <Divider />
                </div>

                <div className={'uploaded-files-wrapper'}>
                    {
                        this.props.histories && this.props.histories.length > 0 &&
                        <div className={'uploaded-files'}>
                            <div className={'file'}>
                                { this.renderHistories() }
                            </div>
                        </div>
                    }
                </div>
            </Dialog>
        );
    }
}

export default ViewHistoryModal;