import React from 'react';
import strings from "../../../localization";
import {getError, hasError} from "../../../functions/Validation";
import {Button, TextField, Divider} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import ApplicationInternalStatus from '../../../constants/ApplicationInternalStatus';

const ChangesInInformationForm = ({

                         data,

                         disabled,
                         handleToggle
                     }) => (

    <form id='changes-in-information-form'>
        <Grid container spacing={9} className={'grid-wrapper'}>
            <Grid item md={10}>
                <div className={'question'}>{ strings.changesInInformation.changesInInformationQuestion }</div>
            </Grid>
            <Grid item md={9} className={'switch-wrapper'}>
                <label className="switch" onClick={handleToggle}>
                    <input checked={data.approveDataChangeNotificationToCompany} disabled={ disabled } type="checkbox" id="togBtn" name={'approveDataChangeNotificationToCompany'}/>
                    <div className="slider round">
                        <span className="on">YES</span>
                        <span className="off">NO</span>
                    </div>
                </label>
                {
                    data.approveDataChangeNotificationToCompany
                        ?
                        <div className={'label-wrapper'}>
                            <label className={'changes-in-information-answer'}>{strings.changesInInformation.yes}</label>
                        </div>
                        :
                        <div className={'label-wrapper'}>
                            <label className={'changes-in-information-answer'}>{strings.changesInInformation.no}</label>
                        </div>
                }
            </Grid>
        </Grid>
        <Divider />
    </form>
);

export default ChangesInInformationForm;