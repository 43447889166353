import React, {createRef} from 'react'
import TablePage from "../../common/TablePage";
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import {withSnackbar} from "notistack";
import PageState from '../../constants/PageState';
import {
    Button, Drawer,
    TableBody,
    TableCell,
    TableHead,
    TableRow, TextField,
} from "@material-ui/core";
import DrawerWrapper from "../../common/DrawerWrapper";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ViewDocumentsModal from '../../common/ViewDocumentsModal';
import ViewHistoryModal from "../../common/ViewHistoryModal";
import { getCodebooks } from '../../services/CodebookService';
import {
    getApplications,
    resetApplication,
    updateArchivedStatus,
    updateStatuses
} from '../../services/AddCandidateService';
import {deleteFile, getFiles} from '../../services/FileUpload';
import ApplicationStatus, { getApplicationStatusString } from '../../constants/ApplicationStatus';
import ApplicationInternalStatus from '../../constants/ApplicationInternalStatus';
import ApplicationView from '../../constants/ApplicationView';
import ViewApplicantModal from "../../common/ViewApplicantModal";
import {transformDate} from "../../util/DateUtil";
import SearchIcon from '@material-ui/icons/Search';
import { getApplicationStage } from '../../constants/ApplicationStage';
import { applicationResendEmail } from '../../services/ApplicationService';
import ResetModal from '../../common/ResetModal';
import Checkbox from "@material-ui/core/Checkbox";
import ArchivedStatus from "../../constants/ArchivedStatus";
import WithdrawConfirmationModal from "../../common/WithdrawConfirmationModal";
import RTWProcess from "../../constants/RTWProcess";
import ApplicationStatusRTW from "../../constants/ApplicationStatusRTW";
import IconButton from "@material-ui/core/IconButton";
import {getHistories} from "../../services/HistoryService";

class ViewCandidates extends TablePage {

    tableDescription = [
        { key: 'id', label: strings.candidateProfiles.name, transform: 'renderName', class: 'column-fix' },
        { key: null, label: strings.viewCandidates.email, transform: 'renderEmail' },
        { key: 'firstPhoneContact', label: strings.viewCandidates.phone },
        { key: 'dateCreated', label: strings.viewCandidates.created, transform: 'renderDate'},
        { key: null, label: strings.viewCandidates.stage, transform: 'renderStage'},
        { key: null, label: strings.viewCandidates.status, transform: 'renderStatus'},
        {key: 'id', label: strings.candidateProfiles.automaticCheck, transform: 'renderAutomaticCheck'},
        { key: 'dateUpdated', label: strings.viewCandidates.lastUpdate, transform: 'renderDate'},
        { key: null, label: strings.viewCandidates.archived, transform: 'renderArchiveStatus'},
        { key: null, label: strings.viewCandidates.withdraw, transform: 'renderWithdrawButton'},
        { key: 'id', label: '', transform: 'renderViewApplicant' },
        { key: 'id', label: '', transform: 'renderDocViewerButton' },
        { key: 'id', label: strings.viewCandidates.resend, transform: 'renderResendButton'},
        { key: 'id', label: strings.viewCandidates.reset, transform: 'renderResetButton'},
    ];

    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : {},
            showSearch: true,
            tableData: [],
            pageState: PageState.View,
            errors: {},
            openFileUpload: false,
            openDocView: false,
            openVerifyModal: false,
            openApplicantView: false,
            openAWithdrawView: false,
            documentsDoc: [],
            files: undefined,
            histories: undefined,
            showUploadProgress: false,
            uploadFileError: '',
            viewResetModal: false,
            selectedApplication: null,
            documentModalFullscreen: false,
            openWithdrawModal: false,
            withdrawApplication: null
        };

        this.toggleViewDocumentsModal = this.toggleViewDocumentsModal.bind(this);
        this.toggleViewHistoryModal = this.toggleViewHistoryModal.bind(this);
        this.toggleViewApplicantModal = this.toggleViewApplicantModal.bind(this);
        this.openViewDocuments = this.openViewDocuments.bind(this);
        this.openViewApplicant = this.openViewApplicant.bind(this);
        this.viewDocumentsRef = createRef();
        this.viewApplicantRef = createRef();
        this.findApplication = this.findApplication.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.toggleResetModal = this.toggleResetModal.bind(this);
        this.resetModalRef = createRef();
        this.resetYes  =this.resetYes.bind(this);
        this.showViewDocumentModal = this.showViewDocumentModal.bind(this);
        this.changeArchivedStatus = this.changeArchivedStatus.bind(this);
        this.withdrawModal = this.withdrawModal.bind(this);
        this.toggleWithdrawModel = this.toggleWithdrawModel.bind(this);
        this.applyWithdrawal = this.applyWithdrawal.bind(this);
        this.renderAutomaticCheck = this.renderAutomaticCheck.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
    }

    toggleResetModal(id){

        this.state.selectedApplication = id;

        this.setState({
            viewResetModal: !this.state.viewResetModal
        });
    }

    openResetModal() {
        if (this.resetModalRef.current) {
            this.resetModalRef.current.open();
        }
    };

    renderFirstName(item) {

        if(!item) {
            return '';
        }

        if(item.forename) {
            return item.forename;
        }

        if(item.user) {
            return item.user.firstName;
        }

        return '';
    }

    renderLastName(item) {

        if(!item) {
            return '';
        }

        if(item.surname) {
            return item.surname;
        }

        if(item.user) {
            return item.user.lastName;
        }

        return '';
    }

    renderWithdrawButton(item) {
        return (
            <div className='candidate-button candidate-view-applicant'>
                <Button disabled={item.applicationStatus === ApplicationStatus.WITHDRAWN} variant="contained" color="primary" onClick={() => {
                    this.toggleWithdrawModel()
                    this.setState({
                        withdrawApplication: item
                    });
                }}>
                    { strings.viewCandidates.withdraw }
                </Button>
            </div>
        );
    }

    renderArchiveStatus(item) {

        return (
            <Checkbox
                checked={item.archivedStatus === ArchivedStatus.ARCHIVED}
                onChange={() => this.changeArchivedStatus(item.id, item.archivedStatus === ArchivedStatus.ARCHIVED ?
                    ArchivedStatus.ACTIVE : ArchivedStatus.ARCHIVED)}
                inputProps={{
                    'aria-label': 'secondary checkbox',
                }}
            />
        )
    }

    changeArchivedStatus(id, status) {

        updateArchivedStatus({
            id: id,
            archivedStatus: status
        }).then(response => {
            this.fetchData()
        })
    }

    renderEmail(item) {
        if(!item) {
            return '';
        }

        if(item.user) {
            return item.user.email;
        }

        return '';
    }

    renderStage(item) {
        return getApplicationStage(item);
    }

    renderStatus(item) {
        return getApplicationStatusString(item.applicationStatus)
    }

    renderResendButton(id) {
        return (
            <div className='comments-btn'>
                <Button variant="contained" color="primary" onClick={() => this.resendEmail(id)}>
                    { strings.viewCandidates.resend }
                </Button>
            </div>
        );
    }

    renderAutomaticCheck(id) {
        let application = this.findApplication(id, this.state.tableData);

        let text = ''

        if (application.rtwProcess === RTWProcess.MANUAL) {
            if (application.rtwStatus === ApplicationStatusRTW.ACCEPTED) {
                text = 'Manual Approved'
            } else if (application.rtwStatus === ApplicationStatusRTW.REJECTED) {
                text = 'Manual Rejected'
            } else {
                text = 'Manual in progress'
            }
        } else if(application.rtwProcess === RTWProcess.IDVT) {
            if (application.rtwStatus === ApplicationStatusRTW.IDVT_ACCEPTED) {
                text = 'IDVT Approved'
            }
            else if (application.rtwStatus === ApplicationStatusRTW.IDVT_REJECTED) {
                text = 'IDVT Rejected'
            }
            else {
                text = 'IDVT in progress'
            }
        } else {
            if (application.rtwStatus === ApplicationStatusRTW.SHARE_CODE_ACCEPTED) {
                text = 'Share Code Approved'
            } else if (application.rtwStatus === ApplicationStatusRTW.SHARE_CODE_REJECTED) {
                text = 'Share Code Rejected'
            } else {
                text = 'Share Code in progress'
            }
        }


        return <div>
            {text}
            <IconButton>
                <img src={'images/history-icon.png'} onClick={() => this.toggleViewHistoryModal(id)}/>
            </IconButton>
        </div>
    }

    renderResetButton(id) {

        let application = this.findApplication(id);

        return (
            <div className='doc-check-btn'>
                <Button variant="contained" color="primary" onClick={() => this.toggleResetModal(id)} 
                disabled={ application.applicationInternalStatus < ApplicationInternalStatus.CONDITIONAL_OFFER_SENT }>
                    { strings.viewCandidates.reset }
                </Button>
            </div>
        );
    }

    viewResetModal(){
        return (
            <ResetModal
                toggleModal={this.toggleResetModal}
                open={this.state.viewResetModal}
                title={strings.viewCandidates.resetCandidate}
                openDialog={this.openResetModal}
                applicationId={this.state.selectedApplication}
                resetYes={this.resetYes}
            />
        );
    }

    resetYes(id) {

        resetApplication(id).then(response => {
            
            this.toggleResetModal();

            this.fetchData();
        })

        
    }

    renderDate(item){

        if(!item){
            return '';
        }

        return transformDate(item);
    }

    renderViewApplicant(id){

        return (
            <div className='candidate-button candidate-view-applicant'>
                <Button variant="contained" color="primary" onClick={() => this.toggleViewApplicantModal(id)}>
                    { strings.candidateProfiles.viewApplicant }
                </Button>
            </div>
        );
    }

    findFile(id) {

        for (let file of this.state.files) {
            if (file && file.id == id) {
                return file;
            }
        }

        return undefined;
    }

    resendEmail(id) {

        applicationResendEmail(id).then(response => {

            if(!response || !response.ok) {
                this.props.enqueueSnackbar(strings.viewCandidates.errorResendEmail, { variant: 'error' });
                return;
            }

            this.props.enqueueSnackbar(strings.viewCandidates.emailResend, { variant: 'success' });
        });
    }

    fetchData() {
        if(!this.state.filter) {
            return;
        }

        this.setState({
            lockTable: true
        });

        getCodebooks().then(response => {

            if(!response.ok) {
                return;
            }

            this.setState({
                titles: response.data.titles,
                genders : response.data.genders,
                divisions: response.data.divisions,
                regions: response.data.regions,
                recruiters: response.data.recruiters,
                jobRoles: response.data.jobRoles,
                travelPreferences: response.data.travelPreferances,
                nationalities: response.data.nationalities,
                idDocumentTypes: response.data.idDocumentTypes,
                proofAddressDocumentTypes: response.data.proofAddressDocumentTypes,
                sessionTypes: response.data.sessionTypes,
                territoryOfWorks: response.data.territoryOfWorks,
            });
        });
        
        if(this.state.filter) {
            this.state.filter.applicationInternalStatus = ApplicationInternalStatus.ALL;
            this.state.filter.view = ApplicationView.VIEW_CANDIDATES;
            this.state.filter.search = this.state.searchData.search;
            this.state.filter.page = this.state.searchData.page - 1;
            this.state.filter.perPage = this.state.searchData.perPage;
        }

        getApplications(this.state.filter).then(response => {

            if(!response.ok) {
                return;
            }

            this.setState({
                tableData: response.data.entities ? response.data.entities : [],
                total: response.data.total,
                lockTable: false
            }, () => {
                const applicationId = this.getSearchParam('id');
                if(applicationId) {
                    this.showViewDocumentModal(applicationId, true);
                }
            });

        });
    }

    componentDidMount() {

        this.props.setApplicationId(-1);

        this.state.filter = this.props.filter ? this.props.filter.filterData : {
            division: -1,
            region: -1,
            location: -1,
            recruiter: -1,
            jobRole: -1,
            applicationStatus: -1,
            contractType: -1,
            search: '',
            page: 0,
            perPage: 30
        };

        this.fetchData();
    }

    componentWillReceiveProps(props) {
        this.state.filter = props.filter ? props.filter.filterData : {
            division: -1,
            region: -1,
            location: -1,
            recruiter: -1,
            jobRole: -1,
            applicationStatus: -1,
            contractType: -1,
            search: '',
            page: 0,
            perPage: 30,
            dateFrom: null,
            dateTo: null,
            stage: -1
        };
        
        this.fetchData();
    }

    renderName(id){

        let application = this.findApplication(id);

        if(!application) {
            return '';
        }

        return <div>
            <div>
                {this.renderCandidateImage()}
            </div>
            <div>
                { this.renderFirstName(application) + ' ' + this.renderLastName(application) }
            </div>
        </div>
    }

    renderCandidateImage(item){
        return(
            <div className={'candidate-profile-image'}>
                {
                    this.state.tableData.image
                        ?
                        <img src={'/images/background.jpg'} alt={'logo'} />
                        :
                        <img src={'/images/user.png'} alt={'logo'} />
                }
            </div>
        );
    }

    renderDocViewerButton(id){

        return (
            <div className='candidate-button candidate-view-doc'>
                <Button variant="contained" color="primary" onClick={() => this.toggleViewDocumentsModal(id)}>
                    { strings.candidateProfiles.viewDocuments }
                </Button>
            </div>
        );
    }

    renderConfirmButton(id){
        return (
            <div className='candidate-button candidate-confirm'>
                <Button variant="contained" color="primary">
                    { strings.candidateProfiles.confirm }
                </Button>
            </div>
        );
    }

    findApplication(id) {
        for(let item of this.state.tableData) {
            if(item.id == id) {
                return item;
            }
        }

        return null;
    }

    deleteFile(id) {
        let file = this.findFile(id);

        deleteFile(id).then(response => {

            let files = this.state.files;
            let index = files.indexOf(file)
            if (index > -1) {
                files.splice(index, 1);
            }

            this.setState({
                files: files
            });
        });
    }

    viewDocumentsModalRender(files){
        return (
            <ViewDocumentsModal
                toggleModal={this.toggleViewDocumentsModal}
                open={this.state.openDocView}
                uploadedFiles={files}
                title={strings.documents.viewDocuments}
                openDialog={this.openViewDocuments}
                deleteFile={this.deleteFile}
                onChange={this.onChange}
                data={this.state.selectedItem}
                fullscreen={this.state.documentModalFullscreen}
            />
        );
    }

    viewHistoryModalRender(histories){
        return (
            <ViewHistoryModal
                toggleModal={this.toggleViewHistoryModal}
                open={this.state.openHistoryView}
                histories={histories}
                title={strings.histories.viewHistories}
            />
        );
    }

    viewApplicant(){

        if(!this.state.selectedApplication) {
            return;
        }

        return (
            <ViewApplicantModal
                toggleModal={this.toggleViewApplicantModal}
                open={this.state.openApplicantView}
                title={strings.candidateProfiles.viewApplicant}
                openDialog={this.openViewApplicant}
                deleteFile={this.deleteFile}
                onChange={this.onChange}
                application={this.state.selectedApplication}
                statusUpdate={this.statusUpdate}
                data={this.state.data}
            />
        );
    }

    toggleViewDocumentsModal(id){


        if(id != -1) {
            getFiles(id).then(response => {

                if(!response || !response.ok) {
                    return;
                }
                this.setState({
                    files: response.data
                });
    
            });
        }
        else {
            this.setState({
                files: []
            });
        }

        this.setState({
            openDocView: !this.state.openDocView
        });
    }

    toggleViewHistoryModal(id){

        if(id != -1) {
            getHistories(id).then(response => {

                if(!response || !response.ok) {
                    return;
                }

                this.setState({
                    histories: response.data
                });

            });
        }
        else {
            this.setState({
                histories: []
            });
        }
        this.setState({
            openHistoryView: !this.state.openHistoryView
        });
    }
    showViewDocumentModal(id, documentModalFullscreen = false) {

        if (id != -1) {
            getFiles(id).then(response => {

                if (!response || !response.ok) {
                    return;
                }

                this.setState({
                    files: response.data
                });

            });
        }
        else {
            this.setState({
                files: []
            });
        }

        this.setState({
            openDocView: true,
            documentModalFullscreen: documentModalFullscreen
        });
    }

    toggleViewApplicantModal(id){

        this.state.selectedApplication = this.findApplication(id);

        getFiles(id).then(response => {

            if(!response || !response.ok) {
                return;
            }

            this.setState({
                files: response.data
            });

        });

        this.setState({
            openApplicantView: !this.state.openApplicantView
        });
    }

    openViewDocuments() {
        if (this.viewDocumentsRef.current) {
            this.viewDocumentsRef.current.open();
        }
    };

    openViewApplicant() {
        if (this.viewApplicantRef.current) {
            this.viewApplicantRef.current.open();
        }
    };


    getPageHeader() {
        return <h1>{ strings.viewCandidates.viewCandidates }</h1>;
    }

    renderLabel(item){
        if(item.label !== 'Upload Files'){
            return item.label;
        }

        if(item.key === 'warning'){
            return '';
        }
    }

    renderTableHeader() {

        let result = [];
        let className = '';

        for(let item of this.tableDescription) {

            if(item.label === 'Share Code No.' || item.label === 'Upload Files'){
                className = 'gray';
            }else {
                className = '';
            }

            result.push(
                <TableCell className={className} key={ 'table-header-' + result.length } >
                    { this.renderLabel(item) }
                </TableCell>
            )
        }

        return (
            <TableHead className='table-header'>
                <TableRow>
                    { result }
                </TableRow>
            </TableHead>
        );
    }

    renderTableRowData(item) {

        let result = [];
        let className = '';

        for(let description of this.tableDescription) {

            if(description.label === 'Share Code No.' || description.label === 'Upload Files'){
                className = 'gray';
            }else if(description.key === 'warning'){
                className = 'warning';
            }else {
                className = '';
            }

            result.push(
                <TableCell className={className} key={ 'table-data-' + description.key + '-' + result.length } className={ description.class ? description.class : '' }>
                    {
                        description.key && description.transform !== undefined &&
                        this[description.transform](item[description.key])
                    }
                    {
                        !description.key && description.transform !== undefined &&
                        this[description.transform](item)
                    }
                    {
                        description.transform === undefined &&
                        item[description.key]
                    }
                </TableCell>
            );
        }

        return result;
    }

    renderTableRow(data = []) {

        let result = [];

        for(let item of data) {

            let className = 'table-row';

            if(this.isRowSelected(item)) {
                className += ' selected';
            }

            result.push(
                <TableRow key={ 'table-row-' + result.length } className={ className } onClick={ () => this.selectRow(item) }>
                    { this.renderTableRowData(item) }
                    {/*{ this.renderRowMenu(result.length, item) }*/}
                </TableRow>
            )
        }

        return (
            <TableBody>
                { result }
            </TableBody>
        );
    }

    renderRowMenu(index, item) {

        let ariaOwns = 'action-menu-' + index;

        return(
            <TableCell>


            </TableCell>
        );
    }

    toggleWithdrawModel() {
        this.setState({
            openWithdrawModal: !this.state.openWithdrawModal
        })
    }

    applyWithdrawal(applicationId, emailOption) {
        let data = {
            ...this.state.withdrawApplication,
            applicationStatus: ApplicationStatus.WITHDRAWN,
            emailOption: emailOption
        };

        updateStatuses(data).then(response => {

            if(!response || !response.ok) {
                this.props.enqueueSnackbar(strings.candidateProfile.errorUpdateStatuse, { variant: 'error' });
                return;
            }
            this.props.enqueueSnackbar(strings.candidateProfile.statusUpdated, { variant: 'success' });

            this.fetchData();
        });
    }

    withdrawModal() {
        return <WithdrawConfirmationModal
            toggleModal={this.toggleWithdrawModel}
            open={this.state.openWithdrawModal}
            title={strings.candidateProfiles.withdrawMessage}
            openDialog={this.toggleWithdrawModel}
            application={this.state.withdrawApplication}
            apply={this.applyWithdrawal}
        />
    }

    render() {

        let docView = this.viewDocumentsModalRender(this.state.files);
        let historyView = this.viewHistoryModalRender(this.state.histories);
        let applicantView = this.viewApplicant();
        let resetModal = this.viewResetModal();
        let withdrawModal = this.withdrawModal();

        return (
            <div>
                <Grid id='candidate-profiles-table-page'>
                    { this.renderDialog(strings.table.confirmDelete, 'To subscribe to this website, please enter your email address here. We will send\n' +
                        'updates occasionally.', this.cancelDelete, this.delete) }
                    <div className='header'>
                        { this.getPageHeader() }

                        <div className='filter-controls'>
                            {
                                this.state.showSearch &&
                                <div className={'filter-wrapper'}>
                                    <TextField
                                        label={ strings.table.search }
                                        type="search"
                                        name='search'
                                        value={ this.state.searchData.search }
                                        onChange={ this.searchChanged }
                                        variant={'outlined'}
                                    />
                                    <SearchIcon/>
                                </div>
                            }
                            {
                                this.state.showAdd &&
                                this.renderTableControls()
                            }
                        </div>
                    </div>
                    <Paper className={'table-wrapper'} md={12}>
                        { this.renderTable(this.state.tableData) }
                    </Paper>

                    <Drawer id='drawer' anchor='right' open={  this.showDrawer() } onClose={ () => this.setPageState(PageState.View) } >
                        <DrawerWrapper onBack={ () => this.setPageState(PageState.View) }>
                            { this.renderDrawerContent() }
                        </DrawerWrapper>
                    </Drawer>
                </Grid>
                {historyView}
                {docView}
                {applicantView}
                {resetModal}
                {withdrawModal}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen,
        setApplicationId: Actions.setApplicationId
    }, dispatch);
}

function mapStateToProps({ menuReducers, filterReducers })
{
    return { menu: menuReducers, filter: filterReducers};
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewCandidates)));