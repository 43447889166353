import React, {Component} from 'react';
import {Dialog, Divider, Slide } from "@material-ui/core/";
import CloseIcon from '@material-ui/icons/Close';
import {Button, IconButton } from "@material-ui/core";
import strings from "../localization";

import FormComponent from "./FormComponent";

class BaseConfirmDialog extends FormComponent {

    constructor(props) {
        super(props);

        this.state = {
            data: {}
        }
    }

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="left" ref={ref} {...props} />;
    });

    yes() {
        this.props.yes();
    }

    no() {
        this.props.no();
    }

    render() {

        return (
            <Dialog
                onClose={this.props.close}
                aria-labelledby="customized-dialog-title"
                open={this.props.open}
                TransitionComponent={this.Transition}
                className={'verify-modal'}
            >
                <div className='form-modal-header' onClose={this.props.close}>
                    <div>
                        <h1>{this.props.title}</h1>
                        <IconButton className={'close-button-wrapper'} onClick={this.props.close}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <Divider />
                </div>

                <div className={'verify-wrapper'}>
                    <div className={'verify-message'}>
                        { this.props.message }
                    </div>

                    <div className={'verify-buttons'}>
                        <Button className={'yes'} variant="contained" color="primary" onClick={() => this.yes()}>
                            { strings.table.yes }
                        </Button>
                        <Button className={'no'} variant="contained" color="secondary" onClick={() => this.no()}>
                            { strings.table.no }
                        </Button>
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default BaseConfirmDialog;