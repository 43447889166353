import React from 'react';
import strings from "../../../localization";
import {getError, hasError} from "../../../functions/Validation";
import {Button, TextField, Divider } from "@material-ui/core";
import SelectControl from '../../controls/SelectControl';
import Grid from '@material-ui/core/Grid';

const PersonalDetailsContractForm = ({
                                 onSubmit,
                                 onCancel,
                                 onChange,
                                 errors,
                                 data,
                                 titles,
                                 genders,
                                 disable
                             }) => (

    <form id='personal-details-contract-form'>
        <Grid container spacing={9} className={'grid-wrapper'}>
            <Grid item xl={4} lg={5} md={12}>
                <div className={ 'select-container' + (hasError(errors, 'title') ? ' error' : '') }>
                    <label>{ strings.personalDetails.title }</label>
                    <TextField
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={ hasError(errors, 'title') }
                        helperText={ getError(errors, 'title') }
                        fullWidth
                        name='title'
                        onChange={ onChange }
                        value={ data.title }
                        margin="dense"
                        variant='outlined'
                        disabled={ disable }
                    />
                </div>

                <div className={ 'select-container' + (hasError(errors, 'surname') ? ' error' : '') }>
                    <label>{ strings.personalDetails.surname }</label>
                    <TextField
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={ hasError(errors, 'surname') }
                        helperText={ getError(errors, 'surname') }
                        fullWidth
                        name='surname'
                        onChange={ onChange }
                        value={ data.surname }
                        margin="dense"
                        variant='outlined'
                        disabled={ disable }
                    />
                </div>
                <div className={ 'select-container' + (hasError(errors, 'dateOfBirth') ? ' error' : '') }>
                    <label>{ strings.personalDetails.dateOfBirth }</label>
                    <TextField
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={ hasError(errors, 'dateOfBirth') }
                        helperText={ getError(errors, 'dateOfBirth') }
                        fullWidth
                        name='surname'
                        onChange={ onChange }
                        value={ data.dateOfBirth }
                        margin="dense"
                        variant='outlined'
                        disabled={ disable }
                    />
                </div>
            </Grid>

            <Grid item xl={4} lg={5} md={12}>
                <div className={ 'select-container' + (hasError(errors, 'forename') ? ' error' : '') }>
                    <label>{ strings.personalDetails.forename }</label>
                    <TextField
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={ hasError(errors, 'forename') }
                        helperText={ getError(errors, 'forename') }
                        fullWidth
                        name='forename'
                        onChange={ onChange }
                        value={ data.forename }
                        margin="dense"
                        variant='outlined'
                        disabled={ disable }
                    />
                </div>
                <div className={ 'select-container' + (hasError(errors, 'email') ? ' error' : '') }>
                    <label>{ strings.personalDetails.email }</label>
                    <TextField
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={ hasError(errors, 'email') }
                        helperText={ getError(errors, 'email') }
                        fullWidth
                        name='email'
                        onChange={ onChange }
                        value={ data.email }
                        margin="dense"
                        variant='outlined'
                        disabled={ disable }
                    />
                </div>
                <div className={ 'select-container' + (hasError(errors, 'gender') ? ' error' : '') }>
                    <label>{ strings.personalDetails.gender }</label>
                    <TextField
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={ hasError(errors, 'gender') }
                        helperText={ getError(errors, 'gender') }
                        fullWidth
                        name='gender'
                        onChange={ onChange }
                        value={ data.gender }
                        margin="dense"
                        variant='outlined'
                        disabled={ disable }
                    />
                </div>

            </Grid>
        </Grid>
        <Divider />
    </form>
);

export default PersonalDetailsContractForm;