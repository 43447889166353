import React, {Component} from 'react';
import {Button, Dialog, Divider, Slide, Tooltip} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from "@material-ui/core";
import {dateTimeToString} from "../util/DateUtil";
import ApplicationFileStatus from "../constants/ApplicationFileStatus";


class ViewSelfieModal extends Component {

    constructor(props) {
        super(props);
    }

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="left" ref={ref} {...props} />;
    });

    renderSelfie(){
        return (
            <div className='img-size' style={{ backgroundImage: 'url(data:image/png;base64,' + this.props.selfie + ')' }} />
        )
    }

    render() {
        return (
            <Dialog
                onClose={() => this.props.toggleModal(-1)}
                aria-labelledby="customized-dialog-title"
                open={this.props.open}
                TransitionComponent={this.Transition}
                className={'view-selfies-modal'}
            >
                <div className='form-modal-header' onClose={() => this.props.toggleModal(-1)}>
                    <div>
                        <h1 className='documen-view-title'>
                            {this.props.title}

                        </h1>
                        <IconButton className={'close-button-wrapper'} onClick={() => this.props.toggleModal(-1)}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <Divider />
                </div>
                {
                    this.props.selfie && this.props.selfie.length > 0 &&
                    <div id='candidate-selfie-expand-view'>
                        { this.renderSelfie() }
                    </div>
                }
            </Dialog>
        );
    }
}

export default ViewSelfieModal;