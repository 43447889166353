const MedicalConditionStatus = {
    ALL: -1,
    NON_DECLARED: 1,
    SELF_DECLARED: 2,
    MEDICAL_UNSUITABLE: 3,
    HR_APPROVED_MEASURES: 4,
    HR_APPROVED: 5
};

export function getMedicalConditionStatuses() {
    return [
        {
            value: MedicalConditionStatus.NON_DECLARED,
            name: 'Non Declared'
        },
        {
            value: MedicalConditionStatus.SELF_DECLARED,
            name: 'Self Declared'
        },
        {
            value: MedicalConditionStatus.MEDICAL_UNSUITABLE,
            name: 'Medical Unsuitable'
        },
        {
            value: MedicalConditionStatus.HR_APPROVED_MEASURES,
            name: 'HR Approved - Measures'
        },
        {
            value: MedicalConditionStatus.HR_APPROVED,
            name: 'HR Approved'
        }
    ]
}

export function getAllMedicalConditionStatuses() {
    return [
        {
            value: MedicalConditionStatus.ALL,
            name: 'All'
        },
        {
            value: MedicalConditionStatus.NON_DECLARED,
            name: 'Non Declared'
        },
        {
            value: MedicalConditionStatus.SELF_DECLARED,
            name: 'Self Declared'
        },
        {
            value: MedicalConditionStatus.MEDICAL_UNSUITABLE,
            name: 'Medical Unsuitable'
        },
        {
            value: MedicalConditionStatus.HR_APPROVED_MEASURES,
            name: 'HR Approved - Measures'
        },
        {
            value: MedicalConditionStatus.HR_APPROVED,
            name: 'HR Approved'
        }
    ]
}

export default MedicalConditionStatus;