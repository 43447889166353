import React from 'react';
import strings from "../../../localization";
import {getError, hasError} from "../../../functions/Validation";
import {Button, TextField, Divider } from "@material-ui/core";
import SelectControl from '../../controls/SelectControl';
import Grid from '@material-ui/core/Grid';
import DatePickerControl from "../../controls/DatePickerControl";

const PersonalDetailsForm = ({
                      onSubmit,
                      onCancel,
                      onChange,
                      errors,
                      data,
                      titles,
                      genders,
                      disable
                  }) =>{
return(
    <form id='personal-details-form'>
        <Grid container spacing={9} className={'grid-wrapper'}>

            <Grid item xl={4} lg={5} md={12}>
                <div className={ 'select-container' + (hasError(errors, 'title') ? ' error' : '') }>
                    <label>{ strings.personalDetails.title }</label>
                    <SelectControl
                        error={ hasError(errors, 'title') }
                        helperText={ getError(errors, 'title') }
                        fullWidth
                        autoFocus
                        name='title'
                        onChange={ onChange }
                        margin="dense"
                        value={ data.title }
                        options={ titles }
                        nameKey={ 'name' }
                        valueKey={ 'id' }
                        selected={ data.title }
                        isDisabled={ disable }
                        isSearchable={false}
                    />
                    {
                        hasError(errors, 'title') ? <p className='select-error'>{strings.validation.RequiredErrorMessage}</p> : ""
                    }
                </div>

                <div className={ 'select-container' + (hasError(errors, 'surname') ? ' error' : '') }>
                    <label>{ strings.personalDetails.surname }</label>
                    <TextField
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={ hasError(errors, 'surname') }
                        helperText={ getError(errors, 'surname') }
                        fullWidth
                        name='surname'
                        onChange={ onChange }
                        value={ data.surname }
                        margin="dense"
                        variant='outlined'
                        disabled={ disable }
                    />
                </div>

                <div className={ 'select-container' + (hasError(errors, 'dateOfBirth') ? ' error' : '') } id="dateOfBirth">
                    <label>{ strings.personalDetails.dateOfBirth }</label>
                    <DatePickerControl
                        date={data.dateOfBirth}
                        name={'dateOfBirth'}
                        placeholder={ strings.personalDetails.dateOfBirthPlaceholder }
                        onChange={ onChange }
                        dateFormat='dd/MM/yyyy'
                        disabled={ disable }
                        hasError={ hasError(errors, 'dateOfBirth') }
                        error={ getError(errors, 'dateOfBirth') }
                        withPortal={true}
                    />
                </div>

                <div className={ 'select-container' + (hasError(errors, 'gender') ? ' error' : '') }>
                    <label>{ strings.personalDetails.gender }</label>
                    <SelectControl
                        error={ hasError(errors, 'gender') }
                        helperText={ getError(errors, 'gender') }
                        fullWidth
                        autoFocus
                        name='gender'
                        onChange={ onChange }
                        margin="normal"
                        value={ data.gender }
                        options={ genders }
                        nameKey={ 'name' }
                        valueKey={ 'id' }
                        selected={ data.gender }
                        isDisabled={ disable }
                        isSearchable={false}
                    />
                    {
                        hasError(errors, 'gender') ? <p className='select-error'>{strings.validation.RequiredErrorMessage}</p> : ""
                    }
                </div>
            </Grid>

            <Grid item xl={4} lg={5} md={12}>
                <div className={ 'select-container' + (hasError(errors, 'forename') ? ' error' : '') }>
                    <label>{ strings.personalDetails.forename }</label>
                    <TextField
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={ hasError(errors, 'forename') }
                        helperText={ getError(errors, 'forename') }
                        fullWidth
                        name='forename'
                        onChange={ onChange }
                        value={ data.forename }
                        margin="dense"
                        variant='outlined'
                        disabled={ disable }
                    />
                </div>
                <div className={ 'select-container' + (hasError(errors, 'email') ? ' error' : '') }>
                    <label>{ strings.personalDetails.email }</label>
                    <TextField
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={ hasError(errors, 'email') }
                        helperText={ getError(errors, 'email') }
                        fullWidth
                        name='email'
                        onChange={ onChange }
                        value={ data.email }
                        margin="dense"
                        variant='outlined'
                        disabled={ disable }
                    />
                </div>
                <div className={ 'select-container' + (hasError(errors, 'secondEmail') ? ' error' : '') }>
                    <label>{ strings.personalDetails.secondaryEmail }</label>
                    <TextField
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={ hasError(errors, 'secondEmail') }
                        helperText={ getError(errors, 'secondEmail') }
                        fullWidth
                        name='secondEmail'
                        onChange={ onChange }
                        value={ data.secondEmail }
                        margin="dense"
                        variant='outlined'
                        disabled={ disable }
                    />
                </div>
                <div className={ 'select-container' + (hasError(errors, 'firstPhoneContact') ? ' error' : '') }>
                    <label>{ strings.addressDetails.firstPhoneContact } {strings.addressDetails.mobileLandline}</label>
                    <TextField
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={ hasError(errors, 'firstPhoneContact') }
                        helperText={ getError(errors, 'firstPhoneContact') }
                        fullWidth
                        name='firstPhoneContact'
                        onChange={ onChange }
                        margin="normal"
                        value={ data.firstPhoneContact }
                        margin="dense"
                        variant='outlined'
                        disabled={ disable }
                    />
                </div>
                <div className={ 'select-container' + (hasError(errors, 'secondPhoneContact') ? ' error' : '') }>
                    <label>{ strings.addressDetails.secondPhoneContact } {strings.addressDetails.mobileLandline}</label>
                    <TextField
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={ hasError(errors, 'secondPhoneContact') }
                        helperText={ getError(errors, 'secondPhoneContact') }
                        fullWidth
                        name='secondPhoneContact'
                        onChange={ onChange }
                        margin="normal"
                        value={ data.secondPhoneContact }
                        margin="dense"
                        variant='outlined'
                        disabled={ disable }
                    />
                </div>
            </Grid>
        </Grid>
        <Divider />
    </form>
)};

export default PersonalDetailsForm;