import React from 'react'
import {bindActionCreators} from "redux";
import CONFIG from "../../config";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import {withSnackbar} from "notistack";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress,
    Paper,
    TablePagination,
    TextField,
} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import {getAllFilesWithType} from '../../services/FileUpload';
import SearchIcon from '@material-ui/icons/Search';
import UploadSection from "../../constants/UploadSection";
import Page from "../../common/Page";
import ApplicationFileStatus from "../../constants/ApplicationFileStatus";
import SelfieCart from "../../components/SelfieCart";

class SelfieGallery extends Page {

    params = [
        { name: 'search', defaultValue: '' },
        { name: 'page', defaultValue: 1 },
        { name: 'perPage', defaultValue: 30 }
    ];

    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : {},
            showSearch: true,
            galleryData: [],
            showProgressBar: false,
            selfie: undefined,
            total: 0
        };
        this.fetchData = this.fetchData.bind(this);
        this.searchChanged = this.searchChanged.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    }

    componentDidMount() {
        this.state.filter = this.props.filter ? this.props.filter.filterData : {
            search: '',
            page: 0,
            perPage: 30,
            selfieStatus: ApplicationFileStatus.ON_HOLD
        };

        this.fetchData();
    }


    componentWillReceiveProps(props) {

        this.state.filter = props.filter ? props.filter.filterData : {
            search: '',
            page: 0,
            perPage: 30,
            selfieStatus: ApplicationFileStatus.ON_HOLD
        };

        this.fetchData();
    }

    fetchData(){
        if(!this.state.filter) {
            return;
        }

        if(this.state.filter) {
            this.state.filter.search = this.state.searchData.search;
            this.state.filter.page = this.state.searchData.page - 1;
            this.state.filter.perPage = this.state.searchData.perPage;
        }

        this.setState({
            showProgressBar: true
        })

        const filterData = { page: this.state.filter.page, perPage: this.state.filter.perPage, search: this.state.filter.search, applicationFileStatus: this.state.filter.selfieStatus }

        getAllFilesWithType(UploadSection.Selfie, filterData).then(response => {
            if(!response.ok) {
                return;
            }

            this.setState({
                data: response.data ? response.data : {},
                showSearch: this.state.showSearch,
                galleryData: response.data.entities,
                showProgressBar: false,
                total: response.data.total
            });
        });
    }

    handleChangePage(event, page) {

        this.state.searchData.page = page + 1;
        this.fetchData();
    }

    handleChangeRowsPerPage(event) {
        this.state.searchData.perPage = event.target.value;
        this.fetchData();
    }

    handleCartsButtonClick(response) {
        if (!response || !response.ok) {
            this.props.enqueueSnackbar(strings.application.errorSavingApplican, { variant: 'error' });
            return;
        }

        this.props.enqueueSnackbar(strings.application.save, { variant: 'success' });
        return;
    }

    renderCarts(data = []) {
        let result = [];
        for(let item of data) {
            result.push(
                <SelfieCart
                    data={item}
                    onClick={(response) => this.handleCartsButtonClick(response)}
                />
            )
        }

        return ( result );
    }

    getPageHeader() {
        return <h1>{ strings.selfieGallery.selfieGallery }</h1>;
    }

    renderSearch() {
        return (<div className='filter-controls'>
            {
                this.state.showSearch &&
                <div className={'filter-wrapper'}>
                    <TextField
                        label={ strings.table.search }
                        type="search"
                        name='search'
                        value={ this.state.searchData.search }
                        onChange={ this.searchChanged }
                        variant={'outlined'}
                    />
                    <SearchIcon/>
                </div>
            }
        </div>)
    }

    searchChanged(event) {

        let searchData = this.state.searchData;

        searchData[event.target.name] = event.target.value;

        this.setState({
            searchData: searchData
        });

        this.fetchData();
    }

    renderPagination() {
        return (
            <TablePagination
                labelRowsPerPage={"Per page"}
                className='pagination-right'
                count={ this.state.total }
                rowsPerPageOptions={ CONFIG.rowsPerPage }
                page={ this.state.searchData.page - 1 }
                rowsPerPage={ this.state.searchData.perPage }
                onChangePage={ this.handleChangePage }
                onChangeRowsPerPage={ this.handleChangeRowsPerPage }
                SelectProps={{ native: true }}
            />
        );
    }

    render() {
        return (
            <div>
                <Grid id='selfie-gallery-page'>
                    <div className='header'>
                        { this.getPageHeader() }
                        { this.renderSearch() }
                    </div>
                    {
                        this.state.showProgressBar &&
                        <LinearProgress/>
                    }
                    <Paper className='content' md={12}>
                        { !this.state.showProgressBar && this.renderCarts(this.state.galleryData) }
                    </Paper>
                    { this.renderPagination() }
                </Grid>
            </div>
        );
    }
}
function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen,
    }, dispatch);
}

function mapStateToProps({ menuReducers, filterReducers })
{
    return { menu: menuReducers, filter: filterReducers};
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(SelfieGallery)));