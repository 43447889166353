import { validate } from "../functions/Validation";

const YesNoOptions = {
    YES: true,
    NO: false,

};

export default YesNoOptions;

export function getYesNoOptions() {
    const options = [
        {
            value: YesNoOptions.YES,
            name: 'Yes'
        },
        {
            value: YesNoOptions.NO,
            name: 'No'
        }
    ];
    
    return options;
}

export function getYesNoOption(value) {
    if(value) {
        return {
            value: YesNoOptions.YES,
            name: 'Yes'
        }
    }

    return {
        value: YesNoOptions.NO,
        name: 'No'
    }
}