const ApplicationInternalStatus = {
    ALL: -1,
    APPLICANT_FILING_DATA: 0,
    APPLICANT_FILING_DATA_COMPLETED: 1,
    NEED_CONDITIONAL_OFFER: 2,
    CONDITIONAL_OFFER_SENT: 3,
    CONTRACT_FILLED: 4,
    CONFIRMED: 5
};

export default ApplicationInternalStatus;

        