import React, {Component} from 'react';
import {Dialog, Divider, Slide} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { Button, IconButton } from "@material-ui/core";
import strings from "../localization";

class ResetModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: {}
        }
    }

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="left" ref={ref} {...props} />;
    });

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.data !== this.props.data){
            this.setState({
                data: this.props.data
            });
        }
    }

    render() {
        return (
            <Dialog
                onClose={this.props.toggleModal}
                aria-labelledby="customized-dialog-title"
                open={this.props.open}
                TransitionComponent={this.Transition}
                className={'send-modal'}
            >
                <div className='form-modal-header' onClose={this.props.toggleModal}>
                    <div>
                        <h1>{this.props.title}</h1>
                        <IconButton className={'close-button-wrapper'} onClick={this.props.toggleModal}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <Divider />
                </div>
                <div className={'send-offer-wrapper'}>
                    <div className={'send-offer-message'}>
                        {strings.viewCandidates.resetMessage}
                    </div>
                    <div className={'send-offer-buttons'}>
                        <Button className={'yes'} variant="contained" color="primary" onClick={() => this.props.resetYes(this.props.applicationId)}>
                            { strings.table.yes }
                        </Button>
                        <Button className={'no'} variant="contained" color="secondary" onClick={this.props.toggleModal}>
                            { strings.table.no }
                        </Button>
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default ResetModal;