const FilterTypes = {
    DIVISION: 1,
    REGION: 2,
    ROLE: 3,
    STATUS: 4,
    LOCATION: 5,
    CONTRACT_TYPE: 6,
    RECRUITER: 7,
    DATE_FROM: 8,
    DATE_TO: 9,
    STATUS_ALL: 10,
    STAGE: 11,
    ARCHIVED: 12,
    MEDICAL_CONDITION_STATUS: 13,
    CRIMINAL_RECORD_STATUS: 14,
    STATUS_SELFIE: 15
};

export default FilterTypes;

export function getFilterTypes() {
    const filters = [
        {
            value: FilterTypes.DIVISION,
            name: 'Division'
        },
        {
            value: FilterTypes.REGION,
            name: 'Region'
        },
        {
            value: FilterTypes.ROLE,
            name: 'Role'
        },
        {
            value: FilterTypes.STATUS,
            name: 'Status'
        },
        {
            value: FilterTypes.LOCATION,
            name: 'Location'
        },
        {
            value: FilterTypes.CONTRACT_TYPE,
            name: 'Contract Type'
        },
        {
            value: FilterTypes.RECRUITER,
            name: 'Recruiter'
        },
        {
            value: FilterTypes.STATUS_ALL,
            name: 'Status All'
        },
    ];

    return filters;
}

export function getFilterTypesString(filter) {
    const selectedFilter = getFilterTypes().find(item => item.value === filter);

    return selectedFilter ? selectedFilter.name : '';
}