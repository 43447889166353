import React, {Component, createRef} from 'react';
import {Dialog, Divider, Slide} from "@material-ui/core/";
import CloseIcon from '@material-ui/icons/Close';
import {Button, Chip, IconButton, LinearProgress, TextField, Tooltip} from "@material-ui/core";
import strings from "../localization";
import {getContractTypesString} from "../constants/ContractTypes";
import {findDataValue} from "../util/DropdownUtil";
import {checkCondition} from "../util/FieldsConditionUtil";
import FormComponent from "./FormComponent";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import CONFIG from "../config";
import Dropzone from "react-dropzone";
import {deleteFile, getFiles, uploadFiles} from "../services/FileUpload";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import UserType from "../constants/UserType";
import HRApprovalStatus from "../constants/HRApprovalStatus";
import {changeHRApprovalStatus} from "../services/OfferService";

class HRApproveModal extends FormComponent {

    constructor(props) {
        super(props);

        this.state = {
            data: {
                comment: ''
            },
            errors: {},
            application: props.application ? props.application : undefined,
            files: [],
            showProgressBar: false
        }

        this.dropzoneRef = createRef();
        this.openDialog = this.openDialog.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.onDropRejected = this.onDropRejected.bind(this);
        this.handleDeleteFile = this.handleDeleteFile.bind(this);
        this.changeOfferHRState = this.changeOfferHRState.bind(this);
    }

    componentWillReceiveProps(props) {
        this.state.application = props.application;
        //console.log(this.state.application)

        if (!this.state.application) {

            this.setState({
                data: {
                    comment: ''
                },
                errors: {}
            });

            return;
        }

        this.setState({
            data: {
                comment: this.state.application?.offer?.hrApprovalComment
            },
        });

        this.fetch();
    }

    componentDidMount() {

        if (!this.state.application) {

            this.setState({
                data: {
                    comment: ''
                },
            });

            return;
        }

        this.setState({
            data: {
                comment: this.state.application?.offer?.hrApprovalComment
            },
        });

        this.fetch();
    }

    fetch() {

        if (!this.state.application) {
            return;
        }

        this.setState({
            showProgressBar: true
        })

        getFiles(this.state.application.id).then(response => {

            this.setState({
                files: response.data ? response.data : [],
                showProgressBar: false
            })
        })
    }

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="left" ref={ref} {...props} />;
    });

    openDialog() {
        if (this.dropzoneRef.current) {
            this.dropzoneRef.current.open();
        }
    };

    onDropRejected(files) {

    }

    onDrop(acceptedFiles) {

        if (!acceptedFiles || acceptedFiles.length === 0) {

            return;
        }

        this.setState({
            showProgressBar: true
        })

        for (let acceptedFile of acceptedFiles) {

            let applicationFile = new FormData();
            applicationFile.append('application', this.state.application.id);
            applicationFile.append('name', acceptedFile.name);
            applicationFile.append('content', acceptedFile);
            applicationFile.append('fileType', acceptedFile.type);

            uploadFiles(applicationFile).then((result) => {

                if (!result || !result.ok) {
                    //this.props.enqueueSnackbar("Error uploading file", {variant: 'error'});
                }

                let files = this.state.files;

                files.push(result.data);

                this.setState({
                    files: files,
                    showProgressBar: false
                });
            });
        }
    }

    handleDeleteFile(id) {
        deleteFile(id).then(response => {
            this.fetch();
        })
    }

    renderFiles() {

        let result = [];

        for (let file of this.state.files) {

            let shortName = file ? file.name : '';

            if (shortName.length > 22) {
                shortName = shortName.substring(0, 22) + '...';
            }

            let content = "data:" + file.fileType + ";base64," + file.content;

            result.push(<div className='file'>
                <Tooltip title={file ? file.name : ''}>
                    <Chip
                        icon={<AttachFileIcon/>}
                        label={shortName}
                        clickable
                        color="success"
                        className={'chip-uploaded-file'}
                    />
                </Tooltip>
                <div className='controls'>
                    <div className={'buttons'}>
                        <div className={'download-button'}>
                            <a download={content} href={content}>
                                <img src={'/images/download.png'} alt={'logo'}/>
                            </a>
                        </div>
                        <div className={'printing-button'}>
                            <a target="_blank" href={content}>
                                <img src={'/images/printing.png'} alt={'logo'}/>
                            </a>
                        </div>
                        <div className={'delete-button'} onClick={() => this.handleDeleteFile(file.id)}>
                            <img src={'/images/delete.png'} alt={'logo'}/>
                        </div>
                    </div>
                </div>


            </div>)
        }

        return result;
    }

    changeOfferHRState(state) {

        let data = {
            offerId: this.state.application.id,
            state: state,
            comment: this.state.data.comment
        }

        changeHRApprovalStatus(data).then(response => {
            this.props.toggleModal();
        })
    }

    render() {

        return (
            <Dialog
                onClose={this.props.toggleModal}
                aria-labelledby="customized-dialog-title"
                open={this.props.open}
                TransitionComponent={this.Transition}
                className={'hr-approval-modal'}
            >
                <div className='form-modal-header' onClose={this.props.toggleModal}>
                    <div>
                        <h1>{this.props.title}</h1>
                        <IconButton className={'close-button-wrapper'} onClick={this.props.toggleModal}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <Divider/>
                    <div className={'hr-approval-modal hr-approval-modal-wrapper'}>
                        <div className={'inner-wrapper'}>
                            <div className={'content-wrapper'}>
                                <div className={'left-wrapper'}>
                                    <div className={'first-name left'}>
                                        <label>{strings.makeOffer.firstName}</label>
                                        <div className={'inner-left'}>
                                            {this.state.application ? this.state.application.forename : ""}
                                        </div>
                                    </div>
                                    <div className={'division left'}>
                                        <label>{strings.makeOffer.division}</label>
                                        <div className={'inner-left'}>
                                            {
                                                this.state.application &&
                                                findDataValue(this.state.application, 'division')
                                            }
                                        </div>
                                    </div>
                                    <div className={'contract-type left'}>
                                        <label>{strings.makeOffer.jobRole}</label>
                                        <div className={'inner-left'}>
                                            {
                                                this.state.application &&
                                                (findDataValue(this.state.application, 'jobRole') ? findDataValue(this.state.application, 'jobRole') : this.state.application?.jobRoleText)
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={'right-wrapper'}>
                                    <div className={'surname right'}>
                                        <label>{strings.makeOffer.surname}</label>
                                        <div className={'inner-right'}>
                                            {this.state.application ? this.state.application.surname : ''}
                                        </div>
                                    </div>
                                    {
                                        checkCondition(this.state.application, 'region') &&
                                        <div className={'region right'}>
                                            <label>{strings.makeOffer.region}</label>
                                            <div className={'inner-right'}>
                                                {
                                                    this.state.application &&
                                                    findDataValue(this.state.application, 'region')
                                                }
                                            </div>
                                        </div>
                                    }
                                    <div className={'contract-type right'}>
                                        <label>{strings.makeOffer.contractType}</label>
                                        <div className={'inner-right'}>
                                            {
                                                this.state.application &&
                                                getContractTypesString(this.state.application.contractType)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='comment-area'>
                                <label>Comment</label>
                                <TextField
                                    fullWidth
                                    name='comment'
                                    onChange={this.changeData}
                                    variant={"outlined"}
                                    multiline
                                    value={this.state.data.comment}
                                    rows={2}
                                    maxRows={4}
                                />
                            </div>
                            <div className='documents-header'>
                                <h3>Documents</h3>
                                <Dropzone
                                    onDropAccepted={this.onDrop}
                                    onDropRejected={this.onDropRejected}
                                    onErr
                                    multiple={true}
                                    ref={this.dropzoneRef}
                                    accept={CONFIG.acceptFiles}
                                    maxSize={1048576 * 10}
                                >
                                    {({getRootProps, getInputProps}) => (
                                        <section className={'file-dropzone'}>
                                            <div {...getRootProps()} className={'cursor-pointer'}>
                                                <input {...getInputProps()} />
                                                <Button variant="contained" color="primary">
                                                    Upload documents
                                                </Button>
                                            </div>
                                        </section>

                                    )}
                                </Dropzone>
                            </div>
                            <div className='files'>
                                {
                                    this.state.showProgressBar &&
                                    <LinearProgress/>
                                }

                                {this.renderFiles()}
                            </div>
                        </div>

                        {
                            ((this.props.auth.user.userType === UserType.RECRUITMENT_MANAGER
                                || this.props.auth.user.userType === UserType.HR_RECRUITMENT) &&
                            this.state.application?.offer?.hrApprovalStatus === HRApprovalStatus.WAITING_SUBMIT_FOR_APPROVAL) &&
                            <Button className={'green-button'} variant="contained" color="primary" onClick={() => this.changeOfferHRState(HRApprovalStatus.WAITING_APPROVAL)}>
                                Send for HR approval
                            </Button>
                        }

                        {
                            ((this.props.auth.user.userType === UserType.HR_MANAGER
                            || this.props.auth.user.userType === UserType.HR_RECRUITMENT) &&
                            this.state.application?.offer?.hrApprovalStatus === HRApprovalStatus.WAITING_APPROVAL) &&
                            <React.Fragment>
                                <div className='buttons-container'>
                                    <Button className={'green-button'} variant="contained" color="primary" onClick={() => this.changeOfferHRState(HRApprovalStatus.APPROVED)}>
                                        Approve
                                    </Button>
                                    <Button className={'yellow-button'} variant="contained" color="primary" onClick={() => this.changeOfferHRState(HRApprovalStatus.DECLINED)}>
                                        Decline
                                    </Button>
                                </div>

                            </React.Fragment>


                        }

                    </div>

                </div>
            </Dialog>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

function mapStateToProps({authReducers, siteDataReducers}) {
    return {auth: authReducers, siteData: siteDataReducers};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HRApproveModal));

