const ApplicationFileStatus = {
    ON_HOLD: 0,
    ACCEPTED: 1,
    REJECTED: 2
};

export default ApplicationFileStatus;

export function getApplicationFileStatus() {
    const statuses = [
        {
            value: ApplicationFileStatus.ACCEPTED,
            name: 'Accepted'
        },
        {
            value: ApplicationFileStatus.REJECTED,
            name: 'Rejected'
        }
    ];

    return statuses;
}