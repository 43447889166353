import React from 'react'
import {bindActionCreators} from "redux";
import * as Actions from "../../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Page from "../../../common/Page";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Validators from "../../../constants/ValidatorTypes";
import AddCandidateForm from '../../../components/forms/user/AddCandiateForm';
import {addCandidate} from '../../../services/AddCandidateService';
import strings from '../../../localization';
import {withSnackbar} from "notistack";
import {getCodebooks} from "../../../services/CodebookService";

class AddCandidate extends Page {

    validationList = {
        email: [{type: Validators.EMAIL}],
        firstName: [{type: Validators.REQUIRED}],
        lastName: [{type: Validators.REQUIRED}]
    };

    constructor(props) {
        super(props);

        this.state = {
            data: {
                firstName: '',
                lastName: '',
                email: '',
            },
            divisions: [],
            recruiters: [],
            userDivisions: [],
            jobRoles: [],
            errors: {},
            redirectUrl: props.location.state ? props.location.state.redirectUrl : '/'
        };

        this.keyPress = this.keyPress.bind(this);
    }

    componentDidMount() {

        this.fetchData();

        this.props.setApplicationId(-1);

        if (!this.props.auth.user) {
            this.props.history.push('/');
        }
    }

    fetchData() {
        getCodebooks().then(response => {
            if (!response.ok) {
                return;
            }

            this.setState({
                divisions: response.data.divisions,
                recruiters: response.data.recruiters,
                userDivisions: response.data.userDivisions,
                jobRoles: response.data.jobRoles,
            });
        });
    }

    keyPress(event) {
        if (event.key == 'Enter') {
            this.addCandidate()
        }
    }

    addCandidate() {
        if (!this.validate()) {
            return;
        }

        addCandidate({
            firstName: this.state.data.firstName,
            lastName: this.state.data.lastName,
            email: this.state.data.email,
            divisionId: this.state.data.division ? this.state.data.division.id : -1,
            jobRoleId: this.state.data.jobRole ? this.state.data.jobRole.id : -1,
            recruiterId: this.state.data.recruiter ? this.state.data.recruiter.id : -1,
            jobRoleText: this.state.data.jobRoleText
        }).then(response => {
            if (!response.ok) {
                this.props.enqueueSnackbar(strings.addUser.errorAddingUser, {variant: 'error'});
                return;
            }

            this.setState({
                data: {
                    firstName: '',
                    lastName: '',
                    email: '',
                }
            });

            this.props.enqueueSnackbar(strings.addUser.userAdded, {variant: 'success'});

            this.hideDrawerLoader();
        });
    }

    render() {
        return (
            <div id='add-candidate'>
                <h1>{strings.addCandidate.addCandidate}</h1>
                <Paper className='paper'>

                    <AddCandidateForm onSubmit={() => this.addCandidate()} onChange={this.changeData}
                                      keyPress={this.keyPress}
                                      divisions={this.state.divisions}
                                      recruiters={this.state.recruiters}
                                      userDivisions={this.state.userDivisions}
                                      jobRoles={this.state.jobRoles}
                                      data={this.state.data} errors={this.state.errors}
                    />
                </Paper>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen,
        setApplicationId: Actions.setApplicationId
    }, dispatch);
}

function mapStateToProps({menuReducers, authReducers}) {
    return {menu: menuReducers, auth: authReducers};
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(AddCandidate)));