import {request} from "../base/HTTP";
import HttpMethod from "../constants/HttpMethod";
import {dateToTicks} from "../util/DateUtil";

export async function uploadFiles(data) {
    return await request('api/application_file', data, HttpMethod.POST);
}

export async function uploadFilesRightToWork(data) {
    return await request('api/application_file/right_to_work', data, HttpMethod.POST);
}

export async function getFiles(id) {
    return await request('api/application_file/' + id, {}, HttpMethod.GET);
}

export async function changeFileType(id, type) {
    return await request('api/application_file/document_type/' + id, { documentType: parseInt(type) }, HttpMethod.PUT);
}

export async function changeFileStatus(id, status) {
    return await request('api/application_file/status/' + id + "/" + status, {}, HttpMethod.PUT);
}

export async function deleteFile(id) {
    return await request('api/application_file/' + id, {}, HttpMethod.DELETE);
}

export async function getAllFilesWithType(type, data) {
    return await request('api/application_file/all/'+ type, data, HttpMethod.GET);

}