import React, {Component} from 'react';
import {Dialog, Divider, Slide} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import {Button, IconButton, TextField} from "@material-ui/core";
import strings from "../localization";
import {findDataValue} from "../util/DropdownUtil";
import {checkCondition} from "../util/FieldsConditionUtil";
import ContractTypeMode, {getContractTypesString} from "../constants/ContractTypes";
import { dateTimeToString, dateToString } from '../util/DateUtil';
import { getOffer } from '../services/OfferService';

class ViewOfferModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : {}
        }
    }

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="left" ref={ref} {...props} />;
    });

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.data !== this.props.data){
            this.setState({
                data: this.props.data
            });
        }
    }

    componentDidMount() {

        this.fetch();
    }

    fetch() {
        if(!this.state.data || !this.state.data.id) {
            return;
        }

        getOffer(id).then(response => {

            if(!response || !response.ok) {
                return;
            }

            let data = this.state.data;
            data.offer = response.data;

            this.setState({
                data: data
            });s
        });
    }

    render() {

        return (
            <Dialog
                onClose={this.props.toggleModal}
                aria-labelledby="customized-dialog-title"
                open={this.props.open}
                TransitionComponent={this.Transition}
                className={'view-offer-modal'}
            >
                <div className='form-modal-header' onClose={this.props.toggleModal}>
                    <div>
                        <h1>{this.props.title}</h1>
                        <IconButton className={'close-button-wrapper'} onClick={this.props.toggleModal}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <Divider />
                    <div className={'view-offer-wrapper'}>
                        <div className={'content-wrapper'}>
                            <div className={'left-wrapper'}>
                                <div className={'first-name left'}>
                                    <label>{strings.hrManager.firstName}</label>
                                    <div className={'inner-left'}>
                                        {this.state.data.forename}
                                    </div>
                                </div>
                                <div className={'division left'}>
                                    <label>{strings.hrManager.division}</label>
                                    <div className={'inner-left'}>
                                        {
                                            findDataValue(this.state.data, 'division')
                                        }
                                    </div>
                                </div>
                                <div className={'jobRole left'}>
                                    <label>{strings.hrManager.jobRole}</label>
                                    <div className={'inner-left'}>
                                        {
                                            findDataValue(this.state.data, 'jobRole') ? findDataValue(this.state.data, 'jobRole') : this.state.data?.jobRoleText
                                        }
                                    </div>
                                </div>
                                <div className={'contact-type left'}>
                                    <label>{strings.hrManager.contractType}</label>
                                    <div className={'inner-left'}>
                                        {
                                            this.state.data &&
                                            getContractTypesString(this.state.data.contractType)
                                        }
                                    </div>
                                </div>
                                {
                                    this.state.data.offer && this.state.data.offer.workPattern &&
                                    <div className={'work-pattern left'}>
                                        <label>{strings.hrManager.workPattern}</label>
                                        <div className={'inner-left'}>
                                            <div>{findDataValue(this.state.data.offer, 'workPattern')}</div>
                                        </div>
                                    </div>
                                }
                                
                                {
                                    checkCondition(this.state.data, 'hoursPerWeek') &&
                                    <div className={'hours-per-week left'}>
                                        <label>{strings.hrManager.hoursPerWeek}</label>
                                        <div className={'inner-left'}>
                                            <div>{this.state.data.offer ? this.state.data.offer.hoursPerWeek : ''}</div>
                                        </div>
                                    </div>
                                }
                                {
                                    checkCondition(this.state.data, 'carAllowancePerMonth') &&
                                    <div className={'car-allowance-per-month left'}>
                                        <label>{strings.hrManager.carAllowancePerMonth}</label>
                                        <div className={'inner-left'}>
                                            <div>{this.state.data.offer ? (this.state.data.offer.currency + this.state.data.offer.carAllowancePerMonth) : ''}</div>
                                        </div>
                                    </div>
                                }
                                {
                                    checkCondition(this.state.data, 'mileageRate') &&
                                    <div className={'mileage-rate left'}>
                                        <label>{strings.hrManager.mileageRate}</label>
                                        <div className={'inner-left'}>
                                            <div>{this.state.data.offer ? (this.state.data.offer.currency + this.state.data.offer.mileageRate) : ''}</div>
                                        </div>
                                    </div>
                                }
                                {
                                    checkCondition(this.state.data, 'contractedHoursPerMonth') &&
                                    <div className={'contracted-hours-per-month left'}>
                                        <label>{strings.hrManager.contractedHoursPerMonth}</label>
                                        <div className={'inner-left'}>
                                            <div>{this.state.data.offer ? this.state.data.offer.contractedHoursPerMonth : ''}</div>
                                        </div>
                                    </div>
                                }
                                {
                                    checkCondition(this.state.data, 'healthcare') &&
                                    <div className={'healthcare left'}>
                                        <label>{strings.hrManager.healthcare}</label>
                                        <div className={'inner-left'}>
                                            <div>{this.state.data.offer && this.state.data.offer.healthcare ? 'Yes': 'No'}</div>
                                        </div>
                                    </div>
                                }
                                {
                                    checkCondition(this.state.data, 'unpaidBreak') &&
                                    <div className={'unpaid-break left'}>
                                        <label>{strings.hrManager.unpaidBreak}</label>
                                        <div className={'inner-left'}>
                                            <div>{this.state.data.offer ? this.state.data.offer.unpaidBreak : ''}</div>
                                        </div>
                                    </div>
                                }
                                {/* {
                                    checkCondition(this.state.data, 'additionalBenefits') &&
                                    <div className={'additional-benefits left'}>
                                        <label>{strings.hrManager.additionalBenefits}</label>
                                        <div className={'inner-left'}>
                                            <div>{this.state.data.offer ? this.state.data.offer.additionalBenefits : ''}</div>
                                        </div>
                                    </div>
                                } */}
                                {
                                    checkCondition(this.state.data, 'bonus') &&
                                    <div className={'additional-benefits left'}>
                                        <label>{strings.hrManager.bonus}</label>
                                        <div className={'inner-left'}>
                                            <div>{this.state.data.offer && this.state.data.offer.bonus  ? 'Yes' : 'No'}</div>
                                        </div>
                                    </div>
                                }
                                {
                                    checkCondition(this.state.data, 'commission') &&
                                    <div className={'additional-benefits left'}>
                                        <label>{strings.hrManager.commission}</label>
                                        <div className={'inner-left'}>
                                            <div>{this.state.data.offer && this.state.data.offer.commission  ? 'Yes' : 'No'}</div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className={'right-wrapper'}>
                                <div className={'surname right'}>
                                    <label>{strings.hrManager.surname}</label>
                                    <div className={'inner-right'}>
                                        {this.state.data.surname}
                                    </div>
                                </div>
                                <div className={'region right'}>
                                    <label>{strings.hrManager.region}</label>
                                    <div className={'inner-right'}>
                                        {
                                            findDataValue(this.state.data, 'region')
                                        }
                                    </div>
                                </div>
                                <div className={'start-date right'}>
                                    <label>{strings.hrManager.startDate}</label>
                                    <div className={'inner-right'}>
                                        <div>{ this.state.data.offer ? dateToString(this.state.data.offer.startDate) : ''}</div>
                                    </div>
                                </div>
                                {
                                    checkCondition(this.state.data, 'continuousStartDate') &&
                                    <div className={'continuous-start-date right'}>
                                        <label>{strings.hrManager.continuousStartDate}</label>
                                        <div className={'inner-right'}>
                                            <div>{this.state.data.offer ? dateToString(this.state.data.offer.continuousStartDate) : ''}</div>
                                        </div>
                                    </div>
                                }
                                {
                                    checkCondition(this.state.data, 'rateOfPay') &&
                                    <div className={'rate-of-pay right'}>
                                        <label>{strings.hrManager.rateOfPay}</label>
                                        <div className={'inner-right'}>
                                            <div>{this.state.data.offer ? (this.state.data.offer.currency + this.state.data.offer.rateOfPay) : ''}</div>
                                        </div>
                                    </div>
                                }
                                {
                                    checkCondition(this.state.data, 'annualSalary') &&
                                    <div className={'annual-salary right'}>
                                        <label>{strings.hrManager.annualSalary}</label>
                                        <div className={'inner-right'}>
                                            <div>{this.state.data.offer ? (this.state.data.offer.currency + this.state.data.offer.annualSalary) : ''}</div>
                                        </div>
                                    </div>
                                }
                                {
                                    checkCondition(this.state.data, 'carAllowanceSelect') &&
                                    <div className={'car-allowance right'}>
                                        <label>{strings.hrManager.carAllowance}</label>
                                        <div className={'inner-right'}>
                                            <div>{ this.state.data.offer && this.state.data.offer.carAllowance ? 'Yes' : 'No' }</div>
                                        </div>
                                    </div>
                                }
                                {
                                    checkCondition(this.state.data, 'carAllowancePerMonthSelect') &&
                                    <div className={'car-allowance right'}>
                                        <label>{strings.hrManager.carAllowancePerMonth}</label>
                                        <div className={'inner-right'}>
                                            <div>{this.state.data.offer ? (this.state.data.offer.currency + this.state.data.offer.carAllowancePerMonth) : ''}</div>
                                        </div>
                                    </div>
                                }
                                {
                                    checkCondition(this.state.data, 'holidayEntitlement') &&
                                    <div className={'car-allowance right'}>
                                        <label>{strings.hrManager.holidayEntitlement}</label>
                                        <div className={'inner-right'}>
                                            <div>{this.state.data.offer ? this.state.data.offer.holidayEntitlement : ''}</div>
                                        </div>
                                    </div>
                                }
                                {
                                    checkCondition(this.state.data, 'rateOfPayPerHalfDay') &&
                                    <div className={'car-allowance right'}>
                                        <label>{strings.hrManager.rateOfPayPerHalfDay}</label>
                                        <div className={'inner-right'}>
                                            <div>{this.state.data.offer ? (this.state.data.offer.currency + this.state.data.offer.rateOfPayPerHalfDay) : ''}</div>
                                        </div>
                                    </div>
                                }
                                {
                                    checkCondition(this.state.data, 'pension') &&
                                    <div className={'pension right'}>
                                        <label>{strings.hrManager.pension}</label>
                                        <div className={'inner-right'}>
                                            <div>{this.state.data.offer ? this.state.data.offer.pension : ''}</div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={'button'}>
                            <Button className={'view-offer-button'} variant="contained" color="primary" onClick={this.props.toggleModal}>
                                { strings.hrManager.done }
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default ViewOfferModal;