import React, {Component} from 'react'
import {Link, withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {withSnackbar} from "notistack";
import * as Actions from "../actions/Actions";
import connect from "react-redux/es/connect/connect";
import IconButton from '@material-ui/core/IconButton';
import MoreVert from '@material-ui/icons/MoreVert';
import ArrowBack from '@material-ui/icons/ArrowBack';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuState from "../constants/MenuState";
import {ListItemIcon, ListItemText, Menu, MenuItem, TextField} from "@material-ui/core";
import strings from "../localization";
import {lock, logout} from "../base/OAuth";
import SelectControl from "./controls/SelectControl";
import Button from "@material-ui/core/Button";
import { getCodebooks } from '../services/CodebookService';
import { getApplicationStatuses } from '../constants/ApplicationStatus';
import Page from '../common/Page';
import { getApplicationStatusesRTW } from '../constants/ApplicationStatusRTW';
import update from 'immutability-helper';
import FilterTypes from "../constants/FilterTypes";

import { getContractTypes } from '../constants/ContractTypes';
import Validators from "../constants/ValidatorTypes";
import {checkPermission} from "../util/SecurityUtil";
import Permission from "../constants/Permission";
import { getApplicationStages } from '../constants/ApplicationStage';
import DatePickerControl from './controls/DatePickerControl';
import ArchivedStatus, {getArchivedStatuses} from "../constants/ArchivedStatus";
import BaseConfirmDialog from "../common/BaseConfirmDialog";
import {withdrawApplication} from "../services/ApplicationService";
import MedicalConditionStatus, {getAllMedicalConditionStatuses} from "../constants/MedicalConditionStatus";
import CriminalRecordStatus, {getAllCriminalRecordStatuses} from "../constants/CriminalRecordStatus";
import ApplicationFileStatus, {getApplicationFileStatus} from "../constants/ApplicationFileStatus";
import CONFIG from '../config';

class Header extends Page {

    validationList = {
        email: [ {type: Validators.EMAIL } ]
    };

    emptyFilter = {
        division: -1,
        region: -1,
        location: -1,
        recruiter: -1,
        jobRole: -1,
        status: -1,
        contractType: -1,
        medicalConditionStatus: MedicalConditionStatus.ALL,
        criminalRecordStatus: CriminalRecordStatus.ALL,
        search: '',
        page: 1,
        perPage: 30,
        dateFrom: null,
        dateTo: null,
        stage: -1,
        archivedStatus: ArchivedStatus.ACTIVE,
        fileStatus: -1,
        selfieStatus: ApplicationFileStatus.ON_HOLD
    };

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            titles: [],
            genders : [],
            divisions: [],
            regions: [],
            recruiters: [],
            locations: [],
            jobRoles: [],
            travelPreferences: [],
            nationalities: [],
            idDocumentTypes: [],
            proofAddressDocumentTypes: [],
            sessionTypes: [],
            territoryOfWorks: [],
            allowUpdate: false,
            data: this.emptyFilter,
            errors: {},
            showWithdrawConfirmModal: false
        }

        this.submit = this.submit.bind(this);
        this.back = this.back.bind(this);
    }

    componentDidMount() {

        getCodebooks().then(response => {
            if(!response.ok) {
                return;
            }

            let recruiters = [];

            for(let recruter of response.data.recruiters) {
                recruiters.push({
                    ...recruter,
                    name: recruter.firstName + ' ' + recruter.lastName
                })
            }

            this.setState({
                titles: response.data.titles,
                genders : response.data.genders,
                divisions: response.data.divisions,
                regions: response.data.regions,
                recruiters: recruiters,
                locations: response.data.locations,
                jobRoles: response.data.jobRoles,
                travelPreferences: response.data.travelPreferances,
                nationalities: response.data.nationalities,
                idDocumentTypes: response.data.idDocumentTypes,
                proofAddressDocumentTypes: response.data.proofAddressDocumentTypes,
                sessionTypes: response.data.sessionTypes,
                territoryOfWorks: response.data.territoryOfWorks
            });
        });
    }

    /** HANDLERS **/

    handleMenuClick(event) {
        this.setState({ anchorEl: event.currentTarget });
    }

    handleMenuClose() {
        this.setState({ anchorEl: null });
    }

    logout() {
        this.props.setApplicationId(-1);
        logout();
        this.props.logout();
        this.props.history.push('/');
    }

    lock() {
        lock();
        this.props.history.push('/');
    }

    getHeaderClass() {

        if(this.props.menu.state === MenuState.SHORT) {
            return 'short';
        }
        else {
            return '';
        }
    }

    getUrlLocation(){
        let url = new URL(window.location);

        return (url.pathname.includes('candidate-profile') || url.pathname.includes('conditional-offers')
        || url.pathname.includes('candidate-applications') || url.pathname.includes('view-candidate')
        || url.pathname.includes('selfie-gallery'));
    }

    back() {
        
        let returnUrl = this.props.siteData.returnUrl ? this.props.siteData.returnUrl : '/';

        this.props.history.push(returnUrl);
    }

    renderSaveButton(){
        let url = new URL(window.location);

        return url.pathname.includes('application') && !url.pathname.includes('candidate-applications');
    }

    checkFilters(field){
        let url = new URL(window.location);

        if (field === FilterTypes.STATUS_ALL) {
                return url.pathname.includes('candidate-applications') || url.pathname.includes('view-candidate');
        }
        else if (field === FilterTypes.ROLE || field === FilterTypes.STATUS) {
            return (url.pathname.includes('candidate-profiles') || url.pathname.includes('candidate-applications'));
        }
        else if (field === FilterTypes.RECRUITER || field === FilterTypes.MEDICAL_CONDITION_STATUS
            || field === FilterTypes.CRIMINAL_RECORD_STATUS) {
            return url.pathname.includes('candidate-applications');
        }
        else if (field === FilterTypes.CONTRACT_TYPE) {
            return url.pathname.includes('conditional-offers');
        }
        else if (field === FilterTypes.DIVISION || field === FilterTypes.REGION ||
            field === FilterTypes.LOCATION) {
            return !url.pathname.includes('view-candidate') && !url.pathname.includes('selfie-gallery');
        }
        else if (field === FilterTypes.DATE_FROM || field === FilterTypes.DATE_TO ||
            field === FilterTypes.STAGE || field === FilterTypes.ARCHIVED) {
            return url.pathname.includes('view-candidate');
        }
        else if (field === FilterTypes.STATUS_SELFIE) {
            return url.pathname.includes('selfie-gallery');
        }
        
    }

    changeData(event, data = 'data') {
        let value = event.target.value && event.target.value.value ? event.target.value.value : event.target.value;
        this.setState({
            [data]: update(this.state[data], { [event.target.name]: {$set: value} })
        }, () => {
            this.props.changeFilter(this.state.data);
        });
    }

    clearData() {
        this.setState({
            data: this.emptyFilter
        }, () => {

            this.props.changeFilter(this.state.data);
        })
    }

    componentWillReceiveProps(props) {

        this.validationList = Object.assign(this.validationList, this.drivingLicenceValidation);

        this.setState({
            data: props.filter.filterData ? props.filter.filterData : this.emptyFilter,
            allowUpdate: props.application.allowUpdate
        });
    }

    renderFilters(){

        return (
            <div className={'filters-wrapper'}>
                <div className={'filters'}>
                    <img src={'/images/filter.png'} alt={'filter'} />
                    <div className={'filters-dropdowns'}>
                        <div className={'filter-row'}>
                            {
                                this.checkFilters(FilterTypes.DIVISION) &&
                                <SelectControl
                                    placeholder={ strings.filters.division }
                                    fullWidth
                                    autoFocus
                                    name='division'
                                    onChange={ this.changeData }
                                    margin="normal"
                                    options={ this.state.divisions }
                                    nameKey={ 'name' }
                                    valueKey={ 'id' }
                                    selected={ this.state.data.division }
                                />
                            }
                            {
                                this.checkFilters(FilterTypes.REGION) &&
                                <SelectControl
                                    placeholder={ strings.filters.region }
                                    fullWidth
                                    autoFocus
                                    name='region'
                                    onChange={ this.changeData }
                                    margin="normal"
                                    options={ this.state.regions }
                                    nameKey={ 'name' }
                                    valueKey={ 'id' }
                                    selected={ this.state.data.region }
                                />
                            }
                            {
                                this.checkFilters(FilterTypes.LOCATION) &&
                                <SelectControl
                                    placeholder={ strings.filters.location }
                                    fullWidth
                                    autoFocus
                                    name='location'
                                    onChange={ this.changeData }
                                    options={ this.state.locations }
                                    margin="normal"
                                    nameKey={ 'name' }
                                    valueKey={ 'value' }
                                    selected={ this.state.data.location }
                                />
                            }
                            {
                                this.checkFilters(FilterTypes.RECRUITER) &&
                                <SelectControl
                                    placeholder={ strings.filters.recruiter }
                                    fullWidth
                                    autoFocus
                                    name='recruiter'
                                    onChange={ this.changeData }
                                    margin="normal"
                                    options={ this.state.recruiters }
                                    nameKey={ 'name' }
                                    valueKey={ 'id' }
                                    selected={ this.state.data.recruiter }
                                />
                            }
                            {
                                this.checkFilters(FilterTypes.ROLE) &&
                                <SelectControl
                                    placeholder={ strings.filters.role }
                                    fullWidth
                                    autoFocus
                                    name='jobRole'
                                    onChange={ this.changeData }
                                    options={ this.state.jobRoles }
                                    margin="normal"
                                    nameKey={ 'name' }
                                    valueKey={ 'id' }
                                    selected={ this.state.data.jobRole }
                                />
                            }
                            {
                                this.checkFilters(FilterTypes.STATUS) &&
                                <SelectControl
                                    placeholder={ strings.filters.status }
                                    fullWidth
                                    autoFocus
                                    name='rtwStatus'
                                    onChange={ this.changeData }
                                    options={ getApplicationStatusesRTW() }
                                    margin="normal"
                                    nameKey={ 'name' }
                                    valueKey={ 'value' }
                                    selected={ this.state.data.rtwStatus }
                                />
                            }
                            {
                                this.checkFilters(FilterTypes.CONTRACT_TYPE) &&
                                <SelectControl
                                    placeholder={ strings.filters.contractType }
                                    fullWidth
                                    autoFocus
                                    name='contractType'
                                    onChange={ this.changeData }
                                    options={ getContractTypes() }
                                    margin="normal"
                                    nameKey={ 'name' }
                                    valueKey={ 'value' }
                                    selected={ this.state.data.contractType }
                                />
                            }
                            {
                                this.checkFilters(FilterTypes.DATE_FROM) &&
                                <DatePickerControl
                                    date={this.state.data.from}
                                    name={'from'}
                                    placeholder={ strings.filters.from }
                                    onChange={ this.changeData }
                                    dateFormat='dd/MM/yyyy'
                                />
                            }
                            {
                                this.checkFilters(FilterTypes.DATE_TO) &&
                                <DatePickerControl
                                    date={this.state.data.to} 
                                    name={'to'}
                                    placeholder={ strings.filters.to }
                                    onChange={ this.changeData }
                                    dateFormat='dd/MM/yyyy'
                                />
                            }
                            {
                                this.checkFilters(FilterTypes.STAGE) &&
                                <SelectControl
                                    placeholder={ strings.filters.stage }
                                    fullWidth
                                    autoFocus
                                    name='stage'
                                    onChange={ this.changeData }
                                    options={ getApplicationStages() }
                                    margin="normal"
                                    nameKey={ 'name' }
                                    valueKey={ 'value' }
                                    selected={ this.state.data.stage }
                                />
                            }
                            {
                                this.checkFilters(FilterTypes.STATUS_ALL) &&
                                <SelectControl
                                    placeholder={ strings.filters.status_all }
                                    fullWidth
                                    autoFocus
                                    name='status'
                                    onChange={ this.changeData }
                                    options={ getApplicationStatuses() }
                                    margin="normal"
                                    nameKey={ 'name' }
                                    valueKey={ 'value' }
                                    selected={ this.state.data.status }
                                />
                            }
                            {
                                this.checkFilters(FilterTypes.ARCHIVED) &&
                                <SelectControl
                                    placeholder={ strings.filters.archived }
                                    fullWidth
                                    autoFocus
                                    name='archivedStatus'
                                    onChange={ this.changeData }
                                    options={ getArchivedStatuses() }
                                    margin="normal"
                                    nameKey={ 'name' }
                                    valueKey={ 'value' }
                                    selected={ this.state.data.archivedStatus }
                                />
                            }
                            {
                                this.checkFilters(FilterTypes.MEDICAL_CONDITION_STATUS) &&
                                <SelectControl
                                    placeholder={ strings.filters.medicalConditionStatus }
                                    fullWidth
                                    autoFocus
                                    name='medicalConditionStatus'
                                    onChange={ this.changeData }
                                    options={ getAllMedicalConditionStatuses() }
                                    margin="normal"
                                    nameKey={ 'name' }
                                    valueKey={ 'value' }
                                    selected={ this.state.data.medicalConditionStatus }
                                />
                            }
                            {
                                this.checkFilters(FilterTypes.CRIMINAL_RECORD_STATUS) &&
                                <SelectControl
                                    placeholder={ strings.filters.criminalRecordStatus }
                                    fullWidth
                                    autoFocus
                                    name='criminalRecordStatus'
                                    onChange={ this.changeData }
                                    options={ getAllCriminalRecordStatuses() }
                                    margin="normal"
                                    nameKey={ 'name' }
                                    valueKey={ 'value' }
                                    selected={ this.state.data.criminalRecordStatus }
                                />
                            }
                            {
                                this.checkFilters(FilterTypes.STATUS_SELFIE) &&
                                <SelectControl
                                    placeholder={ strings.filters.selfieStatus }
                                    fullWidth
                                    autoFocus
                                    name='selfieStatus'
                                    onChange={ this.changeData }
                                    options={ getApplicationFileStatus() }
                                    margin="normal"
                                    nameKey={ 'name' }
                                    valueKey={ 'value' }
                                    selected={ this.state.data.selfieStatus }
                                />
                            }
                        </div>
                    </div>
                    <Button variant="contained" color="primary" className={'clear-filter'}
                        onClick={ () => this.clearData() }
                    >
                        { strings.filters.clear }
                    </Button>
                </div>
            </div>
        );
    }

    submit() {
        this.props.doUpdate(true);
    }

    applicationWithdrawal() {
        this.setState({
            showWithdrawConfirmModal: true
        })
    }

    closeWithdrawConfirmModal() {
        this.setState({
            showWithdrawConfirmModal: false
        })
    }

    confirmWithdraw() {
        withdrawApplication().then(response => {
            this.setState({
                showWithdrawConfirmModal: false
            })
        })
    }

    render() {

        const userType = this.props.user ? this.props.user.userType : null;
        const confDiv = CONFIG.currentDivision +'div';
        return (
            <div id='header' className={ this.getHeaderClass() + confDiv + 'Header' }>

                <BaseConfirmDialog
                    close={this.closeWithdrawConfirmModal}
                    title={'Application Withdrawal'}
                    open={this.state.showWithdrawConfirmModal}
                    message={strings.candidateProfile.withdrawConfirm}
                    no={this.closeWithdrawConfirmModal}
                    yes={this.confirmWithdraw}
                />

                <div className='left'>

                    {
                        this.props.menu.state === MenuState.FULL &&
                        <IconButton size='small' onClick={ () => this.props.changeMenuState(MenuState.SHORT) }>
                            <MoreVert/>
                        </IconButton>
                    }

                    {
                        this.props.menu.state === MenuState.SHORT &&
                        <IconButton size='small' onClick={ () => this.props.changeMenuState(MenuState.FULL) }>
                            <MenuIcon/>
                        </IconButton>
                    }                

                </div>

                {
                    this.getUrlLocation() &&
                    this.renderFilters()
                }

                <div className='center'>
                    {
                        (
                            checkPermission(userType, Permission.USER_APPLICATION)
                        ) &&
                        // checkPermission(userType, Permission.USER_APPLICATION) &&
                        <Button variant="contained" color="primary" disabled={ !this.state.allowUpdate } className={'save-button'} onClick={() => (this.submit())} >
                            { strings.candidateProfile.save }
                        </Button>
                    }
                </div>

                <div className='right'>
                    {
                        (
                            checkPermission(userType, Permission.USER_APPLICATION)
                        ) &&
                        // checkPermission(userType, Permission.USER_APPLICATION) &&
                        <React.Fragment>
                            <Button variant="contained" color="primary" disabled={ !this.state.allowUpdate } className={'save-button'} onClick={() => (this.submit())} >
                                { strings.candidateProfile.save }
                            </Button>
                        </React.Fragment>
                    }

                    {
                        (this.props.history.location.pathname.includes('/application')  || this.props.history.location.pathname.includes('/contract')) &&
                        checkPermission(userType, Permission.GO_BACK) &&
                        <Button variant="contained" color="primary" className={'save-button'} onClick={() => (this.back())} >
                            { strings.candidateProfile.back }
                        </Button>
                    }

                    
                </div>
                <div className={'user-wrapper'}>
                        <p>{this.props.user ? this.props.user.firstName + ' ' + this.props.user.lastName : ''} </p>
                        <IconButton
                            size='small'
                            aria-owns={ this.state.anchorEl ? 'person-menu' : undefined }
                            aria-haspopup="true"
                            onClick={ (event) => this.handleMenuClick(event) }
                        >
                            <PersonIcon/>
                        </IconButton>
                    </div>
                    <Menu
                        id='person-menu'
                        anchorEl={ this.state.anchorEl }
                        open={ Boolean(this.state.anchorEl) }
                        onClose={ () => this.handleMenuClose() }
                    >
                        <MenuItem onClick={ () => this.logout() }>
                            <ListItemIcon>
                                <ExitToAppIcon/>
                            </ListItemIcon>
                            <ListItemText inset primary={ strings.header.logout }/>
                        </MenuItem>
                    </Menu>
            </div>

        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeMenuState: Actions.changeMenuState,
        logout: Actions.logout,
        changeFilter: Actions.changeFilter,
        getData: Actions.getData,
        getErrors: Actions.getErrors,
        doUpdate: Actions.doUpdate,
        setApplicationId: Actions.setApplicationId

    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers, filterReducers, applicationReducers })
{
    return { menu: menuReducers, user: authReducers.user, siteData: siteDataReducers, filter: filterReducers,
        application: applicationReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(Header)));