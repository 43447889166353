
const FullUKorRoIDrivingLicences = {
    YES: true,
    NO: false,

};

export default FullUKorRoIDrivingLicences;

export function getFullUKorRoIDrivingLicences() {
    const options = [
        {
            value: FullUKorRoIDrivingLicences.YES,
            name: 'Yes'
        },
        {
            value: FullUKorRoIDrivingLicences.NO,
            name: 'No'
        }
    ];
    
    return options;
}