import * as Actions from '../actions/Actions';
import { changeFullScreen } from '../actions/Actions';

const initialState = {
    returnUrl: '/',
    loader: false,
    loaderCount: 0,
    changeIndicator: {
        personalDetails: false,
        addressDetails: false,
        emergencyContact: false,
        jobDetails: false,
        ukNationals: false,
        rightToWork: false,
        gdprDisclaimer: false,
        immigrationControl: false,
        submitApplication: false,
        personalDetailsContract: false,
        bankDetails: false,
        employment: false,
        uniform: false,
        medicalDeclaration: false,
        workingTimeElection: false,
        changesInInformation: false,
        termsAndConditions: false,
        digitalSignature: false
    },
    linkIndicator: {
        personalDetails: false,
        addressDetails: false,
        emergencyContact: false,
        jobDetails: false,
        ukNationals: false,
        rightToWork: false,
        gdprDisclaimer: false,
        immigrationControl: false,
        submitApplication: false,
        header: false,
        personalDetailsContract: false,
        bankDetails: false,
        employment: false,
        uniform: false,
        medicalDeclaration: false,
        workingTimeElection: false,
        changesInInformation: false,
        termsAndConditions: false,
        digitalSignature: false,
        rehabilitation: false
    },
    data: {},
    errors: {},
    hasContract: false,
    applicationId: -1
};

const siteDataReducers = (state = initialState, action) => {

    switch (action.type) {

        case Actions.TOGGLE_LOADER:

            let loaderCount = action.loader ? state.loaderCount + 1 : state.loaderCount - 1;

            return {
                ...state,
                loaderCount: loaderCount,
                loader: loaderCount > 0
            };
        case Actions.FORCE_HIDE_LOADER:
            return {
                ...state,
                loaderCount: 0,
                loader: false
            };
        case Actions.HAS_CONTRACT:
            return {
                ...state,
                hasContract: action.hasContract
            }
        case Actions.CHANGE_INDICATOR:
            return {
                ...state,
                changeIndicator: {
                    personalDetails: action.changeIndicator ? action.changeIndicator.personalDetails : false,
                    addressDetails: action.changeIndicator ? action.changeIndicator.addressDetails : false,
                    emergencyContact: action.changeIndicator ? action.changeIndicator.emergencyContact : false,
                    jobDetails: action.changeIndicator ? action.changeIndicator.jobDetails : false,
                    ukNationals: action.changeIndicator ? action.changeIndicator.ukNationals : false,
                    rightToWork: action.changeIndicator ? action.changeIndicator.rightToWork : false,
                    gdprDisclaimer: action.changeIndicator ? action.changeIndicator.gdprDisclaimer : false,
                    immigrationControl: action.changeIndicator ? action.changeIndicator.immigrationControl : false,
                    submitApplication: action.changeIndicator ? action.changeIndicator.submitApplication : false,
                    personalDetailsContract: action.changeIndicator ? action.changeIndicator.personalDetailsContract : false,
                    bankDetails: action.changeIndicator ? action.changeIndicator.bankDetails : false,
                    employment: action.changeIndicator ? action.changeIndicator.employment : false,
                    uniform: action.changeIndicator ? action.changeIndicator.uniform : false,
                    medicalDeclaration: action.changeIndicator ? action.changeIndicator.medicalDeclaration : false,
                    workingTimeElection: action.changeIndicator ? action.changeIndicator.workingTimeElection : false,
                    changesInInformation: action.changeIndicator ? action.changeIndicator.changesInInformation : false,
                    termsAndConditions: action.changeIndicator ? action.changeIndicator.termsAndConditions : false,
                    digitalSignature: action.changeIndicator ? action.changeIndicator.digitalSignature : false,
                }
            };
        case Actions.CHANGE_LINK_INDICATOR:
            return {
                ...state,
                linkIndicator: {
                    personalDetails: action.changeLinkIndicator ? action.changeLinkIndicator.personalDetails : false,
                    addressDetails: action.changeLinkIndicator ? action.changeLinkIndicator.addressDetails : false,
                    emergencyContact: action.changeLinkIndicator ? action.changeLinkIndicator.emergencyContact : false,
                    jobDetails: action.changeLinkIndicator ? action.changeLinkIndicator.jobDetails : false,
                    ukNationals: action.changeLinkIndicator ? action.changeLinkIndicator.ukNationals : false,
                    rightToWork: action.changeLinkIndicator ? action.changeLinkIndicator.rightToWork : false,
                    gdprDisclaimer: action.changeLinkIndicator ? action.changeLinkIndicator.gdprDisclaimer : false,
                    immigrationControl: action.changeLinkIndicator ? action.changeLinkIndicator.immigrationControl : false,
                    submitApplication: action.changeLinkIndicator ? action.changeLinkIndicator.submitApplication : false,
                    header: action.changeLinkIndicator ? action.changeLinkIndicator.header : false,
                    personalDetailsContract: action.changeLinkIndicator ? action.changeLinkIndicator.personalDetailsContract : false,
                    bankDetails: action.changeLinkIndicator ? action.changeLinkIndicator.bankDetails : false,
                    employment: action.changeLinkIndicator ? action.changeLinkIndicator.employment : false,
                    uniform: action.changeLinkIndicator ? action.changeLinkIndicator.uniform : false,
                    medicalDeclaration: action.changeLinkIndicator ? action.changeLinkIndicator.medicalDeclaration : false,
                    rehabilitation: action.changeLinkIndicator ? action.changeLinkIndicator.rehabilitation : false,
                    workingTimeElection: action.changeLinkIndicator ? action.changeLinkIndicator.workingTimeElection : false,
                    changesInInformation: action.changeLinkIndicator ? action.changeLinkIndicator.changesInInformation : false,
                    termsAndConditions: action.changeLinkIndicator ? action.changeLinkIndicator.termsAndConditions : false,
                    digitalSignature: action.changeLinkIndicator ? action.changeLinkIndicator.digitalSignature : false,
                    contractHeader: action.changeLinkIndicator ? action.changeLinkIndicator.contractHeader : false,
                }
            };
        case Actions.GET_DATA:
            return {
                ...state,
                data: action.data
            };
        case Actions.GET_ERRORS:
            return {
                ...state,
                errors: action.errors
            };
        case Actions.APPLICATION_ID:
            return {
                ...state,
                applicationId: action.applicationId
            };
        case Actions.RETURN_URL:
            return {
                ...state,
                returnUrl: action.returnUrl
            };
        default: return state;
    }
};

export default siteDataReducers