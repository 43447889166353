const HealthcareTypes = {
    YES: true,
    NO: false
};

export default HealthcareTypes;

export function getHealthcareTypes() {
    const healthcare = [
        {
            value: HealthcareTypes.YES,
            name: 'Yes'
        },
        {
            value: HealthcareTypes.NO,
            name: 'No'
        }
    ];

    return healthcare;
}

export function getHealthcareTypesString(filter) {
    const selected = getHealthcareTypes().find(item => item.value === filter);

    return selected ? selected.name : '';
}