import React from 'react';
import strings from "../../../localization";
import {getError, hasError} from "../../../functions/Validation";
import {TextField, Divider} from "@material-ui/core";
import SelectControl from '../../controls/SelectControl';
import Grid from '@material-ui/core/Grid';

const JobDetailsForm = ({
                            onChange,
                            onChangeDivision,
                            errors,
                            data,
                            divisions,
                            regions,
                            recruiters,
                            userDivisions,
                            relationshipTypes,
                            jobRoles,
                            travelPreferences,
                            sessionTypes,
                            placeOfWorks,
                            disable,
                            pickupLocations,
                            minibuses
                        }) => {

    function checkCondition() {
        if (data.division) {
            return (data.division.name === 'Retail (Operations)' || data.division.name === 'Store Support (Operations)');
        }
    }

    function checkConditionMiniBus(data) {
        return data.travelPreference && data.travelPreference.name == "Mini Bus";
    }

    function getRegions(data, regions) {

        if (!data.division) {
            return regions;
        }

        return regions;
    }

    function getRoles(data, roles) {
        if (!data.division) {
            return roles;
        }

        let result = [];

        for (let role of roles) {
            if (role.division && role.division.id == data.division.id) {
                result.push(role);
            }
        }

        return result;
    }

    function haveRolesSelect(data, roles) {
        if (!data.division) {
            return true;
        }

        return getRoles(data, roles).length > 0;
    }

    function getRecruiters(data, recruiters, userDivisions) {
        if (!data.division) {
            return recruiters;
        }

        let result = [];

        for (let rec of recruiters) {

            const userDivision = userDivisions.find(x => x.user && x.user.id === rec.id && x.division && x.division.id === data.division.id)

            if (!userDivision || !userDivision.visibleToCandidate || userDivision.deleted) {
                continue;
            }

            rec.name = rec.firstName + " " + rec.lastName;
            result.push(rec);
        }


        return result;
    }

    function getMinibuses(data, minibuses) {
        if (!data.region) {
            return [];
        }

        let result = [];

        for (let minibus of minibuses) {
            if (minibus.region && minibus.region.id == data.region.id) {
                result.push(minibus);
            }
        }
        return result;
    }

    function getPickupLocation(data, locations) {

        if (!data.minibus) {
            return [];
        }

        let result = [];

        for (let location of locations) {
            if (location.minibus && location.minibus.id == data.minibus.id) {

                result.push({
                    ...location,
                    publicName: location.name + ' - ' + location.description
                });

            }
        }

        return result;
    }

    function getPlacesOfWork(placesOfWork, data) {

        if(!placesOfWork) {
            return []
        }

        if (!data.division || ((data.division && data.division.name !== 'Supply Chain (Operations non-DIY)') &&
            (data.division && data.division.name !== 'Supply Chain (Operations DIY)'))) {
            return placesOfWork ? placesOfWork.filter(x => !x.division && !x.region && !x.deleted) : [];
        }

        let result = placesOfWork;

        if(data.division) {
            result = result.filter(x => x.division && x.division.id === data.division.id);
        }

        if(data.region) {
            result = result.filter(x => (x.region && x.region.id === data.region.id) || !x.region);
        }

        return [...placesOfWork.filter(x => !x.division && !x.region && !x.deleted),
            ...result
        ];
    }

    return (
        <form id='job-details-form'>
            <Grid container spacing={9} className={'grid-wrapper'}>
                <Grid item xl={4} lg={5} md={12}>
                    <div className={'select-container' + (hasError(errors, 'division') ? ' error' : '')}>
                        <label>{strings.jobDetails.division}</label>
                        <SelectControl
                            error={hasError(errors, 'division')}
                            helperText={getError(errors, 'division')}
                            fullWidth
                            autoFocus
                            name='division'
                            onChange={onChangeDivision}
                            margin="normal"
                            value={data.division}
                            options={divisions}
                            nameKey={'name'}
                            valueKey={'id'}
                            selected={data.division}
                            isDisabled={disable}
                            isSearchable={false}
                        />
                        {
                            hasError(errors, 'division') ?
                                <p className='select-error'>{strings.validation.RequiredErrorMessage}</p> : ""
                        }
                    </div>
                    <div className={'select-container' + (hasError(errors, 'region') ? ' error' : '')}>
                        <label>{strings.jobDetails.region}</label>
                        <SelectControl
                            error={hasError(errors, 'region')}
                            helperText={getError(errors, 'region')}
                            fullWidth
                            autoFocus
                            name='region'
                            onChange={onChange}
                            margin="normal"
                            value={data.region}
                            options={getRegions(data, regions)}
                            nameKey={'name'}
                            valueKey={'id'}
                            selected={data.region}
                            isDisabled={disable}
                            isSearchable={false}
                        />
                        {
                            hasError(errors, 'region') ?
                                <p className='select-error'>{strings.validation.RequiredErrorMessage}</p> : ""
                        }
                    </div>
                    <div className={'select-container' + (hasError(errors, 'recruiter') ? ' error' : '')}>
                        <label>{strings.jobDetails.recruiter}</label>
                        <SelectControl
                            error={hasError(errors, 'recruiter')}
                            helperText={getError(errors, 'recruiter')}
                            fullWidth
                            autoFocus
                            name='recruiter'
                            onChange={onChange}
                            margin="normal"
                            value={data.recruiter}
                            options={getRecruiters(data, recruiters, userDivisions)}
                            nameKey={'name'}
                            valueKey={'id'}
                            selected={data.recruiter}
                            isDisabled={disable || !data.division}
                            isSearchable={false}
                        />
                        {
                            hasError(errors, 'recruiter') ?
                                <p className='select-error'>{strings.validation.RequiredErrorMessage}</p> : ""
                        }
                    </div>
                    <div className={'select-container' + (hasError(errors, 'placeOfWork') ? ' error' : '')}>
                        <label>{strings.jobDetails.placeOfWork}</label>
                        <SelectControl
                            error={hasError(errors, 'placeOfWork')}
                            helperText={getError(errors, 'placeOfWork')}
                            fullWidth
                            autoFocus
                            name='placeOfWork'
                            onChange={onChange}
                            margin="normal"
                            value={data.placeOfWork}
                            options={getPlacesOfWork(placeOfWorks, data)}
                            nameKey={'publicName'}
                            valueKey={'id'}
                            selected={data.placeOfWork}
                            isDisabled={disable}
                            isSearchable={false}
                        />
                        {
                            hasError(errors, 'placeOfWork') ?
                                <p className='select-error'>{strings.validation.RequiredErrorMessage}</p> : ""
                        }
                    </div>
                    {
                        checkCondition() &&
                        <div className={'select-container' + (hasError(errors, 'travelPreference') ? ' error' : '')}>
                            <label>{strings.jobDetails.travelPreference}</label>
                            <SelectControl
                                error={hasError(errors, 'travelPreference')}
                                helperText={getError(errors, 'travelPreference')}
                                fullWidth
                                autoFocus
                                name='travelPreference'
                                onChange={onChange}
                                margin="normal"
                                value={data.travelPreference}
                                options={travelPreferences}
                                nameKey={'name'}
                                valueKey={'id'}
                                selected={data.travelPreference}
                                isDisabled={disable}
                                isSearchable={false}
                            />
                            {
                                hasError(errors, 'travelPreference') ?
                                    <p className='select-error'>{strings.validation.RequiredErrorMessage}</p> : ""
                            }
                        </div>
                    }
                    {
                        checkCondition() &&
                        checkConditionMiniBus(data) &&
                        <div className={'select-container' + (hasError(errors, 'minibus') ? ' error' : '')}>
                            <label>{strings.jobDetails.minibusName}</label>
                            <SelectControl
                                error={hasError(errors, 'minibus')}
                                helperText={getError(errors, 'minibus')}
                                fullWidth
                                autoFocus
                                options={getMinibuses(data, minibuses)}
                                name='minibus'
                                onChange={onChange}
                                margin="normal"
                                nameKey={'name'}
                                valueKey={'id'}
                                selected={data.minibus}
                                isDisabled={disable}
                                isSearchable={false}
                            />
                            {
                                hasError(errors, 'minibus') ?
                                    <p className='select-error'>{strings.validation.RequiredErrorMessage}</p> : ""
                            }
                        </div>
                    }
                    {
                        checkCondition() &&
                        checkConditionMiniBus(data) &&
                        <div className={'select-container' + (hasError(errors, 'pickupLocation') ? ' error' : '')}>
                            <label>{strings.jobDetails.pickupPointOne}</label>
                            <SelectControl
                                error={hasError(errors, 'pickupLocation')}
                                helperText={getError(errors, 'pickupLocation')}
                                fullWidth
                                autoFocus
                                options={getPickupLocation(data, pickupLocations)}
                                name='pickupLocation'
                                onChange={onChange}
                                margin="normal"
                                nameKey={'publicName'}
                                valueKey={'id'}
                                selected={data.pickupLocation}
                                isDisabled={disable}
                                isSearchable={false}
                            />
                            {
                                hasError(errors, 'pickupLocation') ?
                                    <p className='select-error'>{strings.validation.RequiredErrorMessage}</p> : ""
                            }
                        </div>
                    }
                </Grid>
                <Grid item xl={4} lg={5} md={12}>

                    {
                        haveRolesSelect(data, jobRoles) &&
                        <React.Fragment>
                            <div className={'select-container' + (hasError(errors, 'jobRole') ? ' error' : '')}>
                                <label>{strings.jobDetails.job}</label>
                                <SelectControl
                                    error={hasError(errors, 'jobRole')}
                                    helperText={getError(errors, 'jobRole')}
                                    fullWidth
                                    autoFocus
                                    name='jobRole'
                                    onChange={onChange}
                                    margin="normal"
                                    value={data.jobRole}
                                    options={getRoles(data, jobRoles)}
                                    nameKey={'name'}
                                    valueKey={'id'}
                                    selected={data.jobRole}
                                    isDisabled={disable || !data.division}
                                    isSearchable={false}
                                />
                                {
                                    hasError(errors, 'jobRole') ?
                                        <p className='select-error'>{strings.validation.RequiredErrorMessage}</p> : ""
                                }
                            </div>
                        </React.Fragment>

                    }
                    {
                        !haveRolesSelect(data, jobRoles) &&
                        <React.Fragment>
                            <div className={'select-container' + (hasError(errors, 'jobRole') ? ' error' : '')}
                                 style={{marginBottom: '0'}}>
                                <label>{strings.jobDetails.job}</label>
                                <TextField
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={hasError(errors, 'jobRole')}
                                    helperText={getError(errors, 'jobRole')}
                                    fullWidth
                                    name='jobRole'
                                    onChange={onChange}
                                    margin="normal"
                                    value={typeof data.jobRole === 'object' && data.jobRole !== null ? '' : data.jobRole}
                                    margin="dense"
                                    variant='outlined'
                                    disabled={disable}

                                />
                                {
                                    hasError(errors, 'jobRole') ?
                                        <p className='select-error'>{strings.validation.RequiredErrorMessage}</p> : ""
                                }
                            </div>
                        </React.Fragment>
                    }

                    <div className={'select-container' + (hasError(errors, 'sessionType') ? ' error' : '')}>
                        <label>{strings.jobDetails.sessionType}</label>
                        <SelectControl
                            error={hasError(errors, 'sessionType')}
                            helperText={getError(errors, 'sessionType')}
                            fullWidth
                            autoFocus
                            name='sessionType'
                            onChange={onChange}
                            margin="normal"
                            value={data.sessionType}
                            options={sessionTypes}
                            nameKey={'name'}
                            valueKey={'id'}
                            selected={data.sessionType}
                            isDisabled={disable}
                            isSearchable={false}
                        />
                        {
                            hasError(errors, 'sessionType') ?
                                <p className='select-error'>{strings.validation.RequiredErrorMessage}</p> : ""
                        }
                    </div>
                    <div className={'select-container' + (hasError(errors, 'sessionLocation') ? ' error' : '')}
                         style={{marginBottom: '0'}}>
                        <label>{strings.jobDetails.sessionLocation}</label>
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={hasError(errors, 'sessionLocation')}
                            helperText={getError(errors, 'sessionLocation')}
                            fullWidth
                            name='sessionLocation'
                            onChange={onChange}
                            value={data.sessionLocation}
                            margin="dense"
                            variant='outlined'
                            disabled={disable}
                        />
                    </div>
                    <div className={'select-container' + (hasError(errors, 'bringBuddy') ? ' error' : '')}
                         style={{marginBottom: '0'}}>
                        <label>{strings.jobDetails.bringBuddy}</label>
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={hasError(errors, 'bringBuddy')}
                            helperText={getError(errors, 'bringBuddy')}
                            fullWidth
                            name='bringBuddy'
                            onChange={onChange}
                            value={data.bringBuddy}
                            margin="dense"
                            variant='outlined'
                            disabled={disable}
                        />
                    </div>
                </Grid>
            </Grid>
            <Divider/>
        </form>
    )
};

export default JobDetailsForm;
