import React, {Component} from 'react';
import {Dialog, Divider, Slide} from "@material-ui/core/";
import CloseIcon from '@material-ui/icons/Close';
import {Button, IconButton, TextField} from "@material-ui/core";
import strings from "../localization";
import SelectControl from '../components/controls/SelectControl';
import DatePickerControl from "../components/controls/DatePickerControl";
import {getContractTypesString} from "../constants/ContractTypes";
import {findDataValue} from "../util/DropdownUtil";
import {checkCondition} from "../util/FieldsConditionUtil";
import FormComponent from "./FormComponent";
import { getOffer, sendOffer } from '../services/OfferService';
import { getYesNoOption, getYesNoOptions } from '../constants/YesNoOptions';
import {getCurrenciesList} from "../constants/Currencies";
import {getError, hasError} from "../functions/Validation";
import {getHours} from "../constants/Hours";
import HRApprovalStatus from "../constants/HRApprovalStatus";


class MakeOfferModal extends FormComponent {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : {},
            errors: {},
            application: props.application ? props.application : undefined
        }

        this.done = this.done.bind(this);
    }
    hoursPerWeekGlbl = '0';
    rateOfPayGlbl ='0';
    componentWillReceiveProps(props) {
        this.state.application = props.application;
        if(props.application !=null)
        {
            if(props.application.offer != null || props.application.offer != undefined)
            {
            this.rateOfPayGlbl =  props.application.offer.rateOfPay;
            this.hoursPerWeekGlbl =  props.application.offer.contractedHoursPerMonth;
            }
        }
        if(!this.state.application) {
            
            this.setState({
                data: {},
                errors: {}
            });
            return;
        }

        this.fetch();
    }

    componentDidMount() {

        if(!this.state.application) {

            this.setState({
                data: {}
            });

            return;
        }

        this.fetch();
    }

    fetch() {

        if(!this.state.application.id) {
            return;
        }

        getOffer(this.state.application.id).then(response => {

            if(!response || !response.ok) {

                this.setState({
                    data: {}
                });

                return;
            }

            this.setState({
                data: {
                    ...response.data,
                    bonus: getYesNoOption(response.data.bonus),
                    commission: getYesNoOption(response.data.commission),
                    carAllowance: getYesNoOption(response.data.carAllowance),
                    startDate: response.data.startDate ? new Date(response.data.startDate) : Date.now(),
                    continuousStartDate: response.data.continuousStartDate ? new Date(response.data.continuousStartDate) : Date.now()
                }
            });
        });
    }
    calculateAnnualSalary() {
        const workingWeeksInYear = 52; // You can adjust this value as needed
        const salary =  this.rateOfPayGlbl * this.hoursPerWeekGlbl * workingWeeksInYear;
        console.log(salary);
return salary.toFixed(2);
      }
    
      // Handler for the rateOfPay TextField's onChange event
      handleRateOfPayChange = (event) => {
        const { name, value } = event.target;
        this.rateOfPayGlbl = value;
        const annualSalary = this.calculateAnnualSalary(); // Calculate annual salary
        this.setState({
          data: {
            ...this.state.data,
            [name]: value,
            annualSalary: annualSalary, // Update annual salary in state
          },
        });
      };
      handleHoursPerWeekChange = (event) => {
        
        const { name, value } = event.target;
        this.hoursPerWeekGlbl = value.value;
        const annualSalary = this.calculateAnnualSalary(); // Calculate annual salary
        this.setState({
          data: {
            ...this.state.data,
            [name]: value,
            annualSalary: annualSalary, // Update annual salary in state
          },
        });
      };

    validateSalary(value) {

        if(!value) {
            return true;
        }

        const re = new RegExp('^\\d+(\\.\\d{1,2})?$');

        return re.test(value) && value.length < 10;
    }

    done() {

        let hasError = false;
        let errors = this.state.errors;

        if(!this.validateSalary(this.state.data.annualSalary)) {
            hasError = true;
            errors['annualSalary'] = [{message: 'You can only enter up to 6 digits plus two decimal places.'}]

            this.setState({
                errors: errors
            });
        }

        if(!this.validateSalary(this.state.data.carAllowancePerMonth)) {
            hasError = true;
            errors['carAllowancePerMonth'] = [{message: 'You can only enter up to 6 digits plus two decimal places.'}]

            this.setState({
                errors: errors
            });
        }

        if(hasError) {
            return
        }

        this.setState({
            errors: {}
        });

        if(this.state.data.currency && !this.state.data.currency.name) {
            this.state.data.currency = getCurrenciesList().find(x => x.name === this.state.data.currency)
        }

        if(this.state.data.hoursPerWeek && !this.state.data.hoursPerWeek.name) {
            this.state.data.hoursPerWeek = getHours().find(x => x.name === this.state.data.hoursPerWeek)
        }

        if(this.state.data.contractedHoursPerMonth && !this.state.data.contractedHoursPerMonth.name) {
            this.state.data.contractedHoursPerMonth = getHours().find(x => x.name === this.state.data.contractedHoursPerMonth)
        }

        sendOffer(this.state.application.id, this.state.data).then(response => {
            if(!response || !response.ok) {
                return;
            }

            this.props.toggleModal();
        })
    }

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="left" ref={ref} {...props} />;
    });

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevProps.selectedItem !== this.props.selectedItem){
            this.setState({
                data: this.props.selectedItem
            });
        }

        if(this.props.contractType){
            const data = this.state.data;
            data.contractType = this.props.contractType;

            if(prevProps.contractType !== this.props.contractType){
                this.setState({
                    data: data
                });
            }
        }
    }

    render() {

        return (
            <Dialog
                onClose={this.props.toggleModal}
                aria-labelledby="customized-dialog-title"
                open={this.props.open}
                TransitionComponent={this.Transition}
                className={'make-offer-modal'}
            >
                <div className='form-modal-header' onClose={this.props.toggleModal}>
                    <div>
                        <h1>{this.props.title}</h1>
                        <IconButton className={'close-button-wrapper'} onClick={this.props.toggleModal}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <Divider />
                    <div className={'make-offer-wrapper'}>
                        <div className={'content-wrapper'}>
                            <div className={'left-wrapper'}>
                                <div className={'first-name left'}>
                                    <label>{strings.makeOffer.firstName}</label>
                                    <div className={'inner-left'}>
                                        {this.state.application ? this.state.application.forename : ""}
                                    </div>
                                </div>
                                <div className={'division left'}>
                                    <label>{strings.makeOffer.division}</label>
                                    <div className={'inner-left'}>
                                        {
                                            this.state.application &&
                                            findDataValue(this.state.application, 'division')
                                        }
                                    </div>
                                </div>
                                <div className={'contract-type left'}>
                                    <label>{strings.makeOffer.jobRole}</label>
                                    <div className={'inner-left'}>
                                        {
                                            this.state.application &&
                                            findDataValue(this.state.application, 'jobRole') ? findDataValue(this.state.application, 'jobRole') : this.state.application?.jobRoleText
                                        }
                                    </div>
                                </div>
                                {
                                    checkCondition(this.state.application, 'workPattern') &&
                                    <div className={'work-pattern left'}>
                                        <label>{strings.makeOffer.workPattern}</label>
                                        <SelectControl
                                            isDisabled={this.state.application?.offer?.hrApprovalStatus === HRApprovalStatus.WAITING_APPROVAL}
                                            isSearchable={false}
                                            placeholder={ strings.makeOffer.workPattern }
                                            options={this.props.workPatterns}
                                            fullWidth
                                            autoFocus
                                            name='workPattern'
                                            onChange={ this.changeData }
                                            margin="normal"
                                            value={ this.state.data.workPattern }
                                            nameKey={ 'name' }
                                            valueKey={ 'id' }
                                            selected={ this.state.data.workPattern }
                                        />
                                    </div>
                                }
                                {
                                    checkCondition(this.state.application, 'hoursPerWeek') &&
                                    <div className={'hours-per-week left'}>
                                        <label>{strings.makeOffer.hoursPerWeek}</label>
                                        <SelectControl
                                            isDisabled={this.state.application?.offer?.hrApprovalStatus === HRApprovalStatus.WAITING_APPROVAL}
                                            isSearchable={false}
                                            placeholder={ strings.makeOffer.hoursPerWeek }
                                            options={getHours()}
                                            fullWidth
                                            autoFocus
                                            name='hoursPerWeek'
                                            onChange={this.changeData}
                                            margin="normal"
                                            value={ this.state.data.hoursPerWeek && this.state.data.hoursPerWeek.name ?
                                                getHours().find(x => x.name === this.state.data.hoursPerWeek.name): getHours().find(x => x.name === this.state.data.hoursPerWeek)}
                                            nameKey={ 'name' }
                                            valueKey={ 'value' }
                                            selected={ this.state.data.hoursPerWeek && this.state.data.hoursPerWeek.name ?
                                                getHours().find(x => x.name === this.state.data.hoursPerWeek.name) : getHours().find(x => x.name === this.state.data.hoursPerWeek)}
                                        />
                                    </div>
                                }
                                {
                                    checkCondition(this.state.application, 'carAllowancePerMonth', this.state.data) &&
                                    <div className={'car-allowance-per-month left'}>
                                        <label>{strings.makeOffer.carAllowancePerMonth}</label>
                                        <TextField
                                            error={ hasError(this.state.errors, 'carAllowancePerMonth') }
                                            helperText={ getError(this.state.errors, 'carAllowancePerMonth') }
                                            fullWidth
                                            name='carAllowancePerMonth'
                                            onChange={ this.changeData }
                                            margin="none"
                                            value={ this.state.data.carAllowancePerMonth }
                                            variant={"outlined"}
                                            disabled={this.state.application?.offer?.hrApprovalStatus === HRApprovalStatus.WAITING_APPROVAL}
                                        />
                                    </div>
                                }
                                {
                                    checkCondition(this.state.application, 'mileageRate') &&
                                    <div className={'mileage-rate left'}>
                                        <label>{strings.makeOffer.mileageRate}</label>
                                        <TextField
                                            fullWidth
                                            name='mileageRate'
                                            onChange={ this.changeData }
                                            margin="none"
                                            value={ this.state.data.mileageRate }
                                            variant={"outlined"}
                                            disabled={this.state.application?.offer?.hrApprovalStatus === HRApprovalStatus.WAITING_APPROVAL}
                                        />
                                    </div>
                                }
                                {
                                    checkCondition(this.state.application, 'contractedHoursPerMonth') &&
                                    <div className={'mileage-rate left'}>
                                        <label>{strings.makeOffer.contractedHoursPerMonth}</label>
                                        <SelectControl
                                            isDisabled={this.state.application?.offer?.hrApprovalStatus === HRApprovalStatus.WAITING_APPROVAL}
                                            isSearchable={false}
                                            placeholder={ strings.makeOffer.contractedHoursPerMonth }
                                            options={getHours()}
                                            fullWidth
                                            autoFocus
                                            name='contractedHoursPerMonth'
                                            onChange={ this.handleHoursPerWeekChange }
                                            margin="normal"
                                            value={ this.state.data.contractedHoursPerMonth && this.state.data.contractedHoursPerMonth.name ?
                                                getHours().find(x => x.name === this.state.data.contractedHoursPerMonth.name): getHours().find(x => x.name === this.state.data.contractedHoursPerMonth)}
                                            nameKey={ 'name' }
                                            valueKey={ 'value' }
                                            selected={ this.state.data.contractedHoursPerMonth && this.state.data.contractedHoursPerMonth.name ?
                                                getHours().find(x => x.name === this.state.data.contractedHoursPerMonth.name) : getHours().find(x => x.name === this.state.data.contractedHoursPerMonth)}
                                        />
                                    </div>
                                }
                                {
                                    checkCondition(this.state.application, 'healthcare') &&
                                    <div className={'healthcare left'}>
                                        <label>{strings.makeOffer.healthcare}</label>
                                        <SelectControl
                                            isSearchable={false}
                                            placeholder={ strings.makeOffer.healthcare }
                                            options={this.props.healthcare}
                                            fullWidth
                                            autoFocus
                                            name='healthcare'
                                            onChange={ this.changeData }
                                            margin="normal"
                                            value={ this.state.data.healthcare }
                                            nameKey={ 'name' }
                                            valueKey={ 'value' }
                                            selected={ this.state.data.healthcare }
                                            isDisabled={this.state.application?.offer?.hrApprovalStatus === HRApprovalStatus.WAITING_APPROVAL}
                                        />
                                    </div>
                                }
                                {
                                    checkCondition(this.state.application, 'unpaidBreak') &&
                                    <div className={'car-allowance left'}>
                                        <label>{strings.makeOffer.unpaidBreak}</label>
                                        <TextField
                                            fullWidth
                                            name='unpaidBreak'
                                            onChange={ this.changeData }
                                            margin="none"
                                            value={ this.state.data.unpaidBreak }
                                            variant={"outlined"}
                                            disabled={this.state.application?.offer?.hrApprovalStatus === HRApprovalStatus.WAITING_APPROVAL}
                                        />
                                    </div>
                                }
                                {/* {
                                    checkCondition(this.state.application, 'additionalBenefits') &&
                                    <div className={'car-allowance left'}>
                                        <label>{strings.makeOffer.additionalBenefits}</label>
                                        <TextField
                                            fullWidth
                                            name='additionalBenefits'
                                            onChange={ this.changeData }
                                            margin="none"
                                            value={ this.state.data.additionalBenefits }
                                            variant={"outlined"}
                                        />
                                    </div>
                                } */}
                            </div>
                            <div className={'right-wrapper'}>
                                <div className={'surname right'}>
                                    <label>{strings.makeOffer.surname}</label>
                                    <div className={'inner-right'}>
                                        {this.state.application ? this.state.application.surname : ''}
                                    </div>
                                </div>
                                {
                                    checkCondition(this.state.application, 'region') &&
                                    <div className={'region right'}>
                                        <label>{strings.makeOffer.region}</label>
                                        <div className={'inner-right'}>
                                            {
                                                this.state.application &&
                                                findDataValue(this.state.application, 'region')
                                            }
                                        </div>
                                    </div>
                                }
                                <div className={'contract-type right'}>
                                    <label>{strings.makeOffer.contractType}</label>
                                    <div className={'inner-right'}>
                                        {
                                            this.state.application &&
                                            getContractTypesString(this.state.application.contractType)
                                        }
                                    </div>
                                </div>
                                {
                                    checkCondition(this.state.application, 'startDate') &&
                                    <div className={'start-date right'}>
                                        <label>{strings.makeOffer.startDate}</label>
                                        <div className={'inner-right'}>
                                            <DatePickerControl
                                                dateFormat='dd/MM/yyyy'
                                                date={this.state.data.startDate}
                                                name={'startDate'}
                                                placeholder={ strings.makeOffer.pleaseSelect }
                                                onChange={ this.changeData }
                                                withPortal={true}
                                                disabled={this.state.application?.offer?.hrApprovalStatus === HRApprovalStatus.WAITING_APPROVAL}
                                            />
                                        </div>
                                    </div>
                                }
                                {
                                    checkCondition(this.state.application, 'continuousStartDate') &&
                                    <div className={'start-date right'}>
                                        <label>{strings.makeOffer.continuousStartDate}</label>
                                        <div className={'inner-right'}>
                                            <DatePickerControl
                                                dateFormat='dd/MM/yyyy'
                                                date={this.state.data.continuousStartDate}
                                                name={'continuousStartDate'}
                                                placeholder={ strings.makeOffer.pleaseSelect }
                                                onChange={ this.changeData }
                                                withPortal={true}
                                                disabled={this.state.application?.offer?.hrApprovalStatus === HRApprovalStatus.WAITING_APPROVAL}
                                            />
                                        </div>
                                    </div>
                                }
                                <div className={'carAllowance right'}>
                                    <label>{strings.makeOffer.currency}</label>
                                    <SelectControl
                                        isDisabled={this.state.application?.offer?.hrApprovalStatus === HRApprovalStatus.WAITING_APPROVAL}
                                        isSearchable={false}
                                        placeholder={ strings.makeOffer.currency }
                                        options={getCurrenciesList()}
                                        fullWidth
                                        autoFocus
                                        name='currency'
                                        onChange={ this.changeData }
                                        margin="normal"
                                        value={ this.state.data.currency && this.state.data.currency.name ?
                                            getCurrenciesList().find(x => x.name === this.state.data.currency.name): getCurrenciesList().find(x => x.name === this.state.data.currency)}
                                        nameKey={ 'name' }
                                        valueKey={ 'value' }
                                        selected={ this.state.data.currency && this.state.data.currency.name ?
                                            getCurrenciesList().find(x => x.name === this.state.data.currency.name) : getCurrenciesList().find(x => x.name === this.state.data.currency)}
                                    />
                                </div>
                                {
                                    checkCondition(this.state.application, 'rateOfPay') &&
                                    <div className={'rate-of-pay right'}>
                                        <label>{strings.makeOffer.rateOfPay}</label>
                                            <TextField
                                                disabled={this.state.application?.offer?.hrApprovalStatus === HRApprovalStatus.WAITING_APPROVAL}
                                                fullWidth
                                                name='rateOfPay'
                                                onChange={this.handleRateOfPayChange}
                                                margin="none"
                                                value={ this.state.data.rateOfPay }
                                                variant={"outlined"}
                                            />
                                    </div>
                                }
                                {
                                    checkCondition(this.state.application, 'annualSalary') &&
                                    <div className={'annual-salary right'}>
                                        <label>{strings.makeOffer.annualSalary}</label>
                                        <TextField
                                            disabled={this.state.application?.offer?.hrApprovalStatus === HRApprovalStatus.WAITING_APPROVAL}
                                            error={ hasError(this.state.errors, 'annualSalary') }
                                            helperText={ getError(this.state.errors, 'annualSalary') }
                                            fullWidth
                                            name='annualSalary'
                                            onChange={ this.changeData }
                                            margin="none"
                                            value={ this.state.data.annualSalary }
                                            variant={"outlined"}
                                        />
                                    </div>
                                }
                                {
                                    checkCondition(this.state.application, 'carAllowanceSelect', this.state.data) &&
                                    <div className={'carAllowance right'}>
                                        <label>{strings.makeOffer.carAllowance}</label>
                                        <SelectControl
                                            isDisabled={this.state.application?.offer?.hrApprovalStatus === HRApprovalStatus.WAITING_APPROVAL}
                                            isSearchable={false}
                                            placeholder={ strings.makeOffer.carAllowance }
                                            options={getYesNoOptions()}
                                            fullWidth
                                            autoFocus
                                            name='carAllowance'
                                            onChange={ this.changeData }
                                            margin="normal"
                                            value={ this.state.data.carAllowance }
                                            nameKey={ 'name' }
                                            valueKey={ 'value' }
                                            selected={ this.state.data.carAllowance }
                                        />
                                    </div>
                                }
                                {
                                    checkCondition(this.state.application, 'holidayEntitlement') &&
                                    <div className={'car-allowance right'}>
                                        <label>{strings.makeOffer.holidayEntitlement}</label>
                                        <TextField
                                            disabled={this.state.application?.offer?.hrApprovalStatus === HRApprovalStatus.WAITING_APPROVAL}
                                            fullWidth
                                            name='holidayEntitlement'
                                            onChange={ this.changeData }
                                            margin="none"
                                            value={ this.state.data.holidayEntitlement }
                                            variant={"outlined"}
                                        />
                                    </div>
                                }
                                {
                                    checkCondition(this.state.application, 'pension') &&
                                    <div className={'pension right'}>
                                        <label>{strings.makeOffer.pension}</label>
                                        <TextField
                                            disabled={this.state.application?.offer?.hrApprovalStatus === HRApprovalStatus.WAITING_APPROVAL}
                                            fullWidth
                                            name='pension'
                                            onChange={ this.changeData }
                                            margin="none"
                                            value={ this.state.data.pension }
                                            variant={"outlined"}
                                        />
                                    </div>
                                }
                                {
                                    checkCondition(this.state.application, 'rateOfPayPerHalfDay') &&
                                    <div className={'pension right'}>
                                        <label>{strings.makeOffer.rateOfPayPerHalfDay}</label>
                                        <TextField
                                            disabled={this.state.application?.offer?.hrApprovalStatus === HRApprovalStatus.WAITING_APPROVAL}
                                            fullWidth
                                            name='rateOfPayPerHalfDay'
                                            onChange={ this.changeData }
                                            margin="none"
                                            value={ this.state.data.rateOfPayPerHalfDay }
                                            variant={"outlined"}
                                        />
                                    </div>
                                }
                                {
                                    checkCondition(this.state.application, 'bonus') &&
                                    <div className={'carAllowance right'}>
                                        <label>{strings.makeOffer.bonus}</label>
                                        <SelectControl
                                            isDisabled={this.state.application?.offer?.hrApprovalStatus === HRApprovalStatus.WAITING_APPROVAL}
                                            isSearchable={false}
                                            placeholder={ strings.makeOffer.bonus }
                                            options={getYesNoOptions()}
                                            fullWidth
                                            autoFocus
                                            name='bonus'
                                            onChange={ this.changeData }
                                            margin="normal"
                                            value={ this.state.data.bonus }
                                            nameKey={ 'name' }
                                            valueKey={ 'value' }
                                            selected={ this.state.data.bonus }
                                        />
                                    </div>
                                }
                                {
                                    checkCondition(this.state.application, 'commission') &&
                                    <div className={'carAllowance right'}>
                                        <label>{strings.makeOffer.commission}</label>
                                        <SelectControl
                                            isSearchable={false}
                                            placeholder={ strings.makeOffer.commission }
                                            options={getYesNoOptions()}
                                            fullWidth
                                            autoFocus
                                            name='commission'
                                            onChange={ this.changeData }
                                            margin="normal"
                                            value={ this.state.data.commission }
                                            nameKey={ 'name' }
                                            valueKey={ 'value' }
                                            selected={ this.state.data.commission }
                                            isDisabled={this.state.application?.offer?.hrApprovalStatus === HRApprovalStatus.WAITING_APPROVAL}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                        <Button className={'make-offer-button'} variant="contained" color="primary" onClick={ this.done}>
                            { strings.makeOffer.done }
                        </Button>
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default MakeOfferModal;