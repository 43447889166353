import {makeParametersList, request} from "../base/HTTP";
import HttpMethod from "../constants/HttpMethod";
import {dateToTicks} from "../util/DateUtil";
import CONFIG from '../config';


export async function getAdminAlert(id) {
    return await request('/api/admin_alerts/' + id, {}, HttpMethod.GET);
}

export async function getAdminAlerts(data) {

    if (data) {
        data = {
            ...data,
        }
    }

    return await request('/api/admin_alerts/all', data, HttpMethod.GET);
}

export async function editAdminAlert(data, mode) {

    return await request('/api/update_admin_alert/' + mode, data, HttpMethod.PUT);
}