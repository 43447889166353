import {getSidebarLinks} from "./SidebarLinks";

const Territories = {
    UK: 1,
    IRELAND: 2
};

export default Territories;

export function getTerritories() {
    const territories = [
        {
            value: Territories.UK,
            name: 'UK'
        },
        {
            value: Territories.IRELAND,
            name: 'Ireland'
        }
    ];

    return territories;
}

export function getTerritoriesString(link) {
    const selectedTerritory = getTerritories().find(item => item.value === link);

    return selectedTerritory ? selectedTerritory.name : '';
}