import React, {Component} from 'react';
import {Dialog, Divider, Slide} from "@material-ui/core/";
import CloseIcon from '@material-ui/icons/Close';
import {Button, IconButton, TextField} from "@material-ui/core";
import strings from "../localization";
import SelectControl from '../components/controls/SelectControl';
import DatePickerControl from "../components/controls/DatePickerControl";
import ContractTypeMode from "../constants/ContractTypes";

class MakeOfferModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data
        }
    }

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="left" ref={ref} {...props} />;
    });

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevProps.selectedItem !== this.props.selectedItem){
            this.setState({
                data: this.props.selectedItem
            });
        }

        if(this.props.contractType){
            const data = this.state.data;
            data.contractType = this.props.contractType;

            if(prevProps.contractType !== this.props.contractType){
                this.setState({
                    data: data
                });
            }
        }
    }

    render() {

        return (
            <Dialog
                onClose={this.props.toggleModal}
                aria-labelledby="customized-dialog-title"
                open={this.props.open}
                TransitionComponent={this.Transition}
                className={'message-modal'}
            >
                <div className='form-modal-header' onClose={this.props.toggleModal}>
                    <div>
                        <h1>{this.props.title}</h1>
                        <IconButton className={'close-button-wrapper'} onClick={this.props.toggleModal}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <Divider />
                    <div className={'message-wrapper'}>
                        <div className={'message'}>
                            {this.props.message}
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default MakeOfferModal;