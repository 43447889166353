import React from 'react';
import strings from "../../../localization";
import {getError, hasError} from "../../../functions/Validation";
import {Button, TextField, Divider, Chip} from "@material-ui/core";
import SelectControl from '../../controls/SelectControl';
import Grid from '@material-ui/core/Grid';
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloseIcon from "@material-ui/icons/Close";
import DocumentType from "../../../constants/DocumentType";
import ApplicationInternalStatus from '../../../constants/ApplicationInternalStatus';
import { checkPermission } from '../../../util/SecurityUtil';
import Permission from '../../../constants/Permission';

const MedicalDeclarationForm = ({
                            onSubmit,
                            onCancel,
                            onChange,
                            errors,
                            data,
                            onDelete,
                            titles,
                            genders,
                            disabled,
                            handleToggle,
                            user,
                            rehabilitationRef
                        }) => (

    <form id='medical-declaration-form'>
        <Grid container spacing={9} className={'grid-wrapper'}>
            {
                checkPermission(user.userType, Permission.MEDICAL_DECLARATION_VIEW) &&
                <React.Fragment>
                    <Grid item md={10}>
                        <div className={'question'}>{ strings.medicalDeclaration.medicalDeclarationQuestion }</div>
                    </Grid>
                    <Grid item md={9} className={'switch-wrapper'}>
                        <label className="switch" onClick={handleToggle}>
                            <input checked={data.haveMedicalConditions} disabled={ disabled } type="checkbox" id="togBtn" name={'haveMedicalConditions'}/>
                            <div className="slider round">
                                <span className="on">YES</span>
                                <span className="off">NO</span>
                            </div>
                        </label>
                    </Grid>

                    {
                        data.haveMedicalConditions
                            ?
                            <div className={'medical-declaration-show'}>
                                <Grid item md={9}>
                                    <div className={'medical-declaration-comment'}>
                                        <label>{ strings.medicalDeclaration.medicalDeclarationYes }</label>
                                        <TextField
                                            placeholder={strings.medicalDeclaration.enterText}
                                            multiline
                                            rows={2}
                                            rowsMax={4}
                                            variant={'outlined'}
                                            onChange={onChange}
                                            name={'medicalConditionDescription'}
                                            value={ data ? data.medicalConditionDescription : '' }
                                            disabled={ disabled }
                                        />
                                    </div>
                                </Grid>
                            </div>
                            :
                            ""
                    }
                </React.Fragment>
            }

            {
                !checkPermission(user.userType, Permission.MEDICAL_DECLARATION_VIEW) &&
                <Grid item md={10}>
                    <div className={'question'}>{ strings.medicalDeclaration.medicalDeclarationNotAllowed }</div>
                </Grid>
            }
                 
        </Grid>
        <Divider />

        <h1>{strings.medicalDeclaration.rehabilitation}</h1>
        <Grid container spacing={9} className={'grid-wrapper'} ref={rehabilitationRef} id="rehabilitation">

            {
                checkPermission(user.userType, Permission.REHABILITATION) &&
                <React.Fragment>
                    <Grid item md={10}>
                        <div className={'question'}>{ strings.medicalDeclaration.rehabilitationQuestion }</div>
                    </Grid>
                    <Grid item md={9} className={'switch-wrapper'}>
                        <label className="switch" onClick={handleToggle}>
                            <input checked={data.rehabilitationOfOffenders} disabled={ disabled } type="checkbox" id="togBtn" name={'rehabilitationOfOffenders'}/>
                            <div className="slider round">
                                <span className="on">YES</span>
                                <span className="off">NO</span>
                            </div>
                        </label>
                    </Grid>
                    {
                        data.rehabilitationOfOffenders
                            ?
                            <div className={'medical-declaration-show'}>
                                <Grid item md={9}>
                                    <div className={'medical-declaration-comment'}>
                                        <label>{ strings.medicalDeclaration.rehabilitationYes }</label>
                                        <TextField
                                            placeholder={strings.medicalDeclaration.enterText}
                                            multiline
                                            rows={2}
                                            rowsMax={4}
                                            variant={'outlined'}
                                            onChange={onChange}
                                            name={'rehabilitationComment'}
                                            value={ data ? data.rehabilitationComment : '' }
                                            disabled={ disabled }
                                        />
                                    </div>
                                </Grid>
                            </div>
                            :
                            ""
                    }
                </React.Fragment>
            }
            {
                !checkPermission(user.userType, Permission.REHABILITATION) &&
                <Grid item md={10}>
                    <div className={'question'}>{ strings.medicalDeclaration.rehabilitationNotAllowed }</div>
                </Grid>
            }
        </Grid>
        <Divider />
    </form>
);

export default MedicalDeclarationForm;