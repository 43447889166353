import React, {Component} from 'react';
import {Dialog, DialogContent, DialogTitle, Divider, Slide} from "@material-ui/core/";
import CloseIcon from '@material-ui/icons/Close';
import {Button, IconButton, LinearProgress, Paper, TextField} from "@material-ui/core";
import strings from "../localization";
import {bindActionCreators} from "redux";
import * as Actions from "../actions/Actions";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import UserType from "../constants/UserType";
import {transformDate} from "../util/DateUtil";
import {findDataValue} from "../util/DropdownUtil";
import { editApplication, getApplication } from '../services/AddCandidateService';
import Page from './Page';
import { getVisaTypes } from '../constants/VisaType';
import SelectControl from '../components/controls/SelectControl';
import ApplicationModes from '../constants/ApplicationMode';
import Grid from '@material-ui/core/Grid';
import {getFiles} from "../services/FileUpload";
import UploadSection from "../constants/UploadSection";
import SelfieCart from "../components/SelfieCart";

class ViewApplicantModal extends Page {

    constructor(props) {
        super(props);

        this.state = {
            application: {},
            data: {},
            files: [],
            showProgressBar: false
        }
    }

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="left" ref={ref} {...props} />;
    });

    componentDidMount() {
        if(!this.props.application) {
            return;
        }

        this.fetchApplication(this.props.application.id);
        this.fetchSelfie(this.props.application.id);
    }

    componentWillReceiveProps(props) {
        if(props.open) {
            this.fetchSelfie(props.application.id);
        }

        if(!props.application) {
            return;
        }

        this.fetchApplication(props.application.id);
    }

    fetchSelfie(id) {
        this.setState({
            showProgressBar: true,
        })
        getFiles(id).then(async response => {
            this.setState({
                files: response.data
            });

            await Promise.all(
                this.state.files.map((file) => {
                    if (file.type === UploadSection.Selfie) {
                        this.setState({
                            selfie: file
                        });
                    }
                })
            )

            this.setState({
                showProgressBar: false
            });

        });
    }

    fetchApplication(id) {
        getApplication(id).then(response => {

            if(!response || !response.ok) 
            {
                return;
            }

            this.setState({
                application: response.data,
                data: response.data,
                files: []
            });
        });
    }

    handleCartsButtonClick(response) {
        if (!response || !response.ok) {
            this.props.enqueueSnackbar(strings.application.errorSavingApplican, { variant: 'error' });
            return;
        }

        this.props.enqueueSnackbar(strings.application.save, { variant: 'success' });
        return;
    }

    renderUserImage(selfie){
        let result;
        if(selfie) {
            result =
                <Grid id='selfie-gallery-page'>
                    <SelfieCart
                        data={this.state.selfie}
                        onClick={(response) => this.handleCartsButtonClick(response)}
                        className={'view-applicant-selfie'}
                        hideNameAndEmail={true}
                    />
                </Grid>
        }
        return result ? result : <img src='/images/user.png'/>;
    }

    edit() {

        this.props.setReturnUrl(window.location.pathname);
        
        this.props.history.push({
            pathname: '/application',
            state: { applicationId: this.props.application.id, returnUrl: window.location.pathname }
        })
    }

    getRecruiterName(application) {

        if(!application || !application.recruiter) {
            return '';
        }

        return application.recruiter.firstName + ' ' + application.recruiter.lastName;
    }

    renderRole(application) {

        if (!application) {
            return '';
        }

        if(application.jobRoleText) {
            return application.jobRoleText
        }

        return application.jobRole ? application.jobRole.name : '';
    }

    update() {

        if(this.state.data.visaType) {

            if(this.state.data.visaType.value) 
            {
                this.state.data.visaType = parseInt(this.state.data.visaType.value);
            }
            else {
                this.state.data.visaType = parseInt(this.state.data.visaType);
            }          
        }

        editApplication(this.state.data, ApplicationModes.SAVE).then(response => {

            if (!response || !response.ok) {
                this.props.enqueueSnackbar(strings.application.errorSavingApplican, { variant: 'error' });
                return;
            }

            this.props.enqueueSnackbar(strings.application.candidatSaved, { variant: 'success' });
        });
    }

    render() {
        if(!this.props.application) {
            return '';
        }

        return (
            <Dialog
                onClose={() => this.props.toggleModal(this.props.application.id)}
                aria-labelledby="customized-dialog-title"
                open={this.props.open}
                TransitionComponent={this.Transition}
                className={'view-applicant-modal'}
            >
                <div className='form-modal-header' onClose={() => this.props.toggleModal(this.props.application.id)}>
                    <div>
                        <h1>{this.props.title}</h1>
                        <IconButton className={'close-button-wrapper'} onClick={this.props.toggleModal}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <Divider />
                    {
                        this.state.showProgressBar &&
                        <LinearProgress/>
                    }
                    <div className={'view-applicant-wrapper'}>
                        <div className={'content-wrapper'}>
                            { !this.state.showProgressBar && this.renderUserImage(this.state.selfie) }
                            <div className={'left-wrapper'}>
                                <div className={'left'}>
                                    <label>{strings.hrManager.title}</label>
                                    <div className={'inner-left'}>
                                        {this.props.application ? findDataValue(this.props.application, 'title') : ''}
                                    </div>
                                </div>
                                <div className={'left'}>
                                    <label>{strings.hrManager.firstName}</label>
                                    <div className={'inner-left'}>
                                        {this.props.application ? this.props.application.forename : ''}
                                    </div>
                                </div>
                                <div className={'left'}>
                                    <label>{strings.hrManager.lastName}</label>
                                    <div className={'inner-left'}>
                                        {this.props.application ? this.props.application.surname : ''}
                                    </div>
                                </div>
                                <div className={'left'}>
                                    <label>{strings.hrManager.gender}</label>
                                    <div className={'inner-left'}>

                                        {this.props.application ? findDataValue(this.props.application, 'gender') : ''}
                                    </div>
                                </div>
                                <div className={'left'}>
                                    <label>{strings.hrManager.dateOfBirth}</label>
                                    <div className={'inner-left'}>
                                        {this.props.application ? transformDate(this.props.application.dateOfBirth) : ''}
                                    </div>
                                </div>
                                <div className={'left'}>
                                    <label>{strings.hrManager.email}</label>
                                    <div className={'inner-left'}>
                                        {this.props.application ? this.props.application.email : ''}
                                    </div>
                                </div>
                                <div className={'left'}>
                                    <label>{strings.hrManager.firstLineAddress}</label>
                                    <div className={'inner-left'}>
                                        {this.props.application ? this.props.application.firstLineAddress : ''}
                                    </div>
                                </div>
                                <div className={'left'}>
                                    <label>{strings.hrManager.secondLineAddress}</label>
                                    <div className={'inner-left'}>
                                        {this.props.application ? this.props.application.secondLineAddress : ''}
                                    </div>
                                </div>
                                <div className={'left'}>
                                    <label>{strings.hrManager.townCity}</label>
                                    <div className={'inner-left'}>
                                        {this.props.application ? this.props.application.townCity : ''}
                                    </div>
                                </div>
                                <div className={'left'}>
                                    <label>{strings.hrManager.postcode}</label>
                                    <div className={'inner-left'}>
                                        {this.props.application ? this.props.application.postcode : ''}
                                    </div>
                                </div>
                                <div className={'left'}>
                                    <label>{strings.hrManager.visaNumber}</label>
                                    <div className={'inner-left'}>
                                        <TextField
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                            name='visaNumber'
                                            onChange={ this.changeData }
                                            margin="dense"
                                            value={ this.state.data.visaNumber }
                                            variant='outlined'
                                            disabled={ !(this.props.auth.user.userType == UserType.HR_MANAGER ||
                                                this.props.auth.user.userType == UserType.HR_RECRUITMENT) }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={'right-wrapper'}>
                                <div className={'right'}>
                                    <label>{strings.hrManager.primaryContact}</label>
                                    <div className={'inner-right'}>
                                        {this.props.application ? this.props.application.firstPhoneContact : ''}
                                    </div>
                                </div>
                                <div className={'right'}>
                                    <label>{strings.hrManager.secondaryContact}</label>
                                    <div className={'inner-right'}>
                                        {this.props.application ? this.props.application.secondPhoneContact : ''}
                                    </div>
                                </div>
                                <div className={'right'}>
                                    <label>{strings.hrManager.emergencyContact}</label>
                                    <div className={'inner-right'}>
                                        {this.props.application ? this.props.application.emergencyFirstPhoneContact : ''}
                                    </div>
                                </div>
                                <div className={'right'}>
                                    <label>{strings.hrManager.recruiterName}</label>
                                    <div className={'inner-right'}>
                                        {
                                            this.getRecruiterName(this.props.application)
                                        }
                                    </div>
                                </div>
                                <div className={'right'}>
                                    <label>{strings.hrManager.division}</label>
                                    <div className={'inner-right'}>
                                        {
                                            findDataValue(this.props.application, 'division')
                                        }
                                    </div>
                                </div>
                                <div className={'right'}>
                                    <label>{strings.hrManager.region}</label>
                                    <div className={'inner-right'}>
                                        {
                                            findDataValue(this.props.application, 'region')
                                        }
                                    </div>
                                </div>
                                <div className={'right'}>
                                    <label>{strings.hrManager.jobRole}</label>
                                    <div className={'inner-right'}>
                                        {
                                            this.renderRole(this.props.application)
                                        }
                                    </div>
                                </div>
                                <div className={'right'}>
                                    <label>{strings.hrManager.sessionLocation}</label>
                                    <div className={'inner-right'}>
                                        {this.props.application ? this.props.application.sessionLocation : ''}
                                    </div>
                                </div>
                                <div className={'right'}>
                                    <label>{strings.hrManager.travelPreference}</label>
                                    <div className={'inner-right'}>
                                        {
                                            findDataValue(this.props.application, 'travelPreference')
                                        }
                                    </div>
                                </div>

                                <div className={'right'}>
                                    <label>{strings.hrManager.visaType}</label>
                                    <div className={'inner-right'}>
                                        <SelectControl
                                            fullWidth
                                            autoFocus
                                            name='visaType'
                                            onChange={ this.changeData }
                                            margin="normal"
                                            options={ getVisaTypes() }
                                            nameKey={ 'name' }
                                            valueKey={ 'value' }
                                            selected={ this.state.data.visaType }
                                            isSearchable={false}
                                            isDisabled={ !(this.props.auth.user.userType == UserType.HR_MANAGER ||
                                                this.props.auth.user.userType == UserType.HR_RECRUITMENT) }
                                        />
                                    </div>
                                </div>
                                <div className={'right'}>
                                    <label>{strings.hrManager.visaLimit}</label>
                                    <div className={'inner-right'}>
                                        <TextField
                                            InputLabelProps={{
                                                shrink: true,
                                            }}

                                            fullWidth
                                            type={'number'}
                                            name='visaLimit'
                                            onChange={ (e) => {
                                                let value = parseInt(e.target.value, 10);

                                                if (value > 40) value = 40;
                                                if (value < 0) value = 0;

                                                let data = this.state.data;
                                                data.visaLimit = value;

                                                this.setState({
                                                    data: data
                                                })
                                            } }
                                            margin="dense"
                                            value={ this.state.data.visaLimit }
                                            variant='outlined'
                                            disabled={ !(this.props.auth.user.userType == UserType.HR_MANAGER ||
                                                this.props.auth.user.userType == UserType.HR_RECRUITMENT) }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'buttons'}>
                            {
                                <div>
                                    <Button className={'edit-candidate-button'} variant="contained" color="primary" onClick={ () => this.edit() }>
                                        { strings.hrManager.edit }
                                    </Button>
                                    <Button className={'done-button'} variant="contained" color="primary" style={{ marginLeft: '10px' }}
                                     onClick={ () => this.update() }
                                     disabled={ !(this.props.auth.user.userType == UserType.HR_MANAGER ||
                                        this.props.auth.user.userType == UserType.HR_RECRUITMENT) }>
                                        { strings.hrManager.update }
                                    </Button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen,
        setReturnUrl: Actions.setReturnUrl
    }, dispatch);
}

function mapStateToProps({ menuReducers, filterReducers, authReducers })
{
    return { menu: menuReducers, filter: filterReducers, auth: authReducers};
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewApplicantModal)));
