import {getFilterTypes} from "./FilterTypes";

const SidebarLinks = {
    PERSONAL_DETAILS: 1,
    ADDRESS_DETAILS: 2,
    EMERGENCY_CONTACT: 3,
    JOB_DETAILS: 4,
    UK_NATIONALS: 5,
    RIGHT_TO_WORK: 6,
    IMMIGRATION_CONTROL: 7,
    GDPR_DISCLAIMER: 8,
    SUBMIT_APPLICATION: 9,
    HEADER: 10,
    PERSONAL_DETAILS_CONTRACT: 11,
    BANK_DETAILS: 12,
    EMPLOYMENT: 13,
    UNIFORM: 14,
    MEDICAL_DECLARATION: 15,
    WORKING_TIME_ELECTION: 16,
    CHANGES_IN_INFORMATION: 17,
    TERMS_AND_CONDITIONS: 18,
    DIGITAL_SIGNATURE: 19,
    CONTRACT_HEADER: 20,
    REHABILITATION: 21,
};

export default SidebarLinks;

export function getSidebarLinks() {
    const links = [
        {
            value: SidebarLinks.PERSONAL_DETAILS,
            name: 'personalDetails'
        },
        {
            value: SidebarLinks.ADDRESS_DETAILS,
            name: 'addressDetails'
        },
        {
            value: SidebarLinks.EMERGENCY_CONTACT,
            name: 'emergencyContact'
        },
        {
            value: SidebarLinks.JOB_DETAILS,
            name: 'jobDetails'
        },
        {
            value: SidebarLinks.UK_NATIONALS,
            name: 'ukNationals'
        },
        {
            value: SidebarLinks.RIGHT_TO_WORK,
            name: 'rightToWork'
        },
        {
            value: SidebarLinks.IMMIGRATION_CONTROL,
            name: 'immigrationControl'
        },
        {
            value: SidebarLinks.GDPR_DISCLAIMER,
            name: 'gdprDisclaimer'
        },
        {
            value: SidebarLinks.SUBMIT_APPLICATION,
            name: 'submitApplication'
        },
        {
            value: SidebarLinks.HEADER,
            name: 'header'
        },
        {
            value: SidebarLinks.PERSONAL_DETAILS_CONTRACT,
            name: 'personalDetailsContract'
        },
        {
            value: SidebarLinks.BANK_DETAILS,
            name: 'bankDetails'
        },
        {
            value: SidebarLinks.EMPLOYMENT,
            name: 'employment'
        },
        {
            value: SidebarLinks.UNIFORM,
            name: 'uniform'
        },
        {
            value: SidebarLinks.MEDICAL_DECLARATION,
            name: 'medicalDeclaration'
        },
        {
            value: SidebarLinks.WORKING_TIME_ELECTION,
            name: 'workingTimeElection'
        },
        {
            value: SidebarLinks.CHANGES_IN_INFORMATION,
            name: 'changesInInformation'
        },
        {
            value: SidebarLinks.TERMS_AND_CONDITIONS,
            name: 'termsAndConditions'
        },
        {
            value: SidebarLinks.DIGITAL_SIGNATURE,
            name: 'digitalSignature'
        },
        {
            value: SidebarLinks.CONTRACT_HEADER,
            name: 'contractHeader'
        },
        {
            value: SidebarLinks.REHABILITATION,
            name: 'rehabilitation'
        },
    ];

    return links;
}

export function getSidebarLinksString(link) {
    const selectedLink = getSidebarLinks().find(item => item.value === link);

    return selectedLink ? selectedLink.name : '';
}