import React, {Component} from 'react';
import {Chip, Tooltip} from "@material-ui/core/";
import CloseIcon from '@material-ui/icons/Close';
import { Button, IconButton } from "@material-ui/core";
import strings from "../localization";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import SelectControl from '../components/controls/SelectControl';
import {getDocumentTypes} from '../constants/DocumentType';
import { changeFileType } from '../services/FileUpload';

class CustomFileList extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            files: this.props.files ? this.props.files : []
        }

        this.onChange = this.onChange.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            files: props.files ? props.files : []
        });
    }

    findFile(id) {

        for(let file of this.state.files) {
            if(file.id == id) {
                return file;
            }
        }

        return null;
    }

    onChange(event, id) {

        let file = this.findFile(id);
        
        if(!file) {
            return;
        }
        
        let index = this.state.files.indexOf(file);
        
        if(index < 0) {
            return;
        }
        
        changeFileType(id, event.target.value.value).then(response => {

            if(!response || !response.ok) {
                return;
            }

            let files = this.state.files;
            files[index].documentType = event.target.value;
            
            this.setState({
                files: files
            });
        });
    }

    renderFiles() {

        let result = [];

        let options = getDocumentTypes(this.props.uploadSection)

        for(let file of this.state.files) {

            let shortName = file ? file.name : '';

            if(shortName.length > 22) {
                shortName = shortName.substring(0, 22) + '...';
            }

            result.push(
                <div className={'uploaded-file-chip'} key={ "file-" + file.id}>
                    <Tooltip title={ file ? file.name : '' }>
                        <Chip
                            icon={<AttachFileIcon />}
                            label={shortName}
                            clickable
                            color="success"
                            onDelete={() => {this.props.onDelete(file.id)}}
                            deleteIcon={<CloseIcon className={'close-icon'} />}
                            className={'chip-uploaded-file'}
                            disabled={this.props.disable}
                        />
                    </Tooltip>
                    
                    <SelectControl
                        placeholder={ strings.documents.documentType }
                        fullWidth
                        autoFocus
                        name='documentType'
                        onChange={ (event) => this.onChange(event, file.id) }
                        margin="normal"
                        options={ options }
                        nameKey={ 'name' }
                        valueKey={ 'value' }
                        selected={ file.documentType }
                        isDisabled={this.props.disable}
                        isSearchable={false}
                    />
                </div>
            )
        }

        return result;
    }

    render() {
        return (
            <React.Fragment>
                { this.renderFiles() }
            </React.Fragment>    
        );
    }
}

export default CustomFileList;