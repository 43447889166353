let CONFIG = {
     baseUrlAPI: 'http://appoint-uatapi.rascareers.com/api',
     baseUrl: 'http://appoint-uatapi.rascareers.com',
    //baseUrlAPI: 'http://localhost:58143/api',
    //baseUrl: 'http://localhost:58143',
    // baseUrlAPI: 'http://ras-api.lilly021-staging.com/api',
    // baseUrl: 'http://ras-api.lilly021-staging.com',
    // baseUrlAPI: 'https://asgappoint-apiuat.retailassetsolutions.com/api',
    // baseUrl: 'https://asgappoint-apiuat.retailassetsolutions.com',
    // baseUrlAPI: 'http://51.132.19.217:82/api',
    // baseUrl: 'http://51.132.19.217:82',
    tokenKey: '1stKgorpnoj8mFIsI3sg',
    refreshTokenKey: 'ZJmD63XQFsK6cHUWwN5T',
    clientId: 'CLIENT_ID',
    clientSecret: 'CLIENT_SECRET',
    facebookAppId: 'FACEBOOK_APP_ID',
    facebookAppSecret: 'FACEBOOK_APP_SECRET',
    googleClientId: 'GOOGLE_CLIENT_ID',
    googleClientSecret: 'GOOGLE_CLIENT_SECRET',
    linkedInClientId: 'LINKEDIN_CLIENT_ID',
    linkedInCliendSecret: 'LINKEDIN_CLIENT_SECRET',
    rowsPerPage: [ 30, 50, 100 ],
    rolesAllowed: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    acceptFiles: ['image/jpeg', 'image/png', 'image/jpg', 'image/bmp', 'image/tiff', 'image/tif', 'application/pdf',
     'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    divisonStockTakingURL : "https://stocktaking.rascareers.com",
    divisonStoreSupportURL : "https://storesupport.rascareers.com",
    divisonSupplyChainURL : "https://supplychain.rascareers.com",
    currentDivision : 'ss'
};

export default CONFIG;
