import React from 'react';
import strings from "../../../localization";
import {getError, hasError} from "../../../functions/Validation";
import {Button, TextField, Divider, Chip} from "@material-ui/core";
import SelectControl from '../../controls/SelectControl';
import Grid from '@material-ui/core/Grid';
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloseIcon from "@material-ui/icons/Close";
import DocumentType from "../../../constants/DocumentType";
import ApplicationInternalStatus from '../../../constants/ApplicationInternalStatus';

const UniformForm = ({
                            onSubmit,
                            onCancel,
                            onChange,
                            errors,
                            data,
                            onDelete,
                            titles,
                            genders,
                            disabled,
                            handleToggle
                        }) => (

    <form id='uniform-form'>
        <Grid container spacing={9} className={'grid-wrapper'}>
            <Grid item md={10}>
                <div className={'question'}>{ strings.uniform.uniformQuestion }</div>
            </Grid>
            <Grid item md={9} className={'switch-wrapper'}>
                <label className="switch" onClick={handleToggle}>
                    <input checked={data.approveAddressForUniformSending} disabled={ disabled } type="checkbox" id="togBtn" name={'approveAddressForUniformSending'}/>
                    <div className="slider round">
                        <span className="on">YES</span>
                        <span className="off">NO</span>
                    </div>
                </label>
                {
                    data.approveAddressForUniformSending
                        ?
                        <div className={'label-wrapper'}>
                            <label className={'uniform-answer'}>{strings.uniform.yes}</label>
                        </div>
                        :
                        <div className={'label-wrapper'}>
                            <label className={'uniform-answer'}>{strings.uniform.no}</label>
                        </div>
                }
            </Grid>
        </Grid>
        <Divider />
    </form>
);

export default UniformForm;