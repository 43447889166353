const ApplicationView = {
    ALL: -1,
    CANDIDATE_PROFILES: 0,
    CONDITIONAL_OFFERS: 1,
    CANDIDATE_APPLICATIONS: 2,
    VIEW_CANDIDATES: 3,
    ADMIN_ALERTS: 4
};

export default ApplicationView;

        