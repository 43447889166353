import React, {createRef} from 'react'
import CONFIG from "../../config";
import TablePage from "../../common/TablePage";
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import {withSnackbar} from "notistack";
import PageState from '../../constants/PageState';
import SelectControl from '../../components/controls/SelectControl';
import {
    Button, Drawer,
    TableBody,
    TableCell,
    TableHead,
    TableRow, TextField, LinearProgress
} from "@material-ui/core";
import DrawerWrapper from "../../common/DrawerWrapper";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FileUploadModal from '../../common/FileUploadModal';
import Dropzone from 'react-dropzone';
import ViewDocumentsModal from '../../common/ViewDocumentsModal';
import VerifyModal from "../../common/VerifyModal";
import { getCodebooks } from '../../services/CodebookService';
import {getApplications, updateStatuses, updateRTWStatus, updateRTWProcess} from '../../services/AddCandidateService';
import DocumentType from '../../constants/DocumentType';
import ViewHistoryModal from "../../common/ViewHistoryModal";
import {getHistories} from "../../services/HistoryService";
import {deleteFile, getFiles, uploadFiles} from '../../services/FileUpload';
import { getContractTypes } from '../../constants/ContractTypes';
import ApplicationStatusRTW, { getApplicationStatusesRTW } from '../../constants/ApplicationStatusRTW';
import ApplicationStatus, { getApplicationStatuses, getApplicationStatusesWithoutConditionalOffer, 
    getApplicationStatusesWithConditionalOffer } from '../../constants/ApplicationStatus';
import ApplicationInternalStatus from '../../constants/ApplicationInternalStatus';
import ApplicationView from '../../constants/ApplicationView';
import ViewApplicantModal from "../../common/ViewApplicantModal";
import {transformDate} from "../../util/DateUtil";
import AddInfoRequiredModal from "../../common/AddInfoRequiredModal";
import SearchIcon from '@material-ui/icons/Search';
import UploadSection from '../../constants/UploadSection';
import WithdrawConfirmationModal from "../../common/WithdrawConfirmationModal";
import CommentsModal from '../../common/CommentsModal';
import RTWProcess from "../../constants/RTWProcess";
import IconButton from "@material-ui/core/IconButton";
import applicationStatusRTW from "../../constants/ApplicationStatusRTW";

class CandidateProfiles extends TablePage {

    tableDescription = [
        { key: 'id', label: strings.candidateProfiles.name, transform: 'renderName', class: 'column-fix' },
        { key: 'id', transform: 'renderWarningImage' },
        { key: 'division', label: strings.candidateProfiles.division, transform: 'renderDivision' },
        { key: 'region', label: strings.candidateProfiles.region, transform: 'renderRegion' },
        { key: 'recruiter', label: strings.candidateProfiles.recruiterName, transform: 'renderRecruiter' },
        { key: 'id', label: strings.candidateProfiles.role, transform: 'renderRole' },
        { key: 'dateUpdated', label: strings.candidateProfiles.applicationDate, transform: 'renderDate'},
        { key: 'sessionType', label: strings.candidateProfiles.sessionType, transform: 'renderSessionType' },
        { key: 'sessionLocation', label: strings.candidateProfiles.sessionLocation },
        { key: 'ukShareCode', label: strings.candidateProfiles.shareCodeNo },
        { key: 'idvtComments', label: strings.candidateProfiles.IDVTComments },
        { key: 'id', label: strings.candidateProfiles.uploadFiles, transform: 'renderUploadButton' },
        { key: 'id', label: strings.candidateProfiles.automaticCheck, transform: 'renderAutomaticCheck' },
        { key: 'id', label: strings.candidateProfiles.rtwStatus, transform: 'renderVerifyCopyOfRTWDoc' },
        { key: 'id', label: '', transform: 'renderViewApplicant' },
        { key: 'id', label: '', transform: 'renderDocViewerButton' },
        { key: 'id', label: strings.candidateProfiles.applicationStatus, transform: 'renderApplicationStatus' },
        { key: 'id', label: strings.candidateProfiles.contractType, transform: 'renderContractType' },
        { key: 'id', label: strings.candidateProfiles.status, transform: 'renderConfirmButton' },
        { key: 'id', label: strings.hrManager.comments, transform: 'renderCommentsButton'},
    ];

    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : {},
            showSearch: true,
            tableData: [],
            pageState: PageState.View,
            errors: {},
            openFileUpload: false,
            openDocView: false,
            openVerifyModal: false,
            openApplicantView: false,
            openAWithdrawView: false,
            documentsDoc: [],
            files: undefined,
            showUploadProgress: false,
            documentModalFullscreen: false,
            uploadFileError: '',
            options: [{
                "id" : 1,
                "name" : 'Passport'
            },
            {
                "id" : 2,
                "name" : 'ID'
            }],
            rtwProcess: []
        };
        this.toggleViewHistoryModal = this.toggleViewHistoryModal.bind(this);
        this.toggleFileUploadModal = this.toggleFileUploadModal.bind(this);
        this.toggleViewDocumentsModal = this.toggleViewDocumentsModal.bind(this);
        this.toggleVerifyModal = this.toggleVerifyModal.bind(this);
        this.toggleViewApplicantModal = this.toggleViewApplicantModal.bind(this);
        this.toggleAddInfoRequiredModal = this.toggleAddInfoRequiredModal.bind(this);
        this.toggleWithdrawModal = this.toggleWithdrawModal.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.openViewDocuments = this.openViewDocuments.bind(this);
        this.openVerifyModal = this.openVerifyModal.bind(this);
        this.openViewApplicant = this.openViewApplicant.bind(this);
        this.openAddInfoRequired = this.openAddInfoRequired.bind(this);
        this.openWithdraw = this.openWithdraw.bind(this);
        this.updateRTWStatus = this.updateRTWStatus.bind(this);
        this.dropzoneRef = createRef();
        this.viewDocumentsRef = createRef();
        this.verifyModalRef = createRef();
        this.viewApplicantRef = createRef();
        this.addInfoRequired = createRef();
        this.openWithdrawRef = createRef();
        this.onDrop = this.onDrop.bind(this);
        this.onDropRejected = this.onDropRejected.bind(this);
        this.statusUpdate = this.statusUpdate.bind(this);
        this.findApplication = this.findApplication.bind(this);
        this.toggleCommentsModal = this.toggleCommentsModal.bind(this);
        this.applyAddInfoRequiredModal = this.applyAddInfoRequiredModal.bind(this);
        this.applyWithdrawal = this.applyWithdrawal.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
        this.openCommentsModal = this.openCommentsModal.bind(this);
        this.commentsModalRef = createRef();
        this.fetchData = this.fetchData.bind(this);
        this.showViewDocumentModal = this.showViewDocumentModal.bind(this);

    }

    renderCommentsButton(id) {

        let application = this.findApplication(id);

        return (
            <div className='comments-btn'>
                <Button variant="contained" color="primary"
                 disabled={ application.applicationInternalStatus == ApplicationInternalStatus.APPLICANT_FILING_DATA &&
                    application.applicationStatus == ApplicationStatus.ADD_INFO_REQUIRED }
                 onClick={() => this.toggleCommentsModal(id)}>
                    { strings.hrManager.comments }
                </Button>
            </div>
        );
    }

    renderDate(item){

        if(!item){
            return '';
        }

        return transformDate(item);
    }

    renderDivision(item) {
        if (!item) {
            return '';
        }

        return item.name;
    }

    renderRegion(item) {
        if (!item) {
            return '';
        }

        return item.name;
    }

    renderRecruiter(item) {
        if (!item) {
            return '';
        }

        return item.firstName + " " + item.lastName;
    }

    renderRole(id) {

        let application = this.findApplication(id);

        if (!application) {
            return '';
        }

        if(application.jobRoleText) {
            return application.jobRoleText
        }

        return application.jobRole ? application.jobRole.name : '';
    }

    renderSessionType(item) {
        if (!item) {
            return '';
        }

        return item.name;
    }

    renderViewApplicant(id){

        return (
            <div className='candidate-button candidate-view-applicant'>
                <Button variant="contained" color="primary" onClick={() => this.toggleViewApplicantModal(id)}>
                    { strings.candidateProfiles.viewApplicant }
                </Button>
            </div>
        );
    }

    onDropRejected(files) {

        if(files.length == 0) {
            return;
        }

        if(files[0].errors[0].code == 'file-invalid-type') {
            this.setState({
                uploadFileError: strings.uploadFileErrorFileType
            });
        }
        else {
            this.setState({
                uploadFileError: strings.uploadFileError
            });
        }
    }

    onDrop(acceptedFiles){

        if (!acceptedFiles || acceptedFiles.length == 0) {

            this.setState({
                uploadFileError: strings.uploadFileError
            });

            return;
        }

        this.setState({
            uploadFileError: '',
            showUploadProgress: true
        });

        for(let acceptedFile of acceptedFiles){

            let applicationFile = new FormData();
            applicationFile.append('application', this.state.selectedApplication);
            applicationFile.append('name', acceptedFile.name);
            applicationFile.append('content', acceptedFile);
            applicationFile.append('fileType', acceptedFile.type);
            applicationFile.append('type', UploadSection.Aditional);

            uploadFiles(applicationFile).then(response => {

                if(!response || !response.ok) {
                    this.props.enqueueSnackbar("Error uploading file", { variant: 'error' });
                    return;
                }

                let files = this.state.files;

                files.push(response.data);

                this.setState({
                    files: files,
                    showUploadProgress: false
                });
            });
        }
    }

    findFile(id) {

        for (let file of this.state.files) {
            if (file && file.id == id) {
                return file;
            }
        }

        return undefined;
    }

    deleteFile(id) {
        let file = this.findFile(id);

        deleteFile(id).then(response => {

            let files = this.state.files;
            let index = files.indexOf(file)
            if (index > -1) {
                files.splice(index, 1);
            }

            this.setState({
                files: files
            });
        });
    }

    fetchData() {

        if(!this.state.filter) {
            return;
        }

        this.setState({
            lockTable: true
        });

        getCodebooks().then(response => {

            if(!response.ok) {
                return;
            }

            this.setState({
                titles: response.data.titles,
                genders : response.data.genders,
                divisions: response.data.divisions,
                regions: response.data.regions,
                recruiters: response.data.recruiters,
                jobRoles: response.data.jobRoles,
                travelPreferences: response.data.travelPreferances,
                nationalities: response.data.nationalities,
                idDocumentTypes: response.data.idDocumentTypes,
                proofAddressDocumentTypes: response.data.proofAddressDocumentTypes,
                sessionTypes: response.data.sessionTypes,
                territoryOfWorks: response.data.territoryOfWorks,
            });
        });
        
        if(this.state.filter) {
            this.state.filter.applicationInternalStatus = ApplicationInternalStatus.ALL;
            this.state.filter.view = ApplicationView.CANDIDATE_PROFILES;
            this.state.filter.search = this.state.searchData.search;
            this.state.filter.page = this.state.searchData.page - 1;
            this.state.filter.perPage = this.state.searchData.perPage;
        }

        getApplications(this.state.filter).then(response => {

            if(!response.ok) {
                return;
            }

            this.setState({
                tableData: response.data.entities ? response.data.entities : [],
                total: response.data.total,
                lockTable: false
            }, () => {
                const applicationId = this.getSearchParam('id');
                if(applicationId) {
                    this.showViewDocumentModal(applicationId, true);
                }
            });

        });
    }

    componentDidMount() {

        this.props.setApplicationId(-1);

        this.state.filter = this.props.filter ? this.props.filter.filterData : {
            division: -1,
            region: -1,
            location: -1,
            recruiter: -1,
            jobRole: -1,
            applicationStatus: -1,
            contractType: -1,
            search: '',
            page: 1,
            perPage: 30,
            dateFrom: null,
            dateTo: null,
            stage: -1
        };

        this.fetchData();
    }

    componentWillReceiveProps(props) {
        this.state.filter = props.filter ? props.filter.filterData : {
            division: -1,
            region: -1,
            location: -1,
            recruiter: -1,
            jobRole: -1,
            applicationStatus: -1,
            contractType: -1,
            search: '',
            page: 1,
            perPage: 30,
            dateFrom: null,
            dateTo: null,
            stage: -1
        };
        
        this.fetchData();
    }

    renderName(id){

        let application = this.findApplication(id);

        if(!application) {
            return '';
        }

        return <div>
            <div>
                {this.renderCandidateImage()}
            </div>
            <div>
                {application.forename + ' ' + application.surname}
            </div>
        </div>
    }

    renderWarningImage(id){

        let application = this.findApplication(id);

        if(!application || !application.additionalInfoRequired) {
            return '';
        }


        return(
            <div className={'tooltip left'}>
                <div className={'warning-image'}>
                    <img src={'/images/warning.png'} alt={'logo'} />
                    <span className="tooltip-content">{application.additionalInfoRequired}</span>
                </div>
            </div>
        );
    }

    renderCandidateImage(item){
        return(
            <div className={'candidate-profile-image'}>
                {
                    this.state.tableData.image
                        ?
                        <img src={'/images/background.jpg'} alt={'logo'} />
                        :
                        <img src={'/images/user.png'} alt={'logo'} />
                }
            </div>
        );
    }

    renderUploadButton(id){

        return (
            <div className='candidate-button candidate-upload-files'>
                <Button variant="contained" color="primary" onClick={() => this.toggleFileUploadModal(id)}>
                    { strings.candidateProfiles.uploadFiles }
                </Button>
            </div>
        );
    }

    renderDocViewerButton(id){

        return (
            <div className='candidate-button candidate-view-doc'>
                <Button variant="contained" color="primary" onClick={() => this.toggleViewDocumentsModal(id)}>
                    { strings.candidateProfiles.viewDocuments }
                </Button>
            </div>
        );
    }

    renderConfirmButton(id){

        let application = this.findApplication(id);

        const disabled = application.rtwStatus != ApplicationStatusRTW.ACCEPTED || application.rtwStatus != applicationStatusRTW.IDVT_ACCEPTED || application.rtwStatus != applicationStatusRTW.SHARE_CODE_ACCEPTED || !application.contractType;

        if(application.applicationStatus === ApplicationStatus.ADD_INFO_REQUIRED){
             
            return (
                <div className='candidate-button candidate-confirm'>
                    <Button variant="contained" color="primary" 
                        disabled={ application.applicationInternalStatus == ApplicationInternalStatus.APPLICANT_FILING_DATA }
                    onClick={() => this.toggleAddInfoRequiredModal(id)}>
                        { strings.candidateProfiles.confirm }
                    </Button>
                </div>
            );
        } else if(application.applicationStatus === ApplicationStatus.WITHDRAWN) {
            return (
                <div className='candidate-button candidate-confirm'>
                    <Button variant="contained" color="primary" onClick={() => this.toggleWithdrawModal(id)}>
                        { strings.candidateProfiles.confirm }
                    </Button>
                </div>
            );
        } else {

            let disable = false;

            if((application.rtwStatus == ApplicationStatusRTW.ACCEPTED ||
                application.rtwStatus == ApplicationStatusRTW.IDVT_ACCEPTED ||
                application.rtwStatus == ApplicationStatusRTW.SHARE_CODE_ACCEPTED)
                && application.applicationStatus == ApplicationStatus.CONDITIONAL_OFFER && !application.contractType)
            {
                disable = true;
            }

            return (
                <div className='candidate-button candidate-confirm'>
                    <Button variant="contained" color="primary" disabled={disable} onClick={() =>this.statusUpdate(id)}>
                        { strings.candidateProfiles.confirm }
                    </Button>
                </div>
            );
        }
    }

    findApplication(id) {
        for(let item of this.state.tableData) {
            if(item.id == id) {
                return item;
            }
        }

        return null;
    }

    changeDataStatus(event, id) {

        let application = this.findApplication(id);

        let index = this.state.tableData.indexOf(application);

        if(index == -1) {
            return;
        }

        let data = this.state.tableData;
        data[index][event.target.name] = event.target.value.value

        this.setState({
            tableData: data
        });
    }

    changeDataRTWStatus(event, id) {
        this.toggleVerifyModal(id, event.target.value.value);
    }

    updateRTWStatus(id, status) {
        let RTWprocess = '';

        if(status === ApplicationStatusRTW.ACCEPTED || status === ApplicationStatusRTW.REJECTED) {
            RTWprocess = RTWProcess.MANUAL;
        } else if(status === ApplicationStatusRTW.IDVT_ACCEPTED) {
            RTWprocess = RTWProcess.IDVT;
        } else if(status === ApplicationStatusRTW.IDVT_REJECTED) {
            RTWprocess = RTWProcess.MANUAL;
            status = 0;
        } else {
            RTWprocess = RTWProcess.SHARE_CODE;
        }

        updateRTWStatus({
            id: id,
            status: status
        }).then(response => {

            if(!response || !response.ok) {
                this.props.enqueueSnackbar(strings.candidateProfile.errorUpdateStatuse, { variant: 'error' });
                return;
            }

            if(this.state.data.rtwProcess !== RTWprocess) {
                this.state.data.rtwProcess = RTWprocess;
                updateRTWProcess({
                    id: id,
                    Process: RTWprocess
                }).then(() => {
                    this.props.enqueueSnackbar(strings.candidateProfile.statusUpdated, {variant: 'success'});
                    this.fetchData();
                });
            } else {
                this.props.enqueueSnackbar(strings.candidateProfile.statusUpdated, {variant: 'success'});
                this.fetchData();
            }
        });
    }

    selectColor(application){
        if(application.rtwStatus === ApplicationStatusRTW.ACCEPTED ||
            application.rtwStatus === ApplicationStatusRTW.IDVT_ACCEPTED ||
            application.rtwStatus === ApplicationStatusRTW.SHARE_CODE_ACCEPTED){
            return '#378a9c'
        }else if(application.rtwStatus === ApplicationStatusRTW.REJECTED ||
                application.rtwStatus === ApplicationStatusRTW.IDVT_REJECTED ||
                application.rtwStatus === ApplicationStatusRTW.SHARE_CODE_REJECTED){
            return '#f74464';
        }else {
            return '#fff';
        }
    }

    renderAutomaticCheck(id) {
        let application = this.findApplication(id);
        let text = ''

        if(application.rtwProcess === RTWProcess.MANUAL) {
            if(application.rtwStatus === ApplicationStatusRTW.ACCEPTED) {
                text = 'Manual Approved'
            }
            else if(application.rtwStatus === ApplicationStatusRTW.REJECTED) {
                text = 'Manual Rejected'
            }
            else {
                text = 'Manual in progress'
            }
        } else if(application.rtwProcess === RTWProcess.IDVT) {
            if(application.rtwStatus === ApplicationStatusRTW.IDVT_ACCEPTED) {
                text = 'IDVT Approved'
            }
            else if(application.rtwStatus === ApplicationStatusRTW.IDVT_REJECTED) {
                text = 'IDVT Rejected'
            }
            else {
                text = 'IDVT in progress'
            }
        } else {
            if(application.rtwStatus === ApplicationStatusRTW.SHARE_CODE_ACCEPTED) {
                text = 'Share Code Approved'
            }
            else if(application.rtwStatus === ApplicationStatusRTW.SHARE_CODE_REJECTED) {
                text = 'Share Code Rejected'
            }
            else {
                text = 'Share Code in progress'
            }
        }

        return <div>
            {text}
            <IconButton>
                <img src={'images/history-icon.png'} onClick={() => this.toggleViewHistoryModal(id)}/>
            </IconButton>
        </div>
    }

    renderVerifyCopyOfRTWDoc(id){

        let application = this.findApplication(id);

        let colorStyles = {
            control: styles => ({
                ...styles,
                backgroundColor: this.selectColor(application)
            }),
            singleValue: (styles) => {
                const color = '#fff';

                return { ...styles, color };
            },
            menuPortal: base => ({ ...base, zIndex: 9999 }),
            menu: provided => ({ ...provided, zIndex: "9999 !important" })
        };

        return (
            <div>
                <SelectControl
                    fullWidth
                    isSearchable={false}
                    name='rtwStatus'
                    onChange={ (event) => this.changeDataRTWStatus(event, id) }
                    margin="normal"
                    options={ getApplicationStatusesRTW() }
                    nameKey={ 'name' }
                    valueKey={ 'value' }
                    selected={ application ? application.rtwStatus : undefined }
                    styles={colorStyles}
                    isDisabled={ (application.applicationInternalStatus == ApplicationInternalStatus.APPLICANT_FILING_DATA &&
                    application.applicationStatus == ApplicationStatus.ADD_INFO_REQUIRED) }
                />
            </div>
        );
    }

    renderContractType(id){

        let application = this.findApplication(id);

        const selectStyles = {
            menuPortal: base => ({ ...base, zIndex: 9999 }),
            menu: provided => ({ ...provided, zIndex: "9999 !important", width: 'auto', })
        };

        return (
            <div>
                <SelectControl
                    fullWidth
                    isSearchable={false}
                    autoFocus
                    name='contractType'
                    onChange={ (event) => this.changeDataStatus(event, id) }
                    margin="normal"
                    options={ getContractTypes() }
                    nameKey={ 'name' }
                    valueKey={ 'value' }
                    selected={ application ? application.contractType : undefined }
                    styles={selectStyles}
                    isDisabled={ application.applicationInternalStatus == ApplicationInternalStatus.APPLICANT_FILING_DATA &&
                        application.applicationStatus == ApplicationStatus.ADD_INFO_REQUIRED }
                />
            </div>
        );
    }

    renderApplicationStatus(id){

        let application = this.findApplication(id);

        const selectStyles = {
            menuPortal: base => ({ ...base, zIndex: 9999 }),
            menu: provided => ({ ...provided, zIndex: "9999 !important" })
        };

        return (
            <div>
                <SelectControl
                    fullWidth
                    autoFocus
                    isSearchable={false}
                    name='applicationStatus'
                    onChange={ (event) => this.changeDataStatus(event, id) }
                    margin="normal"
                    value={ this.state.data.applicationStatus }
                    options={ application.rtwStatus === ApplicationStatusRTW.ACCEPTED ||
                            application.rtwStatus === ApplicationStatusRTW.IDVT_ACCEPTED ||
                        application.rtwStatus === ApplicationStatusRTW.SHARE_CODE_ACCEPTED ? getApplicationStatusesWithConditionalOffer() :
                        getApplicationStatusesWithoutConditionalOffer() }
                    nameKey={ 'name' }
                    valueKey={ 'value' }
                    selected={ application ? application.applicationStatus : undefined }
                    styles={selectStyles}
                    isDisabled={ application.applicationInternalStatus == ApplicationInternalStatus.APPLICANT_FILING_DATA &&
                        application.applicationStatus == ApplicationStatus.ADD_INFO_REQUIRED }
                />
            </div>
        );
    }

    formModalRender(file){
        return (
            <FileUploadModal
                toggleModal={this.toggleFileUploadModal}
                open={this.state.openFileUpload}
                uploadedFile={file}
                title={strings.documents.uploadDocuments}
                openDialog={this.openDialog}
                deleteFile={this.deleteFile}
                onChange={this.changeData}
                data={this.state.selectedItem}
                uploadSection={ UploadSection.Aditional }
                content={
                    <div className={'dropzone-wrapper'}>
                        {
                            this.state.showUploadProgress &&
                            <LinearProgress/>
                        }
                        <Dropzone
                            onDropAccepted={this.onDrop}
                            onDropRejected={this.onDropRejected}
                            multiple={true}
                            ref={this.dropzoneRef}
                            accept={ CONFIG.acceptFiles }
                            maxSize={1048576 * 10}
                        >
                            {({getRootProps, getInputProps}) => (
                                <div>
                                    <section className={'file-dropzone'}>
                                        <div {...getRootProps()} className={'cursor-pointer'}>
                                            <input {...getInputProps()} />
                                            <p>{strings.documents.dragAndDrop}</p>
                                        </div>
                                    </section>
                                    <p className={'file-size'}>*10MB file size <span>{this.state.uploadFileError}</span></p>
                                </div>
                            )}
                        </Dropzone>
                    </div>
                }
            />
        );
    }

    viewDocumentsModalRender(files){
        return (
            <ViewDocumentsModal
                toggleModal={this.toggleViewDocumentsModal}
                open={this.state.openDocView}
                uploadedFiles={files}
                title={strings.documents.viewDocuments}
                openDialog={this.openViewDocuments}
                deleteFile={this.deleteFile}
                onChange={this.onChange}
                data={this.state.selectedItem}
                fullscreen={this.state.documentModalFullscreen}
            />
        );
    }

    viewHistoryModalRender(histories){
        return (
            <ViewHistoryModal
                toggleModal={this.toggleViewHistoryModal}
                open={this.state.openHistoryView}
                histories={histories}
                title={strings.histories.viewHistories}
            />
        );
    }

    toggleViewHistoryModal(id){

        if(id != -1) {
            getHistories(id).then(response => {

                if(!response || !response.ok) {
                    return;
                }

                this.setState({
                    histories: response.data
                });

            });
        }
        else {
            this.setState({
                histories: []
            });
        }
        this.setState({
            openHistoryView: !this.state.openHistoryView
        });
    }

    viewVerifyModal(){


        if(!this.state.selectedApplication) {
            return '';
        }

        let application = this.findApplication(this.state.selectedApplication, this.state.tableData);

        return (
            <VerifyModal
                closeVerifyModal={() => this.closeVerifyModal()}
                open={this.state.openVerifyModal}
                title={strings.candidateProfiles.confirmTitle}
                openDialog={this.openVerifyModal}
                application={this.state.selectedApplication}
                currentStatus={this.state.currentStatus}
                statusUpdate={this.updateRTWStatus}
                applicationId={this.state.selectedApplication}
                sessionType={application && application.sessionType ? application.sessionType.name : ''}
            />
        );
    }

    viewCommentsModal(){

        let application = this.findApplication(this.state.selectedApplication, this.state.tableData);

        return(
            <CommentsModal
                toggleModal={this.toggleCommentsModal}
                open={this.state.openCommentsModal}
                title={strings.hrManager.comments}
                openDialog={this.openCommentsModal}
                onChange={this.changeData}
                fetchData={this.fetchData}
                application={ application }
            />
        );
    }

    viewApplicant(){

        if(!this.state.selectedApplication) {
            return;
        }

        return (
            <ViewApplicantModal
                toggleModal={this.toggleViewApplicantModal}
                open={this.state.openApplicantView}
                title={strings.candidateProfiles.viewApplicant}
                openDialog={this.openViewApplicant}
                deleteFile={this.deleteFile}
                onChange={this.onChange}
                application={this.state.selectedApplication}
                statusUpdate={this.statusUpdate}
                data={this.state.data}
            />
        );
    }

    viewAddInfoRequired(){

        let application = this.findApplication(this.state.selectedApplication);

        return (
            <AddInfoRequiredModal
                toggleModal={this.toggleAddInfoRequiredModal}
                open={this.state.openAddInfoRequired}
                title={strings.candidateProfiles.addInfoRequired}
                openDialog={this.openAddInfoRequired}
                application={application}
                apply={this.applyAddInfoRequiredModal}
            />
        );
    }

    viewWithdrawModal(){
        let application = this.findApplication(this.state.selectedApplication);

        return (
            <WithdrawConfirmationModal
                toggleModal={this.toggleWithdrawModal}
                open={this.state.openWithdraw}
                // open={true}
                title={strings.candidateProfiles.withdrawMessage}
                openDialog={this.openWithdraw}
                application={application}
                apply={this.applyWithdrawal}
            />
        );
    }

    toggleFileUploadModal(id) {

        this.state.selectedApplication = id;

        getFiles(id).then(response => {

            if(!response || !response.ok) {
                return;
            }

            this.setState({
                files: response.data
            });

        });

        this.setState({
            openFileUpload: !this.state.openFileUpload,
            uploadFileError: ''
        });
    }

    toggleViewDocumentsModal(id){


        if(id != -1) {
            getFiles(id).then(response => {

                if(!response || !response.ok) {
                    return;
                }
    
                this.setState({
                    files: response.data
                });
    
            });
        }
        else {
            this.setState({
                files: []
            });
        }

        this.setState({
            openDocView: !this.state.openDocView
        });
    }

    showViewDocumentModal(id, documentModalFullscreen = false) {

        if (id != -1) {
            getFiles(id).then(response => {

                if (!response || !response.ok) {
                    return;
                }

                this.setState({
                    files: response.data
                });

            });
        }
        else {
            this.setState({
                files: []
            });
        }

        this.setState({
            openDocView: true,
            documentModalFullscreen: documentModalFullscreen
        });
    }

    toggleViewApplicantModal(id){

        this.state.selectedApplication = this.findApplication(id);

        getFiles(id).then(response => {

            if(!response || !response.ok) {
                return;
            }

            this.setState({
                files: response.data
            });

        });

        this.setState({
            openApplicantView: !this.state.openApplicantView
        });
    }

    statusUpdate(id) {

        let application = this.findApplication(id);

        if(application == null) {
            return;
        }

        let data = {
            id: application.id,
            applicationStatus: application.applicationStatus,
            contractType: application.contractType,
            additionalInfoRequired: application.additionalInfoRequired,
            emailOption: application.emailOption
        };

        updateStatuses(data).then(response => {

            if(!response || !response.ok) {
                this.props.enqueueSnackbar(strings.candidateProfile.errorUpdateStatuse, { variant: 'error' });
                return;
            }
            this.props.enqueueSnackbar(strings.candidateProfile.statusUpdated, { variant: 'success' });

            this.fetchData();

        });
    }

    toggleVerifyModal(id, status){

        this.state.selectedApplication = id;
        this.state.currentStatus = status;

        this.setState({
            openVerifyModal: !this.state.openVerifyModal
        });
    }

    openCommentsModal() {
        if (this.commentsModalRef.current) {
            this.commentsModalRef.current.open();
        }
    };

    toggleCommentsModal(id){
        this.setState({
            openCommentsModal: !this.state.openCommentsModal,
            selectedApplication: id
        });
    }

    closeVerifyModal() {
        this.state.selectedApplication = null;
        this.state.currentStatus = null;

        this.setState({
            openVerifyModal: false
        });
    }

    toggleAddInfoRequiredModal(id){

        this.state.selectedApplication = id;

        this.setState({
            openAddInfoRequired: !this.state.openAddInfoRequired
        });
    }

    toggleWithdrawModal(id){
        this.state.selectedApplication = id;

        this.setState({
            openWithdraw: !this.state.openWithdraw
        });
    }

    applyAddInfoRequiredModal(id, comment){

        let application = this.findApplication(id);

        let index = this.state.tableData.indexOf(application);
        
        
        if(index == -1) {
            return;
        }

        let data = this.state.tableData;
        data[index]['additionalInfoRequired'] = comment;
        this.state.tableData = data;

        this.statusUpdate(data[index].id);
    }

    applyWithdrawal(id, emailOption){

        let application = this.findApplication(id);

        let index = this.state.tableData.indexOf(application);

        if(index == -1) {
            return;
        }

        let data = this.state.tableData;
        data[index]['emailOption'] = emailOption;
        this.state.tableData = data;

        this.statusUpdate(data[index].id);
    }

    openDialog() {
        if (this.dropzoneRef.current) {
            this.dropzoneRef.current.open();
        }
    };

    openAddInfoRequired() {
        if (this.addInfoRequired.current) {
            this.addInfoRequired.current.open();
        }
    };

    openViewDocuments() {
        if (this.viewDocumentsRef.current) {
            this.viewDocumentsRef.current.open();
        }
    };

    openVerifyModal() {
        if (this.verifyModalRef.current) {
            this.verifyModalRef.current.open();
        }
    };

    openViewApplicant() {
        if (this.viewApplicantRef.current) {
            this.viewApplicantRef.current.open();
        }
    };

    openWithdraw() {
        if (this.openWithdrawRef.current) {
            this.openWithdrawRef.current.open();
        }
    };

    getPageHeader() {
        return <h1>{ strings.candidateProfiles.candidateProfiles }</h1>;
    }

    renderLabel(item){
        if(item.label !== 'Upload Files'){
            return item.label;
        }

        if(item.key === 'warning'){
            return '';
        }
    }

    renderTableHeader() {

        let result = [];
        let className = '';

        for(let item of this.tableDescription) {

            if(item.label === 'Share Code No.' || item.label === 'Upload Files'){
                className = 'gray';
            }else {
                className = '';
            }

            result.push(
                <TableCell className={className} key={ 'table-header-' + result.length } >
                    { this.renderLabel(item) }
                </TableCell>
            )
        }

        return (
            <TableHead className='table-header'>
                <TableRow>
                    { result }
                </TableRow>
            </TableHead>
        );
    }

    renderTableRowData(item) {

        let result = [];
        let className = '';

        for(let description of this.tableDescription) {

            if(description.label === 'Share Code No.' || description.label === 'Upload Files'){
                className = 'gray';
            }else if(description.key === 'warning'){
                className = 'warning';
            }else {
                className = '';
            }

            result.push(
                <TableCell className={className} key={ 'table-data-' + description.key + '-' + result.length } className={ description.class ? description.class : '' }>
                    {
                        description.transform !== undefined &&
                        this[description.transform](item[description.key])
                    }
                    {
                        description.transform === undefined &&
                        item[description.key]
                    }
                </TableCell>
            );
        }

        return result;
    }

    renderTableRow(data = []) {

        let result = [];

        for(let item of data) {

            let className = 'table-row';

            if(this.isRowSelected(item)) {
                className += ' selected';
            }

            result.push(
                <TableRow key={ 'table-row-' + result.length } className={ className } onClick={ () => this.selectRow(item) }>
                    { this.renderTableRowData(item) }
                    {/*{ this.renderRowMenu(result.length, item) }*/}
                </TableRow>
            )
        }

        return (
            <TableBody>
                { result }
            </TableBody>
        );
    }

    renderRowMenu(index, item) {

        let ariaOwns = 'action-menu-' + index;

        return(
            <TableCell>


            </TableCell>
        );
    }

    render() {
        let historyView = this.viewHistoryModalRender(this.state.histories);
        let formModal = this.formModalRender(this.state.files);
        let docView = this.viewDocumentsModalRender(this.state.files);
        let verifyModal = this.viewVerifyModal();
        let applicantView = this.viewApplicant();
        let addInfoRequired = this.viewAddInfoRequired();
        let withdrawModal = this.viewWithdrawModal();
        let commentsModal = this.viewCommentsModal();

        return (
            <div>
                <Grid id='candidate-profiles-table-page'>
                    { this.renderDialog(strings.table.confirmDelete, 'To subscribe to this website, please enter your email address here. We will send\n' +
                        'updates occasionally.', this.cancelDelete, this.delete) }
                    <div className='header'>
                        { this.getPageHeader() }

                        <div className='filter-controls'>
                            {
                                this.state.showSearch &&
                                <div className={'filter-wrapper'}>
                                    <TextField
                                        label={ strings.table.search }
                                        type="search"
                                        name='search'
                                        value={ this.state.searchData.search }
                                        onChange={ this.searchChanged }
                                        variant={'outlined'}
                                    />
                                    <SearchIcon/>
                                </div>
                            }
                            {
                                this.state.showAdd &&
                                this.renderTableControls()
                            }
                        </div>
                    </div>
                    <Paper className={'table-wrapper'} md={12}>
                        { this.renderTable(this.state.tableData) }
                    </Paper>

                    <Drawer id='drawer' anchor='right' open={  this.showDrawer() } onClose={ () => this.setPageState(PageState.View) } >
                        <DrawerWrapper onBack={ () => this.setPageState(PageState.View) }>
                            { this.renderDrawerContent() }
                        </DrawerWrapper>
                    </Drawer>
                </Grid>
                {historyView}
                {formModal}
                {docView}
                {verifyModal}
                {applicantView}
                {addInfoRequired}
                {withdrawModal}
                {commentsModal}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen,
        setApplicationId: Actions.setApplicationId
    }, dispatch);
}

function mapStateToProps({ menuReducers, filterReducers })
{
    return { menu: menuReducers, filter: filterReducers};
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(CandidateProfiles)));