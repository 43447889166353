import Permission from "../constants/Permission";
import UserType from "../constants/UserType";

export function checkPermission(userType, permission) {

    if(!userType) {
        return false;
    }

    switch(permission) {
        case Permission.ADD_CANDIDATE: return checkAddCandidate(userType);
        case Permission.APPLICATIONS:  return checkApplications(userType);
        case Permission.CANDIDATE_PROFILE: return checkCandidateProfile(userType);
        case Permission.CONDITIONAL_OFFER: return checkConditionalOffer(userType);
        case Permission.USER_APPLICATION: return checkUserApplication(userType);
        case Permission.CONTRACT: return checkUserContract(userType);
        case Permission.VIEW_CANDIDATES: return checkViewCandidates(userType);
        case Permission.SELFIE_GALLERY: return checkSelfieGallery(userType);
        case Permission.GO_BACK: return checkGoBack(userType);
        case Permission.MEDICAL_DECLARATION_VIEW: return checkMedicalDeclaration(userType);
        case Permission.REHABILITATION: return checkRehabilitation(userType);
        case Permission.BANK_DETAILS: return checkBankDetails(userType);
        case Permission.ADMIN_ALERT: return checkAdminAlerts(userType);
        default: return false;
    }
}

function checkMedicalDeclaration(userType) {
    return userType == UserType.HR_MANAGER || userType == UserType.APPLICANT || userType == UserType.HR_RECRUITMENT; 
}

function checkAdminAlerts(userType) {
    return userType == UserType.ADMIN || userType == UserType.RECRUITMENT_MANAGER
    || userType == UserType.HR_MANAGER 
}

function checkBankDetails(userType) {
    return userType == UserType.HR_MANAGER || userType == UserType.APPLICANT || userType == UserType.HR_RECRUITMENT;
}

function checkRehabilitation(userType) {
    return userType == UserType.HR_MANAGER || userType == UserType.APPLICANT || userType == UserType.HR_RECRUITMENT;
}

function checkUserContract(userType){
    return true;
}

function checkGoBack(userType) {
    return userType != UserType.APPLICANT; 
}

function checkAddCandidate(userType) {

    return userType == UserType.ADMIN || userType == UserType.RECRUITMENT_MANAGER
    || userType == UserType.HR_MANAGER 
}

function checkViewCandidates(userType) {
    return userType == UserType.ADMIN || userType == UserType.RECRUITMENT_MANAGER
    || userType == UserType.HR_MANAGER;
}

function checkSelfieGallery(userType) {
    return userType == UserType.ADMIN || userType == UserType.RECRUITMENT_MANAGER
        || userType == UserType.HR_MANAGER;
}

function checkApplications(userType) {

    return userType == UserType.HR_MANAGER;
}

function checkCandidateProfile(userType) {

    return userType == UserType.RECRUITMENT_MANAGER || userType == UserType.ADMIN;
}

function checkConditionalOffer(userType) {
    
    return userType == UserType.RECRUITMENT_MANAGER || userType == UserType.ADMIN || userType == UserType.HR_MANAGER;
}

function checkUserApplication(userType) {

    return userType == UserType.APPLICANT;
}