import React from 'react';
import strings from "../../../localization";
import {Button, Divider} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';

import ApplicationInternalStatus from '../../../constants/ApplicationInternalStatus';
import {getError, hasError} from "../../../functions/Validation";

const TermsAndConditionsForm = ({
                                    onSubmit,
                                    errors,
                                    data,
                                    disabled
                                }) => (

    <form id='terms-and-conditions-form'>
        <Grid container spacing={9} className={'grid-wrapper-terms'}>
            <Grid item md={12}>
                <div className={'question'}>{strings.termsAndConditions.selectTerms}</div>
            </Grid>
            <Grid item md={12}>
                <div className='submit-container'>
                    <Button variant="contained" color="primary" onClick={onSubmit}>
                        {strings.termsAndConditions.termsAndConditions}
                    </Button>
                </div>
            </Grid>
            {

                hasError(errors, 'termsOfConditionsAccepted') &&
                <p className={'error'}>
                    {getError(errors, 'termsOfConditionsAccepted')}
                </p>
            }
        </Grid>
        <Divider/>
    </form>
);

export default TermsAndConditionsForm;