import {request} from "../base/HTTP";
import HttpMethod from "../constants/HttpMethod";

export async function applicationConfirm(id) {
    return await request('/api/application/confirm/' + id, {}, HttpMethod.PUT);
}

export async function applicationSendComment(id, data) {
    return await request('/api/application/comment/' + id, data, HttpMethod.PUT);
}

export async function applicationResendEmail(id) {
    return await request('/api/application/resend-invite-email/' + id, {}, HttpMethod.GET);
}

export async function withdrawApplication() {
    return await request('/api/application/withdraw', {}, HttpMethod.PUT);
}