import React, { Component } from 'react'
import { bindActionCreators } from "redux";
import * as Actions from "../actions/Actions";
import history from "../history";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MenuState from "../constants/MenuState";
import strings from "../localization";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import PersonIcon from '@material-ui/icons/Person';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { Drawer } from "@material-ui/core";
import { HashLink as Link } from 'react-router-hash-link';
import { checkPermission } from '../util/SecurityUtil';
import Permission from '../constants/Permission';
import SidebarLinks from "../constants/SidebarLinks";
import AssignmentIcon from '@material-ui/icons/Assignment';
import { checkNationalityIDVTVerification, checkUniformConditions } from "../util/FieldsConditionUtil";
import UserType from '../constants/UserType';
import {findDataValue} from "../util/DropdownUtil";
import CONFIG from '../config';
class Navigation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            applicationId: props.siteData.applicationId,
            submenu: {
                example: true,
                contract: false,
                recruitment: false,
            }
        };

        this.scrollWithOffset = this.scrollWithOffset.bind(this);
    }

    componentWillReceiveProps(props) {

        this.setState({
            applicationId: props.siteData.applicationId
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.siteData.hasContract !== this.props.siteData.hasContract) {
            let userType = this.props.auth.user ? this.props.auth.user.userType : null;

            if (checkPermission(userType, Permission.CONTRACT) && this.props.siteData.hasContract) {

                let data = this.state.submenu;
                data.example = false;
                data.contract = true;

                this.setState({
                    submenu: data
                });

                history.push('/contract');
            }
        }
    }

    getNavigationClass() {

        if (this.props.menu.state === MenuState.SHORT) {
            return 'navigation-content-container short';
        }
        else {

            return 'navigation-content-container'
        }
    }

    isCurrentPath(path) {

        return this.props.history.location.pathname == path;
    }

    isCurrentPathContains(path) {

        return this.props.history.location.pathname.includes(path);
    }

    toggleSubmenu(key, link) {

        let submenu = this.state.submenu;

        submenu[key] = !submenu[key];

        this.setState({
            submenu: submenu
        });

        if (!link) {
            return;
        }

        history.push(link);
    }

    scrollWithOffset(el) {

        const elementPosition = el.getBoundingClientRect().top - 100;

        window.scrollTo({
            top: elementPosition,
            left: 0,
            behavior: "auto"
        });
    }

    getClassName(link) {
        switch (link) {
            case SidebarLinks.PERSONAL_DETAILS:
                return this.props.siteData.linkIndicator.personalDetails ? ' yellow' : '';
            case SidebarLinks.ADDRESS_DETAILS:
                return this.props.siteData.linkIndicator.addressDetails ? ' yellow' : '';
            case SidebarLinks.EMERGENCY_CONTACT:
                return this.props.siteData.linkIndicator.emergencyContact ? ' yellow' : '';
            case SidebarLinks.JOB_DETAILS:
                return this.props.siteData.linkIndicator.jobDetails ? ' yellow' : '';
            case SidebarLinks.UK_NATIONALS:
                return this.props.siteData.linkIndicator.ukNationals ? ' yellow' : '';
            case SidebarLinks.RIGHT_TO_WORK:
                return this.props.siteData.linkIndicator.rightToWork ? ' yellow ' : '';
            case SidebarLinks.GDPR_DISCLAIMER:
                return this.props.siteData.linkIndicator.gdprDisclaimer ? ' yellow' : '';
            case SidebarLinks.IMMIGRATION_CONTROL:
                return this.props.siteData.linkIndicator.immigrationControl ? ' yellow' : '';
            case SidebarLinks.SUBMIT_APPLICATION:
                return this.props.siteData.linkIndicator.submitApplication ? ' yellow' : '';
            case SidebarLinks.PERSONAL_DETAILS_CONTRACT:
                return this.props.siteData.linkIndicator.personalDetailsContract ? ' yellow' : '';
            case SidebarLinks.BANK_DETAILS:
                return this.props.siteData.linkIndicator.bankDetails ? ' yellow' : '';
            case SidebarLinks.EMPLOYMENT:
                return this.props.siteData.linkIndicator.employment ? ' yellow' : '';
            case SidebarLinks.UNIFORM:
                return this.props.siteData.linkIndicator.uniform ? ' yellow' : '';
            case SidebarLinks.MEDICAL_DECLARATION:
                return this.props.siteData.linkIndicator.medicalDeclaration ? ' yellow' : '';
            case SidebarLinks.WORKING_TIME_ELECTION:
                return this.props.siteData.linkIndicator.workingTimeElection ? ' yellow' : '';
            case SidebarLinks.CHANGES_IN_INFORMATION:
                return this.props.siteData.linkIndicator.changesInInformation ? ' yellow' : '';
            case SidebarLinks.TERMS_AND_CONDITIONS:
                return this.props.siteData.linkIndicator.termsAndConditions ? ' yellow' : '';
            case SidebarLinks.DIGITAL_SIGNATURE:
                return this.props.siteData.linkIndicator.digitalSignature ? ' yellow' : '';
            case SidebarLinks.REHABILITATION:
                return this.props.siteData.linkIndicator.rehabilitation ? ' yellow' : '';
            default: return '';
        }
    }

    menuClick() {

        if(window.innerWidth > 768) {
            return;
        }

        this.props.changeMenuState(MenuState.SHORT)
    }

    render() {

        const userType = this.props.auth.user ? this.props.auth.user.userType : null;
        const currDiv = this.props.auth.user ? this.props.auth.user.div : null;
        const confDiv = CONFIG.currentDivision +'div';
        return (
            <Drawer variant="permanent" id='navigation'>
                <div className={this.getNavigationClass()}>
                    <div className='logo-container'>
                        <div className='logo'>
                            <Link to={'/'}>
                                <img src={'/images/' + confDiv + '.svg'} alt={'logo'} />
                            </Link>
                        </div>
                    </div>
                    <List component="nav">
                        {(this.isCurrentPathContains('/application') || this.state.applicationId > 0) &&
                            <React.Fragment>
                                <ListItem className='navigation-item' button onClick={() => this.toggleSubmenu('example', '/application')} >
                                    <ListItemIcon className='navigation-icon user-icon'>
                                        <PersonIcon />
                                    </ListItemIcon>
                                    <ListItemText inset primary={strings.candidateProfile.candidateProfile} className='navigation-text' />
                                    {this.state.submenu.example ? <ExpandLess className='navigation-icon user-icon' /> : <ExpandMore className='navigation-icon user-icon' />}
                                </ListItem>
                                <Collapse in={this.state.submenu.example} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding className='submenu'>
                                        <Link onClick={() => this.menuClick()} scroll={el => this.scrollWithOffset(el)} to={'/application/#personal-details-anchor'} className={this.isCurrentPath('/') ? 'navigation-link active ' : 'navigation-link' + this.getClassName(SidebarLinks.PERSONAL_DETAILS)} >
                                            <ListItem className='navigation-item'>
                                                <ListItemIcon className='navigation-icon'>
                                                    <div className={this.props.siteData.changeIndicator.personalDetails ? 'circle green' : 'circle yellow'}></div>
                                                </ListItemIcon>
                                                <ListItemText inset primary={strings.candidateProfile.personalDetails} className='navigation-text' />
                                            </ListItem>
                                        </Link>
                                        <Link onClick={() => this.menuClick()} scroll={el => this.scrollWithOffset(el)} to={'/application/#uk-nationals-anchor'} className={this.isCurrentPath('/') ? 'navigation-link active' : 'navigation-link' + this.getClassName(SidebarLinks.UK_NATIONALS)} >
                                            <ListItem className='navigation-item'>
                                                <ListItemIcon className='navigation-icon'>
                                                    <div className={this.props.siteData.changeIndicator.ukNationals ? 'circle green' : 'circle yellow'}></div>
                                                </ListItemIcon>
                                                <ListItemText inset primary={strings.candidateProfile.ukNationals} className='navigation-text' />
                                            </ListItem>
                                        </Link>
                                        {
                                            !this.props.siteData.data.ukCitizen && !checkNationalityIDVTVerification(findDataValue(this.props.siteData.data, 'nationality')) &&
                                            <Link onClick={() => this.menuClick()} scroll={el => this.scrollWithOffset(el)} to={'/application/#right-to-work-anchor'} className={this.isCurrentPath('/') ? 'navigation-link active' : 'navigation-link' + this.getClassName(SidebarLinks.RIGHT_TO_WORK)} >
                                                <ListItem className='navigation-item'>
                                                    <ListItemIcon className='navigation-icon'>
                                                        <div className={this.props.siteData.changeIndicator.rightToWork ? 'circle green' : 'circle yellow'}></div>
                                                    </ListItemIcon>
                                                    <ListItemText inset primary={strings.candidateProfile.rightToWork} className='navigation-text' />
                                                </ListItem>
                                            </Link>
                                        }
                                        <Link onClick={() => this.menuClick()} scroll={el => this.scrollWithOffset(el)} to={'/application/#address-details-anchor'} className={this.isCurrentPath('/') ? 'navigation-link active' : 'navigation-link' + this.getClassName(SidebarLinks.ADDRESS_DETAILS)} >
                                            <ListItem className='navigation-item'>
                                                <ListItemIcon className='navigation-icon'>
                                                    <div className={this.props.siteData.changeIndicator.addressDetails === true ? 'circle green' : 'circle yellow'}></div>
                                                </ListItemIcon>
                                                <ListItemText inset primary={strings.candidateProfile.addressDetails} className='navigation-text' />
                                            </ListItem>
                                        </Link>
                                        <Link onClick={() => this.menuClick()} scroll={el => this.scrollWithOffset(el)} to={'/application/#emergency-contact-anchor'} className={this.isCurrentPath('/') ? 'navigation-link active' : 'navigation-link' + this.getClassName(SidebarLinks.EMERGENCY_CONTACT)} >
                                            <ListItem className='navigation-item'>
                                                <ListItemIcon className='navigation-icon'>
                                                    <div className={this.props.siteData.changeIndicator.emergencyContact ? 'circle green' : 'circle yellow'}></div>
                                                </ListItemIcon>
                                                <ListItemText inset primary={strings.candidateProfile.emergencyContact} className='navigation-text' />
                                            </ListItem>
                                        </Link>
                                        <Link onClick={() => this.menuClick()} scroll={el => this.scrollWithOffset(el)} to={'/application/#job-details-anchor'} className={this.isCurrentPath('/') ? 'navigation-link active' : 'navigation-link' + this.getClassName(SidebarLinks.JOB_DETAILS)} >
                                            <ListItem className='navigation-item'>
                                                <ListItemIcon className='navigation-icon'>
                                                    <div className={this.props.siteData.changeIndicator.jobDetails ? 'circle green' : 'circle yellow'}></div>
                                                </ListItemIcon>
                                                <ListItemText inset primary={strings.candidateProfile.jobDetails} className='navigation-text' />
                                            </ListItem>
                                        </Link>
                                        {/* <Link scroll={el => this.scrollWithOffset(el)} to={'/application/#immigration-control-anchor'} className={ this.isCurrentPath('/') ? 'navigation-link active' : 'navigation-link' + this.getClassName(SidebarLinks.IMMIGRATION_CONTROL)} >
                                            <ListItem className='navigation-item'>
                                                <ListItemIcon className='navigation-icon'>
                                                    <div className={ this.props.siteData.changeIndicator.immigrationControl ? 'circle green' : 'circle yellow'}></div>
                                                </ListItemIcon>
                                                <ListItemText inset primary={strings.candidateProfile.immigrationControl} className='navigation-text'/>
                                            </ListItem>
                                        </Link> */}
                                        <Link onClick={() => this.menuClick()} scroll={el => this.scrollWithOffset(el)} to={'/application/#gdpr-disclaimer-anchor'} className={this.isCurrentPath('/') ? 'navigation-link active' : 'navigation-link' + this.getClassName(SidebarLinks.GDPR_DISCLAIMER)} >
                                            <ListItem className='navigation-item'>
                                                <ListItemIcon className='navigation-icon'>
                                                    <div className={this.props.siteData.changeIndicator.gdprDisclaimer ? 'circle green' : 'circle yellow'}></div>
                                                </ListItemIcon>
                                                <ListItemText inset primary={strings.candidateProfile.gdprDisclaimer} className='navigation-text' />
                                            </ListItem>
                                        </Link>
                                        <Link onClick={() => this.menuClick()} scroll={el => this.scrollWithOffset(el)} to={'/application/#submit-container-anchor'} className={this.isCurrentPath('/') ? 'navigation-link active' : 'navigation-link' + this.getClassName(SidebarLinks.SUBMIT_APPLICATION)} >
                                            <ListItem className='navigation-item'>
                                                <ListItemIcon className='navigation-icon'>
                                                    <div className={this.props.siteData.changeIndicator.submitApplication ? 'circle green' : 'circle yellow'}></div>
                                                </ListItemIcon>
                                                <ListItemText inset primary={strings.candidateProfile.submitApplication} className='navigation-text' />
                                            </ListItem>
                                        </Link>
                                    </List>
                                </Collapse>

                            </React.Fragment>
                        }
                        {!this.isCurrentPathContains('/application') && !this.isCurrentPathContains('/contract') &&
                            <React.Fragment>
                                {
                                    checkPermission(userType, Permission.CANDIDATE_PROFILE) &&

                                    <Link onClick={() => this.menuClick()} to={'/candidate-profiles'} className={this.isCurrentPath('/candidate-profiles') ? 'navigation-link active' : 'navigation-link'} >
                                        <ListItem className='navigation-item' button >
                                            <ListItemIcon className='navigation-icon user-icon'>
                                                <PersonIcon />
                                            </ListItemIcon>
                                            <ListItemText inset primary={strings.candidateProfiles.candidateProfiles} className='navigation-text' />
                                        </ListItem>
                                    </Link>
                                }

                                {
                                    checkPermission(userType, Permission.APPLICATIONS) && !this.isCurrentPath('/application') && !this.isCurrentPath('/contract') &&

                                    <Link onClick={() => this.menuClick()} to={'/candidate-applications'} className={this.isCurrentPath('/candidate-applications') ? 'navigation-link active' : 'navigation-link'} >
                                        <ListItem className='navigation-item' button >
                                            <ListItemIcon className='navigation-icon user-icon'>
                                                <PersonIcon />
                                            </ListItemIcon>
                                            <ListItemText inset primary={strings.hrManager.candidateApplications} className='navigation-text' />
                                        </ListItem>
                                    </Link>
                                }

                                {
                                    checkPermission(userType, Permission.CONDITIONAL_OFFER) &&

                                    <Link onClick={() => this.menuClick()} to={'/conditional-offers'} className={this.isCurrentPath('/conditional-offers') ? 'navigation-link active' : 'navigation-link'} >
                                        <ListItem className='navigation-item' button >
                                            <ListItemIcon className='navigation-icon user-icon'>
                                                <BusinessCenterIcon />
                                            </ListItemIcon>
                                            <ListItemText inset primary={strings.conditionalOffers.conditionalOffers} className='navigation-text' />
                                        </ListItem>
                                    </Link>
                                }

                                {
                                    checkPermission(userType, Permission.VIEW_CANDIDATES) && !this.isCurrentPath('/application') && !this.isCurrentPath('/contract') &&

                                    <Link onClick={() => this.menuClick()} to={'/view-candidate'} className={this.isCurrentPath('/view-candidate') ? 'navigation-link active' : 'navigation-link'} >
                                        <ListItem className='navigation-item' button >
                                            <ListItemIcon className='navigation-icon user-icon'>
                                                <PersonIcon />
                                            </ListItemIcon>
                                            <ListItemText inset primary={strings.navigation.viewCandidate} className='navigation-text' />
                                        </ListItem>
                                    </Link>
                                }
                                 {
                                    checkPermission(userType, Permission.ADMIN_ALERT) && !this.isCurrentPath('/admin-alerts') && !this.isCurrentPath('/contract') &&

                                    <Link onClick={() => this.menuClick()} to={'/admin-alerts'} className={this.isCurrentPath('/admin-alerts') ? 'navigation-link active' : 'navigation-link'} >
                                        <ListItem className='navigation-item' button >
                                            <ListItemIcon className='navigation-icon user-icon'>
                                                <PersonIcon />
                                            </ListItemIcon>
                                            <ListItemText inset primary={strings.navigation.adminAlert} className='navigation-text' />
                                        </ListItem>
                                    </Link>
                                }

                                {
                                    checkPermission(userType, Permission.ADD_CANDIDATE) && !this.isCurrentPath('/application') && !this.isCurrentPath('/contract') &&

                                    <Link onClick={() => this.menuClick()} to={'/add-candidate'} className={this.isCurrentPath('/add-candidate') ? 'navigation-link active' : 'navigation-link'} >
                                        <ListItem className='navigation-item' button >
                                            <ListItemIcon className='navigation-icon user-icon'>
                                                <img src={'/images/add.png'} alt={'logo'} />
                                            </ListItemIcon>
                                            <ListItemText inset primary={strings.addCandidate.addCandidate} className='navigation-text' />
                                        </ListItem>
                                    </Link>
                                }

                                {
                                    checkPermission(userType, Permission.SELFIE_GALLERY) && !this.isCurrentPath('/application') && !this.isCurrentPath('/contract') &&

                                    <Link onClick={() => this.menuClick()} to={'/selfie-gallery'} className={this.isCurrentPath('/selfie-gallery') ? 'navigation-link active' : 'navigation-link'} >
                                        <ListItem className='navigation-item' button >
                                            <ListItemIcon className='navigation-icon user-icon'>
                                                <BusinessCenterIcon />
                                            </ListItemIcon>
                                            <ListItemText inset primary={strings.navigation.selfieGallery} className='navigation-text' />
                                        </ListItem>
                                    </Link>
                                }

                            </React.Fragment>
                        }

                        {
                            checkPermission(userType, Permission.CONTRACT) && this.props.siteData.hasContract && this.state.applicationId > 0 &&
                            <React.Fragment>
                                <ListItem className='navigation-item' button onClick={() => this.toggleSubmenu('contract', '/contract')} >
                                    <ListItemIcon className='navigation-icon user-icon'>
                                        <AssignmentIcon />
                                    </ListItemIcon>
                                    <ListItemText inset primary={strings.contract.contract} className='navigation-text' />
                                    {this.state.submenu.contract ? <ExpandLess className='navigation-icon user-icon' /> : <ExpandMore className='navigation-icon user-icon' />}
                                </ListItem>
                                <Collapse in={this.state.submenu.contract} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding className='submenu'>
                                        <Link onClick={() => this.menuClick()} scroll={el => this.scrollWithOffset(el)} to={'/contract/#personal-details-anchor'} className={this.isCurrentPath('/') ? 'navigation-link active ' : 'navigation-link' + this.getClassName(SidebarLinks.PERSONAL_DETAILS_CONTRACT)} >
                                            <ListItem className='navigation-item'>
                                                <ListItemIcon className='navigation-icon'>
                                                    {/* <div className={ this.props.siteData.changeIndicator.personalDetailsContract ? 'circle green' : 'circle yellow'}></div> */}
                                                </ListItemIcon>
                                                <ListItemText inset primary={strings.contract.personalDetails} className='navigation-text' />
                                            </ListItem>
                                        </Link>
                                        <Link onClick={() => this.menuClick()} scroll={el => this.scrollWithOffset(el)} to={'/contract/#bank-details-anchor'} className={this.isCurrentPath('/') ? 'navigation-link active ' : 'navigation-link' + this.getClassName(SidebarLinks.BANK_DETAILS)} >
                                            <ListItem className='navigation-item'>
                                                <ListItemIcon className='navigation-icon'>
                                                    {/* <div className={ this.props.siteData.changeIndicator.bankDetails ? 'circle green' : 'circle yellow'}></div> */}
                                                </ListItemIcon>
                                                <ListItemText inset primary={strings.contract.bankDetails} className='navigation-text' />
                                            </ListItem>
                                        </Link>
                                        <Link onClick={() => this.menuClick()} scroll={el => this.scrollWithOffset(el)} to={'/contract/#employment-anchor'} className={this.isCurrentPath('/') ? 'navigation-link active ' : 'navigation-link' + this.getClassName(SidebarLinks.EMPLOYMENT)} >
                                            <ListItem className='navigation-item'>
                                                <ListItemIcon className='navigation-icon'>
                                                    {/* <div className={ this.props.siteData.changeIndicator.employment ? 'circle green' : 'circle yellow'}></div> */}
                                                </ListItemIcon>
                                                <ListItemText inset primary={strings.contract.employment} className='navigation-text' />
                                            </ListItem>
                                        </Link>
                                        {
                                            checkUniformConditions(this.props.siteData.data) &&
                                            <Link onClick={() => this.menuClick()} scroll={el => this.scrollWithOffset(el)} to={'/contract/#uniform-anchor'} className={this.isCurrentPath('/') ? 'navigation-link active ' : 'navigation-link' + this.getClassName(SidebarLinks.UNIFORM)} >
                                                <ListItem className='navigation-item'>
                                                    <ListItemIcon className='navigation-icon'>
                                                        {/* <div className={ this.props.siteData.changeIndicator.uniform ? 'circle green' : 'circle yellow'}></div> */}
                                                    </ListItemIcon>
                                                    <ListItemText inset primary={strings.contract.uniform} className='navigation-text' />
                                                </ListItem>
                                            </Link>
                                        }
                                        <Link onClick={() => this.menuClick()} scroll={el => this.scrollWithOffset(el)} to={'/contract/#medical-declaration-anchor'} className={this.isCurrentPath('/') ? 'navigation-link active ' : 'navigation-link' + this.getClassName(SidebarLinks.MEDICAL_DECLARATION)} >
                                            <ListItem className='navigation-item'>
                                                <ListItemIcon className='navigation-icon'>
                                                    {/* <div className={ this.props.siteData.changeIndicator.medicalDeclaration ? 'circle green' : 'circle yellow'}></div> */}
                                                </ListItemIcon>
                                                <ListItemText inset primary={strings.contract.medicalDeclaration} className='navigation-text' />
                                            </ListItem>
                                        </Link>
                                        <Link onClick={() => this.menuClick()} scroll={el => this.scrollWithOffset(el)} to={'/contract/#rehabilitation'} className={this.isCurrentPath('/') ? 'navigation-link active ' : 'navigation-link' + this.getClassName(SidebarLinks.REHABILITATION)} >
                                            <ListItem className='navigation-item'>
                                                <ListItemIcon className='navigation-icon'>
                                                    {/* <div className={ this.props.siteData.changeIndicator.medicalDeclaration ? 'circle green' : 'circle yellow'}></div> */}
                                                </ListItemIcon>
                                                <ListItemText inset primary={strings.contract.rehabilitation} className='navigation-text' />
                                            </ListItem>
                                        </Link>
                                        <Link onClick={() => this.menuClick()} scroll={el => this.scrollWithOffset(el)} to={'/contract/#working-time-election-anchor'} className={this.isCurrentPath('/') ? 'navigation-link active ' : 'navigation-link' + this.getClassName(SidebarLinks.WORKING_TIME_ELECTION)} >
                                            <ListItem className='navigation-item'>
                                                <ListItemIcon className='navigation-icon'>
                                                    {/* <div className={ this.props.siteData.changeIndicator.workingTimeElection ? 'circle green' : 'circle yellow'}></div> */}
                                                </ListItemIcon>
                                                <ListItemText inset primary={strings.contract.workingTimeElection} className='navigation-text' />
                                            </ListItem>
                                        </Link>
                                        <Link onClick={() => this.menuClick()} scroll={el => this.scrollWithOffset(el)} to={'/contract/#changes-in-information-anchor'} className={this.isCurrentPath('/') ? 'navigation-link active ' : 'navigation-link' + this.getClassName(SidebarLinks.CHANGES_IN_INFORMATION)} >
                                            <ListItem className='navigation-item'>
                                                <ListItemIcon className='navigation-icon'>
                                                    {/* <div className={ this.props.siteData.changeIndicator.changesInInformation ? 'circle green' : 'circle yellow'}></div> */}
                                                </ListItemIcon>
                                                <ListItemText inset primary={strings.contract.changesInInformation} className='navigation-text' />
                                            </ListItem>
                                        </Link>
                                        <Link onClick={() => this.menuClick()} scroll={el => this.scrollWithOffset(el)} to={'/contract/#terms-and-conditions-anchor'} className={this.isCurrentPath('/') ? 'navigation-link active ' : 'navigation-link' + this.getClassName(SidebarLinks.TERMS_AND_CONDITIONS)} >
                                            <ListItem className='navigation-item'>
                                                <ListItemIcon className='navigation-icon'>
                                                    {/* <div className={ this.props.siteData.changeIndicator.termsAndConditions ? 'circle green' : 'circle yellow'}></div> */}
                                                </ListItemIcon>
                                                <ListItemText inset primary={strings.contract.termsAndConditions} className='navigation-text' />
                                            </ListItem>
                                        </Link>
                                        <Link onClick={() => this.menuClick()} scroll={el => this.scrollWithOffset(el)} to={'/contract/#digital-signature-anchor'} className={this.isCurrentPath('/') ? 'navigation-link active ' : 'navigation-link' + this.getClassName(SidebarLinks.DIGITAL_SIGNATURE)} >
                                            <ListItem className='navigation-item'>
                                                <ListItemIcon className='navigation-icon'>
                                                    {/* <div className={ this.props.siteData.changeIndicator.digitalSignature ? 'circle green' : 'circle yellow'}></div> */}
                                                </ListItemIcon>
                                                <ListItemText inset primary={strings.contract.digitalSignature} className='navigation-text' />
                                            </ListItem>
                                        </Link>
                                    </List>
                                </Collapse>
                            </React.Fragment>
                        }

                        {
                            userType == UserType.HR_RECRUITMENT && !this.isCurrentPathContains('/application') && !this.isCurrentPathContains('/contract') &&
                            <React.Fragment>

                                <ListItem className='navigation-item' button onClick={() => this.toggleSubmenu('recruitment', null)} >
                                    <ListItemIcon className='navigation-icon user-icon'>
                                        <AssignmentIcon />
                                    </ListItemIcon>
                                    <ListItemText inset primary={strings.navigation.recruitment} className='navigation-text' />
                                    {this.state.submenu.recruitment ? <ExpandLess className='navigation-icon user-icon' /> : <ExpandMore className='navigation-icon user-icon' />}
                                </ListItem>
                                <Collapse in={this.state.submenu.recruitment} timeout="auto" unmountOnExit>
                                    <Link onClick={() => this.menuClick()} to={'/candidate-profiles'} className={this.isCurrentPath('/candidate-profiles') ? 'navigation-link active' : 'navigation-link'} >
                                        <ListItem className='navigation-item' button >
                                            <ListItemIcon className='navigation-icon user-icon'>
                                                <PersonIcon />
                                            </ListItemIcon>
                                            <ListItemText inset primary={strings.candidateProfiles.candidateProfiles} className='navigation-text' />
                                        </ListItem>
                                    </Link>

                                    <Link onClick={() => this.menuClick()} to={'/conditional-offers'} className={this.isCurrentPath('/conditional-offers') ? 'navigation-link active' : 'navigation-link'} >
                                        <ListItem className='navigation-item' button >
                                            <ListItemIcon className='navigation-icon user-icon'>
                                                <BusinessCenterIcon />
                                            </ListItemIcon>
                                            <ListItemText inset primary={strings.conditionalOffers.conditionalOffers} className='navigation-text' />
                                        </ListItem>
                                    </Link>
                                </Collapse>

                                <ListItem className='navigation-item' button onClick={() => this.toggleSubmenu('hr', null)} >
                                    <ListItemIcon className='navigation-icon user-icon'>
                                        <AssignmentIcon />
                                    </ListItemIcon>
                                    <ListItemText inset primary={strings.navigation.hr} className='navigation-text' />
                                    {this.state.submenu.hr ? <ExpandLess className='navigation-icon user-icon' /> : <ExpandMore className='navigation-icon user-icon' />}
                                </ListItem>
                                <Collapse in={this.state.submenu.hr} timeout="auto" unmountOnExit>
                                    <Link onClick={() => this.menuClick()} to={'/candidate-applications'} className={this.isCurrentPath('/candidate-applications') ? 'navigation-link active' : 'navigation-link'} >
                                        <ListItem className='navigation-item' button >
                                            <ListItemIcon className='navigation-icon user-icon'>
                                                <PersonIcon />
                                            </ListItemIcon>
                                            <ListItemText inset primary={strings.hrManager.candidateApplications} className='navigation-text' />
                                        </ListItem>
                                    </Link>

                                    <Link onClick={() => this.menuClick()} to={'/view-candidate'} className={this.isCurrentPath('/view-candidate') ? 'navigation-link active' : 'navigation-link'} >
                                        <ListItem className='navigation-item' button >
                                            <ListItemIcon className='navigation-icon user-icon'>
                                                <PersonIcon />
                                            </ListItemIcon>
                                            <ListItemText inset primary={strings.navigation.viewCandidate} className='navigation-text' />
                                        </ListItem>
                                    </Link>
                                    
                                    <Link onClick={() => this.menuClick()} to={'/admin-alerts'} className={this.isCurrentPath('/admin-alerts') ? 'navigation-link active' : 'navigation-link'} >
                                        <ListItem className='navigation-item' button >
                                            <ListItemIcon className='navigation-icon user-icon'>
                                                <PersonIcon />
                                            </ListItemIcon>
                                            <ListItemText inset primary={strings.navigation.adminAlert} className='navigation-text' />
                                        </ListItem>
                                    </Link>
                                
                                    <Link onClick={() => this.menuClick()} to={'/add-candidate'} className={this.isCurrentPath('/add-candidate') ? 'navigation-link active' : 'navigation-link'} >
                                        <ListItem className='navigation-item' button >
                                            <ListItemIcon className='navigation-icon user-icon'>
                                                <img src={'/images/add.png'} alt={'logo'} />
                                            </ListItemIcon>
                                            <ListItemText inset primary={strings.addCandidate.addCandidate} className='navigation-text' />
                                        </ListItem>
                                    </Link>

                                    <Link onClick={() => this.menuClick()} to={'/selfie-gallery'} className={this.isCurrentPath('/selfie-gallery') ? 'navigation-link active' : 'navigation-link'} >
                                        <ListItem className='navigation-item' button >
                                            <ListItemIcon className='navigation-icon user-icon'>
                                                <BusinessCenterIcon />
                                            </ListItemIcon>
                                            <ListItemText inset primary={strings.navigation.selfieGallery} className='navigation-text' />
                                        </ListItem>
                                    </Link>
                                </Collapse>

                            </React.Fragment>

                        }

                        {
                            userType === UserType.SYSTEM_ADMIN &&
                            <React.Fragment>
                                <Link onClick={() => this.menuClick()} to={'/system-settings'} className={this.isCurrentPath('/system-settings') ? 'navigation-link active' : 'navigation-link'} >
                                    <ListItem className='navigation-item' button >
                                        <ListItemIcon className='navigation-icon user-icon'>
                                            <img src={'/images/add.png'} alt={'logo'} />
                                        </ListItemIcon>
                                        <ListItemText inset primary={strings.navigation.idvtOverride} className='navigation-text' />
                                    </ListItem>
                                </Link>
                            </React.Fragment>
                        }
                    </List>
                </div>
            </Drawer>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeMenuState: Actions.changeMenuState,
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
    return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navigation));