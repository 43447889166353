
const ArchivedStatus = {
    ALL: -1,
    ACTIVE: 1,
    ARCHIVED: 2
};

export default ArchivedStatus;

export function getArchivedStatuses() {
    return [
        {
            value: ArchivedStatus.ALL,
            name: 'All'
        },
        {
            value: ArchivedStatus.ACTIVE,
            name: 'Active'
        },
        {
            value: ArchivedStatus.ARCHIVED,
            name: 'Archived'
        }
    ]
}