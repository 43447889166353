import React, {Component} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Dialog, Divider, IconButton, Slide, TextField } from "@material-ui/core";
import strings from "../localization";
import ApplicationStatusRTW from "../constants/ApplicationStatusRTW";
import history from "../history";
import TermsAndConditionsStatus from "../constants/TermsAndConditionsStatus";

class TermsAndConditionsModal extends Component {

    constructor(props) {
        super(props);

        this.state = {}
    }

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="left" ref={ref} {...props} />;
    });

    render() {
        return (
            <Dialog
                onClose={this.props.toggleModal}
                aria-labelledby="customized-dialog-title"
                open={this.props.open}
                TransitionComponent={this.Transition}
                className={'terms-and-conditions-modal'}
            >
                <div className='form-modal-header' onClose={this.props.toggleModal}>
                    <div>
                        <h1>{this.props.title}</h1>
                        <IconButton className={'close-button-wrapper'} onClick={this.props.toggleModal}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <Divider />
                </div>
                <div className={'verify-wrapper'}>
                    <div className={'verify-message'}>
                        {
                            this.props.termsAndConditions && this.props.termsAndConditions === TermsAndConditionsStatus.ACCEPT
                            && strings.termsAndConditions.acceptMessage
                        }

                        {
                            this.props.termsAndConditions && this.props.termsAndConditions === TermsAndConditionsStatus.DECLINE
                            && strings.termsAndConditions.declineMessage
                        }
                    </div>
                    <div className={'verify-buttons'}>
                        
                        <Button className={'yes'} variant="contained" color="primary" onClick={() => this.props.toggleModal(true, this.props.termsAndConditions)}>
                            { strings.table.yes }
                        </Button>
                        
                        <Button className={'no'} variant="contained" color="secondary" onClick={() => this.props.toggleModal(false, this.props.termsAndConditionss)}>
                            { strings.table.no }
                        </Button>
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default TermsAndConditionsModal;