import React, {createRef} from 'react'
import CONFIG from '../../config'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import strings from '../../localization'
import Page from "../../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {Button, Divider, LinearProgress} from "@material-ui/core";
import history from '../../history';
import {withSnackbar} from "notistack";
import PersonalDetailsForm from "../../components/forms/user/PersonalDetailsForm";
import {deleteFile, getFiles, uploadFiles} from "../../services/FileUpload";
import Validators from '../../constants/ValidatorTypes';
import SidebarLinks, {getSidebarLinks, getSidebarLinksString} from "../../constants/SidebarLinks";
import BankDetailsForm from "../../components/forms/user/BankDetailsForm";
import EmploymentForm from "../../components/forms/user/EmploymentForm";
import UniformForm from "../../components/forms/user/UniformForm";
import MedicalDeclarationForm from "../../components/forms/user/MedicalDeclarationForm";
import WorkingTimeElectionForm from "../../components/forms/user/WorkingTimeElectionForm";
import ChangesInInformationForm from "../../components/forms/user/ChangesInInformationForm";
import TermsAndConditionsForm from "../../components/forms/user/TermsAndConditionsForm";
import SignaturePad from "react-signature-canvas";
import DocumentType from "../../constants/DocumentType";
import FileUploadModal from "../../common/FileUploadModal";
import Dropzone from "react-dropzone";
import PersonalDetailsContractForm from "../../components/forms/user/PersonalDetailsContract";
import {getApplication} from '../../services/AddCandidateService';
import {dateToString} from '../../util/DateUtil';
import {getContract, updateContract, declineContract} from '../../services/ContractService';
import ApplicationInternalStatus from '../../constants/ApplicationInternalStatus';
import TermsAndConditions from '../termsAndConditions/TermsAndConditions';
import MessageModal from "../../common/MessageModal";
import {findDataValue} from "../../util/DropdownUtil";
import UploadSection from '../../constants/UploadSection';
import ContractTypeMode from '../../constants/ContractTypes';
import {checkUniformConditions} from "../../util/FieldsConditionUtil";
import TermsAndConditionsStatus from "../../constants/TermsAndConditionsStatus";
import BaseConfirmDialog from '../../common/BaseConfirmDialog';
import {withdrawApplication} from "../../services/ApplicationService";
class Contract extends Page {

    validationList = {}

    refsList = getSidebarLinks();

    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : {},
            errors: {},
            openFileUpload: false,
            openMessageModal: false,
            uploadedFile: [],
            changeIndicator: {},
            changeLinkIndicator: {},
            fileUploadModal: undefined,
            files: [],
            disableField: false,
            trimmedDataUrl: null,
            showTermsAndConditions: false,
            showUploadProgress: false,
            applicationId: this.props.applicationId ? this.props.applicationId : -1,
            uploadFileError: '',
            signatureError: '',
            showWithdrawConfirmModal: false,
        };

        this.props.changeFullScreen(false);
        this.dropzoneRef = createRef();
        this.messageModalRef = createRef();
        this.onDrop = this.onDrop.bind(this);
        this.onDropRejected = this.onDropRejected.bind(this);
        this.toggleFileUploadModal = this.toggleFileUploadModal.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.clear = this.clear.bind(this);
        this.trim = this.trim.bind(this);
        this.submit = this.submit.bind(this);
        this.openTermsAndConditions = this.openTermsAndConditions.bind(this);
        this.submitTermsAndCodnitions = this.submitTermsAndCodnitions.bind(this);
        this.closeTermsAndCodnitions = this.closeTermsAndCodnitions.bind(this);
        this.toggleMessageModal = this.toggleMessageModal.bind(this);
        this.validateBankShortCode = this.validateBankShortCode.bind(this);
        this.validateBankNumber = this.validateBankNumber.bind(this);
        this.closeWithdrawConfirmModal = this.closeWithdrawConfirmModal.bind(this);
        this.applicationWithdrawal = this.applicationWithdrawal.bind(this);
        this.confirmWithdraw = this.confirmWithdraw.bind(this);
        this.contractHeaderRef = createRef();
        this.personalDetailsContractRef = createRef();
        this.bankDetailsRef = createRef();
        this.employmentRef = createRef();
        this.uniformRef = createRef();
        this.medicalDeclarationRef = createRef();
        this.rehabilitationRef = createRef();
        this.workingTimeElectionRef = createRef();
        this.changesInInformationRef = createRef();
        this.termsAndConditionsRef = createRef();
        this.digitalSignatureRef = createRef();
        this.openDialog = this.openDialog.bind(this);
        this.signaturePadRef = createRef();

        let queryName = this.props.location.search ? new URLSearchParams(this.props.location.search) : undefined;
        this.state.termsAndConditions = queryName ? queryName.get('termsAndConditions') : undefined;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.changeIndicator(prevProps, prevState);

        if (prevProps.siteData.errors !== this.props.siteData.errors) {
            this.setState({
                errors: this.props.siteData.errors
            })
        }

        if (prevProps.siteData.data !== this.state.data) {
            this.props.getData(this.state.data);
        }
    }

    componentWillReceiveProps(props) {

        if (this.state.applicationId == -1 && props.siteData.applicationId != -1) {
            this.state.applicationId = props.siteData.applicationId;
            this.fetchData();
        }
    }


    changeIndicator(prevProps) {
        if (prevProps.siteData.changeIndicator !== undefined) {

            if (prevProps.siteData.changeIndicator.personalDetailsContract === false) {
                if (this.state.data.title !== null && this.state.data.forename !== undefined && this.state.data.surname !== undefined
                    && this.state.data.email !== undefined && this.state.data.gender !== null && this.state.data.dateOfBirth !== undefined) {
                    let changeIndicator = this.props.siteData.changeIndicator;
                    changeIndicator.personalDetailsContract = true;
                    this.props.changeIndicator(changeIndicator);
                }
            }

            if (prevProps.siteData.changeIndicator.bankDetails === false) {

                if (this.state.data.bankName !== undefined && this.state.data.accountHolderName !== undefined) {
                    if (findDataValue(this.state.data, 'territoryOfWork') === 'UK') {
                        if (this.state.data.bankAccountNumber !== undefined && this.state.data.bankSortCode !== undefined) {
                            let changeIndicator = this.props.siteData.changeIndicator;
                            changeIndicator.bankDetails = true;
                            this.props.changeIndicator(changeIndicator);
                        }
                    } else {
                        if (this.state.data.iban !== undefined) {

                            let changeIndicator = this.props.siteData.changeIndicator;
                            changeIndicator.bankDetails = true;
                            this.props.changeIndicator(changeIndicator);
                        }
                    }
                }
            }

            if (prevProps.siteData.changeIndicator.employment === false) {
                if (this.state.data.isOnlyEmployment !== undefined && this.state.data.havePreviousWorked !== undefined) {
                    let changeIndicatorEmployment = !!((this.state.data.isOnlyEmployment === false && (this.state.data.otherWorkHours !== undefined && this.state.data.otherWorkHours !== null)) || this.state.data.isOnlyEmployment === true);
                    let changeIndicatorPreviousEmployment = !!((this.state.data.havePreviousWorked === true && (this.state.data.previousWorkedLeavingReason !== undefined && this.state.data.previousWorkedLeavingReason !== null)) || this.state.data.havePreviousWorked === false);

                    if (changeIndicatorEmployment && changeIndicatorPreviousEmployment) {
                        let changeIndicator = this.props.siteData.changeIndicator;
                        changeIndicator.employment = true;
                        this.props.changeIndicator(changeIndicator);
                    }
                }
            }

            if (prevProps.siteData.changeIndicator.uniform === false) {
                if (this.state.data.approveAddressForUniformSending !== undefined) {
                    let changeIndicator = this.props.siteData.changeIndicator;
                    changeIndicator.uniform = true;
                    this.props.changeIndicator(changeIndicator);
                }
            }

            if (prevProps.siteData.changeIndicator.medicalDeclaration === false) {
                if (this.state.data.haveMedicalConditions) {
                    if (this.state.data.medicalConditionDescription) {
                        let changeIndicator = this.props.siteData.changeIndicator;
                        changeIndicator.medicalDeclaration = true;
                        this.props.changeIndicator(changeIndicator);
                    }
                } else if (this.state.data.haveMedicalConditions === false) {
                    let changeIndicator = this.props.siteData.changeIndicator;
                    changeIndicator.medicalDeclaration = true;
                    this.props.changeIndicator(changeIndicator);
                }
            }

            if (prevProps.siteData.changeIndicator.workingTimeElection === false) {
                if (this.state.data.workingTimeElection) {
                    let changeIndicator = this.props.siteData.changeIndicator;
                    changeIndicator.workingTimeElection = true;
                    this.props.changeIndicator(changeIndicator);
                }
            }

            if (prevProps.siteData.changeIndicator.changesInInformation === false) {
                if (this.state.data.approveDataChangeNotificationToCompany) {
                    let changeIndicator = this.props.siteData.changeIndicator;
                    changeIndicator.changesInInformation = true;
                    this.props.changeIndicator(changeIndicator);
                }
            }

            if (prevProps.siteData.changeIndicator.termsAndConditions === false) {
                if (this.state.data.termsOfConditionsAccepted) {
                    let changeIndicator = this.props.siteData.changeIndicator;
                    changeIndicator.termsAndConditions = true;
                    this.props.changeIndicator(changeIndicator);
                }
            }
        }
    }

    changeLinkIndicator(name, state) {
        let changeLinkIndicator = this.props.siteData.linkIndicator;

        for (let item in changeLinkIndicator) {
            changeLinkIndicator[item] = false;
        }

        changeLinkIndicator[name] = state;
        this.props.changeLinkIndicator(changeLinkIndicator);
    }

    componentDidMount() {
        const data = this.state.data;

        if (this.state.termsAndConditions !== undefined) {
            data.termsAndConditions = this.state.termsAndConditions === 'true';
        }

        this.setState({
            data: data
        });

        this.props.setHasContract(true);

        this.fetchData();

        this.state.changeIndicator = this.props.siteData.changeIndicator;
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    fetchData() {

        getContract(this.state.applicationId).then(response => {

            if (!response || !response.ok) {
                return;
            }

            this.state.data = response.data;

            getApplication(this.state.applicationId).then(response => {

                if (!response || !response.ok) {
                    return;
                }

                let data = this.state.data;

                data.forename = response.data.forename;
                data.surname = response.data.surname;
                data.email = response.data.email;
                data.dateOfBirth = dateToString(new Date(response.data.dateOfBirth));
                data.gender = response.data.gender.name;
                data.title = response.data.title.name;
                data.applicationInternalStatus = response.data.applicationInternalStatus;
                data.territoryOfWork = response.data.territoryOfWork;
                data.contractType = response.data.contractType;

                this.setState({
                    data: data
                })

                getFiles(this.state.data.applicationId).then(response => {

                    this.setState({
                        files: response.data
                    })
                });
            });
        });
    }

    submit() {

        updateContract(this.state.data).then(response => {

            if (!response || !response.ok) {
                this.props.enqueueSnackbar(strings.contract.errorSavingContract, {variant: 'error'});
                return;
            }

            this.props.enqueueSnackbar(strings.contract.contractSaved, {variant: 'success'});

            response.data.applicationInternalStatus = ApplicationInternalStatus.CONTRACT_FILLED;

            this.toggleMessageModal();

            this.fetchData();
        })
    }

    onDropRejected(files) {

        if (files.length == 0) {
            return;
        }

        if (files[0].errors[0].code == 'file-invalid-type') {
            this.setState({
                uploadFileError: strings.uploadFileErrorFileType
            });
        } else {
            this.setState({
                uploadFileError: strings.uploadFileError
            });
        }
    }

    onDrop(acceptedFiles, uploadSection) {

        if (!acceptedFiles || acceptedFiles.length == 0) {

            this.setState({
                uploadFileError: strings.uploadFileError
            });

            return;
        }

        this.setState({
            uploadFileError: '',
            showUploadProgress: true
        });

        for (let acceptedFile of acceptedFiles) {

            let contractFile = new FormData();
            contractFile.append('application', this.state.data.applicationId);
            contractFile.append('name', acceptedFile.name);
            contractFile.append('content', acceptedFile);
            contractFile.append('fileType', acceptedFile.type);
            contractFile.append('type', uploadSection);

            uploadFiles(contractFile).then((result) => {

                if (!result || !result.ok) {
                    this.props.enqueueSnackbar("Error uploading file", {variant: 'error'});
                }

                let files = this.state.files;

                files.push(result.data);

                this.setState({
                    files: files,
                    showUploadProgress: false
                });
            });
        }
    }

    toggleFileUploadModal(fileUploadModal) {

        this.setState({
            openFileUpload: !this.state.openFileUpload,
            fileUploadModal: fileUploadModal,
            uploadFileError: ''
        });
    }

    toggleMessageModal() {
        this.setState({
            openMessageModal: !this.state.openMessageModal
        });
    }

    findFile(id) {

        for (let file of this.state.files) {
            if (file && file.id == id) {
                return file;
            }
        }

        return undefined;
    }

    deleteFile(id) {
        let file = this.findFile(id);

        deleteFile(id).then(response => {

            let files = this.state.files;
            let index = files.indexOf(file)
            if (index > -1) {
                files.splice(index, 1);
            }

            this.setState({
                files: files
            });

        });
    }

    getDimensions(ele) {
        const height = ele ? ele.getBoundingClientRect() : 0;
        const offsetTop = ele ? ele.offsetTop : 0;
        const offsetBottom = offsetTop + height;

        return {
            height,
            offsetTop,
            offsetBottom
        };
    }

    handleScroll() {
        for (let ref of this.refsList) {

            let ele;

            switch (ref.value) {
                case SidebarLinks.CONTRACT_HEADER:
                    ele = this.contractHeaderRef.current !== null ? this.getDimensions(this.contractHeaderRef.current) : '';

                    if (window.pageYOffset + 100 <= ele.offsetTop) {
                        this.changeLinkIndicator(getSidebarLinksString(SidebarLinks.PERSONAL_DETAILS_CONTRACT), true);
                    }
                    break;
                case SidebarLinks.PERSONAL_DETAILS_CONTRACT:
                    ele = this.personalDetailsContractRef.current !== null ? this.getDimensions(this.personalDetailsContractRef.current) : '';

                    if (window.pageYOffset + 110 > ele.offsetTop) {
                        this.changeLinkIndicator(getSidebarLinksString(SidebarLinks.PERSONAL_DETAILS_CONTRACT), true);
                    }
                    break;
                case SidebarLinks.BANK_DETAILS:
                    ele = this.bankDetailsRef.current !== null ? this.getDimensions(this.bankDetailsRef.current) : '';

                    if (window.pageYOffset + 110 > ele.offsetTop) {
                        this.changeLinkIndicator(getSidebarLinksString(SidebarLinks.BANK_DETAILS), true);
                    }
                    break;
                case SidebarLinks.EMPLOYMENT:
                    ele = this.employmentRef.current !== null ? this.getDimensions(this.employmentRef.current) : '';

                    if (window.pageYOffset + 110 > ele.offsetTop) {
                        this.changeLinkIndicator(getSidebarLinksString(SidebarLinks.EMPLOYMENT), true);
                    }
                    break;
                case SidebarLinks.UNIFORM:
                    ele = this.uniformRef.current !== null ? this.getDimensions(this.uniformRef.current) : '';

                    if (window.pageYOffset + 110 > ele.offsetTop) {
                        this.changeLinkIndicator(getSidebarLinksString(SidebarLinks.UNIFORM), true);
                    }
                    break;
                case SidebarLinks.MEDICAL_DECLARATION:
                    ele = this.medicalDeclarationRef.current !== null ? this.getDimensions(this.medicalDeclarationRef.current) : '';

                    if (window.pageYOffset + 110 > ele.offsetTop) {
                        this.changeLinkIndicator(getSidebarLinksString(SidebarLinks.MEDICAL_DECLARATION), true);
                    }
                    break;
                case SidebarLinks.WORKING_TIME_ELECTION:
                    ele = this.workingTimeElectionRef.current !== null ? this.getDimensions(this.workingTimeElectionRef.current) : '';

                    if (window.pageYOffset + 110 > ele.offsetTop) {
                        this.changeLinkIndicator(getSidebarLinksString(SidebarLinks.WORKING_TIME_ELECTION), true);
                    }
                    break;
                case SidebarLinks.CHANGES_IN_INFORMATION:
                    ele = this.changesInInformationRef.current !== null ? this.getDimensions(this.changesInInformationRef.current) : '';

                    if (window.pageYOffset + 110 > ele.offsetTop) {
                        this.changeLinkIndicator(getSidebarLinksString(SidebarLinks.CHANGES_IN_INFORMATION), true);
                    }
                    break;
                case SidebarLinks.TERMS_AND_CONDITIONS:
                    ele = this.termsAndConditionsRef.current !== null ? this.getDimensions(this.termsAndConditionsRef.current) : '';

                    if (window.pageYOffset + 110 > ele.offsetTop) {
                        this.changeLinkIndicator(getSidebarLinksString(SidebarLinks.TERMS_AND_CONDITIONS), true);
                    }
                    break;
                case SidebarLinks.DIGITAL_SIGNATURE:
                    ele = this.digitalSignatureRef.current !== null ? this.getDimensions(this.digitalSignatureRef.current) : '';

                    if (window.pageYOffset + 110 > ele.offsetTop) {
                        this.changeLinkIndicator(getSidebarLinksString(SidebarLinks.DIGITAL_SIGNATURE), true);
                    }
                    break;
                case SidebarLinks.REHABILITATION:
                    ele = this.rehabilitationRef.current !== null ? this.getDimensions(this.rehabilitationRef.current) : '';

                    if (window.pageYOffset + 110 > ele.offsetTop) {
                        this.changeLinkIndicator(getSidebarLinksString(SidebarLinks.REHABILITATION), true);
                    }
                    break;
                default:
            }
        }
    }

    openTermsAndConditions() {

        this.setState({
            showTermsAndConditions: true
        });

        window.scrollTo(0, 0);
    }

    clear() {
        if (this.signaturePadRef) {
            this.signaturePadRef.clear()
        }
    }

    validateBankNumber() {
        if (findDataValue(this.state.data, 'territoryOfWork') !== 'UK') {
            return true;
        }

        const re = new RegExp('^[0-9]*$');
        let errors = this.state.errors;

        if (!this.state.data.bankAccountNumber || !re.test(this.state.data.bankAccountNumber) || this.state.data.bankAccountNumber.length !== 8) {

            errors['bankAccountNumber'] = [{message: 'Please enter a valid Bank Account Number'}]

            this.setState({
                errors: errors
            })
            return false
        } else {
            errors['bankAccountNumber'] = []
            this.setState({
                errors: errors
            })
        }

        return true;
    }

    validateBIC() {
        if (findDataValue(this.state.data, 'territoryOfWork') === 'UK') {
            return true;
        }

        let errors = this.state.errors;
        if(!this.state.data.bic || (this.state.data.bic.length < 8 || this.state.data.bic.length > 11)) {
            errors['bic'] = [{message: 'Please enter a valid BIC number'}]

            this.setState({
                errors: errors
            })
            return false
        }
        else {
            errors['bic'] = []
            this.setState({
                errors: errors
            })
        }

        return true
    }

    validateBankShortCode() {
        if (findDataValue(this.state.data, 'territoryOfWork') !== 'UK') {
            return true;
        }

        const re = new RegExp('^[0-9]*$');
        let errors = this.state.errors;

        if (!this.state.data.bankShortCode || !re.test(this.state.data.bankShortCode) || this.state.data.bankShortCode.length !== 6) {

            errors['bankShortCode'] = [{message: 'Please enter a valid Bank Sort Code'}]

            this.setState({
                errors: errors
            })
            return false
        } else {
            errors['bankShortCode'] = []
            this.setState({
                errors: errors
            })
        }

        return true;
    }

    validateBankDetailsConfirmation() {
        let errors = this.state.errors;
        if(!this.state.data.bankDetailConfirmation) {
            errors['bankDetailConfirmation'] = [{message: 'Please confirm that you have checked the above entered bank details'}]

            this.setState({
                errors: errors
            })
            return false
        }
        else {
            errors['bankDetailConfirmation'] = []
            this.setState({
                errors: errors
            })
        }

        return true
    }

    validateTermsAndConditions() {
        let errors = this.state.errors;
        if(!this.state.data.bankDetailConfirmation) {
            errors['termsOfConditionsAccepted'] = [{message: 'Please confirm that you have checked the above entered bank details'}]

            this.setState({
                errors: errors
            })
            return false
        }
        else {

            if(this.state.data.termsOfConditionsAccepted) {
                errors['termsOfConditionsAccepted'] = []
                this.setState({
                    errors: errors
                })
                return true
            }
            else {
                errors['termsOfConditionsAccepted'] = [{message: 'Please accept terms and conditions'}]
                this.setState({
                    errors: errors
                })
                return false
            }
        }

        return true
    }

    validateBankName() {
        let errors = this.state.errors;
        if(!this.state.data.bankName) {
            errors['bankName'] = [{message: 'Please enter your details'}]

            this.setState({
                errors: errors
            })
            return false
        }
        else {
            errors['bankName'] = []
            this.setState({
                errors: errors
            })
        }

        return true
    }

    validateAccountHolderName() {
        let errors = this.state.errors;
        if(!this.state.data.accountHolderName) {
            errors['accountHolderName'] = [{message: 'Please enter your details'}]

            this.setState({
                errors: errors
            })
            return false
        }
        else {
            errors['accountHolderName'] = []
            this.setState({
                errors: errors
            })
        }

        return true
    }
    applicationWithdrawal() {
        this.setState({
            showWithdrawConfirmModal: true
        })
    }

    closeWithdrawConfirmModal() {
        this.setState({
            showWithdrawConfirmModal: false
        })
    }

    confirmWithdraw() {
        withdrawApplication().then(response => {
            this.setState({
                showWithdrawConfirmModal: false
            })
        })
    }
    validateIBAN() {
        if (findDataValue(this.state.data, 'territoryOfWork') === 'UK') {
            return true;
        }

        let errors = this.state.errors;
        if(!this.state.data.iban) {
            errors['iban'] = [{message: 'Please enter your details'}]

            this.setState({
                errors: errors
            })
            return false
        }
        else {
            errors['iban'] = []
            this.setState({
                errors: errors
            })
        }

        return true
    }

    trim() {

        let hasErrors = false;

        if (!this.validateBankShortCode()) {
            hasErrors = true;
        }

        if (!this.validateBankNumber()) {
            hasErrors = true;
        }

        if (!this.validateBIC()) {
            hasErrors = true;
        }

        if(!this.validateBankDetailsConfirmation()) {
            hasErrors = true;
        }

        if(!this.validateTermsAndConditions()) {
            hasErrors = true;
        }

        if(!this.validateBankName()) {
            hasErrors = true;
        }

        if(!this.validateAccountHolderName()) {
            hasErrors = true;
        }

        if(!this.validateIBAN()) {
            hasErrors = true;
        }

        if (hasErrors) {
            this.scrollToError(this.state.errors)

            return;
        }

        this.setState({
            signatureError: ''
        });

        if (!this.signaturePadRef && !this.state.data.signature) {
            return;
        }

        if (this.signaturePadRef?.isEmpty() && !this.state.data.signature) {

            this.setState({
                signatureError: strings.contract.signatureError
            });

            return;
        }

        let data = this.state.data;
        if(!this.state.data.signature && this.signaturePadRef)
       {
         data.signature = this.signaturePadRef.getTrimmedCanvas().toDataURL('image/png');
         this.setState({
            data: data
         });
        }
        this.setState({
            signatureError: ''
        });

        this.submit();
    }

    openDialog() {
        if (this.dropzoneRef.current) {
            this.dropzoneRef.current.open();
        }
    };

    formModalRender(uploadSection) {
        return (
            <FileUploadModal
                toggleModal={this.toggleFileUploadModal}
                open={this.state.openFileUpload}
                uploadedFile={this.getFilesForType(uploadSection)}
                title={strings.documents.uploadDocuments}
                openDialog={this.openDialog}
                deleteFile={this.deleteFile}
                onChange={this.changeData}
                data={this.state.data}
                uploadSection={uploadSection}
                content={
                    <div className={'dropzone-wrapper'}>
                        {
                            this.state.showUploadProgress &&
                            <LinearProgress/>
                        }
                        <Dropzone
                            onDrop={(files) => this.onDrop(files, uploadSection)}
                            onDropRejected={this.onDropRejected}
                            multiple={true}
                            ref={this.dropzoneRef}
                            accept={CONFIG.acceptFiles}
                            maxSize={1048576 * 10}
                        >
                            {({getRootProps, getInputProps}) => (
                                <div>
                                    <section className={'file-dropzone'}>
                                        <div {...getRootProps()} className={'cursor-pointer'}>
                                            <input {...getInputProps()} />
                                            <p>{strings.documents.dragAndDrop}</p>
                                        </div>
                                    </section>
                                    <p className={'file-size'}>*10MB file size <span>{this.state.uploadFileError}</span>
                                    </p>
                                </div>
                            )}
                        </Dropzone>
                    </div>
                }
            />
        );
    }

    getFilesForType(type) {

        let result = []

        for (let file of this.state.files) {

            if (!file) {
                continue;
            }

            if (file.type == type) {
                result.push(file);
            }
        }

        return result;
    }

    viewMessage() {
        return (
            <MessageModal
                toggleModal={this.toggleMessageModal}
                open={this.state.openMessageModal}
                title={strings.contract.thankYou}
                message={strings.contract.message}
                openDialog={this.openMessageDialog}
            />
        );
    }

    fileUpload() {

        let formModal = [];

        switch (this.state.fileUploadModal) {
            case UploadSection.Proof_of_Account_Details:
                formModal = this.formModalRender(UploadSection.Proof_of_Account_Details);
                break;
            case UploadSection.Contract_Employment:
                formModal = this.formModalRender(UploadSection.Contract_Employment);
                break;
            default:
                formModal = this.formModalRender([]);
        }

        return formModal;
    }

    submitTermsAndCodnitions(response, type) {

        let data = this.state.data;
        data.termsOfConditionsAccepted = response;

        if (response && type == TermsAndConditionsStatus.DECLINE) {
            declineContract(this.state.data.id).then(response => {

                if (!response || !response.ok) {
                    return;
                }

                this.props.enqueueSnackbar(strings.contract.contractDeclined, {variant: 'success'});

                this.fetchData();
            });
        }

        this.setState({
            showTermsAndConditions: false,
            data: data
        }, () => {
            window.scrollTo(0, 2450);
        });
    }

    closeTermsAndCodnitions() {

        this.setState({
            showTermsAndConditions: false,
        }, () => {
            window.scrollTo(0, 2450);
        });
    }

    render() {
        let formModal = this.fileUpload();
        let messageModal = this.viewMessage();
        const btnwithdrawStyle = {
            backgroundColor: '#b29d24',
            boxShadow: 'none',
            borderRadius: '0',
            whiteSpace: 'normal',
            marginRight: '20px',
            padding: '5px 20px',
            alignSelf: 'center',
            height:'10vh'
          };
          const containerStyle = {
            display: 'flex',
            justifyContent: 'center', // Horizontally center
            marginTop: '30px',
          };
        if (this.state.showTermsAndConditions) {
            return <TermsAndConditions contract={this.state.data} submit={this.submitTermsAndCodnitions}
                                       close={this.closeTermsAndCodnitions}
                                       disabled={this.state.data && this.state.data.applicationInternalStatus >= ApplicationInternalStatus.CONTRACT_FILLED}/>;
        }

        return (
            <div>
                <BaseConfirmDialog
                    close={this.closeWithdrawConfirmModal}
                    title={'Application Withdrawal'}
                    open={this.state.showWithdrawConfirmModal}
                    message={strings.candidateProfile.withdrawConfirm}
                    no={this.closeWithdrawConfirmModal}
                    yes={this.confirmWithdraw}
                />
                <Grid container spacing={9} ref={this.contractHeaderRef}>
                    <Grid item md={12}>
                        <Paper className='paper'>
                        <div style={containerStyle}>
                        <Button variant="contained" color="secondary" style={btnwithdrawStyle} className={'withdraw-button'} onClick={() => (this.applicationWithdrawal())} >
                                { strings.candidateProfile.withdraw }
                        </Button>
                        </div>
                            <div id={'personal-details-anchor'} ref={this.personalDetailsContractRef}>
                                <h1>{strings.candidateProfile.personalDetails}</h1>
                                <PersonalDetailsContractForm data={this.state.data}
                                                             onChange={this.changeData}
                                                             errors={this.state.errors}
                                                             titles={this.state.titles}
                                                             genders={this.state.genders}
                                                             disable={true}
                                />
                            </div>
                            <div id={'bank-details-anchor'} ref={this.bankDetailsRef}>
                                <h1>{strings.bankDetails.title}</h1>
                                <BankDetailsForm data={this.state.data}
                                                 user={this.props.user}
                                                 onChange={this.changeData}
                                                 errors={this.state.errors}
                                                 onDelete={this.deleteFile}
                                                 proofOfAccountDetails={this.getFilesForType(UploadSection.Proof_of_Account_Details)}
                                                 onModalOpen={this.toggleFileUploadModal}
                                                 disable={this.state.disableField}
                                                 handleToggle={this.changeCheckBox}
                                                 disabled={this.state.data && this.state.data.applicationInternalStatus >= ApplicationInternalStatus.CONTRACT_FILLED}
                                />
                            </div>
                            <div id={'employment-anchor'} ref={this.employmentRef}>
                                <h1>{strings.employment.employment}</h1>
                                <EmploymentForm data={this.state.data}
                                                onChange={this.changeData}
                                                errors={this.state.errors}
                                                onDelete={this.deleteFile}
                                                handleToggle={this.changeCheckBox}
                                                employment={this.state.employment}
                                                onModalOpen={this.toggleFileUploadModal}
                                                disable={this.state.disableField}
                                                proofOfEmployment={this.getFilesForType(UploadSection.Contract_Employment)}
                                                disabled={this.state.data && this.state.data.applicationInternalStatus >= ApplicationInternalStatus.CONTRACT_FILLED}

                                />
                            </div>
                            {
                                checkUniformConditions(this.state.data) &&
                                <div id={'uniform-anchor'} ref={this.uniformRef}>
                                    <h1>{strings.uniform.uniform}</h1>
                                    <UniformForm data={this.state.data}
                                                 onChange={this.changeData}
                                                 errors={this.state.errors}
                                                 onDelete={this.deleteFile}
                                                 handleToggle={this.changeCheckBox}
                                                 disabled={this.state.data && this.state.data.applicationInternalStatus >= ApplicationInternalStatus.CONTRACT_FILLED}

                                    />
                                </div>
                            }
                            <div id={'medical-declaration-anchor'} ref={this.medicalDeclarationRef}>
                                <h1>{strings.medicalDeclaration.medicalDeclaration}</h1>
                                <MedicalDeclarationForm data={this.state.data}
                                                        onChange={this.changeData}
                                                        errors={this.state.errors}
                                                        onDelete={this.deleteFile}
                                                        handleToggle={this.changeCheckBox}
                                                        user={this.props.user}
                                                        rehabilitationRef={this.rehabilitationRef}
                                                        disabled={this.state.data && this.state.data.applicationInternalStatus >= ApplicationInternalStatus.CONTRACT_FILLED}

                                />
                            </div>
                            <div id={'working-time-election-anchor'} ref={this.workingTimeElectionRef}>
                                <h1>{strings.workingTimeElection.workingTimeElection}</h1>
                                <WorkingTimeElectionForm data={this.state.data}
                                                         onChange={this.changeData}
                                                         errors={this.state.errors}
                                                         onDelete={this.deleteFile}
                                                         handleToggle={this.changeCheckBox}
                                                         disabled={this.state.data && this.state.data.applicationInternalStatus >= ApplicationInternalStatus.CONTRACT_FILLED}

                                />
                            </div>
                            <div id={'changes-in-information-anchor'} ref={this.changesInInformationRef}>
                                <h1>{strings.changesInInformation.changesInInformation}</h1>
                                <ChangesInInformationForm data={this.state.data}
                                                          onChange={this.changeData}
                                                          errors={this.state.errors}
                                                          onDelete={this.deleteFile}
                                                          handleToggle={this.changeCheckBox}
                                                          disabled={this.state.data && this.state.data.applicationInternalStatus >= ApplicationInternalStatus.CONTRACT_FILLED}

                                />
                            </div>
                            <div id={'terms-and-conditions-anchor'} ref={this.termsAndConditionsRef}>
                                <h1>{strings.termsAndConditions.termsAndConditions}</h1>
                                <TermsAndConditionsForm data={this.state.data}
                                                        errors={this.state.errors}
                                                        onChange={this.changeData}
                                                        handleToggle={this.changeCheckBox}
                                                        onDelete={this.deleteFile}
                                                        onSubmit={this.openTermsAndConditions}
                                                        disabled={this.state.data && this.state.data.applicationInternalStatus >= ApplicationInternalStatus.CONTRACT_FILLED}

                                />
                            </div>
                            <div id={'digital-signature-anchor'} ref={this.digitalSignatureRef}>
                                <h1>{strings.digitalSignature.digitalSignature}</h1>
                                <form id='digital-signature-form'>
                                    <Grid container spacing={9} className={'grid-wrapper'}>
                                        <Grid item md={6} className={'signature-pad'}>
                                            {
                                                !this.state.data.signature &&
                                                <SignaturePad
                                                    ref={(ref) => {
                                                        this.signaturePadRef = ref
                                                    }}
                                                />
                                            }
                                            {
                                                this.state.data.signature &&
                                                <div className={'signature-preview'}>
                                                    <img style={{maxHeight: '100%;', maxWidth: '100%;'}}
                                                         src={this.state.data.signature}/>
                                                </div>
                                            }
                                        </Grid>
                                        <Grid item md={6} className={'message'}>
                                            {strings.digitalSignature.digitalSignatureMessage}<span
                                            class='signe-error'>{this.state.signatureError}</span>
                                        </Grid>
                                        <Grid item md={6} className={'digital-signature-buttons'}>
                                            <Button className={'clear-btn'} variant="contained"
                                                    disabled={this.state.data && this.state.data.applicationInternalStatus >= ApplicationInternalStatus.CONTRACT_FILLED}
                                                    color="primary" onClick={this.clear}>
                                                {strings.digitalSignature.clear}
                                            </Button>
                                            <Button className={'trim-btn'} variant="contained"
                                                    disabled={(this.state.data && this.state.data.applicationInternalStatus >= ApplicationInternalStatus.CONTRACT_FILLED)}
                                                    color="primary" onClick={this.trim}>
                                                {strings.digitalSignature.accept}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Divider/>
                                </form>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
                {formModal}
                {messageModal}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen,
        changeIndicator: Actions.changeIndicator,
        changeLinkIndicator: Actions.changeLinkIndicator,
        getData: Actions.getData,
        getErrors: Actions.getErrors,
        setHasContract: Actions.setHasContract
    }, dispatch);
}

function mapStateToProps({menuReducers, authReducers, siteDataReducers}) {
    return {menu: menuReducers, user: authReducers.user, siteData: siteDataReducers};
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(Contract)));
