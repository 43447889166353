const UploadSection = {
    Proof_of_NI_or_PPS: 1,
    Proof_of_Identity: 2,
    Proof_of_Address: 3,
    Visa_Documents: 4,
    UK_Citizenship_Documents: 5,
    Right_to_Work_Documents: 6,
    Proof_of_Account_Details: 7,
    Contract_Employment: 8,
    Aditional: 9,
    Selfie: 10,
    Right_to_work: 11

};

export default UploadSection;