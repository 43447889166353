export const TOGGLE_LOADER = '[SITE_DATA] TOGGLE_LOADER';
export const FORCE_HIDE_LOADER = '[SITE_DATA] FORCE_HIDE_LOADER';
export const CHANGE_INDICATOR = '[SITE_DATA] CHANGE_INDICATOR';
export const CHANGE_LINK_INDICATOR = '[SITE_DATA] CHANGE_LINK_INDICATOR';
export const GET_DATA = '[SITE_DATA] GET_DATA';
export const GET_ERRORS = '[SITE_DATA] GET_ERRORS';
export const HAS_CONTRACT = '[SITE_DATA] HAS_CONTRACT';
export const APPLICATION_ID = '[SITE_DATA] APPLICATION_ID';
export const RETURN_URL = '[SITE_DATA] RETURN_URL';

export function setReturnUrl(url) {
    return {
        type: RETURN_URL,
        returnUrl: url
    }
}

export function setApplicationId(id) {
    return {
        type: APPLICATION_ID,
        applicationId: id
    }
}

export function showLoader() {

    return {
        type: TOGGLE_LOADER,
        loader: true
    };
}

export function forceHideLoader() {
    return {
        type: TOGGLE_LOADER
    };
}

export function hideLoader() {
    return {
        type: TOGGLE_LOADER,
        loader: false
    };
}

export function setHasContract(value) {
    return {
        type: HAS_CONTRACT,
        hasContract: value
    };
}

export function changeIndicator(changeIndicator) {
    return {
        type: CHANGE_INDICATOR,
        changeIndicator: changeIndicator
    };
}

export function changeLinkIndicator(linkIndicator) {
    return {
        type: CHANGE_LINK_INDICATOR,
        changeLinkIndicator: linkIndicator
    };
}

export function getData(data) {
    return {
        type: GET_DATA,
        data
    };
}

export function getErrors(errors) {
    return {
        type: GET_ERRORS,
        errors
    };
}