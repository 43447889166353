import React, { Fragment } from 'react';
import strings from "../../../localization";
import {getError, hasError} from "../../../functions/Validation";
import {Button, TextField, Divider} from "@material-ui/core";
import SelectControl from '../../controls/SelectControl';
import Grid from '@material-ui/core/Grid';
import {getFullUKorRoIDrivingLicences} from '../../../constants/FullUKorRoIDrivingLicences';
import Territories, {getTerritoriesString} from "../../../constants/TerritoriesOfWork";
import {checkConditionsForValidation, checkNationalityIDVTVerification, checkTerritoryOfWorkConditions} from "../../../util/FieldsConditionUtil";
import {findDataValue} from "../../../util/DropdownUtil";
import UploadSection from '../../../constants/UploadSection';
import CustomFileList from '../../../common/CustomFileList';
import {createYotiSession} from "../../../services/YotiService";
import ApplicationMode from "../../../constants/ApplicationMode";
import ApplicationStatusRTW from '../../../constants/ApplicationStatusRTW';

function getFullUKorRoIDrivingLicence(data) {

    if (data == undefined) {
        return null;
    }

    for (let item of getFullUKorRoIDrivingLicences()) {

        let value = data.value !== undefined ? data.value : data;

        if (item.value == value) {
            return item;
        }
    }

    return null;
}

const UKNationalsForm = ({
                             onChange,
                             errors,
                             data,
                             onModalOpen,
                             proofOfIdentityDoc,
                             proofOfAddressDoc,
                             proofOfNiDoc,
                             onDelete,
                             nationalities,
                             territoryOfWorks,
                             disable,
                             handleToggle,
                             ukCitizenshipDoc,
                             selfies,
                             save,
                             fetchData,
                             showRefreshButton
                         }) => {


    return (<form id='uk-nationals-form'>
            <Grid container spacing={9} className={'grid-wrapper'}>
                <Grid item xl={4} lg={5} md={12}>
                    <div className={'select-container' + (hasError(errors, 'territoryOfWork') ? ' error' : '')}>
                        <label>{strings.ukNationals.territoryOfWork}</label>
                        <SelectControl
                            error={hasError(errors, 'territoryOfWork')}
                            helperText={getError(errors, 'territoryOfWork')}
                            fullWidth
                            autoFocus
                            name='territoryOfWork'
                            onChange={onChange}
                            margin="normal"
                            value={data.territoryOfWork}
                            options={territoryOfWorks}
                            nameKey={'name'}
                            valueKey={'id'}
                            selected={data.territoryOfWork}
                            isDisabled={disable || data.yotiProcessState === 'COMPLETED'}
                            isSearchable={false}
                        />
                        {hasError(errors, 'territoryOfWork') ?
                            <p className='select-error'>{strings.validation.RequiredErrorMessage}</p> : ""}
                    </div>
                    {// (data.nationality && data.territoryOfWork) &&
                        <div className={'select-container' + (hasError(errors, 'niNoPpsNo') ? ' error' : '')}>
                            {checkTerritoryOfWorkConditions(data, getTerritoriesString(Territories.UK)) ?
                                <label>{strings.ukNationals.nINo}</label> :
                                <label>{strings.ukNationals.ppsNumber}</label>}
                            <div className='tooltip-control-container'>
                                <TextField
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={hasError(errors, 'niNoPpsNo')}
                                    helperText={getError(errors, 'niNoPpsNo')}
                                    fullWidth
                                    name='niNoPpsNo'
                                    onChange={onChange}
                                    value={data.niNoPpsNo}
                                    margin="dense"
                                    variant='outlined'
                                    disabled={disable}
                                    placeholder={findDataValue(data, 'territoryOfWork') === 'UK' ? 'AB 123456 A' : ''}
                                />
                                <div className={'tooltip right'}>
                                    <img src={'/images/info.png'}/>
                                    {checkTerritoryOfWorkConditions(data, getTerritoriesString(Territories.UK)) ?
                                        <span className="tooltip-content">
                                                <p>If you did not have an NI number but have recently applied for it, please add AB 123456 A to this box.</p>
                                                <p>For your proof of NI document, please upload a copy of a letter confirming this application.</p>
                                            </span> : <span className="tooltip-content">
                                                <p>If you did not have an PPS number but have recently applied for it, please add 1234 to this box.</p>
                                                <p>For your proof of PPS document, please upload a copy of a letter confirming this application.</p>
                                            </span>}

                                </div>
                            </div>
                        </div>}

                    {// checkConditionsForValidation(data) &&
                        <div className={'wrapper'}>
                            <Grid item md={10}>
                                {findDataValue(data, 'territoryOfWork') === 'UK' ?
                                    <label className={'question'}>{strings.ukNationals.ukOrRoiCitizen}</label> :
                                    <label className={'question'}>{strings.ukNationals.roiCitizen}</label>}
                            </Grid>
                            <Grid item md={6} className={'switch-wrapper'}>
                                <label className="switch" onClick={handleToggle}>
                                    <input disabled={disable || data.yotiProcessState === 'COMPLETED'} checked={data.ukCitizen} type="checkbox" id="togBtn"
                                           name={'ukCitizen'}/>
                                    <div className="slider round">
                                        <span className="on">YES</span>
                                        <span className="off">NO</span>
                                    </div>
                                </label>
                            </Grid>
                        </div>}
                    {// (data.nationality && data.territoryOfWork) &&
                        // checkTerritoryOfWorkConditions(data, getTerritoriesString(Territories.UK)) &&
                        <div>                            
                            <div className={'select-container-upload'}>
                                <label>{checkTerritoryOfWorkConditions(data, 'UK') ? strings.ukNationals.proofOfNI : strings.ukNationals.proofOfPPS}</label>
                                <div className="open-modal-button">
                                    <Button name='proofOfNiDoc' disabled={disable} variant="contained" color="primary" onClick={() => {
                                        onModalOpen(UploadSection.Proof_of_NI_or_PPS)
                                    }}>
                                        {strings.documents.uploadFiles}
                                    </Button>
                                    <div className={'tooltip right'}>
                                        <img src={'/images/info.png'}/>
                                        <span className="tooltip-content">
                                        <p>Documents you can upload as proof of NI:</p>
                                        <ul>
                                            <li>P45 or P60</li> 
                                            <li>NI Confirmation letter from HM Revenue and Customs.</li>
                                        </ul>
                                        <p>Please ensure that the documents are clear and legible. If we are not able to review documents due to the quality of the uploads, this might delay your application. </p>
                                    </span>
                                    </div>
                                </div>
                            </div>
                            {hasError(errors, 'proofOfNiDoc') ?
                                <p style={{color: 'red'}}>{strings.validation.RequiredErrorMessage}</p> : ""}
                            <Divider/>
                        </div>}
                    {proofOfNiDoc.length > 0 && (data.territoryOfWork) &&
                        <CustomFileList onDelete={onDelete} files={proofOfNiDoc} disable={disable}
                                        uploadSection={UploadSection.Proof_of_NI_or_PPS}/>}
                    {// (data.nationality && data.territoryOfWork) &&
                        <div>                            
                            <div className={'select-container-upload'}>
                                <label>{strings.ukNationals.proofOfAddress}</label>
                                <div className="open-modal-button">
                                    <Button name='proofOfAddressDoc' disabled={disable} variant="contained" color="primary" onClick={() => {
                                        onModalOpen(UploadSection.Proof_of_Address)
                                    }}>
                                        {strings.documents.uploadFiles}
                                    </Button>
                                    <div className={'tooltip right'}>
                                        <img src={'/images/info.png'}/>
                                        <span className="tooltip-content">
                                        <p>Documents you can upload as proof of address:</p>
                                        <ul>
                                            <li>Bank Statement</li>
                                            <li>Council Tax Bill</li>
                                            <li>Utility Bill</li>
                                            <li>Other official documents displaying  your name and address</li>
                                        </ul>
                                        <p>Please ensure that the documents are clear and legible. If we are not able to review documents due to the quality of the uploads, this might delay your application.</p>
                                    </span>
                                    </div>
                                </div>
                            </div>
                            {hasError(errors, 'proofOfAddressDoc') ?
                                <p style={{color: 'red'}}>{strings.validation.RequiredErrorMessage}</p> : ""}
                            <Divider/>
                        </div>}
                    {proofOfAddressDoc.length > 0 && (data.territoryOfWork) &&
                        <CustomFileList onDelete={onDelete} files={proofOfAddressDoc} disable={disable}
                                        uploadSection={UploadSection.Proof_of_Address}/>}

                    {/*{*/}
                    {/*    data.yotiSessionId && data.yotiProcessState !== 'COMPLETED' &&*/}
                    {/*    <div className={'info-message-yellow-wrapper full'}>*/}
                    {/*        <div className={'info-message-yellow'}>*/}
                    {/*            <p>*/}
                    {/*                Your ID checks are incomplete, once they have completed you will be able to submit.*/}
                    {/*            </p>*/}
                    {/*            <p>We will notify you via e-mail/sms once they are complete.</p>*/}
                    {/*            <p>Please click save for later for now.</p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*}*/}
                </Grid>
                <Grid xl={4} lg={5} md={12}>
                    <div className={'select-container' + (hasError(errors, 'nationality') ? ' error' : '')}>
                        <label>{strings.ukNationals.nationality}</label>
                        <SelectControl
                            error={hasError(errors, 'nationality')}
                            helperText={getError(errors, 'nationality')}
                            fullWidth
                            autoFocus
                            name='nationality'
                            onChange={onChange}
                            margin="normal"
                            value={data.nationality}
                            options={nationalities}
                            nameKey={'name'}
                            valueKey={'id'}
                            selected={data.nationality}
                            isDisabled={disable || data.yotiProcessState === 'COMPLETED'}
                        />
                        {hasError(errors, 'nationality') ?
                            <p className='select-error'>{strings.validation.RequiredErrorMessage}</p> : ""}
                    </div>
                    {// (data.nationality && data.territoryOfWork) &&
                        <div
                            className={'select-container' + (hasError(errors, 'fullUKorRoIDrivingLicence') ? ' error' : '')}>
                            {checkTerritoryOfWorkConditions(data, getTerritoriesString(Territories.UK)) ?
                                <label>{strings.ukNationals.fullUKDrivingLicence}</label> :
                                <label>{strings.ukNationals.roiDrivingLicence}</label>}
                            <SelectControl
                                error={hasError(errors, 'fullUKorRoIDrivingLicence')}
                                helperText={getError(errors, 'fullUKorRoIDrivingLicence')}
                                fullWidth
                                autoFocus
                                name='fullUKorRoIDrivingLicence'
                                onChange={onChange}
                                margin="normal"
                                value={data.fullUKorRoIDrivingLicence}
                                options={getFullUKorRoIDrivingLicences()}
                                nameKey={'name'}
                                valueKey={'value'}
                                selected={getFullUKorRoIDrivingLicence(data.fullUKorRoIDrivingLicence)}
                                isDisabled={disable || data.yotiProcessState === 'COMPLETED'}
                                isSearchable={false}
                            />
                            {hasError(errors, 'fullUKorRoIDrivingLicence') ?
                                <p className='select-error'>{strings.validation.RequiredErrorMessage}</p> : ""}
                        </div>}

                    { data.nationality && checkNationalityIDVTVerification(data.nationality.name) &&
                        <div className={'wrapper'}>
                            <Grid item md={10}>
                                <label className={'question'}>{strings.ukNationals.inDatePassport}</label>
                            </Grid>
                            <Grid item md={6} className={'switch-wrapper'}>
                                <label className="switch" onClick={handleToggle}>
                                    <input disabled={disable || data.yotiProcessState === 'COMPLETED'} 
                                            checked={data.validPassport} type="checkbox" id="togBtn"
                                           name={'validPassport'}/>
                                    <div className="slider round">
                                        <span className="on">YES</span>
                                        <span className="off">NO</span>
                                    </div>
                                </label>
                            </Grid>
                        </div> }
                        { data.nationality && checkNationalityIDVTVerification(data.nationality.name) &&
                         ((data.yotiProcessState !== 'COMPLETED' && data.rtwStatus === ApplicationStatusRTW.IDVT_REJECTED) || data.yotiProcessState === 'NOTCOMPLETED') && <div>{strings.ukNationals.yotiValidationMessageFailed}</div> }

                    {data.ukCitizen && data.nationality && !checkNationalityIDVTVerification(data.nationality.name) &&
                        <React.Fragment>
                            <div className={'select-container-upload'}>
                                <label>{strings.ukNationals.proofOfIdentity}</label>
                                <div className="open-modal-button">
                                    <Button disabled={disable || data.yotiProcessState === 'COMPLETED'} variant="contained" color="primary" onClick={() => {
                                        onModalOpen(UploadSection.Proof_of_Identity)
                                    }}>
                                        {strings.documents.uploadFiles}
                                    </Button>
                                    <div className={'tooltip right'}>
                                        <img src={'/images/info.png'}/>
                                        <span className="tooltip-content">
                                        <p>Documents you can upload as proof of identity:</p>
                                        <ul>
                                            <li>If you are not a UK/ROI National, please provide Overseas Passport with cover.</li>
                                            <li>Birth Certificate if you are a UK/ROI National.</li>
                                            <li>Passport Style photo is optional.</li>
                                        </ul>
                                        <p>Please ensure that the documents are clear and legible. If you are uploading documents with photos, use a clear image of your face. If we are not able to review documents due to the quality of the uploads, this might delay your application.</p>
                                    </span>
                                    </div>
                                </div>
                            </div>
                            <Divider/>
                            {proofOfIdentityDoc.length > 0 && (data.territoryOfWork) &&
                                <CustomFileList onDelete={onDelete} files={proofOfIdentityDoc} disable={disable || data.yotiProcessState === 'COMPLETED'}
                                                uploadSection={UploadSection.Proof_of_Identity}/>}
                        </React.Fragment>}

                    {!(data.validPassport && data.territoryOfWork.name === 'UK') && data.nationality && checkNationalityIDVTVerification(data.nationality.name) &&
                        <React.Fragment>
                            <div className={'select-container-upload'}>
                                <label>{strings.ukNationals.proofOfIdentity}</label>
                                <div className="open-modal-button">
                                    <Button disabled={disable || data.yotiProcessState === 'COMPLETED'} variant="contained" color="primary" onClick={() => {
                                        onModalOpen(UploadSection.Proof_of_Identity)
                                    }}>
                                        {strings.documents.uploadFiles}
                                    </Button>
                                    <div className={'tooltip right'}>
                                        <img src={'/images/info.png'}/>
                                        <span className="tooltip-content">
                                        <p>Documents you can upload as proof of identity:</p>
                                        <ul>
                                            <li>If you are not a UK/ROI National, please provide Overseas Passport with cover.</li>
                                            <li>Birth Certificate if you are a UK/ROI National.</li>
                                            <li>Passport Style photo is optional.</li>
                                        </ul>
                                        <p>Please ensure that the documents are clear and legible. If you are uploading documents with photos, use a clear image of your face. If we are not able to review documents due to the quality of the uploads, this might delay your application.</p>
                                    </span>
                                    </div>
                                </div>
                            </div>
                            {hasError(errors, 'proofOfIdFiles') ?
                                <p style={{color: 'red'}}>{strings.validation.RequiredErrorMessage}</p> : ""}
                            <Divider/>
                            {proofOfIdentityDoc.length > 0 && (data.territoryOfWork) &&
                                <CustomFileList onDelete={onDelete} files={proofOfIdentityDoc} disable={disable || data.yotiProcessState === 'COMPLETED'}
                                                uploadSection={UploadSection.Proof_of_Identity}/>}
                            <div className={'select-container-upload'}>
                                <label>{strings.rightToWork.ukCitizenship}</label>
                                <div className="open-modal-button">
                                    <Button disabled={disable || data.yotiProcessState === 'COMPLETED'} variant="contained" color="primary" onClick={() => {
                                        onModalOpen(UploadSection.UK_Citizenship_Documents)
                                    }}>
                                        {strings.documents.uploadFiles}
                                    </Button>
                                    <div className={'tooltip right'}>
                                        <img src={'/images/info.png'}/>
                                        <span className="tooltip-content">
                                    <p>Documents you can upload as your Right to Work documents and additional requirements:</p>
                                    <ul>
                                        <li>Passport: Require copy of cover as well.</li>
                                        <li>National Identity Card: Require copies of front and back.</li>
                                        <li>Permanent Residence: In your current Overseas Passport with cover.</li>
                                        <li>Biometric Immigration Document: Require front and back, and Overseas Passport with cover</li>
                                        <li>Immigration Status Document: Only if it has your visa on it. Require front and back of your Visa, Overseas Passport with cover</li>
                                        <li>Long / Short Birth Certificate</li>
                                        <li>Adoption Certificate</li>
                                        <li>Certificate of Registrationv</li>
                                        <li>Certificate of Naturalisation</li>
                                        <li>Residence Card: Require front and back, Overseas Passport with cover</li>
                                        <li>Certificate of Application / Home Office Letter: Must have reference number or case ID on the document, and Overseas Passport with cover.</li>
                                        <li>Application Registration Card: Require front and back, Overseas Passport with cover.</li>
                                        <li>Positive Verification Document: Require Overseas Passport with cover</li>

                                    </ul>
                                    <p>Please ensure that the documents are clear and legible. If we are not able to review documents due to the quality of the uploads, this might delay your application.</p>
                                </span>
                                    </div>
                                </div>
                            </div>
                            {hasError(errors, 'rtwSupportingDocs') ?
                                <p style={{color: 'red'}}>{strings.validation.RequiredErrorMessage}</p> : ""}
                            <Divider/>
                            {ukCitizenshipDoc.length > 0 &&
                                <CustomFileList onDelete={onDelete} files={ukCitizenshipDoc} disable={disable || data.yotiProcessState === 'COMPLETED'}
                                                uploadSection={UploadSection.UK_Citizenship_Documents}/>}
                        </React.Fragment>}

                        {data.nationality  &&
                            <React.Fragment>
                                <div className={'select-container-upload'}>
                                    <label>Upload Selfie</label>
                                    <div className="open-modal-button">
                                        <Button disabled={disable || data.yotiProcessState === 'COMPLETED'} variant="contained" color="primary" name="selfies" onClick={() => {
                                            onModalOpen(UploadSection.Selfie)
                                        }}>
                                            {strings.documents.uploadFiles}
                                        </Button>
                                        <div className={'tooltip right'}>
                                            <img src={'/images/info.png'}/>
                                            <span className="tooltip-content">
                                                <p>Please upload a clear copy of your selfie</p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {hasError(errors, 'selfies') ?
                                <p style={{color: 'red'}}>{strings.validation.RequiredErrorMessage}</p> : ""}
                                <Divider/>
                                {selfies.length > 0 && 
                                    <CustomFileList onDelete={onDelete} files={selfies} 
                                            disable={disable || data.yotiProcessState === 'COMPLETED'}
                                                uploadSection={UploadSection.Selfie}/>}
                            </React.Fragment>
                        }
                </Grid>
            </Grid>
            {data.yotiProcessState !== 'COMPLETED' && data.ukCitizen && data.validPassport && checkTerritoryOfWorkConditions(data, getTerritoriesString(Territories.UK)) &&
                <React.Fragment>
                    <div className={'button-wrapper open-modal-button"'}>
                        {(!data.yotiSessionEnd || (new Date(data.yotiSessionEnd) < new Date())) &&
                            <Button variant="contained" color="primary" onClick={() => {
                                save(ApplicationMode.SAVE, true)
                            }}>
                                Start ID Verification
                            </Button>}

                    </div>

                    {data.yotiSessionId && data.yotiSessionEnd && new Date(data.yotiSessionEnd) > new Date() && <div style={{textAlign: 'center'}}>
                        <iframe
                            width={600}
                            height={600}
                            allow="camera;gyroscope;accelerometer;magnetometer;xr-spatial-tracking;microphone;"
                            src={'https://api.yoti.com/idverify/v1/web/index.html?sessionID=' + data.yotiSessionId + '&sessionToken=' + data.yotiSessionToken}>

                        </iframe>
                    </div>}

                    {
                        showRefreshButton &&
                        <>
                            <div className={'button-wrapper open-modal-button"'}>
                                <Button variant="contained" color="primary" onClick={() => {
                                    fetchData()
                                }}>
                                    Check verification status
                                </Button>
                            </div>
                            <div style={{background: 'rgba(178, 157, 36, 1)', color: 'white', borderRadius: '15px', padding: '15px', maxWidth: '500px', margin: '10px auto'}}>
                                <p>
                                    Your ID checks are incomplete, once they have completed you will be able to submit.
                                </p>
                                <p>
                                    We will notify you via e-mail/sms once they are complete.
                                </p>
                                <p>
                                    Please click save for later for now.
                                </p>
                            </div>
                        </>

                    }


                </React.Fragment>

            }
        </form>)
};

export default UKNationalsForm;