import React, {createRef} from 'react'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import strings from '../localization'
import Page from "../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {Button} from "@material-ui/core";
import {withSnackbar} from "notistack";
import { checkPermission } from '../util/SecurityUtil';
import Permission from '../constants/Permission';
import UserType from '../constants/UserType';
import { getApplication } from '../services/AddCandidateService';
import ApplicationInternalStatus from '../constants/ApplicationInternalStatus';

class Home extends Page {

    constructor(props) {
        super(props);

        this.state = {
            
        };

        this.props.changeFullScreen(false);


    }

    componentDidMount() {

        if(!this.props.auth.user) {
            this.props.history.push('/login');
            return;
        }

        if(this.props.auth.user.userType == UserType.APPLICANT) {


            getApplication(-1).then(response => {

                if(!response || !response.ok) {
                    this.props.history.push('/application');
                    return;
                }

                let application = response.data;
                this.props.setApplicationId(application.id);

                let hasContract = application.applicationInternalStatus == ApplicationInternalStatus.CONDITIONAL_OFFER_SENT


                if(hasContract) {
                    this.props.history.push('/contract');
                    return;
                }
                else {
                    this.props.history.push('/application');
                    return;
                }
            })

            
        }

        if(this.props.auth.user.userType == UserType.RECRUITMENT_MANAGER
            || this.props.auth.user.userType == UserType.ADMIN 
            || this.props.auth.user.userType == UserType.HR_RECRUITMENT) {
            this.props.history.push('/candidate-profiles');
            return;
        }

        if(this.props.auth.user.userType == UserType.HR_MANAGER) {
            this.props.history.push('/candidate-applications');
            return;
        }
    }

    applicationClick() {
        this.props.history.push('/application')
    }

    render() {
    
        const userType = this.props.auth.user ? this.props.auth.user.userType : null;

        return (
            <div className='home-container'>
                {
                    // checkPermission(userType, Permission.USER_APPLICATION) &&
                    
                    // <Button variant="contained" color="primary"
                    //     onClick={ () => this.applicationClick() }>
                    //     {strings.home.application}
                    // </Button>
                }
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen,
        changeIndicator: Actions.changeIndicator,
        setApplicationId: Actions.setApplicationId
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers })
{
    return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(Home)));
