import React, {Component} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Dialog, Divider, IconButton, Slide, TextField } from "@material-ui/core";
import strings from "../localization";
import FormComponent from './FormComponent';
import { applicationSendComment } from '../services/ApplicationService';

class CommentsModal extends FormComponent {

    constructor(props) {
        super(props);

        this.state = {
            data:  props.application,
        }
    }

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="left" ref={ref} {...props} />;
    });

    componentWillReceiveProps(props) {
        this.setState({
            data: props.application
        });
    }

    submit() {


        applicationSendComment(this.state.data.id, { comment: this.state.data.comment }).then(response => {

            this.props.fetchData()
            this.props.toggleModal();
        })

    }

    render() {
        return (
            <Dialog
                onClose={this.props.toggleModal}
                aria-labelledby="customized-dialog-title"
                open={this.props.open}
                TransitionComponent={this.Transition}
                className={'comments-modal'}
            >
                <div className='form-modal-header' onClose={this.props.toggleModal}>
                    <div>
                        <h1>{this.props.title}</h1>
                        <IconButton className={'close-button-wrapper'} onClick={this.props.toggleModal}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <Divider />
                </div>
                <div className={'comments-wrapper'}>
                    <div className={'comments-content'}>
                        <TextField
                            placeholder={strings.hrManager.enterComments}
                            multiline
                            rows={2}
                            rowsMax={4}
                            variant={'outlined'}
                            onChange={this.changeData}
                            name={'comment'}
                            value={ this.state.data ? this.state.data.comment : '' }
                        />
                    </div>
                    <div className={'verify-buttons'}>
                        <Button className={'done-btn'} variant="contained" color="primary" onClick={() => this.submit()}>
                            { strings.hrManager.done }
                        </Button>
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default CommentsModal;