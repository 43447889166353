export const UPDATE = '[APPLICATION] UPDATE';
export const UPDATE_ALLOW = '[APPLICATION] UPDATE_ALLOW';

export function doUpdate(value) {
    return {
        type: UPDATE,
        doUpdate: value
    }
}

export function allowUpdate(value) {
    return {
        type: UPDATE_ALLOW,
        allowUpdate: value
    }
}