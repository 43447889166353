const ConfirmMode = {
    YES: 1,
    NO: 2
};

export default ConfirmMode;

export function getConfirmModes() {
    const modes = [
        {
            value: ConfirmMode.YES,
            name: 'Yes'
        },
        {
            value: ConfirmMode.NO,
            name: 'No'
        }
    ];

    return modes;
}

export function getConfirmModesString(confirmMode) {
    const selectedMode = getConfirmModes().find(item => item.value === confirmMode);

    return selectedMode ? selectedMode.name : '';
}