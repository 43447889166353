import React, {createRef} from 'react'
import TablePage from "../../common/TablePage";
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import {withSnackbar} from "notistack";
import PageState from '../../constants/PageState';
import SelectControl from '../../components/controls/SelectControl';
import {
    Button,
    Drawer, TextField
} from "@material-ui/core";
import DrawerWrapper from "../../common/DrawerWrapper";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ViewRTWModal from "../../common/ViewRTWModal";
import CommentsModal from "../../common/CommentsModal";
import ViewOfferModal from "../../common/ViewOfferModal";
import ViewApplicantModal from "../../common/ViewApplicantModal";
import {getApplications, updateStatus} from '../../services/AddCandidateService';
import {transformDate} from '../../util/DateUtil';
import ApplicationStatus, {getApplicationStatuses, getApplicationStatusesHR} from '../../constants/ApplicationStatus';
import ApplicationInternalStatus from '../../constants/ApplicationInternalStatus';
import {findApplication} from '../../util/ModelUtil';
import {applicationConfirm} from '../../services/ApplicationService';
import {getFiles, deleteFile, uploadFiles} from "../../services/FileUpload";
import CONFIG from '../../config';
import SearchIcon from "@material-ui/icons/Search";
import {Tooltip} from '@material-ui/core';
import VerifyModal from "../../common/VerifyModal";
import ViewDocumentsModal from '../../common/ViewDocumentsModal';
import {getHistories} from "../../services/HistoryService";
import ViewHistoryModal from "../../common/ViewHistoryModal";
import Checkbox from '@material-ui/core/Checkbox';
import ApplicationView from '../../constants/ApplicationView';
import Dropzone from 'react-dropzone';
import {LinearProgress} from '@material-ui/core';
import UploadSection from '../../constants/UploadSection';
import FileUploadModal from '../../common/FileUploadModal';
import CriminalRecordStatus, {getCriminalRecordStatuses} from "../../constants/CriminalRecordStatus";
import MedicalConditionStatus, {getMedicalConditionStatuses} from "../../constants/MedicalConditionStatus";
import {changeContractCriminalRecordStatus, changeContractMedicalConditionStatus} from "../../services/ContractService";
import RTWProcess from "../../constants/RTWProcess";
import ApplicationStatusRTW from "../../constants/ApplicationStatusRTW";
import IconButton from "@material-ui/core/IconButton";

class CandidateApplications extends TablePage {

    tableDescription = [
        {key: 'id', label: strings.candidateProfiles.name, transform: 'renderName', class: 'column-fix'},
        {key: 'id', transform: 'renderWarningImage'},
        {key: 'division', label: strings.hrManager.division, transform: 'renderDivision'},
        {key: 'region', label: strings.hrManager.region, transform: 'renderRegion'},
        {key: 'recruiter', label: strings.hrManager.recruiterName, transform: 'renderRecruiter'},
        {key: 'id', label: strings.hrManager.role, transform: 'renderRole'},
        {key: 'dateUpdated', label: strings.hrManager.applicationDate, transform: 'renderDate'},
        {key: 'sessionType', label: strings.candidateProfiles.sessionType, transform: 'renderSessionType'},
        {key: 'id', label: strings.hrManager.docCheck, transform: 'renderDocCheck'},
        // removed currently from table
        // { key: 'id', transform: 'renderViewRTW'},
        {key: 'id', label: strings.candidateProfiles.uploadFiles, transform: 'renderUploadButton'},
        {key: 'id', label: '', transform: 'renderDocViewerButton'},
        {key: 'id', transform: 'renderViewOffer'},
        {key: 'id', transform: 'renderViewApplicant'},
        {key: 'contract', label: strings.hrManager.pastEmploymentDeclaration, transform: 'renderHavePreviousWorked'},
        {key: 'payrollID', label: strings.hrManager.payrollID},
        {key: 'contract', label: strings.hrManager.criminalRecord, transform: 'renderCriminalRecord'},
        {key: 'contract', label: strings.hrManager.healthCondition, transform: 'renderHaveMedicalConditions'},
        {key: 'id', label: strings.candidateProfiles.automaticCheck, transform: 'renderAutomaticCheck'},
        {key: 'id', label: strings.hrManager.applicationStatus, transform: 'renderApplicationStatus'},
        {key: 'contract', label: strings.hrManager.contract, transform: 'renderContractButtons'},
        {key: 'id', label: strings.hrManager.status, transform: 'renderConfirmButton'},
        {key: 'id', label: strings.hrManager.comments, transform: 'renderCommentsButton'},
    ];

    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : {},
            showSearch: true,
            offer: undefined,
            selectedItem: {},
            selectedApplication: {},
            showActions: false,
            tableData: [],
            pageState: PageState.View,
            total: 100,
            errors: {},
            openRTWModal: false,
            openCommentsModal: false,
            openViewOfferModal: false,
            openVerifyModal: false,
            documentsDoc: [],
            uploadedDocuments: [],
            tooltips: {},
            showUploadProgress: false,
            uploadFileError: '',
            documentModalFullscreen: false,
            openDocView: false
        };

        this.toggleFileUploadModal = this.toggleFileUploadModal.bind(this);
        this.toggleViewHistoryModal = this.toggleViewHistoryModal.bind(this);
        this.toggleViewRTWModal = this.toggleViewRTWModal.bind(this);
        this.openRTWModal = this.openRTWModal.bind(this);
        this.rtwModalRef = createRef();
        this.toggleCommentsModal = this.toggleCommentsModal.bind(this);
        this.openCommentsModal = this.openCommentsModal.bind(this);
        this.commentsModalRef = createRef();
        this.toggleViewOfferModal = this.toggleViewOfferModal.bind(this);
        this.openViewOfferModal = this.openViewOfferModal.bind(this);
        this.viewOfferModalRef = createRef();
        this.toggleViewApplicantModal = this.toggleViewApplicantModal.bind(this);
        this.openApplicantModal = this.openApplicantModal.bind(this);
        this.viewApplicantModalRef = createRef();
        this.fetchData = this.fetchData.bind(this);
        this.handleTooltipToggle = this.handleTooltipToggle.bind(this);
        this.handleTooltipClose = this.handleTooltipClose.bind(this);
        this.toggleVerifyModal = this.toggleVerifyModal.bind(this);
        this.openVerifyModal = this.openVerifyModal.bind(this);
        this.updateApplicationStatus = this.updateApplicationStatus.bind(this);
        this.toggleViewDocumentsModal = this.toggleViewDocumentsModal.bind(this);
        this.openViewDocuments = this.openViewDocuments.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
        this.statusUpdate = this.statusUpdate.bind(this);
        this.verifyModalRef = createRef();
        this.viewDocumentsRef = createRef();
        this.dropzoneRef = createRef();
        this.openDialog = this.openDialog.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.onDropRejected = this.onDropRejected.bind(this);
        this.showViewDocumentModal = this.showViewDocumentModal.bind(this);
        this.changeMedicalConditionStatus = this.changeMedicalConditionStatus.bind(this);
        this.changeCriminalRecordStatus = this.changeCriminalRecordStatus.bind(this)
        this.renderAutomaticCheck = this.renderAutomaticCheck.bind(this)
    }

    // Lifecicle methods

    componentDidMount() {

        this.props.setApplicationId(-1);

        this.fetchData();
    }

    onDropRejected(files) {

        if (files.length == 0) {
            return;
        }

        if (files[0].errors[0].code == 'file-invalid-type') {
            this.setState({
                uploadFileError: strings.uploadFileErrorFileType
            });
        } else {
            this.setState({
                uploadFileError: strings.uploadFileError
            });
        }
    }

    onDrop(acceptedFiles) {

        if (!acceptedFiles || acceptedFiles.length == 0) {

            this.setState({
                uploadFileError: strings.uploadFileError
            });

            return;
        }

        this.setState({
            uploadFileError: '',
            showUploadProgress: true
        });

        for (let acceptedFile of acceptedFiles) {

            let applicationFile = new FormData();
            applicationFile.append('application', this.state.selectedApplication);
            applicationFile.append('name', acceptedFile.name);
            applicationFile.append('content', acceptedFile);
            applicationFile.append('fileType', acceptedFile.type);
            applicationFile.append('type', UploadSection.Aditional);

            uploadFiles(applicationFile).then(response => {

                if (!response || !response.ok) {
                    this.props.enqueueSnackbar("Error uploading file", {variant: 'error'});
                    return;
                }

                let files = this.state.files;

                files.push(response.data);

                this.setState({
                    files: files,
                    showUploadProgress: false
                });
            });
        }
    }

    openDialog() {
        if (this.dropzoneRef.current) {
            this.dropzoneRef.current.open();
        }
    };

    deleteFile(id) {
        let file = this.findFile(id);

        deleteFile(id).then(response => {

            let files = this.state.files;
            let index = files.indexOf(file)
            if (index > -1) {
                files.splice(index, 1);
            }

            this.setState({
                files: files
            });
        });
    }

    formModalRender(file) {
        return (
            <FileUploadModal
                toggleModal={this.toggleFileUploadModal}
                open={this.state.openFileUpload}
                uploadedFile={file}
                title={strings.documents.uploadDocuments}
                openDialog={this.openDialog}
                deleteFile={this.deleteFile}
                onChange={this.changeData}
                data={this.state.selectedItem}
                uploadSection={UploadSection.Aditional}
                content={
                    <div className={'dropzone-wrapper'}>
                        {
                            this.state.showUploadProgress &&
                            <LinearProgress/>
                        }
                        <Dropzone
                            onDropAccepted={this.onDrop}
                            onDropRejected={this.onDropRejected}
                            multiple={true}
                            ref={this.dropzoneRef}
                            accept={CONFIG.acceptFiles}
                            maxSize={1048576 * 10}
                        >
                            {({getRootProps, getInputProps}) => (
                                <div>
                                    <section className={'file-dropzone'}>
                                        <div {...getRootProps()} className={'cursor-pointer'}>
                                            <input {...getInputProps()} />
                                            <p>{strings.documents.dragAndDrop}</p>
                                        </div>
                                    </section>
                                    <p className={'file-size'}>*10MB file size <span>{this.state.uploadFileError}</span>
                                    </p>
                                </div>
                            )}
                        </Dropzone>
                    </div>
                }
            />
        );
    }

    renderUploadButton(id) {

        return (
            <div className='view-applicant-btn'>
                <Button variant="contained" color="primary" onClick={() => this.toggleFileUploadModal(id)}>
                    {strings.candidateProfiles.uploadFiles}
                </Button>
            </div>
        );
    }

    toggleFileUploadModal(id) {

        this.state.selectedApplication = id;

        getFiles(id).then(response => {

            if (!response || !response.ok) {
                return;
            }

            this.setState({
                files: response.data
            });

        });

        this.setState({
            openFileUpload: !this.state.openFileUpload,
            uploadFileError: ''
        });
    }

    renderConfirmButton(id) {
        return (
            <div className='confirm-btn'>
                <Button variant="contained" color="primary" onClick={() => this.statusUpdate(id)}>
                    {strings.candidateProfiles.confirm}
                </Button>
            </div>
        );
    }

    renderContractButtons(contract) {
        if (!contract || !contract.contractContent) {
            return '';
        }

        return <a target="_blank" href={CONFIG.baseUrlAPI + '/contract/pdf/' + contract.id}>PDF</a>;
    }

    // Render table element components

    renderName(id) {

        let application = findApplication(id, this.state.tableData);

        if (!application) {
            return '';
        }

        return <div>
            <div>
                {this.renderCandidateImage()}
            </div>
            <div>
                {application.forename + ' ' + application.surname}
            </div>
        </div>
    }

    handleTooltipToggle(event, name) {

        let tooltips = this.state.tooltips;

        tooltips[name] = !tooltips[name];

        this.setState({
            tooltips: tooltips
        })
    }

    handleTooltipClose(event, name) {

        let tooltips = this.state.tooltips;

        tooltips[name] = false;

        this.setState({
            tooltips: tooltips
        })
    }

    getTooltipState(name) {
        const open = this.state.tooltips[name];

        return open == undefined ? false : open;
    }

    renderHavePreviousWorked(contract) {

        if (!contract) {
            return '';
        }

        let name = "previousWorkedLeavingReason-" + contract.id;

        return (
            // <ClickAwayListener onClickAway={(event) => this.handleTooltipClose(event, name)}>
            <Tooltip
                key={name}
                PopperProps={{
                    disablePortal: true,
                }}
                open={this.getTooltipState(name)}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={contract && contract.previousWorkedLeavingReason ? contract.previousWorkedLeavingReason : ""}>
                <Button onClick={(event) => this.handleTooltipToggle(event, name)}>
                    {this.renderColumnYesNo(contract ? contract.havePreviousWorked : null)}
                </Button>
            </Tooltip>
            // </ClickAwayListener>
        )
    }

    changeMedicalConditionStatus(event, id) {
        changeContractMedicalConditionStatus(id, event.target.value.value).then(response => {

            this.fetchData();

        });
    }

    changeCriminalRecordStatus(event, id) {
        changeContractCriminalRecordStatus(id, event.target.value.value).then(response => {

            this.fetchData();

        });
    }

    renderAutomaticCheck(id) {
        let application = findApplication(id, this.state.tableData);

        let text = ''

        if (application.rtwProcess === RTWProcess.MANUAL) {
            if (application.rtwStatus === ApplicationStatusRTW.ACCEPTED) {
                text = 'Manual Approved'
            }
            else if (application.rtwStatus === ApplicationStatusRTW.REJECTED) {
                text = 'Manual Rejected'
            }
            else {
                text = 'Manual in progress'
            }
        } else if(application.rtwProcess === RTWProcess.IDVT) {
            if (application.rtwStatus === ApplicationStatusRTW.IDVT_ACCEPTED) {
                text = 'IDVT Approved'
            }
            else if (application.rtwStatus === ApplicationStatusRTW.IDVT_REJECTED) {
                text = 'IDVT Rejected'
            }
            else {
                text = 'IDVT in progress'
            }
        } else {
            if (application.rtwStatus === ApplicationStatusRTW.SHARE_CODE_ACCEPTED) {
                text = 'Share Code Approved'
            } else if (application.rtwStatus === ApplicationStatusRTW.SHARE_CODE_REJECTED) {
                text = 'Share Code Rejected'
            } else {
                text = 'Share Code in progress'
            }
        }

        return <div>
            {text}
            <IconButton>
                <img src={'images/history-icon.png'} onClick={() => this.toggleViewHistoryModal(id)}/>
            </IconButton>
        </div>
    }

    renderCriminalRecord(contract) {

        if (!contract) {
            return '';
        }

        let application = findApplication(contract.applicationId, this.state.tableData);

        return (
            <div>
                <SelectControl
                    isDisabled={contract.criminalRecordStatus === CriminalRecordStatus.HR_APPROVED || application.applicationStatus == 7}
                    fullWidth
                    autoFocus
                    name='criminalRecordStatus'
                    onChange={(event) => this.changeCriminalRecordStatus(event, contract.id)}
                    margin="normal"
                    value={contract.criminalRecordStatus}
                    options={getCriminalRecordStatuses()}
                    nameKey={'name'}
                    valueKey={'value'}
                    selected={contract ? contract.criminalRecordStatus : undefined}
                />
            </div>
        )
    }

    renderHaveMedicalConditions(contract) {

        if (!contract) {
            return '';
        }

        let application = findApplication(contract.applicationId, this.state.tableData);

        return (
            <div>
                <SelectControl
                    isDisabled={contract.medicalConditionStatus === MedicalConditionStatus.HR_APPROVED || application.applicationStatus == 7}
                    fullWidth
                    autoFocus
                    name='medicalConditionStatus'
                    onChange={(event) => this.changeMedicalConditionStatus(event, contract.id)}
                    margin="normal"
                    value={contract.medicalConditionStatus}
                    options={getMedicalConditionStatuses()}
                    nameKey={'name'}
                    valueKey={'value'}
                    selected={contract ? contract.medicalConditionStatus : undefined}
                />
            </div>
        )
    }

    renderSessionType(item) {
        if (!item) {
            return '';
        }

        return item.name;
    }

    renderDate(item) {

        if (!item) {
            return '';
        }

        return transformDate(item);
    }

    renderDivision(item) {

        if (!item) {
            return '';
        }

        return item.name;
    }

    renderRegion(item) {
        if (!item) {
            return '';
        }

        return item.name;
    }

    renderRecruiter(item) {
        if (!item) {
            return '';
        }

        return item.firstName + " " + item.lastName;
    }

    renderRole(id) {

        let application = findApplication(id, this.state.tableData);

        if (!application) {
            return '';
        }

        if (application.jobRoleText) {
            return application.jobRoleText
        }

        return application.jobRole ? application.jobRole.name : '';
    }

    renderCommentsButton(id) {
        return (
            <div className='comments-btn'>
                <Button variant="contained" color="primary" onClick={() => this.toggleCommentsModal(id)}>
                    {strings.hrManager.comments}
                </Button>
            </div>
        );
    }

    renderPayrollIdNo() {
        return (
            <TextField
                fullWidth
                name='payrollId'
                onChange={this.changeData}
                value={this.state.data.payrollID}
                margin="dense"
                variant='outlined'
            />
        );
    }


    renderDocCheck(id) {

        let application = findApplication(id, this.state.tableData);

        return (
            <Checkbox
                checked={application.docCheckDone}
                onChange={() => this.confirm(id)}
                inputProps={{
                    'aria-label': 'secondary checkbox',
                }}
            />
        )
    }

    renderViewRTW(id) {
        return (
            <div className='view-rtw-btn'>
                <Button variant="contained" color="primary" onClick={() => this.toggleViewRTWModal(id)}>
                    {strings.hrManager.viewRTW}
                </Button>
            </div>
        );
    }

    renderDocViewerButton(id) {

        return (
            <div className='view-applicant-btn'>
                <Button variant="contained" color="primary" onClick={() => this.toggleViewDocumentsModal(id)}>
                    {strings.candidateProfiles.viewDocuments}
                </Button>
            </div>
        );
    }

    renderViewOffer(id) {
        return (
            <div className='view-offer-btn'>
                <Button variant="contained" color="primary" onClick={() => this.toggleViewOfferModal(id)}>
                    {strings.hrManager.viewOffer}
                </Button>
            </div>
        );
    }

    renderViewApplicant(id) {

        return (
            <div className='view-applicant-btn'>
                <Button variant="contained" color="primary"
                        onClick={() => this.toggleViewApplicantModal(id)}>
                    {strings.hrManager.viewApplicant}
                </Button>
            </div>
        );
    }

    renderWarningImage(id) {

        let application = findApplication(id, this.state.tableData);

        if (!application || !application.comment) {
            return '';
        }

        return (
            <div className={'tooltip left'}>
                <div className={'warning-image'}>
                    <img src={'/images/warning.png'} alt={'logo'}/>
                    <span className="tooltip-content">{application.comment}</span>
                </div>
            </div>
        );
    }

    renderCandidateImage() {
        return (
            <div className={'candidate-profile-image'}>
                {
                    this.state.tableData.image
                        ?
                        <img src={'/images/background.jpg'} alt={'logo'}/>
                        :
                        <img src={'/images/user.png'} alt={'logo'}/>
                }
            </div>
        );
    }

    renderApplicationStatus(id) {

        let application = findApplication(id, this.state.tableData);
        return (
            <div>
                <SelectControl
                    isDisabled={application.applicationStatus === ApplicationStatus.APPROVED}
                    fullWidth
                    autoFocus
                    name='applicationStatus'
                    onChange={(event) => this.changeDataStatus(event, id)}
                    margin="normal"
                    value={this.state.data.applicationStatus}
                    options={getApplicationStatusesHR(application.rtwStatus === ApplicationStatusRTW.ACCEPTED || application.rtwStatus === ApplicationStatusRTW.IDVT_ACCEPTED || application.rtwStatus === ApplicationStatusRTW.SHARE_CODE_ACCEPTED)}
                    nameKey={'name'}
                    valueKey={'value'}
                    selected={application ? application.applicationStatus : undefined}
                />
            </div>
        );
    }

    findFile(id) {

        for (let file of this.state.files) {
            if (file && file.id == id) {
                return file;
            }
        }

        return undefined;
    }

    deleteFile(id) {
        let file = this.findFile(id);

        deleteFile(id).then(response => {

            let files = this.state.files;
            let index = files.indexOf(file)
            if (index > -1) {
                files.splice(index, 1);
            }

            this.setState({
                files: files
            });
        });
    }

    // Toggle methods

    toggleViewRTWModal(id) {

        // this.state.selectedApplication = this.findApplication(id);

        getFiles(id).then(response => {

            if (!response || !response.ok) {
                return;
            }

            this.setState({
                files: response.data
            });

        });

        this.setState({
            openRTWModal: !this.state.openRTWModal
        });
    }

    toggleCommentsModal(id) {
        this.setState({
            openCommentsModal: !this.state.openCommentsModal,
            selectedApplication: id
        });
    }

    toggleViewOfferModal(id) {

        this.state.selectedApplication = id;

        this.setState({
            openViewOfferModal: !this.state.openViewOfferModal
        });
    }

    toggleViewApplicantModal(id) {

        this.state.selectedApplication = id;

        this.setState({
            openApplicantModal: !this.state.openApplicantModal
        });
    }

    toggleVerifyModal(id, status) {
        this.state.selectedApplication = id;
        this.state.currentStatus = status;

        this.setState({
            openVerifyModal: !this.state.openVerifyModal
        });
    }

    openViewDocuments() {
        if (this.viewDocumentsRef.current) {
            this.viewDocumentsRef.current.open();
        }
    };

    toggleViewDocumentsModal(id) {


        if (id != -1) {
            getFiles(id).then(response => {

                if (!response || !response.ok) {
                    return;
                }

                this.setState({
                    files: response.data
                });

            });
        } else {
            this.setState({
                files: []
            });
        }

        this.setState({
            openDocView: !this.state.openDocView,
            documentModalFullscreen: false
        });
    }

    showViewDocumentModal(id, documentModalFullscreen = false) {

        if (id != -1) {
            getFiles(id).then(response => {

                if (!response || !response.ok) {
                    return;
                }

                this.setState({
                    files: response.data
                });

            });
        } else {
            this.setState({
                files: []
            });
        }

        this.setState({
            openDocView: true,
            documentModalFullscreen: documentModalFullscreen
        });
    }

    //  Open modal methods

    openRTWModal() {
        if (this.rtwModalRef.current) {
            this.rtwModalRef.current.open();
        }
    };

    openCommentsModal() {
        if (this.commentsModalRef.current) {
            this.commentsModalRef.current.open();
        }
    };

    openViewOfferModal() {
        if (this.viewOfferModalRef.current) {
            this.viewOfferModalRef.current.open();
        }
    };

    openApplicantModal() {
        if (this.viewApplicantModalRef.current) {
            this.viewApplicantModalRef.current.open();
        }
    };

    openVerifyModal() {
        if (this.verifyModalRef.current) {
            this.verifyModalRef.current.open();
        }
    }

    closeVerifyModal() {
        this.state.selectedApplication = null;
        this.state.currentStatus = null;

        this.setState({
            openVerifyModal: false
        });
    }

    // Render modals

    viewCommentsModal() {

        let application = findApplication(this.state.selectedApplication, this.state.tableData);

        return (
            <CommentsModal
                toggleModal={this.toggleCommentsModal}
                open={this.state.openCommentsModal}
                title={strings.hrManager.comments}
                openDialog={this.openCommentsModal}
                onChange={this.changeData}
                fetchData={this.fetchData}
                application={application}
            />
        );
    }

    viewDocumentsModalRender(files) {
        return (
            <ViewDocumentsModal
                toggleModal={this.toggleViewDocumentsModal}
                open={this.state.openDocView}
                uploadedFiles={files}
                title={strings.documents.viewDocuments}
                openDialog={this.openViewDocuments}
                deleteFile={this.deleteFile}
                onChange={this.onChange}
                data={this.state.selectedItem}
                fullscreen={this.state.documentModalFullscreen}
            />
        );
    }

    viewHistoryModalRender(histories){
        return (
            <ViewHistoryModal
                toggleModal={this.toggleViewHistoryModal}
                open={this.state.openHistoryView}
                histories={histories}
                title={strings.histories.viewHistories}
            />
        );
    }

    toggleViewHistoryModal(id){

        if(id != -1) {
            getHistories(id).then(response => {

                if(!response || !response.ok) {
                    return;
                }

                this.setState({
                    histories: response.data
                });

            });
        }
        else {
            this.setState({
                histories: []
            });
        }
        this.setState({
            openHistoryView: !this.state.openHistoryView
        });
    }

    viewOfferModal() {

        return (
            <ViewOfferModal
                toggleModal={this.toggleViewOfferModal}
                open={this.state.openViewOfferModal}
                title={strings.hrManager.viewOffer}
                openDialog={this.openViewOfferModal}
                onChange={this.changeData}
                data={this.state.selectedItem}
            />
        );
    }

    viewApplicantModal() {

        let application = findApplication(this.state.selectedApplication, this.state.tableData);

        return (
            <ViewApplicantModal
                toggleModal={this.toggleViewApplicantModal}
                open={this.state.openApplicantModal}
                title={strings.hrManager.viewApplicant}
                openDialog={this.openApplicantModal}
                onChange={this.changeData}
                application={application}
            />
        );
    }

    viewRTWModal(files) {
        return (
            <ViewRTWModal
                toggleModal={this.toggleViewRTWModal}
                open={this.state.openRTWModal}
                title={strings.hrManager.fullViewRTW}
                openDialog={this.openRTWModal}
                data={this.state.selectedItem}
                uploadedFiles={files}
            />
        );
    }

    viewVerifyModal() {
        if (!this.state.selectedApplication) {
            return '';
        }

        return (
            <VerifyModal
                closeVerifyModal={() => this.closeVerifyModal()}
                open={this.state.openVerifyModal}
                title={strings.hrManager.candidateRejection}
                openDialog={this.openVerifyModal}
                application={this.state.selectedApplication}
                currentStatus={this.state.currentStatus}
                statusUpdate={this.updateApplicationStatus}
                applicationId={this.state.selectedApplication}
                onChange={this.changeData}
                fetchData={this.fetchData}
            />
        );
    }

    // Backend methods

    componentWillReceiveProps(props) {

        if (!props.filter) {
            return;
        }

        this.state.filter = props.filter ? props.filter.filterData : {

            division: -1,
            region: -1,
            location: -1,
            recruiter: -1,
            jobRole: -1,
            status: -1,
            contractType: -1,
            search: '',
            page: 1,
            perPage: 30,
            dateFrom: null,
            dateTo: null,
            stage: -1
        };

        this.fetchData();
    }

    fetchData() {

        if (!this.state.filter) {
            return;
        }

        this.setState({
            lockTable: true
        });

        if (this.state.filter) {
            this.state.filter.applicationInternalStatus = ApplicationInternalStatus.ALL;
            this.state.filter.view = ApplicationView.CANDIDATE_APPLICATIONS;
            this.state.filter.search = this.state.searchData.search;
            this.state.filter.page = this.state.searchData.page - 1;
            this.state.filter.perPage = this.state.searchData.perPage;
        }

        getApplications(this.state.filter).then(response => {
            if (!response.ok) {
                return;
            }

            this.setState({
                tableData: response.data.entities ? response.data.entities : [],
                total: response.data.total,
                lockTable: false
            }, () => {
                const applicationId = this.getSearchParam('id');

                if (applicationId) {
                    this.showViewDocumentModal(applicationId, true);
                }
            });
        });
    }

    confirm(id) {

        applicationConfirm(id).then(response => {

            if (!response || !response.ok) {
                this.props.enqueueSnackbar(strings.hrManager.errorConfirmApplication, {variant: 'error'});
                return;
            }

            this.props.enqueueSnackbar(strings.hrManager.applicationConfirmed, {variant: 'success'});

            this.fetchData();
        });
    }

    changeDataStatus(event, id) {

        let application = findApplication(id, this.state.tableData);

        let index = this.state.tableData.indexOf(application);

        if (index == -1) {
            return;
        }

        let data = this.state.tableData;

        if (event !== undefined) {
            data[index][event.target.name] = event.target.value.value
        } else {
            data[index]['applicationStatus'] = status;
            data[index]['reason'] = reason;
        }

        this.setState({
            tableData: data
        });

        if (event.target.value.value == ApplicationStatus.REJECTED || event.target.value.value == ApplicationStatus.APPROVED) {
            this.toggleVerifyModal(id, event.target.value.value);
        }
    }

    getPageHeader() {
        return <h1>{strings.hrManager.candidateApplications}</h1>;
    }

    updateApplicationStatus(id, status, reason, event) {

        let application = findApplication(id, this.state.tableData);

        let index = this.state.tableData.indexOf(application);

        if (index == -1) {
            return;
        }

        let data = this.state.tableData;

        if (event !== undefined) {
            data[index][event.target.name] = event.target.value.value
        } else {
            data[index]['applicationStatus'] = status;
            data[index]['reason'] = reason;
        }

        updateStatus(data[index]).then(response => {
            if (!response || !response.ok) {

                this.props.enqueueSnackbar(strings.hrManager.errorUpdatingStatus, {variant: 'error'});
                return;
            }

            this.props.enqueueSnackbar(strings.hrManager.statusUpdated, {variant: 'success'});
            this.setState({
                tableData: data
            });
        });
    }

    statusUpdate(id) {

        let application = findApplication(id, this.state.tableData);

        updateStatus(application).then(response => {
            if (!response || !response.ok) {

                this.props.enqueueSnackbar(strings.hrManager.errorUpdatingStatus, {variant: 'error'});
                return;
            }

            this.props.enqueueSnackbar(strings.hrManager.statusUpdated, {variant: 'success'});
        });
    }

    render() {
        let historyView = this.viewHistoryModalRender(this.state.histories);
        let rtwModal = this.viewRTWModal(this.state.files);
        let commentsModal = this.viewCommentsModal();
        let viewOfferModal = this.viewOfferModal();
        let viewApplicantModal = this.viewApplicantModal();
        let verifyModal = this.viewVerifyModal();
        let docView = this.viewDocumentsModalRender(this.state.files);
        let formModal = this.formModalRender(this.state.files);

        return (
            <div>
                <Grid id='candidate-applications-table-page'>
                    {this.renderDialog(strings.table.confirmDelete, 'To subscribe to this website, please enter your email address here. We will send\n' +
                        'updates occasionally.', this.cancelDelete, this.delete)}
                    <div className='header'>
                        {this.getPageHeader()}

                        <div className='filter-controls'>

                            {
                                this.state.showSearch &&
                                <div className={'filter-wrapper'}>
                                    <TextField
                                        label={strings.table.search}
                                        type="search"
                                        name='search'
                                        value={this.state.searchData.search}
                                        onChange={this.searchChanged}
                                        variant={'outlined'}
                                    />
                                    <SearchIcon/>
                                </div>
                            }

                            {
                                this.state.showAdd &&
                                this.renderTableControls()
                            }
                        </div>
                    </div>
                    <Paper className={'table-wrapper'} md={12}>
                        {this.renderTable(this.state.tableData)}
                    </Paper>

                    <Drawer id='drawer' anchor='right' open={this.showDrawer()}
                            onClose={() => this.setPageState(PageState.View)}>
                        <DrawerWrapper onBack={() => this.setPageState(PageState.View)}>
                            {this.renderDrawerContent()}
                        </DrawerWrapper>
                    </Drawer>
                </Grid>
                {historyView}
                {rtwModal}
                {commentsModal}
                {viewOfferModal}
                {viewApplicantModal}
                {verifyModal}
                {docView}
                {formModal}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen,
        setApplicationId: Actions.setApplicationId
    }, dispatch);
}

function mapStateToProps({menuReducers, filterReducers}) {
    return {menu: menuReducers, filter: filterReducers};
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(CandidateApplications)));