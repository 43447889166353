import React, {Component} from 'react';
import {Dialog, Divider, Slide, Tooltip} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from "@material-ui/core";
import { Page } from 'react-pdf';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import DocumentType, {getStringDocumentType} from "../constants/DocumentType";
import {transformDate} from "../util/DateUtil";


class ViewDocumentsModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: {},
            selectedImage: this.props.uploadedFiles && this.props.uploadedFiles[0] ? this.props.uploadedFiles[0].src : undefined,
            selectedItem: this.props.uploadedFiles ? this.props.uploadedFiles[0] : undefined,
            selectedContent: this.props.uploadedFiles && this.props.uploadedFiles[0] ? this.props.uploadedFiles[0].name : undefined,
            onLoad: true,
            pdfPage: 1
        }

        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    }

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="left" ref={ref} {...props} />;
    });

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.data !== this.props.data){
            this.setState({
                data: this.props.data
            });
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setInitialDocument(nextProps.uploadedFiles);
    }

    renderImage(item){
        let content = "data:" + item.fileType + ";base64," + item.content;
        
        this.setState({
            selectedImage: item.name,
            selectedItem: item,
            selectedContent: content
        });
    }

    renderFiles(){
        let result = [];

        for(let item of this.props.uploadedFiles){

            let selected = this.state.selectedItem === item ? 'selected' : '';

            result.push(
                <div className={'file-info ' + selected} onClick={() => {this.renderImage(item)}}>
                    <div className={'file-info-border'}></div>
                    <div className={'file-info-content'}>
                        <div className='data'>
                            <div className={'file-name'}>
                                {item.name.length > 20 ? item.name.substring(0, 20) : item.name }
                            </div>
                            <div className={'file-data'}>
                                {getStringDocumentType(item.documentType)} - {transformDate(item.dateCreated)}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return result;
    }

    onDocumentLoadSuccess (event) {

        this.setState({
            pdfPage: event.numPages
        });
    }

    renderImageContent(){

        if(!this.state.selectedItem) {
            return '';
        }

        return (
            <div className={'content'}>
                <div className={'document-image'}>
                {
                    this.state.selectedItem &&
                    (this.state.selectedItem.fileType == 'image/png' || this.state.selectedItem.fileType == 'image/jpg' ||
                    this.state.selectedItem.fileType == 'image/jpeg' || this.state.selectedItem.fileType == 'image/bmp'
                    || this.state.selectedItem.fileType == 'image/tiff' || this.state.selectedItem.fileType == 'image/tif') &&

                    <img src={this.state.selectedContent } />
                }

                {
                    this.state.selectedItem &&
                    (this.state.selectedItem.fileType == 'application/pdf') &&
                    <Document
                        file={this.state.selectedContent}
                        onLoadSuccess={this.onDocumentLoadSuccess}
                    >
                        {Array.apply(null, Array(this.state.pdfPage))
                        .map((x, i)=>i+1)
                        .map(page => <Page pageNumber={page}/>)}
                    </Document>
                }

                {
                    this.state.selectedItem &&
                    (this.state.selectedItem.fileType != 'image/png' && this.state.selectedItem.fileType != 'image/jpg' &&
                    this.state.selectedItem.fileType != 'image/jpeg' && this.state.selectedItem.fileType != 'image/bmp'
                    && this.state.selectedItem.fileType != 'image/tiff' && this.state.selectedItem.fileType != 'image/tif' 
                    && this.state.selectedItem.fileType != 'application/pdf') &&
                    <img src={'images/placeholder.jpg'} />
                }

                </div>
                <div className={'buttons'}>
                    <div className={'download-button'}>
                        <a download={this.state.selectedImage} href={this.state.selectedContent}>
                            <img src={'/images/download.png'} alt={'logo'} />    
                        </a>
                    </div>
                    <div className={'printing-button'}>
                        <a target="_blank" href={this.state.selectedContent}>
                            <img src={'/images/printing.png'} alt={'logo'} />    
                        </a>
                    </div>
                    <div className={'delete-button'} onClick={() => this.props.deleteFile(this.state.selectedItem ? this.state.selectedItem.id: '')}>
                        <img src={'/images/delete.png'} alt={'logo'} />
                    </div>
                </div>
            </div>
        );
    }

    setInitialDocument(uploadedFiles){
        if(uploadedFiles && uploadedFiles.length !== 0){

            this.renderImage(uploadedFiles[0])
        }else {
            this.state.selectedImage = null;
            this.state.selectedItem = null;
            this.state.selectedContent = null;
        }
    }

    render() {

        let id = -1;

        if(this.props.uploadedFiles && this.props.uploadedFiles.length > 0) {
            id = this.props.uploadedFiles[0].application.id;
        }

        return (
            <Dialog
                onClose={() => this.props.toggleModal(-1)}
                aria-labelledby="customized-dialog-title"
                open={this.props.open}
                TransitionComponent={this.Transition}
                className={'view-documents-modal ' + (this.props.fullscreen ? ' fullscreen' : '')}
            >
                <div className='form-modal-header' onClose={() => this.props.toggleModal(-1)}>
                    <div>
                        <h1 className='documen-view-title'>
                            {this.props.title}
                            <Tooltip title='New tab'>
                                <a target="_blank" href={window.location + '?id=' + id}>
                                    <img src={'/images/maximize-icon.png'} alt={'logo'} style={{ width: '25px' }} />    
                                </a>
                            </Tooltip>
                        </h1>
                        <IconButton className={'close-button-wrapper'} onClick={() => this.props.toggleModal(-1)}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <Divider />
                </div>

                <div className={'uploaded-files-wrapper'}>
                {
                    this.props.uploadedFiles && this.props.uploadedFiles.length > 0 &&
                    <div className={'uploaded-files'}>
                        <div className={'file'}>
                            { this.renderFiles() }
                        </div>
                    </div>
                }
                {
                    this.props.uploadedFiles &&
                     this.renderImageContent()
                }
                </div>
            </Dialog>
        );
    }
}

export default ViewDocumentsModal;