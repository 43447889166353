import UploadSection from "./UploadSection";

const DocumentType = {
    P45_P60: 1,
    NI_Confirmation_Letter: 2,
    Passport: 3,
    Birth_Certificate: 4,
    Passport_Style_Photo: 5,
    Other: 6,
    Bank_Statement: 7,
    Council_Tax_Bill: 8,
    Utility_Bill: 9,
    Drivers_Licence: 10,
    Visa: 11,
    Bank_Confirmation_Letter: 12,
    National_Identity_Card: 13,
    Permanent_Residence_Document: 14,
    Biometric_Immigraton_Document: 15,
    Immigration_Control_Exemption: 16,
    Immigration_Status_Document: 17,
    Long_Birth_Certificate: 18,
    Short_Birth_Certificate: 19,
    Adoption_Certificate: 20,
    Certificate_of_registration: 21,
    Certificate_of_Naturalisation: 22,
    Residence_Card: 23,
    Certificate_of_Application: 24,
    Application_Registration_Card: 25,
    Positive_Verification_Document: 26,
    Selfie: 27,
    ShareCodeCheck: 28,
    FailedShareCodeCheck: 29
};

export function getStringDocumentType(documentType)
{
    switch(documentType) 
    {
        case DocumentType.P45_P60: return "P45/P60";
        case DocumentType.NI_Confirmation_Letter: return "NI Confirmation Letter";
        case DocumentType.Passport: return "Passport";
        case DocumentType.Birth_Certificate: return "Birth Certificate";
        case DocumentType.Passport_Style_Photo: return "Passport Style Photo";
        case DocumentType.Other: return "Other";
        case DocumentType.Bank_Statement: return "Bank Statement";
        case DocumentType.Council_Tax_Bill: return "Council Tax Bill";
        case DocumentType.Utility_Bill: return "Utility Bill";
        case DocumentType.Drivers_Licence: return "Driver's Licence";
        case DocumentType.Visa: return "Visa";
        case DocumentType.Bank_Confirmation_Letter: return "Bank Confirmation Letter";
        case DocumentType.National_Identity_Card: return "National Identity Card";
        case DocumentType.Permanent_Residence_Document: return "Permanent Residence Document";
        case DocumentType.Biometric_Immigraton_Document: return "Biometric Immigraton Document";
        case DocumentType.Immigration_Control_Exemption: return "Immigration Control Exemption";
        case DocumentType.Immigration_Status_Document: return "Immigration Status Document";
        case DocumentType.Long_Birth_Certificate: return "Long Birth Certificate";
        case DocumentType.Short_Birth_Certificate: return "Short Birth Certificate";
        case DocumentType.Adoption_Certificate: return "Adoption Certificate";
        case DocumentType.Certificate_of_registration: return "Certificate of registration";
        case DocumentType.Certificate_of_Naturalisation: return "Certificate of Naturalisation";
        case DocumentType.Residence_Card: return "Residence Card";
        case DocumentType.Certificate_of_Application: return "Certificate of Application";
        case DocumentType.Application_Registration_Card: return "Application Registration Card";
        case DocumentType.Positive_Verification_Document: return "Positive Verification Document";
        case DocumentType.Selfie: return "Selfie";
    }

    return '';
}

function getSelfie() {
    return [
        {
            value: DocumentType.Selfie,
            name: getStringDocumentType(DocumentType.Selfie)
        },
    ];
}

function getProof_of_NI_or_PPS() {
    return [
        {
            value: DocumentType.P45_P60,
            name: getStringDocumentType(DocumentType.P45_P60)
        },
        {
            value: DocumentType.NI_Confirmation_Letter,
            name: getStringDocumentType(DocumentType.NI_Confirmation_Letter)
        },
    ];
}

function getProof_of_Identity() {
    return [
        {
            value: DocumentType.Passport,
            name: getStringDocumentType(DocumentType.Passport)
        },
        {
            value: DocumentType.Birth_Certificate,
            name: getStringDocumentType(DocumentType.Birth_Certificate)
        },
        {
            value: DocumentType.Other,
            name: getStringDocumentType(DocumentType.Other)
        },
    ];
}

function getProof_of_Address() {
    return [
        {
            value: DocumentType.Bank_Statement,
            name: getStringDocumentType(DocumentType.Bank_Statement)
        },
        {
            value: DocumentType.Council_Tax_Bill,
            name: getStringDocumentType(DocumentType.Council_Tax_Bill)
        },
        {
            value: DocumentType.Utility_Bill,
            name: getStringDocumentType(DocumentType.Utility_Bill)
        },
        {
            value: DocumentType.Drivers_Licence,
            name: getStringDocumentType(DocumentType.Drivers_Licence)
        },
        {
            value: DocumentType.Other,
            name: getStringDocumentType(DocumentType.Other)
        },
    ];
}

function getVisa_Documents() {
    return [
        {
            value: DocumentType.Visa,
            name: getStringDocumentType(DocumentType.Visa)
        },
        {
            value: DocumentType.Passport,
            name: getStringDocumentType(DocumentType.Passport)
        },
    ];
}

function getRight_to_Work_Documents() {
    return [
        {
            value: DocumentType.Passport,
            name: getStringDocumentType(DocumentType.Passport)
        },
        {
            value: DocumentType.National_Identity_Card,
            name: getStringDocumentType(DocumentType.National_Identity_Card)
        },
        {
            value: DocumentType.Permanent_Residence_Document,
            name: getStringDocumentType(DocumentType.Permanent_Residence_Document)
        },
        {
            value: DocumentType.Biometric_Immigraton_Document,
            name: getStringDocumentType(DocumentType.Biometric_Immigraton_Document)
        },
        {
            value: DocumentType.Immigration_Control_Exemption,
            name: getStringDocumentType(DocumentType.Immigration_Control_Exemption)
        },
        {
            value: DocumentType.Immigration_Status_Document,
            name: getStringDocumentType(DocumentType.Immigration_Status_Document)
        },
        {
            value: DocumentType.Long_Birth_Certificate,
            name: getStringDocumentType(DocumentType.Long_Birth_Certificate)
        },
        {
            value: DocumentType.Short_Birth_Certificate,
            name: getStringDocumentType(DocumentType.Short_Birth_Certificate)
        },
        {
            value: DocumentType.Adoption_Certificate,
            name: getStringDocumentType(DocumentType.Adoption_Certificate)
        },
        {
            value: DocumentType.Certificate_of_registration,
            name: getStringDocumentType(DocumentType.Certificate_of_registration)
        },
        {
            value: DocumentType.Certificate_of_Naturalisation,
            name: getStringDocumentType(DocumentType.Certificate_of_Naturalisation)
        },
        {
            value: DocumentType.Residence_Card,
            name: getStringDocumentType(DocumentType.Residence_Card)
        },
        {
            value: DocumentType.Certificate_of_Application,
            name: getStringDocumentType(DocumentType.Certificate_of_Application)
        },
        {
            value: DocumentType.Application_Registration_Card,
            name: getStringDocumentType(DocumentType.Application_Registration_Card)
        },
        {
            value: DocumentType.Positive_Verification_Document,
            name: getStringDocumentType(DocumentType.Positive_Verification_Document)
        }
    ];
}

function getProof_of_Account_Details() {
    return [
        {
            value: DocumentType.Bank_Statement,
            name: getStringDocumentType(DocumentType.Bank_Statement)
        },
        {
            value: DocumentType.Bank_Confirmation_Letter,
            name: getStringDocumentType(DocumentType.Bank_Confirmation_Letter)
        }
    ];
}

function getContract_Employment() {
    return [
        {
            value: DocumentType.P45_P60,
            name: getStringDocumentType(DocumentType.P45_P60)
        }
    ];
}

function getAditional() {
    return getAll();
}

function getAll() {

    return [
        {
            value: DocumentType.P45_P60,
            name: getStringDocumentType(DocumentType.P45_P60)
        },
        {
            value: DocumentType.NI_Confirmation_Letter,
            name: getStringDocumentType(DocumentType.NI_Confirmation_Letter)
        },
        {
            value: DocumentType.Passport,
            name: getStringDocumentType(DocumentType.Passport)
        },
        {
            value: DocumentType.Birth_Certificate,
            name: getStringDocumentType(DocumentType.Birth_Certificate)
        },
        {
            value: DocumentType.Other,
            name: getStringDocumentType(DocumentType.Other)
        },
        {
            value: DocumentType.Bank_Statement,
            name: getStringDocumentType(DocumentType.Bank_Statement)
        },
        {
            value: DocumentType.Council_Tax_Bill,
            name: getStringDocumentType(DocumentType.Council_Tax_Bill)
        },
        {
            value: DocumentType.Utility_Bill,
            name: getStringDocumentType(DocumentType.Utility_Bill)
        },
        {
            value: DocumentType.Drivers_Licence,
            name: getStringDocumentType(DocumentType.Drivers_Licence)
        },
        {
            value: DocumentType.Visa,
            name: getStringDocumentType(DocumentType.Visa)
        },
        {
            value: DocumentType.Bank_Confirmation_Letter,
            name: getStringDocumentType(DocumentType.Bank_Confirmation_Letter)
        },
        {
            value: DocumentType.National_Identity_Card,
            name: getStringDocumentType(DocumentType.National_Identity_Card)
        },
        {
            value: DocumentType.Permanent_Residence_Document,
            name: getStringDocumentType(DocumentType.Permanent_Residence_Document)
        },
        {
            value: DocumentType.Biometric_Immigraton_Document,
            name: getStringDocumentType(DocumentType.Biometric_Immigraton_Document)
        },
        {
            value: DocumentType.Immigration_Control_Exemption,
            name: getStringDocumentType(DocumentType.Immigration_Control_Exemption)
        },
        {
            value: DocumentType.Immigration_Status_Document,
            name: getStringDocumentType(DocumentType.Immigration_Status_Document)
        },
        {
            value: DocumentType.Long_Birth_Certificate,
            name: getStringDocumentType(DocumentType.Long_Birth_Certificate)
        },
        {
            value: DocumentType.Short_Birth_Certificate,
            name: getStringDocumentType(DocumentType.Short_Birth_Certificate)
        },
        {
            value: DocumentType.Adoption_Certificate,
            name: getStringDocumentType(DocumentType.Adoption_Certificate)
        },
        {
            value: DocumentType.Certificate_of_registration,
            name: getStringDocumentType(DocumentType.Certificate_of_registration)
        },
        {
            value: DocumentType.Certificate_of_Naturalisation,
            name: getStringDocumentType(DocumentType.Certificate_of_Naturalisation)
        },
        {
            value: DocumentType.Residence_Card,
            name: getStringDocumentType(DocumentType.Residence_Card)
        },
        {
            value: DocumentType.Certificate_of_Application,
            name: getStringDocumentType(DocumentType.Certificate_of_Application)
        },
        {
            value: DocumentType.Application_Registration_Card,
            name: getStringDocumentType(DocumentType.Application_Registration_Card)
        },
        {
            value: DocumentType.Positive_Verification_Document,
            name: getStringDocumentType(DocumentType.Positive_Verification_Document)
        },
        {
            value: DocumentType.Selfie,
            name: getStringDocumentType(DocumentType.Selfie)
        },
    ];
}

export function getDocumentTypes(uploadSection) {

    switch(uploadSection) {
        case UploadSection.Proof_of_NI_or_PPS: return getProof_of_NI_or_PPS();
        case UploadSection.Proof_of_Identity: return getProof_of_Identity();
        case UploadSection.Proof_of_Address: return getProof_of_Address();
        case UploadSection.Visa_Documents: return getVisa_Documents();
        case UploadSection.Right_to_Work_Documents: return getRight_to_Work_Documents();
        case UploadSection.UK_Citizenship_Documents: return getRight_to_Work_Documents();
        case UploadSection.Proof_of_Account_Details: return getProof_of_Account_Details();
        case UploadSection.Contract_Employment: return getContract_Employment();
        case UploadSection.Aditional: return getAditional();
        case UploadSection.Selfie: return getSelfie();
    }
    
    return [];
}

export default DocumentType;