import React, {Component} from 'react';
import {Dialog, Divider, Slide} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import {Button, IconButton } from "@material-ui/core";
import strings from "../localization";
import {findDataValue} from "../util/DropdownUtil";
import DocumentType from '../constants/DocumentType';
import { Document, Page } from "react-pdf";
import UploadSection from '../constants/UploadSection';

class ViewRTWModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : {},
            proofOfIdentityHide: true,
            proofOfAddressHide: true,
            // selectedImage: this.props.uploadedFiles ? this.props.uploadedFiles[0].src : undefined,
            // selectedItem: this.props.uploadedFiles ? this.props.uploadedFiles[0] : undefined,
            // selectedContent: this.props.uploadedFiles ? this.props.uploadedFiles[0].name : undefined
        }

        this.changeClass = this.changeClass.bind(this);
    }

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="left" ref={ref} {...props} />;
    });

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.data !== this.props.data){
            this.setState({
                data: this.props.data
            });
        }
    }

    changeClass(item){
        if(item === 'proofOfAddress'){
            this.setState({
                proofOfAddressHide : !this.state.proofOfAddressHide
            })
        }else if(item === 'proofOfIdentity'){
            this.setState({
                proofOfIdentityHide : !this.state.proofOfIdentityHide
            })
        }
    }

    onDocumentLoadSuccess (event) {
    }

    renderImage(documentType){

        for(let item in this.props.uploadedFiles){

            let file = this.props.uploadedFiles[item];

            if(file.type === documentType){
                let content = "data:" + file.fileType + ";base64," + file.content;

                this.state.selectedContent = content;
                this.state.selectedItem = file;
            }
        }
    }

    render() {

        let proofOfIdentityClass = this.state.proofOfIdentityHide ? 'left hide' : 'left';
        let proofOfAddressClass = this.state.proofOfAddressHide ? 'left hide' : 'left';

        return (
            <Dialog
                onClose={this.props.toggleModal}
                aria-labelledby="customized-dialog-title"
                open={this.props.open}
                TransitionComponent={this.Transition}
                className={'view-rtw-modal'}
            >
                <div className='form-modal-header' onClose={this.props.toggleModal}>
                    <div>
                        <h1>{this.props.title}</h1>
                        <IconButton className={'close-button-wrapper'} onClick={this.props.toggleModal}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <Divider />
                    <div className={'view-rtw-wrapper'}>
                        <div className={'content'}>
                            <div className={'left-wrapper'}>
                                <div className={'left'}>
                                    <label>{strings.hrManager.firstName}</label>
                                    <div>{this.state.data.forename}</div>
                                </div>
                                <div className={'left'}>
                                    <label>{strings.hrManager.division}</label>
                                    <div>
                                        {
                                            findDataValue(this.state.data, 'division')
                                        }
                                    </div>
                                </div>
                                <div className={'left'}>
                                    <label>{strings.hrManager.niNumber}</label>
                                    <div>{this.state.data.niNoPpsNo}</div>
                                </div>
                                <div className={'left'}>
                                    <label>{strings.hrManager.proofOfIdentity}</label>
                                    <Button className={'view-btn'} variant="contained" color="primary" onClick={() => (this.changeClass('proofOfIdentity'))}>
                                        { strings.hrManager.view }
                                    </Button>
                                </div>
                                <div className={proofOfIdentityClass}>
                                    {
                                        this.renderImage(UploadSection.Proof_of_Identity)
                                    }
                                    {
                                        this.state.selectedItem &&
                                        (this.state.selectedItem.fileType == 'image/png' || this.state.selectedItem.fileType == 'image/jpg' ||
                                            this.state.selectedItem.fileType == 'image/jpeg') &&

                                        <img src={this.state.selectedContent } />
                                    }
                                    {
                                        this.state.selectedItem &&
                                        (this.state.selectedItem.fileType == 'application/pdf') &&
                                        this.state.selectedContent &&
                                        <Document
                                            file={this.state.selectedContent}
                                            onLoadSuccess={this.onDocumentLoadSuccess}
                                            onLoadError={(error) => alert('Error while loading document! ' + error.message)}
                                        >
                                            <Page pageNumber={1} />
                                        </Document>
                                    }
    
                                </div>
                                <div className={'left'}>
                                    <label>{strings.hrManager.documentType}</label>
                                    <div>{this.state.data.proofOfIdentity ? this.state.data.proofOfIdentity.type : ''}</div>
                                </div>
                                <div className={'left'}>
                                    <label>{strings.hrManager.proofOfAddress}</label>
                                    <Button className={'view-btn'} variant="contained" color="primary" onClick={() => (this.changeClass('proofOfAddress'))}>
                                        { strings.hrManager.view }
                                    </Button>
                                </div>
                                <div className={proofOfAddressClass}>
                                    {
                                        this.renderImage(UploadSection.Proof_of_Address)
                                    }
                                    {
                                        this.state.selectedItem &&
                                        (this.state.selectedItem.fileType == 'image/png' || this.state.selectedItem.fileType == 'image/jpg' ||
                                            this.state.selectedItem.fileType == 'image/jpeg') &&

                                        <img src={this.state.selectedContent } />
                                    }
                                    {
                                        this.state.selectedItem &&
                                        (this.state.selectedItem.fileType == 'application/pdf' || this.state.selectedItem.fileType == 'txt') &&
                                        this.state.selectedContent &&
                                        <Document
                                            file={this.state.selectedContent}
                                            onLoadSuccess={this.onDocumentLoadSuccess}
                                            onLoadError={(error) => alert('Error while loading document! ' + error.message)}
                                        >
                                            <Page pageNumber={1} />
                                        </Document>
                                    }
                                </div>
                                <div className={'left'}>
                                    <label>{strings.hrManager.documentType}</label>
                                    <div>{this.state.data.proofOfAddress ? this.state.data.proofOfAddress.type : ""}</div>
                                </div>
                            </div>
                            <div className={'right-wrapper'}>
                                <div className={'right'}>
                                    <label>{strings.hrManager.lastName}</label>
                                    <div>{this.state.data.surname}</div>
                                </div>
                                <div className={'right'}>
                                    <label>{strings.hrManager.region}</label>
                                    <div>
                                        {
                                            findDataValue(this.state.data, 'region')
                                        }
                                    </div>
                                </div>
                                <div className={'right'}>
                                    <label>{strings.hrManager.nationality}</label>
                                    <div>
                                        {
                                            findDataValue(this.state.data, 'nationality')
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'button'}>
                            <Button className={'done-btn'} variant="contained" color="primary" onClick={this.props.toggleModal}>
                                { strings.hrManager.done }
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default ViewRTWModal;