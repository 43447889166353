const ApplicationStatus = {
    NEW: 0,
    ON_HOLD: 1,
    WITHDRAWN: 2,
    UNSUITABLE: 3,
    ADD_INFO_REQUIRED: 4,
    CONDITIONAL_OFFER: 5,
    AWAITING_HR: 6,
    APPROVED: 7,
    REJECTED: 8,
    ADD_INFO_PROVIDED: 9
};

const ApplicationStatusStrings = {
    0: 'New',
    1: 'On hold',
    2: 'Withdrawn',
    3: 'Unsuitable',
    4: 'Add info required',
    5: 'Conditional offer',
    6: 'Awaiting hr',
    7: 'Approved',
    8: 'Rejected',
    9: 'Additional info provided'
}

export default ApplicationStatus;

export function getApplicationStatusString(status) 
{
    return ApplicationStatusStrings[status];
}

export function getApplicationStatuses() {
    const statuses = [
        {
            value: ApplicationStatus.ON_HOLD,
            name: 'On hold'
        },
        {
            value: ApplicationStatus.WITHDRAWN,
            name: 'Withdrawn'
        },
        {
            value: ApplicationStatus.UNSUITABLE,
            name: 'Unsuitable'
        },
        {
            value: ApplicationStatus.ADD_INFO_REQUIRED,
            name: 'Add info required'
        },
        {
            value: ApplicationStatus.ADD_INFO_PROVIDED,
            name: 'Add info provided'
        },
        {
            value: ApplicationStatus.CONDITIONAL_OFFER,
            name: 'Conditional offer'
        },
        {
            value: ApplicationStatus.AWAITING_HR,
            name: 'Awaiting hr'
        },
        {
            value: ApplicationStatus.APPROVED,
            name: 'Approved'
        },
        {
            value: ApplicationStatus.REJECTED,
            name: 'Rejected'
        }
    ];
    
    return statuses;
}

export function getApplicationStatusesWithoutConditionalOffer() {
    const statuses = [
        {
            value: ApplicationStatus.ON_HOLD,
            name: 'On hold'
        },
        {
            value: ApplicationStatus.WITHDRAWN,
            name: 'Withdrawn'
        },
        {
            value: ApplicationStatus.UNSUITABLE,
            name: 'Unsuitable'
        },
        {
            value: ApplicationStatus.ADD_INFO_REQUIRED,
            name: 'Add info required'
        },
        {
            value: ApplicationStatus.ADD_INFO_PROVIDED,
            name: 'Add info provided',
            disabled: true
        },
        
    ];
    
    return statuses;
}

export function getApplicationStatusesWithConditionalOffer() {
    const statuses = [
        {
            value: ApplicationStatus.ON_HOLD,
            name: 'On hold'
        },
        {
            value: ApplicationStatus.WITHDRAWN,
            name: 'Withdrawn'
        },
        {
            value: ApplicationStatus.UNSUITABLE,
            name: 'Unsuitable'
        },
        {
            value: ApplicationStatus.ADD_INFO_REQUIRED,
            name: 'Add info required'
        },
        {
            value: ApplicationStatus.ADD_INFO_PROVIDED,
            name: 'Add info provided',
            disabled: true
        },
        {
            value: ApplicationStatus.CONDITIONAL_OFFER,
            name: 'Conditional offer'
        },
    ];
    
    return statuses;
}


export function getApplicationStatusesHR(rtwApproved = true) {
    const statuses = [
        {
            value: ApplicationStatus.ON_HOLD,
            name: 'On hold'
        },
        {
            value: ApplicationStatus.AWAITING_HR,
            name: 'Awaiting HR'
        },
        {
            value: ApplicationStatus.APPROVED,
            name: 'Approved',
            disabled: !rtwApproved
        }
        ,
        {
            value: ApplicationStatus.REJECTED,
            name: 'Rejected'
        }
    ];
    
    return statuses;
}