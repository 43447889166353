import React from 'react';
import strings from "../../../localization";
import {getError, hasError} from "../../../functions/Validation";
import { Divider, TextField} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import SelectControl from "../../controls/SelectControl";

const AddressDetailsForm = ({
                      onSubmit,
                      onCancel,
                      onChange,
                      errors,
                      data,
                      disable,
                      countries
                  }) => {
return(
    <form id='address-details-form'>
        <Grid container spacing={9} className={'grid-wrapper'}>
            <Grid item xl={4} lg={5} md={12}>
                <div className={ 'select-container' + (hasError(errors, 'postcode') ? ' error' : '') }>
                    <label>{ strings.addressDetails.postcode }</label>
                    <TextField
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={ hasError(errors, 'postcode') }
                        helperText={ getError(errors, 'postcode') }
                        fullWidth
                        name='postcode'
                        onChange={ onChange }
                        margin="normal"
                        value={ data.postcode }
                        margin="dense"
                        variant='outlined'
                        disabled={ disable }
                    />
                </div>
                <div className={ 'select-container' + (hasError(errors, 'firstLineAddress') ? ' error' : '') }>
                    <label>{ strings.addressDetails.firstLineAddress }</label>
                    <TextField
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={ hasError(errors, 'firstLineAddress') }
                        helperText={ getError(errors, 'firstLineAddress') }
                        fullWidth
                        name='firstLineAddress'
                        onChange={ onChange }
                        margin="normal"
                        value={ data.firstLineAddress }
                        margin="dense"
                        variant='outlined'
                        disabled={ disable }
                    />
                </div>
                <div className={ 'select-container' + (hasError(errors, 'secondLineAddress') ? ' error' : '') }>
                    <label>{ strings.addressDetails.secondLineAddress }</label>
                    <TextField
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={ hasError(errors, 'secondLineAddress') }
                        helperText={ getError(errors, 'secondLineAddress') }
                        fullWidth
                        name='secondLineAddress'
                        onChange={ onChange }
                        margin="normal"
                        value={ data.secondLineAddress }
                        margin="dense"
                        variant='outlined'
                        disabled={ disable }
                    />
                </div>
                <div className={ 'select-container' + (hasError(errors, 'townCity') ? ' error' : '') }>
                    <label>{ strings.addressDetails.townCity }</label>
                    <TextField
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={ hasError(errors, 'townCity') }
                        helperText={ getError(errors, 'townCity') }
                        fullWidth
                        name='townCity'
                        onChange={ onChange }
                        margin="normal"
                        value={ data.townCity }
                        margin="dense"
                        variant='outlined'
                        disabled={ disable }
                    />
                </div>
            </Grid>

            <Grid item xl={4} lg={5} md={12}>
                <div className={ 'select-container' + (hasError(errors, 'country') ? ' error' : '') }>
                    <label>{ strings.personalDetails.country }</label>
                    <SelectControl
                        error={ hasError(errors, 'country') }
                        helperText={ getError(errors, 'country') }
                        fullWidth
                        autoFocus
                        name='country'
                        onChange={ onChange }
                        margin="normal"
                        value={ data.country }
                        options={ countries }
                        nameKey={ 'name' }
                        valueKey={ 'id' }
                        selected={ data.country }
                        isDisabled={ disable }
                    />
                    {
                        hasError(errors, 'country') ? <p className='select-error'>{strings.validation.RequiredErrorMessage}</p> : ""
                    }
                </div>

            </Grid>
        </Grid>
        <Divider />
    </form>
)};

export default AddressDetailsForm;