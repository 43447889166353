import React, {createRef} from 'react'
import CONFIG from '../../config'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import strings from '../../localization'
import Page from "../../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {Button, Dialog, Divider, IconButton, LinearProgress, Slide} from "@material-ui/core";
import Dropzone from "react-dropzone";
import {withSnackbar} from "notistack";
import PersonalDetailsForm from "../../components/forms/user/PersonalDetailsForm";
import AddressDetailsForm from "../../components/forms/user/AddressDetailsForm";
import EmergencyContactForm from "../../components/forms/user/EmergencyContactForm";
import JobDetailsForm from "../../components/forms/user/JobDetailsForm";
import UKNationalsForm from "../../components/forms/user/UKNationalsForm";
import RightToWorkForm from "../../components/forms/user/RightToWorkForm";
import GDRPDisclaimerForm from '../../components/forms/user/GDPRDisclaimerForm';
import FileUploadModal from '../../common/FileUploadModal';
import {getCodebooks} from '../../services/CodebookService';
import {getApplication, editApplication, updateRTWStatus, updateRTWProcess} from '../../services/AddCandidateService';
import {deleteFile, getFiles, uploadFiles, uploadFilesRightToWork} from "../../services/FileUpload";
import Validators from '../../constants/ValidatorTypes';
import ConfirmMode, {getConfirmModesString} from "../../constants/ConfirmMode";
import SidebarLinks, {getSidebarLinks, getSidebarLinksString} from "../../constants/SidebarLinks";
import CloseIcon from '@material-ui/icons/Close';
import {withdrawApplication} from "../../services/ApplicationService";

import MessageModal from "../../common/MessageModal";
import ApplicationMode from "../../constants/ApplicationMode";
import ApplicationInternalStatus from '../../constants/ApplicationInternalStatus';
import {findDataValue} from "../../util/DropdownUtil";
import Territories, {getTerritoriesString} from "../../constants/TerritoriesOfWork";
import UserType from '../../constants/UserType';
import {checkConditionsForValidation, checkNationalityIDVTVerification} from "../../util/FieldsConditionUtil";
import UploadSection from '../../constants/UploadSection';
import BaseConfirmDialog from '../../common/BaseConfirmDialog';
import RTWProcess from "../../constants/RTWProcess";
import ApplicationStatusRTW from "../../constants/ApplicationStatusRTW";
import ApplicationFileStatus from "../../constants/ApplicationFileStatus";
import {dateToString} from "../../util/DateUtil";
import {checkApplicantsRightToWork} from "../../services/WebScrapingService";
import {createYotiSession, yotiSessionDone} from "../../services/YotiService";
import {getSystemSettings} from "../../services/SystemSettingsService";
import YotiValidationModal from '../../common/YotiValidationModal';
import  { useState, useEffect } from 'react';
class Application extends Page {

    validationList = {}

    clearIndicators = {
        personalDetails: false,
        addressDetails: false,
        emergencyContact: false,
        jobDetails: false,
        ukNationals: false,
        rightToWork: false,
        gdprDisclaimer: false,
        immigrationControl: false,
        submitApplication: false,
        personalDetailsContract: false,
        bankDetails: false,
        employment: false,
        uniform: false,
        medicalDeclaration: false,
        workingTimeElection: false,
        changesInInformation: false,
        termsAndConditions: false,
        digitalSignature: false
    }

    submitValidationList = {
        title: [{type: Validators.SELECTED_OPTION}],
        forename: [{type: Validators.REQUIRED}],
        surname: [{type: Validators.REQUIRED}],
        email: [{type: Validators.EMAIL}],
        gender: [{type: Validators.REQUIRED}],
        dateOfBirth: [{type: Validators.REQUIRED}],
        postcode: [{type: Validators.REQUIRED}],
        country: [{type: Validators.SELECTED_OPTION}],
        firstLineAddress: [{type: Validators.REQUIRED}],
        firstPhoneContact: [{type: Validators.REQUIRED}],
        townCity: [{type: Validators.REQUIRED}],

        emergencyFullName: [{type: Validators.REQUIRED}],
        emergencyRelationshipToYou: [{type: Validators.REQUIRED}],
        emergencyFirstPhoneContact: [{type: Validators.REQUIRED}],

        division: [{type: Validators.SELECTED_OPTION}],
        region: [{type: Validators.SELECTED_OPTION}],
        sessionType: [{type: Validators.SELECTED_OPTION}],
        recruiter: [{type: Validators.SELECTED_OPTION}],
        sessionLocation: [{type: Validators.REQUIRED}],
        travelPreference:[{type:Validators.SELECTED_OPTION}],
        minibus:[{type:Validators.SELECTED_OPTION}],
        pickupLocation:[{type:Validators.SELECTED_OPTION}],

        territoryOfWork: [{type: Validators.SELECTED_OPTION}],
        nationality: [{type: Validators.SELECTED_OPTION}],
        niNoPpsNo: [{type: Validators.REQUIRED}]
    };

    drivingLicenceValidation = {};

    ukShareCodeValidation = {
        ukShareCode: [{type: Validators.REQUIRED}],
    };

    irExpireDateValidation = {
        irExpireDate: [{type: Validators.REQUIRED}],
    };

    saveValidationList = {
        email: [{type: Validators.EMAIL}]
    };

    refsList = getSidebarLinks();

    shareCodeFormat() {
        return new RegExp('^[A-Z0-9]{3}-[A-Z0-9]{3}-[A-Z0-9]{3}$');
    }


    constructor(props) {
        super(props);

        this.state = {
            data: {},
            openGDPRModal: false,
            openYotiValidationModal: false,
            openYotiUnableValidationModal: false,
            applicationId: this.props.location.state && this.props.location.state.applicationId ? this.props.location.state.applicationId : -1,
            errors: {},
            openFileUpload: false,
            openMessageModal: false,
            titles: [],
            genders: [],
            divisions: [],
            regions: [],
            recruiters: [],
            userDivisions: [],
            relationshipTypes: [],
            jobRoles: [],
            travelPreferences: [],
            territoryOfWorks: [],
            nationalities: [],
            sessionTypes: [],
            uploadedFile: [],
            changeIndicator: {},
            changeLinkIndicator: {},
            fileUploadModal: undefined,
            files: [],
            idvt: [],
            disableField: false,
            showUploadProgress: false,
            userTimeZone: '',
            uploadFileError: '',
            showProgressBar: false,
            showRefreshButton: false,
            showWithdrawConfirmModal: false,
            returnUrl: this.props.location.state && this.props.location.state.returnUrl ? this.props.location.state.returnUrl : null
        };

        this.props.changeFullScreen(false);
        this.dropzoneRef = createRef();
        this.messageModalRef = createRef();
        this.submit = this.submit.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.onDropRejected = this.onDropRejected.bind(this);
        this.toggleFileUploadModal = this.toggleFileUploadModal.bind(this);
        this.toggleMessageModal = this.toggleMessageModal.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.toggleGDPRModal = this.toggleGDPRModal.bind(this);
        this.openGDPRModal = this.openGDPRModal.bind(this);
        this.sumbitGDPR = this.sumbitGDPR.bind(this);

        this.openYotiValidationModal = this.openYotiValidationModal.bind(this);
        this.toggleYotiValidationModal = this.toggleYotiValidationModal.bind(this);
        this.openYotiUnableValidationModal = this.openYotiUnableValidationModal.bind(this);
        this.toggleYotiUnableValidationModal = this.toggleYotiUnableValidationModal.bind(this);
        this.sumbitYotiValidation = this.sumbitYotiValidation.bind(this);
        this.cancelYotiValidation = this.cancelYotiValidation.bind(this);
        this.sumbitYotiUnableValidation = this.sumbitYotiUnableValidation.bind(this);
        this.cancelYotiUnableValidation = this.cancelYotiUnableValidation.bind(this);
        this.changeDataDivision = this.changeDataDivision.bind(this);
        this.updateApplicationWithSessionData = this.updateApplicationWithSessionData.bind(this);
        //Withdraw Changes
        this.closeWithdrawConfirmModal = this.closeWithdrawConfirmModal.bind(this);
        this.applicationWithdrawal = this.applicationWithdrawal.bind(this);
        this.confirmWithdraw = this.confirmWithdraw.bind(this);

        this.headerRef = createRef();
        this.personalDetailsRef = createRef();
        this.addressDetailsRef = createRef();
        this.emergencyContactRef = createRef();
        this.jobDetailsRef = createRef();
        this.ukNationalsRef = createRef();
        this.immigrationControlRef = createRef();
        this.gdprDisclaimerRef = createRef();
        this.submitApplicationRef = createRef();
        this.GDPRModal = createRef();
        this.YotiValidationModal = createRef();
    }

    // LIFE CICLE METHODS

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.changeIndicator(prevProps, prevState);

        if (prevProps.siteData.errors !== this.props.siteData.errors) {
            this.setState({
                errors: this.props.siteData.errors
            })
        }

        if (this.state.dataHasChanged) {
            this.props.getData(this.state.data);
            this.state.dataHasChanged = false;
        }

        if (prevProps.siteData.data !== this.state.data) {
            this.props.getData(this.state.data);
        }

        if (prevState.data !== this.state.data) {
            if(this.state.data.ukCitizen && !checkNationalityIDVTVerification(findDataValue(this.state.data, 'nationality'))) {
                this.state.data.ukCitizen = false;
                this.state.data.validPassport = false;
            }

            if (this.state.data.ukShareCode && !checkNationalityIDVTVerification(findDataValue(this.state.data, 'nationality'))) {
                if (!this.shareCodeFormat().test(this.state.data.ukShareCode) && this.state.data.ukShareCode.toLowerCase() !== 'manual-sc') {
                    let error = {'ukShareCode': [{message: 'Share Code is not in the right format. Example format: 9XY-6GY-2S2'}]}
                    this.setState({
                        errors: error
                    });
                    return;
                }
                this.setState({
                    errors: {}
                });
            }
        }

        //ADMIN ENABLE IDVT OR USER NOT REJECTED YOTI PROCESS     
        if (this.state.idvt || (this.state.data.yotiProcessState !== "COMPLETED" && this.state.data.rtwStatus === ApplicationStatusRTW.IDVT_REJECTED) || this.state.data.yotiProcessState === "NOTCOMPLETED") {
            this.state.data.validPassport = false;
        }
    }


    componentWillReceiveProps(props) {
        if (this.state.applicationId == -1 && props.siteData.applicationId != -1) {
            this.state.applicationId = props.siteData.applicationId;
            this.fetchData();
        }

        if (!props.applicationReducers.doUpdate) {
            return;
        }

        this.submit(ApplicationMode.SAVE);

        props.doUpdate(false);
    }

    componentDidMount() {
        this.fetchData();
        this.yotiVerificationListener();

        this.state.changeIndicator = this.props.siteData.changeIndicator;
        window.addEventListener('scroll', this.handleScroll);

        this.props.changeIndicator(this.clearIndicators);
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Update the state with the user's time zone
    this.setState({ userTimeZone }, () => {
        console.log('State after update:', this.state);
      });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    changeDataDivision(event) {
        let data = this.state.data;

        if (data.division != event.target.value) {
            data.recruiter = null;
            data.jobRole = null;
            data.jobRoleText = '';
        }

        data.division = event.target.value;

        this.setState({
            data: data
        });
    }

    // INDICATORS

    changeIndicator(prevProps) {

        if (prevProps.siteData.changeIndicator !== undefined) {

            if (prevProps.siteData.changeIndicator.personalDetails === false) {
                if (
                    (this.state.data.title !== undefined && this.state.data.title !== null)
                    && (this.state.data.forename !== undefined && this.state.data.forename !== null)
                    && (this.state.data.surname !== undefined && this.state.data.surname !== null)
                    && (this.state.data.email !== undefined && this.state.data.email !== null)
                    && (this.state.data.gender !== undefined && this.state.data.gender !== null)
                ) {
                    let changeIndicator = this.props.siteData.changeIndicator;
                    changeIndicator.personalDetails = true;
                    this.props.changeIndicator(changeIndicator);
                }
            }

            if (prevProps.siteData.changeIndicator.addressDetails === false) {

                if (
                    (this.state.data.postcode !== undefined && this.state.data.postcode !== null)
                    && (this.state.data.firstLineAddress !== undefined && this.state.data.firstLineAddress !== null)
                    && (this.state.data.townCity !== undefined && this.state.data.townCity !== null)
                    && (this.state.data.firstPhoneContact !== undefined && this.state.data.firstPhoneContact !== null)
                    && (this.state.data.country !== undefined && this.state.data.country !== null)
                ) {
                    let changeIndicator = this.props.siteData.changeIndicator;
                    changeIndicator.addressDetails = true;
                    this.props.changeIndicator(changeIndicator);
                }
            }

            if (prevProps.siteData.changeIndicator.emergencyContact === false) {
                if (
                    (this.state.data.emergencyFullName !== undefined && this.state.data.emergencyFullName !== null)
                    && (this.state.data.emergencyFirstPhoneContact !== undefined && this.state.data.emergencyFirstPhoneContact !== null)
                    && (this.state.data.emergencyRelationshipToYou !== undefined && this.state.data.emergencyRelationshipToYou !== null)
                ) {
                    let changeIndicator = this.props.siteData.changeIndicator;
                    changeIndicator.emergencyContact = true;
                    this.props.changeIndicator(changeIndicator);
                }
            }

            if (prevProps.siteData.changeIndicator.jobDetails === false) {
                if (
                    (this.state.data.division !== undefined && this.state.data.division !== null)
                    && (this.state.data.region !== undefined && this.state.data.region !== null)
                    && (this.state.data.recruiter !== undefined && this.state.data.recruiter !== null)
                    && (this.state.data.sessionType !== undefined && this.state.data.sessionType !== null)
                    && (this.state.data.sessionLocation !== undefined && this.state.data.sessionLocation !== null)
                    // && (this.state.data.placeOfWork !== undefined && this.state.data.placeOfWork !== null)
                     && (this.state.data.travelPreference !== undefined && this.state.data.travelPreference !== null)
                    // && (this.state.data.minibusName !== undefined && this.state.data.minibusName !== null)
                    // && (this.state.data.pickupPointOne !== undefined && this.state.data.pickupPointOne !== null)
                    // && (this.state.data.pickupPointTwo !== undefined && this.state.data.pickupPointTwo !== null)
                ) {
                    let changeIndicator = this.props.siteData.changeIndicator;
                    changeIndicator.jobDetails = true;
                    this.props.changeIndicator(changeIndicator);
                }
            }
            if (prevProps.siteData.changeIndicator.ukNationals === false) {
                if (
                    this.state.data.territoryOfWork !== null
                    && this.state.data.niNoPpsNo !== null
                   // && this.getFilesForType(UploadSection.Proof_of_Identity).length > 0
                    && this.state.data.nationality !== null
                    && this.state.data.fullUKorRoIDrivingLicence !== null
                   // && this.getFilesForType(UploadSection.Proof_of_Address).length > 0
                    && this.getFilesForType(UploadSection.Proof_of_NI_or_PPS).length > 0
                ) {
                    let changeIndicator = this.props.siteData.changeIndicator;
                    changeIndicator.ukNationals = true;
                    this.props.changeIndicator(changeIndicator);
                }
            }

            if (prevProps.siteData.changeIndicator.rightToWork === false) {
                if (
                    (
                        this.state.data.ukShareCode !== null
                        && this.getFilesForType(UploadSection.Visa_Documents).length > 0
                        && this.getFilesForType(UploadSection.UK_Citizenship_Documents).length > 0
                    )
                    ||
                    (
                        this.getFilesForType(UploadSection.Right_to_Work_Documents).length > 0
                        && this.state.data.irExpireDate !== null
                    )
                ) {
                    let changeIndicator = this.props.siteData.changeIndicator;
                    changeIndicator.rightToWork = true;
                    this.props.changeIndicator(changeIndicator);
                }
            }

            if (prevProps.siteData.changeIndicator.gdprDisclaimer === false) {
                if (this.state.data.gdprDisclaimerAnswer) {
                    let changeIndicator = this.props.siteData.changeIndicator;
                    changeIndicator.gdprDisclaimer = true;
                    this.props.changeIndicator(changeIndicator);
                }
            }

            if (prevProps.siteData.changeIndicator.immigrationControl === false) {
                if (this.state.data.immigrationControlAnswer !== null) {
                    if (this.state.data.immigrationControlAnswer === getConfirmModesString(ConfirmMode.YES)) {
                        if (this.state.data.immigrationControlDate !== null) {
                            let changeIndicator = this.props.siteData.changeIndicator;
                            changeIndicator.immigrationControl = true;
                            this.props.changeIndicator(changeIndicator);
                        }
                    } else {
                        let changeIndicator = this.props.siteData.changeIndicator;
                        changeIndicator.immigrationControl = true;
                        this.props.changeIndicator(changeIndicator);
                    }
                }
            }

            if (prevProps.siteData.changeIndicator.submitApplication === false) {
                if (this.state.disableField) {
                    let changeIndicator = this.props.siteData.changeIndicator;
                    changeIndicator.submitApplication = true;
                    this.props.changeIndicator(changeIndicator);
                }
            }
        }
    }

    changeLinkIndicator(name, state) {

        let changeLinkIndicator = this.props.siteData.linkIndicator;

        for (let item in changeLinkIndicator) {
            changeLinkIndicator[item] = false;
        }

        changeLinkIndicator[name] = state;

        this.props.changeLinkIndicator(changeLinkIndicator);
    }

    getDimensions(ele) {
        const height = ele ? ele.getBoundingClientRect() : 0;
        const offsetTop = ele ? ele.offsetTop : 0;
        const offsetBottom = offsetTop + height;

        return {
            height,
            offsetTop,
            offsetBottom
        };
    }

    handleScroll() {

        for (let ref of this.refsList) {

            let ele;

            switch (ref.value) {
                case SidebarLinks.HEADER:
                    ele = this.headerRef.current !== null ? this.getDimensions(this.headerRef.current) : '';

                    if (window.pageYOffset + 100 <= ele.offsetTop) {
                        this.changeLinkIndicator(getSidebarLinksString(SidebarLinks.PERSONAL_DETAILS), true);
                    }
                    break;
                case SidebarLinks.PERSONAL_DETAILS:
                    ele = this.personalDetailsRef.current !== null ? this.getDimensions(this.personalDetailsRef.current) : '';

                    if (window.pageYOffset + 110 > ele.offsetTop) {
                        this.changeLinkIndicator(getSidebarLinksString(SidebarLinks.PERSONAL_DETAILS), true);
                    }
                    break;
                case SidebarLinks.ADDRESS_DETAILS:
                    ele = this.addressDetailsRef.current !== null ? this.getDimensions(this.addressDetailsRef.current) : '';

                    if (window.pageYOffset + 110 > ele.offsetTop) {
                        this.changeLinkIndicator(getSidebarLinksString(SidebarLinks.ADDRESS_DETAILS), true);
                    }
                    break;
                case SidebarLinks.EMERGENCY_CONTACT:
                    ele = this.emergencyContactRef.current !== null ? this.getDimensions(this.emergencyContactRef.current) : '';

                    if (window.pageYOffset + 110 > ele.offsetTop) {
                        this.changeLinkIndicator(getSidebarLinksString(SidebarLinks.EMERGENCY_CONTACT), true);
                    }
                    break;
                case SidebarLinks.JOB_DETAILS:
                    ele = this.jobDetailsRef.current !== null ? this.getDimensions(this.jobDetailsRef.current) : '';

                    if (window.pageYOffset + 110 > ele.offsetTop) {
                        this.changeLinkIndicator(getSidebarLinksString(SidebarLinks.JOB_DETAILS), true);
                    }
                    break;
                case SidebarLinks.UK_NATIONALS:
                    ele = this.ukNationalsRef.current !== null ? this.getDimensions(this.ukNationalsRef.current) : "";

                    if (window.pageYOffset + 110 > ele.offsetTop) {
                        this.changeLinkIndicator(getSidebarLinksString(SidebarLinks.UK_NATIONALS), true);
                    }
                    break;
                case SidebarLinks.IMMIGRATION_CONTROL:
                    ele = this.immigrationControlRef.current !== null ? this.getDimensions(this.immigrationControlRef.current) : '';

                    if (window.pageYOffset + 110 > ele.offsetTop) {
                        this.changeLinkIndicator(getSidebarLinksString(SidebarLinks.IMMIGRATION_CONTROL), true);
                    }
                    break;
                case SidebarLinks.GDPR_DISCLAIMER:
                    ele = this.gdprDisclaimerRef.current !== null ? this.getDimensions(this.gdprDisclaimerRef.current) : '';

                    if (window.pageYOffset + 110 > ele.offsetTop) {
                        this.changeLinkIndicator(getSidebarLinksString(SidebarLinks.GDPR_DISCLAIMER), true);
                    }
                    break;
                case SidebarLinks.SUBMIT_APPLICATION:
                    ele = this.submitApplicationRef.current !== null ? this.getDimensions(this.submitApplicationRef.current) : '';

                    if (window.pageYOffset + 110 > ele.offsetTop) {
                        this.changeLinkIndicator(getSidebarLinksString(SidebarLinks.SUBMIT_APPLICATION), true);
                    }
                    break;
                default:
            }
        }
    }

    // FETCH

    fetchData() {
        getCodebooks().then(response => {
            if (!response.ok) {
                return;
            }

            this.setState({
                titles: response.data.titles,
                countries: response.data.countries,
                genders: response.data.genders,
                divisions: response.data.divisions,
                regions: response.data.regions,
                recruiters: response.data.recruiters,
                userDivisions: response.data.userDivisions,
                relationshipTypes: response.data.relationshipTypes,
                jobRoles: response.data.jobRoles,
                travelPreferences: response.data.travelPreferances,
                nationalities: response.data.nationalities,
                idDocumentTypes: response.data.idDocumentTypes,
                proofAddressDocumentTypes: response.data.proofAddressDocumentTypes,
                sessionTypes: response.data.sessionTypes,
                territoryOfWorks: response.data.territoryOfWorks,
                placeOfWorks: response.data.placeOfWorks,
                minibuses: response.data.minibuses,
                pickupLocations: response.data.pickupLocations,
            });

            getApplication(this.state.applicationId).then(response => {

                if (!response || !response.ok) {
                    return
                }

                let application = response.data;

                this.props.setApplicationId(application.id);

                let hasContract = application.applicationInternalStatus == ApplicationInternalStatus.CONDITIONAL_OFFER_SENT ||
                    application.applicationInternalStatus == ApplicationInternalStatus.CONTRACT_FILLED ||
                    application.applicationInternalStatus == ApplicationInternalStatus.CONFIRMED;

                if (this.props.user.userType == UserType.HR_MANAGER ||
                    this.props.user.userType == UserType.RECRUITMENT_MANAGER) {
                    hasContract = true;
                }

                this.props.setHasContract(hasContract);

                if (response.data.email) {
                    application.email = response.data.email;
                } else {
                    application.email = this.props.user ? this.props.user.email : '';
                }

                let disabledField = this.props.user.userType != UserType.APPLICANT ? false :
                    application.applicationInternalStatus != ApplicationInternalStatus.APPLICANT_FILING_DATA;

                this.props.allowUpdate(!disabledField);

                this.setState({
                    application: response.data,
                    disableField: disabledField
                });

                getFiles(application.id).then(response => {

                    this.setState({
                        files: response.data
                    })
                });
            }).then(response => {
                this.transformData(this.state.application);
            });

            getSystemSettings().then(response => {
                if(response && response.data) {
                    this.setState({
                        idvt: response.data.idvt
                    });
                }
            });
        });
    }

    transformData(application) {

        if (!this.state.application) {
            return;
        }

        for (let item in application) {
            if (item === 'dateOfBirth') {
                this.state.application[item] = application[item] ? new Date(application[item]) : null;
            }

            if (item === 'immigrationControlDate') {
                this.state.application[item] = application[item] ? new Date(application[item]) : null;
            }

            if (item === 'irExpireDate') {
                this.state.application[item] = application[item] ? new Date(application[item]) : null;
            }

            if (item === 'immigrationControlAnswer') {
                if (this.state.application[item] === false) {
                    this.state.application['immigrationControlDate'] = new Date();
                }
            }
        }

        this.state.data = this.state.application ? this.state.application : {};
        this.state.data.jobRole = this.state.application.jobRoleText ? this.state.application.jobRoleText : this.state.application.jobRole;

        if (!this.state.data.forename) {
            this.state.data.forename = this.props.user.firstName;
        }

        if (!this.state.data.surname) {
            this.state.data.surname = this.props.user.lastName;
        }

        if (!this.state.data.territoryOfWork) {
            this.state.data.territoryOfWork = this.state.territoryOfWorks[0];
        }

        let disabledField = this.props.user.userType != UserType.APPLICANT ? false :
            application.applicationInternalStatus != ApplicationInternalStatus.APPLICANT_FILING_DATA;

        this.setState({
            disableField: disabledField
        });
    }

    // MODAL SECTION

    toggleGDPRModal() {
        this.setState({
            openGDPRModal: !this.state.openGDPRModal
        });
    }

    openGDPRModal() {
        if (this.GDPRModal.current) {
            this.GDPRModal.current.open();
        }
    }

    renderGDPRModal() {

        return <BaseConfirmDialog
            close={this.toggleGDPRModal}
            no={this.toggleGDPRModal}
            yes={this.sumbitGDPR}
            open={this.state.openGDPRModal}
            openDialog={this.openGDPRModal}
            title={strings.application.gdprModalTitle}
            message={strings.application.gdprMessage}

        />
    }

    toggleYotiUnableValidationModal() {
        this.setState({
            openYotiUnableValidationModal: !this.state.openYotiUnableValidationModal
        });
    }

    openYotiUnableValidationModal() {
        if (this.YotiUnableValidationModal.current) {
            this.YotiUnableValidationModal.current.open();
        }
    }

    toggleYotiValidationModal() {
        this.setState({
            openYotiValidationModal: !this.state.openYotiValidationModal
        });
    }

    openYotiValidationModal() {
        if (this.YotiValidationModal.current) {
            this.YotiValidationModal.current.open();
        }
    }

    renderYotiValidationModal() {
        return <YotiValidationModal
            close={this.toggleYotiValidationModal}
            no={this.cancelYotiValidation}
            yes={this.sumbitYotiValidation}
            open={this.state.openYotiValidationModal}
            openDialog={this.openYotiValidationModal}
            yotiValidationData={this.state.data.yotiValidationData}
            title={strings.application.yotiValidation.title}
            message={strings.application.yotiValidation.message}
            yesButtonMessage={strings.application.yotiValidation.allowOverride}
            noButtonMessage={strings.application.yotiValidation.doNotOverride}
            className='yoti-validation-modal'
        />
    }

    renderYotiUnableValidationModal() {
        return <YotiValidationModal
            close={this.toggleYotiUnableValidationModal}
            no={this.cancelYotiUnableValidation}
            yes={this.sumbitYotiUnableValidation}
            open={this.state.openYotiUnableValidationModal}
            openDialog={this.openYotiUnableValidationModal}
            title={strings.application.yotiUnableValidation.title}
            message={strings.application.yotiUnableValidation.message}
            yesButtonMessage={strings.application.yotiUnableValidation.returnToManualProcess}
            noButtonMessage={strings.application.yotiUnableValidation.close}
            className='yoti-validation-modal'
        />
    }

    toggleFileUploadModal(fileUploadModal) {
        this.setState({
            openFileUpload: !this.state.openFileUpload,
            fileUploadModal: fileUploadModal,
            uploadFileError: ''
        });
    }

    toggleMessageModal() {
        this.setState({
            openMessageModal: !this.state.openMessageModal
        });
    }

    openDialog() {
        if (this.dropzoneRef.current) {
            this.dropzoneRef.current.open();
        }
    };

    openMessageDialog() {
        if (this.messageModalRef.current) {
            this.messageModalRef.current.open();
        }
    };

    viewMessage() {
        return (
            <MessageModal
                toggleModal={this.toggleMessageModal}
                open={this.state.openMessageModal}
                title={strings.candidateProfiles.thankYou}
                message={this.state.data.gdprDisclaimerAnswer ? strings.candidateProfiles.message : strings.candidateProfiles.GDPRNo}
                openDialog={this.openMessageDialog}
            />
        );
    }

    // FILES SECTION

    onDropRejected(files) {

        if (files.length == 0) {
            return;
        }

        if (files[0].errors[0].code == 'file-invalid-type') {
            this.setState({
                uploadFileError: strings.uploadFileErrorFileType
            });
        } else {
            this.setState({
                uploadFileError: strings.uploadFileError
            });
        }
    }

    onDrop(acceptedFiles) {

        if (!acceptedFiles || acceptedFiles.length == 0) {

            this.setState({
                uploadFileError: strings.uploadFileError
            });

            return;
        }

        this.setState({
            uploadFileError: '',
            showUploadProgress: true
        });

        for (let acceptedFile of acceptedFiles) {

            let applicationFile = new FormData();
            applicationFile.append('application', this.state.application.id);
            applicationFile.append('name', acceptedFile.name);
            applicationFile.append('content', acceptedFile);
            applicationFile.append('fileType', acceptedFile.type);
            applicationFile.append('type', this.state.fileUploadModal);
            applicationFile.append('applicationFileStatus', ApplicationFileStatus.ON_HOLD);

            if(this.state.fileUploadModal === 10) {
                applicationFile.append('documentType', 27);
            }

            uploadFiles(applicationFile).then((result) => {

                if (!result || !result.ok) {
                    this.props.enqueueSnackbar("Error uploading file", {variant: 'error'});
                }

                let errors = this.state.errors;

                if(result.data.type === UploadSection.Proof_of_Address) {
                    errors['proofOfAddressDoc'] = [];
      
                }

                if(result.data.type === UploadSection.Selfie) {
                    errors['selfies'] = [];
                }

                if(result.data.type === UploadSection.Proof_of_Identity) {
                    errors['proofOfIdFiles'] = [];
                }

                if(result.data.type === UploadSection.UK_Citizenship_Documents) {
                    errors['rtwSupportingDocs'] = [];
                }

                if(result.data.type === UploadSection.Proof_of_NI_or_PPS) {
                    errors['proofOfNiDoc'] = [];
                }
                this.setState({
                    errors: errors
                });

                let files = this.state.files;

                files.push(result.data);

                this.setState({
                    files: files,
                    showUploadProgress: false
                });
            });
        }
    }

    findFile(id) {

        for (let file of this.state.files) {
            if (file && file.id == id) {
                return file;
            }
        }

        return undefined;
    }

    deleteFile(id) {
        let file = this.findFile(id);

        deleteFile(id).then(response => {

            let files = this.state.files;
            let index = files.indexOf(file)
            if (index > -1) {
                files.splice(index, 1);
            }

            this.setState({
                files: files
            });

        });
    }

    formModalRender(uploadSection) {
        return (
            <FileUploadModal
                toggleModal={this.toggleFileUploadModal}
                open={this.state.openFileUpload}
                uploadedFile={this.getFilesForType(uploadSection)}
                uploadSection={uploadSection}
                title={strings.documents.uploadDocuments}
                openDialog={this.openDialog}
                deleteFile={this.deleteFile}
                onChange={this.changeData}
                data={this.state.data}
                content={
                    <div className={'dropzone-wrapper'}>
                        {
                            this.state.showUploadProgress &&
                            <LinearProgress/>
                        }
                        <Dropzone
                            onDropAccepted={this.onDrop}
                            onDropRejected={this.onDropRejected}
                            onErr
                            multiple={true}
                            ref={this.dropzoneRef}
                            accept={CONFIG.acceptFiles}
                            maxSize={1048576 * 10}
                        >
                            {({getRootProps, getInputProps}) => (
                                <div>
                                    <section className={'file-dropzone'}>
                                        <div {...getRootProps()} className={'cursor-pointer'}>
                                            <input {...getInputProps()} />
                                            <p>{strings.documents.dragAndDrop}</p>
                                        </div>
                                    </section>
                                    <p className={'file-size'}>*10MB file size <span>{this.state.uploadFileError}</span>
                                    </p>
                                </div>
                            )}
                        </Dropzone>
                    </div>
                }
            />
        );
    }

    getFilesForType(type) {

        let result = []

        for (let file of this.state.files) {

            if (!file) {
                continue;
            }

            if (file.type == type) {
                result.push(file);
            }
        }

        return result;
    }

    fileUpload() {

        let formModal = [];

        switch (this.state.fileUploadModal) {
            case UploadSection.Proof_of_Identity:
                formModal = this.formModalRender(UploadSection.Proof_of_Identity);
                break;
            case UploadSection.Proof_of_Address:
                formModal = this.formModalRender(UploadSection.Proof_of_Address);
                break;
            case UploadSection.Visa_Documents:
                formModal = this.formModalRender(UploadSection.Visa_Documents);
                break;
            case UploadSection.UK_Citizenship_Documents:
                formModal = this.formModalRender(UploadSection.UK_Citizenship_Documents);
                break;
            case UploadSection.Right_to_Work_Documents:
                formModal = this.formModalRender(UploadSection.Right_to_Work_Documents);
                break;
            case UploadSection.Proof_of_NI_or_PPS:
                formModal = this.formModalRender(UploadSection.Proof_of_NI_or_PPS);
                break;
            case UploadSection.Selfie:
                formModal = this.formModalRender(UploadSection.Selfie);
                break;
            default:
                formModal = this.formModalRender([]);
        }

        return formModal;
    }

    findFileInListByType(list, type) {
        let isFound = false;
        list.map((file) => {
            if (file.type == type) {
                isFound = true;
            }
        });
        return isFound;
    }


    submit(mode, createSession = false) {
        
        Object.keys(this.state.errors).forEach(error => delete this.state.errors[error]);

        this.validationList = mode === ApplicationMode.SAVE ? this.saveValidationList : this.submitValidationList;

        if (checkConditionsForValidation(this.state.data) && mode === ApplicationMode.SUBMIT) {
            if (this.state.data.ukCitizen === false && !checkNationalityIDVTVerification(findDataValue(this.state.data, 'nationality'))) {
                this.validationList = Object.assign(this.validationList, this.drivingLicenceValidation);
                this.validationList = Object.assign(this.validationList, this.irExpireDateValidation);

                if ((findDataValue(this.state.data, 'territoryOfWork') === getTerritoriesString(Territories.UK))) {
                    this.validationList = Object.assign(this.validationList, this.ukShareCodeValidation);
                }
                if ((findDataValue(this.state.data, 'territoryOfWork') === getTerritoriesString(Territories.IRELAND))) {
                    delete this.validationList['ukShareCode'];
                }
            } else {
                delete this.validationList['fullUKorRoIDrivingLicence'];
                delete this.validationList['ukShareCode'];
                delete this.validationList['irExpireDate'];
            }

        } else {
            delete this.validationList['fullUKorRoIDrivingLicence'];
            delete this.validationList['ukShareCode'];
            delete this.validationList['irExpireDate'];
        }

        if(!(findDataValue(this.state.data, 'travelPreference') == "Mini Bus"))
        {
            delete this.validationList['minibus'];
            delete this.validationList['pickupLocation'];
        }
        if (!this.state.data.fullUKorRoIDrivingLicence != undefined && this.state.data.fullUKorRoIDrivingLicence.value != undefined) {
            this.state.data.fullUKorRoIDrivingLicence = this.state.data.fullUKorRoIDrivingLicence.value;
        }

        if (!this.validate()) {
            return;
        }

        Object.keys(this.state.errors).forEach(error => delete this.state.errors[error]);

        let errors = this.state.errors;

        if ((this.state.data.territoryOfWork && this.state.data.territoryOfWork.name === 'UK')) {
            if (this.state.data.ukShareCode && !checkNationalityIDVTVerification(findDataValue(this.state.data, 'nationality'))) {
                if (!this.shareCodeFormat().test(this.state.data.ukShareCode) && this.state.data.ukShareCode.toLowerCase() !== 'manual-sc') {
                    errors['ukShareCode'] = [{message: 'Share Code is not in the right format. Example format: 9XY-6GY-2S2'}]
                }
            }
            if (this.state.data.niNoPpsNo) {
                const re = new RegExp('^[A-Z]{2} [0-9]{6} [A-Z]{1}$');
                if (!re.test(this.state.data.niNoPpsNo)) {
                    errors['niNoPpsNo'] = [{message: 'NI Number is not in right format'}]
                }
            }
        }

        if(mode === ApplicationMode.SUBMIT) {
            if(!this.findFileInListByType(this.state.files, UploadSection.Selfie)) {
                errors['selfies'] = [{message: strings.validation.RequiredErrorMessage}]
            }
            if(!this.findFileInListByType(this.state.files, UploadSection.Proof_of_Identity) && checkNationalityIDVTVerification(findDataValue(this.state.data, 'nationality')) && !(this.state.validPassport && this.state.data.territoryOfWork.name === 'UK')) {
                errors['proofOfIdFiles'] = [{message: strings.validation.RequiredErrorMessage}]
            }
            if(!this.findFileInListByType(this.state.files, UploadSection.UK_Citizenship_Documents) && checkNationalityIDVTVerification(findDataValue(this.state.data, 'nationality')) && !(this.state.validPassport && this.state.data.territoryOfWork.name === 'UK')) {
                errors['rtwSupportingDocs'] = [{message: strings.validation.RequiredErrorMessage}]
            }
            if(!this.findFileInListByType(this.state.files, UploadSection.Proof_of_NI_or_PPS) && checkNationalityIDVTVerification(findDataValue(this.state.data, 'nationality')) && !this.state.validPassport) {
                errors['proofOfNiDoc'] = [{message: strings.validation.RequiredErrorMessage}]
            }
        }

        this.setState({
            errors: errors
        });

        if(this.state.errors) {
            let isError = false;
            Object.entries(this.state.errors).map((error) => {
                if(this.state.errors[error[0]].length > 0) {
                    this.scrollToError(this.state.errors);
                    isError = true;
                }
            });

            if(isError) {
                return;
            }
        }

        if(this.state.data.yotiProcessState === "COMPLETED" && this.state.data.rtwStatus !== ApplicationStatusRTW.IDVT_REJECTED && mode !== ApplicationMode.SAVE) {
            if(this.yotiVerificationCheck()) {
                return;
            }
        }
        
        
        if (mode == ApplicationMode.SUBMIT && !this.state.data.gdprDisclaimerAnswer) {
            this.toggleGDPRModal();
            return;
        }

        this.state.data.id = this.state.application.id;

        if (this.state.data.jobRole) {
            if (!this.state.data.jobRole.id) {
                this.state.data.jobRoleText = this.state.data.jobRole;
                this.state.data.jobRole = null;
            } else {
                this.state.data.jobRoleText = null;
            }
        } else {
            this.state.data.jobRole = null;
        }

        let submitWithCheckApplicationRightToWork = false;
        if (mode === ApplicationMode.SUBMIT) {
            if (!checkNationalityIDVTVerification(findDataValue(this.state.data, 'nationality')) && 
                    (this.state.data.ukShareCode?.toLowerCase() !== "manual-sc")) {
                submitWithCheckApplicationRightToWork = true;
            } else {
                this.state.data.ukShareCode = '';
            }
        }

        this.setState({
            showProgressBar: true
        });

        // let applicationRightToWorkDataForCheck = {
        //     "ShareCode": this.state.data.ukShareCode ? this.state.data.ukShareCode.split('-').join(" ") : null,
        //     "DateOfBirth": dateToString(this.state.data.dateOfBirth),
        //     "ApplicationId": this.state.application.id
        // };

        { submitWithCheckApplicationRightToWork &&
            // checkApplicantsRightToWork(applicationRightToWorkDataForCheck).then(responseRightToWork => {
            // let errors = this.state.errors;

            // if (!responseRightToWork.ok) {
            //     this.props.enqueueSnackbar(strings.application.errorFinishingApplication, {variant: 'error'});
            //     errors['ukShareCode'] = [{message: 'Share code or date of birth is not correct'}]
            //     this.setState({
            //         errors: errors,
            //         showProgressBar: false
            //     });
            //     this.scrollToError(this.state.errors)
            //     return;
            // }

            // let applicationFileRightToWork = new FormData();
            // applicationFileRightToWork.append('application', this.state.application.id);
            // applicationFileRightToWork.append('name', "right_to_work.pdf");
            // applicationFileRightToWork.append('content', responseRightToWork.data);
            // applicationFileRightToWork.append('fileType', "application/pdf");
            // applicationFileRightToWork.append('type', UploadSection.Right_to_work);
            // applicationFileRightToWork.append('applicationFileStatus', ApplicationFileStatus.ON_HOLD);

            // uploadFilesRightToWork(applicationFileRightToWork).then((result) => {

            //     if (!result || !result.ok) {
            //         this.props.enqueueSnackbar("Error uploading file", {variant: 'error'});
            //     }

            //     let files = this.state.files;

            //     files.push(result.data);

            //     this.setState({
            //         files: files,
            //         showUploadProgress: false
            //     });

            //     return;
            // });

            editApplication(this.state.data, mode).then(response => {

                this.setState({
                    showProgressBar: false
                })

                if (!response.ok) {
                    this.props.enqueueSnackbar(strings.application.errorFinishingApplication, {variant: 'error'});
                    return;
                }

                this.updateRtwProcessAndStatus(RTWProcess.SHARE_CODE, 0, false);

                this.toggleMessageModal();

                if (this.isUpdate()) {

                    this.props.setApplicationId(-1);

                    if (this.state.returnUrl) {
                        this.props.history.push(this.state.returnUrl);
                        return;
                    }

                    if (this.props.user.userType == UserType.APPLICANT) {
                        this.props.history.push('/application');
                        return;
                    } else if (this.props.user.userType == UserType.HR_MANAGER) {
                        this.props.history.push('/candidate-applications');
                        return;
                    } else if (this.props.user.userType == UserType.RECRUITMENT_MANAGER) {
                        this.props.history.push('/candidate-profiles');
                        return;
                    }
                }

                this.fetchData();
            });
        }
        
        let overrideData = new Date(this.state.data.dateOfBirth);
        overrideData.setHours(overrideData.getHours() + 1);
        this.state.data = { ...this.state.data, dateOfBirth: overrideData };
        {!submitWithCheckApplicationRightToWork &&
        editApplication(this.state.data, mode).then(response => {

            this.setState({
                showProgressBar: false
            })

            if (!response.ok) {
                this.props.enqueueSnackbar(strings.application.errorFinishingApplication, {variant: 'error'});
                return;
            }

            mode === ApplicationMode.SAVE ? this.props.enqueueSnackbar(strings.application.save, {variant: 'success'}) : this.toggleMessageModal();

            if (this.isUpdate()) {

                this.props.setApplicationId(-1);

                if (this.state.returnUrl) {
                    this.props.history.push(this.state.returnUrl);
                    return;
                }

                if (this.props.user.userType == UserType.APPLICANT) {
                    this.props.history.push('/application');
                    return;
                } else if (this.props.user.userType == UserType.HR_MANAGER) {
                    this.props.history.push('/candidate-applications');
                    return;
                } else if (this.props.user.userType == UserType.RECRUITMENT_MANAGER) {
                    this.props.history.push('/candidate-profiles');
                    return;
                }

            }

            if(createSession) {
                this.setState({
                    showProgressBar: true
                })
                createYotiSession(this.state.data.id).then(response => {
                    window.location.reload(false);
                })
            }
            else {
                window.scrollTo(0, 0);
                this.fetchData();
            }
        });
        }
    }

    updateRtwProcessAndStatus(rtwProcess, rtwStatus, submit) {
        if(this.state.data.rtwStatus !== rtwStatus || this.state.data.rtwProcess !== rtwProcess) {
            this.state.data.rtwStatus = rtwStatus;
            updateRTWStatus({
                id: this.state.application.id,
                Status: rtwStatus
            }).then(() => {
                if(this.state.data.rtwProcess !== rtwProcess) {
                    this.state.data.rtwProcess = rtwProcess;
                    updateRTWProcess({
                        id: this.state.application.id,
                        Process: rtwProcess
                    }).then(() => {
                        if(submit) {
                            this.submit(ApplicationMode.SAVE);
                        }
                    });
                }
            });
        }
    }

    sumbitYotiUnableValidation() {
        this.setState({
            showProgressBar: true
        });
        this.toggleYotiUnableValidationModal();

        this.updateRtwProcessAndStatus(RTWProcess.MANUAL, 0, true);
    }

    cancelYotiUnableValidation() {
        this.toggleYotiUnableValidationModal();
        this.updateRtwProcessAndStatus(RTWProcess.IDVT, ApplicationStatusRTW.IDVT_REJECTED, false);
    }

    sumbitYotiValidation() {
        this.setState({
            showProgressBar: false
        });

        this.toggleYotiValidationModal();
        let yotiValidationData = this.state.data.yotiValidationData.split(','); // [0] = name, [1] = surname, [2] = date of birth

        let overrideData = new Date(yotiValidationData[2]);
        overrideData.setHours(overrideData.getHours() + 12);

        this.state.data = { ...this.state.data, forename: yotiValidationData[0], surname: yotiValidationData[1], dateOfBirth: overrideData };

        this.submit(ApplicationMode.SAVE);
    }

    cancelYotiValidation() {
        this.toggleYotiValidationModal();
        this.toggleYotiUnableValidationModal();
    }

    sumbitGDPR() {

        this.toggleGDPRModal();

        this.state.data.id = this.state.application.id;

        if (this.state.data.jobRole) {
            if (!this.state.data.jobRole.id) {
                this.state.data.jobRoleText = this.state.data.jobRole;
                this.state.data.jobRole = null;
            } else {
                this.state.data.jobRoleText = null;
            }
        }

        editApplication(this.state.data, ApplicationMode.SUBMIT).then(response => {

            if (!response.ok) {
                this.props.enqueueSnackbar(strings.application.errorFinishingApplication, {variant: 'error'});
                return;
            }

            this.toggleMessageModal();

            window.scrollTo(0, 0);

            if(crea)

            this.fetchData();
        });
    }

    
    yotiVerificationCheck() {
        this.setState({
            showProgressBar: false,
        });
        if(this.state.data.yotiValidationData) {
            let yotiValidationData = this.state.data.yotiValidationData.split(','); // [0] = name, [1] = surname, [2] = date of birth
            if((this.state.data.forename.toLowerCase() !== yotiValidationData[0].toLowerCase()) || (this.state.data.surname.toLowerCase() !== yotiValidationData[1].toLowerCase()) || (dateToString(this.state.data.dateOfBirth) !== dateToString(yotiValidationData[2]))) {   
                this.toggleYotiValidationModal();
                return true;
            };
        }
        return false;
    }

    yotiVerificationListener() {
        const origin = 'https://api.yoti.com';
        window.addEventListener(
            'message',
            event => {
                if (event.origin === origin) {
                    this.setState({
                        showRefreshButton: true
                    })
                }
            }
        );
    };

    isUpdate() {
        return this.state.data.applicationInternalStatus > 0 || this.props.user.userType != UserType.APPLICANT;
    }

    checkAutoRTWSubmit(application) {

        if(application.rtwProcess === RTWProcess.MANUAL && this.state.data.validPassport && this.state.data.yotiProcessState !== "COMPLETED") {
            return false;
        }

        if(application.rtwProcess === RTWProcess.MANUAL) {
            return true;
        }

        if(!application.validPassport || !application.ukCitizen) {
            return true;
        }

        if(this.state.data.yotiProcessState === "COMPLETED" && application.rtwStatus === ApplicationStatusRTW.IDVT_REJECTED) {
            return true;
        }

        return application.rtwStatus === ApplicationStatusRTW.ACCEPTED || application.rtwStatus === ApplicationStatusRTW.IDVT_ACCEPTED || application.rtwStatus === ApplicationStatusRTW.SHARE_CODE_ACCEPTED
    }

    updateApplicationWithSessionData(yotiSessionId) {
        let data = this.state.data;
        data.yotiSessionId = yotiSessionId;
        this.setState({
            data: data
        })
    }
    applicationWithdrawal() {
        this.setState({
            showWithdrawConfirmModal: true
        })
    }

    closeWithdrawConfirmModal() {
        this.setState({
            showWithdrawConfirmModal: false
        })
    }

    confirmWithdraw() {
        withdrawApplication().then(response => {
            this.setState({
                showWithdrawConfirmModal: false
            })
        })
    }
    render() {
        let formModal = this.fileUpload();
        let messageModal = this.viewMessage();
        let gdprModal = this.renderGDPRModal();
        let yotiValidationModal = this.renderYotiValidationModal();
        let yotiUnableValidationModal = this.renderYotiUnableValidationModal();
        const btnwithdrawStyle = {
            backgroundColor: '#b29d24',
            boxShadow: 'none',
            borderRadius: '0',
            whiteSpace: 'normal',
            marginRight: '20px',
            padding: '5px 20px',
            alignSelf: 'center',
            height:'10vh'
          };
          const containerStyle = {
            display: 'flex',
            justifyContent: 'center', // Horizontally center
            marginTop: '30px',
          };
        return (
            
            <div>
                 <BaseConfirmDialog
                    close={this.closeWithdrawConfirmModal}
                    title={'Application Withdrawal'}
                    open={this.state.showWithdrawConfirmModal}
                    message={strings.candidateProfile.withdrawConfirm}
                    no={this.closeWithdrawConfirmModal}
                    yes={this.confirmWithdraw}
                />
                <Grid container spacing={9} ref={this.headerRef}>
                    <Grid item md={12}>
                        <Paper className='paper'>
                        <div style={containerStyle}>
                        <Button variant="contained" color="secondary" style={btnwithdrawStyle} className={'withdraw-button'} onClick={() => (this.applicationWithdrawal())} >
                                { strings.candidateProfile.withdraw }
                        </Button>
                        </div>
                            <p>
                                Please complete as many fields as possible. If you can't complete some off the fields
                                then use the 'Save for Later' option and we can help you complete during the session.
                            </p>

                            {
                                this.state.data && this.state.data.additionalInfoRequired &&
                                <div className={'message-wrapper'}>
                                    <h4>{strings.candidateProfile.additionalInfoRequired}</h4>
                                    <div className={'message'}>{this.state.data.additionalInfoRequired}</div>
                                </div>
                            }

                            <div id={'personal-details-anchor'} ref={this.personalDetailsRef}>
                                <h1>{strings.candidateProfile.personalDetails}</h1>
                                <PersonalDetailsForm data={this.state.data}
                                                     onChange={this.changeData}
                                                     errors={this.state.errors}
                                                     titles={this.state.titles}
                                                     genders={this.state.genders}
                                                     disable={this.state.disableField}
                                />
                            </div>

                            

                            <div id={'address-details-anchor'} ref={this.addressDetailsRef}>
                                <h1>{strings.candidateProfile.addressDetails}</h1>
                                <AddressDetailsForm data={this.state.data}
                                                    onChange={this.changeData}
                                                    errors={this.state.errors}
                                                    disable={this.state.disableField}
                                                    countries={this.state.countries}
                                />
                            </div>

                            <div id={'emergency-contact-anchor'} ref={this.emergencyContactRef}>
                                <h1>{strings.candidateProfile.emergencyContact}</h1>
                                <EmergencyContactForm data={this.state.data}
                                                      relationshipTypes={this.state.relationshipTypes}
                                                      onChange={this.changeData}
                                                      errors={this.state.errors}
                                                      disable={this.state.disableField}
                                />
                            </div>

                            <div id={'job-details-anchor'} ref={this.jobDetailsRef}>
                                <h1>{strings.candidateProfile.jobDetails}</h1>
                                <JobDetailsForm data={this.state.data}
                                                onChange={this.changeData}
                                                onChangeDivision={this.changeDataDivision}
                                                errors={this.state.errors}
                                                divisions={this.state.divisions}
                                                regions={this.state.regions}
                                                userDivisions={this.state.userDivisions}
                                                relationshipTypes={this.state.relationshipTypes}
                                                recruiters={this.state.recruiters}
                                                jobRoles={this.state.jobRoles}
                                                travelPreferences={this.state.travelPreferences}
                                                sessionTypes={this.state.sessionTypes}
                                                placeOfWorks={this.state.placeOfWorks}
                                                disable={this.state.disableField}
                                                minibuses={this.state.minibuses}
                                                pickupLocations={this.state.pickupLocations}
                                />
                            </div>

                            <div id={'gdpr-disclaimer-anchor'} ref={this.gdprDisclaimerRef}>
                                <h1>{strings.candidateProfile.gdprDisclaimer}</h1>
                                <GDRPDisclaimerForm data={this.state.data}
                                                    onChange={this.changeData}
                                                    errors={this.state.errors}
                                                    handleToggle={this.changeCheckBox}
                                                    disable={this.state.disableField}
                                />
                            </div>

                            <div id={'uk-nationals-anchor'} ref={this.ukNationalsRef}>
                            <Divider />
                                <h1>{strings.candidateProfile.ukNationals}</h1>
                                <UKNationalsForm data={this.state.data} onChange={this.changeData}
                                                 errors={this.state.errors} onDelete={this.deleteFile}
                                                 onModalOpen={this.toggleFileUploadModal}
                                                 proofOfIdentityDoc={this.getFilesForType(UploadSection.Proof_of_Identity)}
                                                 proofOfAddressDoc={this.getFilesForType(UploadSection.Proof_of_Address)}
                                                 proofOfNiDoc={this.getFilesForType(UploadSection.Proof_of_NI_or_PPS)}
                                                 ukCitizenshipDoc={this.getFilesForType(UploadSection.UK_Citizenship_Documents)}
                                                 selfies={this.getFilesForType(UploadSection.Selfie)}
                                                 nationalities={this.state.nationalities}
                                                 territoryOfWorks={this.state.territoryOfWorks}
                                                 fullUKorRoIDrivingLicences={this.state.fullUKorRoIDrivingLicences}
                                                 proofAddressDocumentTypes={this.state.proofAddressDocumentTypes}
                                                 disable={this.state.disableField}
                                                 handleToggle={this.changeCheckBox}
                                                 idDocumentTypes={this.state.idDocumentTypes}
                                                 updateApplicationWithSessionData={this.updateApplicationWithSessionData}
                                                 save={this.submit}
                                                 showRefreshButton={this.state.showRefreshButton}
                                                 fetchData={this.fetchData}
                                />
                                
                            </div>
                            {
                                (!this.state.data.ukCitizen) && !checkNationalityIDVTVerification(findDataValue(this.state.data, 'nationality')) &&
                                <div id={'right-to-work-anchor'}>
                                    <RightToWorkForm data={this.state.data}
                                                     onChange={this.changeData}
                                                     errors={this.state.errors}
                                                     onDelete={this.deleteFile}
                                                     onModalOpen={this.toggleFileUploadModal}
                                                     ukVisaDoc={this.getFilesForType(UploadSection.Visa_Documents)}
                                                     ukCitizenshipDoc={this.getFilesForType(UploadSection.UK_Citizenship_Documents)}
                                                     irelandDoc={this.getFilesForType(UploadSection.Right_to_Work_Documents)}
                                                     disable={this.state.disableField}
                                    />
                                </div>
                            }
                            {
                                this.state.showProgressBar &&
                                <LinearProgress/>
                            }

                            {
                                !this.checkAutoRTWSubmit(this.state.data) &&
                                <div className={'info-message-yellow-wrapper'}>
                                    <div className={'info-message-yellow'}>
                                        <p>
                                            Your ID checks are incomplete, once they have completed you will be able to submit. We will notify you via e-mail/sms once they are complete. Please click save for later for now.
                                        </p>
                                    </div>
                                </div>
                            }


                            <div id={'submit-container-anchor'} ref={this.submitApplicationRef}>
                                {
                                    <div className='submit-container submit-application'>
                                        <Button variant="contained" disabled={this.state.disableField} color="primary"
                                                onClick={() => (this.submit(ApplicationMode.SAVE))} className={'save'}>
                                            {this.isUpdate() ? strings.candidateProfile.update : strings.candidateProfile.save}
                                        </Button>

                                        {
                                            this.props.user.userType == UserType.APPLICANT &&
                                            <Button variant="contained" disabled={this.state.disableField || !this.checkAutoRTWSubmit(this.state.data)}
                                                    color="primary"
                                                    onClick={() => (this.submit(ApplicationMode.SUBMIT))}
                                                    className={'submit'}>
                                                {strings.candidateProfile.submitApplication}
                                            </Button>
                                        }

                                    </div>
                                }
                                {
                                    this.state.showProgressBar &&
                                    <LinearProgress/>
                                }
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
                {formModal}
                {messageModal}
                {gdprModal}
                {yotiValidationModal}
                {yotiUnableValidationModal}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen,
        changeIndicator: Actions.changeIndicator,
        changeLinkIndicator: Actions.changeLinkIndicator,
        getData: Actions.getData,
        getErrors: Actions.getErrors,
        setHasContract: Actions.setHasContract,
        doUpdate: Actions.doUpdate,
        allowUpdate: Actions.allowUpdate,
        setApplicationId: Actions.setApplicationId
    }, dispatch);
}

function mapStateToProps({menuReducers, authReducers, siteDataReducers, applicationReducers}) {
    return {
        menu: menuReducers,
        user: authReducers.user,
        siteData: siteDataReducers,
        applicationReducers: applicationReducers
    };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(Application)));
