import React, {Component} from 'react';
import {Dialog, Divider, Slide} from "@material-ui/core/";
import CloseIcon from '@material-ui/icons/Close';
import {Button, IconButton, TextField} from "@material-ui/core";
import strings from "../localization";
import ApplicationStatusRTW from '../constants/ApplicationStatusRTW';
import ApplicationStatus from "../constants/ApplicationStatus";
import FormComponent from "./FormComponent";

class VerifyModal extends FormComponent {

    constructor(props) {
        super(props);

        this.state = {
            applicationId: props.applicationId,
            data: {}
        }

        this.no = this.no.bind(this);
    }

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="left" ref={ref} {...props} />;
    });

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.application !== this.props.application){
            this.setState({
                application: this.props.application
            });
        }
    }

    yes() {

        if(!this.props.applicationId) {
            return;
        }

        this.props.statusUpdate(this.props.applicationId, this.props.currentStatus, this.state.data.rejectReason);
        this.props.closeVerifyModal();
    }

    no() {
        this.props.closeVerifyModal();
        this.props.fetchData();
    }

    render() {

        return (
            <Dialog
                onClose={this.no}
                aria-labelledby="customized-dialog-title"
                open={this.props.open}
                TransitionComponent={this.Transition}
                className={'verify-modal'}
            >
                <div className='form-modal-header' onClose={this.props.closeVerifyModal}>
                    <div>
                        <h1>{
                            this.props.currentStatus == ApplicationStatus.APPROVED ? "Application status" : this.props.title
                        }</h1>
                        <IconButton className={'close-button-wrapper'} onClick={this.props.closeVerifyModal}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <Divider />
                </div>

                <div className={'verify-wrapper'}>
                    <div className={'verify-message'}>
                        { (this.props.currentStatus) && (this.props.currentStatus == ApplicationStatusRTW.ACCEPTED ||
                                this.props.currentStatus == ApplicationStatusRTW.IDVT_ACCEPTED || this.props.currentStatus == ApplicationStatusRTW.SHARE_CODE_ACCEPTED)
                          &&
                            <React.Fragment>
                                {
                                    this.props.sessionType === 'Physical' &&
                                    strings.candidateProfiles.confirmMessageAcceptPhysical
                                }
                                {
                                    this.props.sessionType === 'Virtual' &&
                                    strings.candidateProfiles.confirmMessageAcceptVirtual
                                }
                            </React.Fragment>
                        }

                        {
                            this.props.currentStatus == ApplicationStatus.APPROVED &&
                            <p>Once a candidate has been approved, the status can not be changed. <br/>Once the Candidate is approved, the Candidate details will be sent to Field Power</p>
                        }

                        { (this.props.currentStatus) && (this.props.currentStatus == ApplicationStatusRTW.REJECTED ||
                                this.props.currentStatus == ApplicationStatusRTW.IDVT_REJECTED|| this.props.currentStatus == ApplicationStatusRTW.SHARE_CODE_REJECTED)
                          && strings.candidateProfiles.confirmMessageReject
                        }

                        {   this.props.currentStatus == ApplicationStatus.REJECTED &&
                            strings.hrManager.rejectMessage
                        }
                    </div>

                    {
                        this.props.currentStatus == ApplicationStatus.REJECTED &&
                        <div className={'rejection-reasons'}>
                            <TextField
                                placeholder={strings.hrManager.rejectionReasons}
                                multiline
                                rows={2}
                                rowsMax={4}
                                variant={'outlined'}
                                onChange={this.changeData}
                                name={'rejectReason'}
                                value={ this.state.data ? this.state.data.rejectionReasons : '' }
                            />
                        </div>
                    }

                    <div className={'verify-buttons'}>
                        <Button className={'yes'} variant="contained" color="primary" onClick={() => this.yes()}>
                            { this.props.currentStatus == ApplicationStatus.APPROVED ? "Confirm" : strings.table.yes }
                        </Button>
                        <Button className={'no'} variant="contained" color="secondary" onClick={() => this.no()}>
                            { this.props.currentStatus == ApplicationStatus.APPROVED ? "Cancel" : strings.table.no }
                        </Button>
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default VerifyModal;