import * as Actions from '../actions/Actions';
import ArchivedStatus from "../constants/ArchivedStatus";
import ApplicationFileStatus from "../constants/ApplicationFileStatus";

const initialState = {
    filterData: {
        division: -1,
        region: -1,
        location: -1,
        recruiter: -1,
        jobRole: -1,
        status: -1,
        contractType: -1,
        search: '',
        page: 0,
        perPage: 30,
        dateFrom: null,
        dateTo: null,
        stage: -1,
        archivedStatus: ArchivedStatus.ACTIVE,
        selfieStatus: ApplicationFileStatus.ON_HOLD
    }
};

const filterReducers = (state = initialState, action) => {

    switch (action.type) {
        case Actions.FILTER_CHANGE:
            return {
                ...state,
                filterData: action.data
            };

        default: return state;
    }
};

export default filterReducers;