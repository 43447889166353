import Page from "../common/Page";
import {withSnackbar} from "notistack";
import {bindActionCreators} from "redux";
import * as Actions from "../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../localization";
import React from "react";
import {getSystemSettings, updateSystemSettings} from "../services/SystemSettingsService";

class SystemSettings extends Page {

    constructor(props) {
        super(props);

        this.state = {
            data: {
                idvt: true
            }
        }

        this.toggleIDVT = this.toggleIDVT.bind(this);
    }

    fetchData() {
        getSystemSettings().then(response => {
            if(response && response.data) {
                this.setState({
                    data: {
                        idvt: response.data.idvt
                    }
                })
            }
        })
    }

    componentDidMount() {
        this.fetchData()
    }

    toggleIDVT(idvt) {
        this.setState({
            data: {
                ...this.state.data,
                idvt: idvt
            }
        })

        updateSystemSettings({
            idvt: idvt
        }).then(response => {
            this.fetchData()
        })
    }

    render() {
        return(
            <div id='system-settings'>
                <h1>{strings.systemSettings.IDVTOverride}</h1>
                {
                    this.state.data && this.state.data.idvt &&
                    <React.Fragment>
                        <h2>The IDVT override is currently enabled</h2>
                        <p>Candidates will be directed to the Manual ID process</p>
                        <p>If the IDVT override is disabled, candidates  can go through the IDVT Process</p>
                    </React.Fragment>
                }
                {
                    this.state.data && !this.state.data.idvt &&
                    <React.Fragment>
                        <h2>The IDVT override is currently disabled</h2>
                        <p>Candidates can go through the IDVT Process</p>
                        <p>If the IDVT override is enabled, candidates will be directed to the Manual ID process</p>
                    </React.Fragment>
                }
                <div className={'switch-wrapper'} >
                    <label className={this.state.data?.idvt ? 'switch checked-switch' : 'switch' } onClick={() => this.toggleIDVT(!this.state.data.idvt)}>
                        <div className="slider round">
                            <span className="on">Enabled</span>
                            <span className="off">Disabled</span>
                        </div>
                    </label>
                </div>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen,
    }, dispatch);
}

function mapStateToProps({menuReducers, authReducers}) {
    return {menu: menuReducers, auth: authReducers};
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(SystemSettings)));