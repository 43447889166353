import {makeParametersList, request} from "../base/HTTP";
import HttpMethod from "../constants/HttpMethod";
import {dateToTicks} from "../util/DateUtil";

export async function addCandidate(data) {
    return await request('/api/user/create', data, HttpMethod.POST);
}

export async function addCandidatePassword(data) {
    return await request('/api/user/change_password_with_token', data, HttpMethod.POST);
}

export async function addCandidateQuestionnaire(data) {
    return await request('/api/application', data, HttpMethod.POST);
}

export async function editApplication(data, mode) {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const updatedData = {
        ...data,
        userTimeZone: userTimeZone, // Replace 'yourNewValue' with the actual value you want to set
      };
    return await request('/api/application/' + mode, transformData(updatedData), HttpMethod.PUT);
}

function transformData(data) {
    console.log("transformed data", data);
    return {
        ...data,
        emergencyRelationshipToYou: data.emergencyRelationshipToYou && data.emergencyRelationshipToYou.name ?
            data.emergencyRelationshipToYou.name : data.emergencyRelationshipToYou
    }
}

export async function getApplication(id) {
    return await request('/api/application/' + id, {}, HttpMethod.GET);
}

export async function getApplications(data) {

    if (data) {
        data = {
            ...data,
            region: data.region && data.region.id ? data.region.id : data.region,
            jobRole: data.jobRole && data.jobRole.id ? data.jobRole.id : data.jobRole,
            division: data.division && data.division.id ? data.division.id : data.division,
            recruiter: data.recruiter && data.recruiter.id ? data.recruiter.id : data.recruiter,
            stage: data.stage && data.stage.id ? data.stage.id : data.stage,
            from: data.from ? dateToTicks(data.from) : 0,
            to: data.to ? dateToTicks(data.to) : 0,
        }
    }

    return await request('/api/application/all', data, HttpMethod.GET);
}

export async function updateStatuses(data) {
    return await request('/api/application/statuses/' + data.id, data, HttpMethod.PUT);
}

export async function updateStatus(data) {
    return await request('/api/application/status/' + data.id, data, HttpMethod.PUT);
}

export async function updateArchivedStatus(data) {
    return await request('/api/application/archived-status/' + data.id, data, HttpMethod.PUT);
}

export async function updateRTWStatus(data) {
    return await request('/api/application/rtw-status/' + data.id, data, HttpMethod.PUT);
}

export async function updateRTWProcess(data) {
    return await request('/api/application/rtw-process/' + data.id, data, HttpMethod.PUT);
}

export async function resetApplication(id) {
    return await request('/api/application/reset/' + id, {}, HttpMethod.PUT);
}
