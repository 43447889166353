const ApplicationStatusRTW = {
    ACCEPTED: 1,
    REJECTED: 2,
    IDVT_ACCEPTED: 3,
    IDVT_REJECTED: 4,
    SHARE_CODE_ACCEPTED: 5,
    SHARE_CODE_REJECTED: 6
};

export default ApplicationStatusRTW;

export function getApplicationStatusesRTW() {
    const statuses = [
        {
            value: ApplicationStatusRTW.ACCEPTED,
            name: 'Accepted Manual'
        },
        {
            value: ApplicationStatusRTW.REJECTED,
            name: 'Rejected Manual'
        },
        {
            value: ApplicationStatusRTW.IDVT_ACCEPTED,
            name: 'Accepted IDVT',
            disabled : true
        },
        {
            value: ApplicationStatusRTW.IDVT_REJECTED,
            name: 'Rejected IDVT',
            disabled : true
        },
        {
            value: ApplicationStatusRTW.SHARE_CODE_ACCEPTED,
            name: 'Accepted Share Code',
            disabled : true
        },
        {
            value: ApplicationStatusRTW.SHARE_CODE_REJECTED,
            name: 'Rejected Share Code',
            disabled : true
        }
    ];
    
    return statuses;
}