import {getConfirmModes} from "./ConfirmMode";

const ContractTypeMode = {
    Salaried_Operative_UK: 1,
    Salaried_Operative_ROI: 2,
    Payroll_52_Group_UK: 3,
    Ad_hoc_Casual_services_agreement_UK: 4,
    Ad_hoc_Casual_services_agreement_ROI: 5,
    VAS_Casual_UK: 6,
    Hourly_Contracted_pay_UK: 7
};

export default ContractTypeMode;

export function getContractTypes() {
    const contractTypes = [
        {
            value: ContractTypeMode.Salaried_Operative_UK,
            name: 'Salaried Operative UK'
        },
        {
            value: ContractTypeMode.Salaried_Operative_ROI,
            name: 'Salaried Operative ROI'
        },
        {
            value: ContractTypeMode.Payroll_52_Group_UK,
            name: 'Payroll 52 / Group UK'
        },
        {
            value: ContractTypeMode.Ad_hoc_Casual_services_agreement_UK,
            name: 'Ad hoc Casual services agreement UK'
        },
        {
            value: ContractTypeMode.Ad_hoc_Casual_services_agreement_ROI,
            name: 'Ad hoc Casual services agreement ROI'
        },
        {
            value: ContractTypeMode.VAS_Casual_UK,
            name: 'VAS Casual UK',
            disabled: true
        },
        {
            value: ContractTypeMode.Hourly_Contracted_pay_UK,
            name: 'Hourly / Contracted pay UK',
        }
    ];
    
    return contractTypes;
}

export function getContractTypesString(contractType) {
    const selectedContractType = getContractTypes().find(item => item.value === contractType);

    return selectedContractType ? selectedContractType.name : '';
}
