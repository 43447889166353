import React from 'react';
import strings from "../../../localization";
import {getError, hasError} from "../../../functions/Validation";
import {Button, TextField, Divider, Chip, FormControlLabel, Checkbox} from "@material-ui/core";
// import { Checkbox } from '@material-ui/core/es';
import Grid from '@material-ui/core/Grid';
import ApplicationInternalStatus from '../../../constants/ApplicationInternalStatus';

const WorkingTimeElectionForm = ({
                         onSubmit,
                         onCancel,
                         onChange,
                         errors,
                         data,
                         onDelete,
                         titles,
                         genders,
                         disabled,
                         handleToggle
                     }) => (

    <form id='working-time-election-form'>
        <Grid container spacing={9} className={'grid-wrapper'}>
            <Grid item md={10}>
                <div className={'question'}>{ strings.workingTimeElection.workingTimeElectionQuestion }</div>
            </Grid>
            <Grid item md={10}>
                <div className="custom-checkbox-wrapper">
                    <label className="container">
                        <input type="checkbox" name={'workingTimeElection'} disabled={ disabled } checked={data.workingTimeElection} onClick={handleToggle} />
                        <span className="checkmark"></span>
                        <div className={'label-wrapper'}>
                            <label>{ strings.workingTimeElection.workingTimeElectionYes }</label>
                            <div className={'italic'}>{ strings.workingTimeElection.workingTimeElectionLabel }</div>
                        </div>
                    </label>

                    <label className="container">
                        <input type="checkbox" name={'workingTimeElection'} disabled={ disabled } checked={!data.workingTimeElection} onClick={handleToggle} />
                        <span className="checkmark"></span>
                        <div className={'label-wrapper'}>
                            <label>{ strings.workingTimeElection.workingTimeElectionNo }</label>
                            <div className={'italic'}>{ strings.workingTimeElection.workingTimeElectionLabel }</div>
                        </div>
                    </label>
                </div>
            </Grid>
        </Grid>
        <Divider />
    </form>
);

export default WorkingTimeElectionForm;