import React, {createRef} from 'react'
import TablePage from "../../common/TablePage";
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import {withSnackbar} from "notistack";
import PageState from '../../constants/PageState';
import {
    Button,
    Drawer,
    TextField
} from "@material-ui/core";
import DrawerWrapper from "../../common/DrawerWrapper";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { getAdminAlerts} from '../../services/AdminAlertService';
import ApplicationInternalStatus from '../../constants/ApplicationInternalStatus';
import SearchIcon from "@material-ui/icons/Search";
import ApplicationView from '../../constants/ApplicationView';
import ViewAdminAlertsModal from "../../common/ViewAdminAlertsModal";
import Checkbox from '@material-ui/core/Checkbox';

 
class AdminAlerts extends TablePage {
 
    validationList = {};
 
    tableDescription = [
        { key: 'alert', label: strings.adminAlerts.alert, transform: 'renderName', class: 'column-fix' },
        { key: 'textToScan', label: strings.adminAlerts.textToScan, transform: 'renderTextToScan' },
        { key: 'active', label: strings.adminAlerts.active, transform: 'renderActive' },
        { key: 'id', label: '', transform: 'renderViewAdminAlert' },
    ];
 
    constructor(props) {
        super(props);
 
        this.state = {
            data: props.data ? props.data : {},
            showSearch: true,
            tableData: [],
            pageState: PageState.View,
            total: 100,
            openAdminAlertView: false,
            selectedApplication: null,
        };
        this.toggleViewAdminAlertModal = this.toggleViewAdminAlertModal.bind(this);
        this.openViewAdminAlerts = this.openViewAdminalerts.bind(this);
        this.viewAdminAlertRef = createRef();
        this.findApplication = this.findApplication.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }
 
    findApplication(id) {
 
        for(let item of this.state.tableData) {
            if(item.id == id) {
                return item;
            }
        }
 
        return null;
    }
    renderViewAdminAlert(id){

        return (
            <div className='candidate-button candidate-view-admin-alert'>
                <Button variant="contained" color="primary" onClick={() => this.toggleViewAdminAlertModal(id)}>
                    { strings.adminAlerts.viewadminalert }
                </Button>
            </div>
        );
    }
    viewApplicant(){

        if(!this.state.selectedApplication) {
            return;
        }

        return (
            <ViewAdminAlertsModal
                toggleModal={this.toggleViewAdminAlertModal}
                open={this.state.openAdminAlertView}
                title={strings.adminAlerts.viewadminalert}
                openDialog={this.openViewAdminalerts}
                onChange={this.onChange}
                application={this.state.selectedApplication}
                data={this.state.data}
            />
        );
    }
   
    toggleViewAdminAlertModal(id){
        this.state.selectedApplication = this.findApplication(id);

        this.setState({
            openAdminAlertView: !this.state.openAdminAlertView
        });
    }
    renderName(item){
        if (!item) {
            return '';
        }
        return item;
    }
    openViewAdminalerts() {
        if (this.viewAdminAlertRef.current) {
            this.viewAdminAlertRef.current.open();
        }
    };
    renderTextToScan(item) {
        if (!item) {
            return '';
        }
 
        return item;
    }
 
    renderActive(item) {
        return (
            <Checkbox
            checked={item == 1}
            inputProps={{
            'aria-label': 'secondary checkbox',
              }}
          />
        );
    }
 
    fetchData() {
 
        if(!this.state.filter) {
            return;
        }
 
        this.setState({
             lockTable: true
        });
 
        if(this.state.filter) {
            this.state.filter.applicationInternalStatus = ApplicationInternalStatus.ALL;
            this.state.filter.view = ApplicationView.CONDITIONAL_OFFERS;
            this.state.filter.search = this.state.searchData.search;
            this.state.filter.page = this.state.searchData.page - 1;
            this.state.filter.perPage = this.state.searchData.perPage;
        }
 
        getAdminAlerts(this.state.filter).then(response => {
            if(!response.ok) {
                return;
            }
 
            this.setState({
                tableData: response.data.entities ? response.data.entities : [],
                total: response.data.total,
                lockTable: false
            });
        });
    }
 
    componentDidMount() {
        this.props.setApplicationId(-1);
 
        this.state.filter = this.props.filter ? this.props.filter.filterData : {
            division: -1,
            region: -1,
            location: -1,
            recruiter: -1,
            jobRole: -1,
            status: -1,
            contractType: -1,
            search: '',
            page: 1,
            perPage: 30,
            dateFrom: null,
            dateTo: null,
            stage: -1
        };
 
        this.fetchData();
    }
 
 
 
    getPageHeader() {
        return <h1>{ strings.adminAlerts.viewadminalert }</h1>;
    }
 
    changeDataStatus(event, id) {
 
        let application = this.findApplication(id);
       
        let index = this.state.tableData.indexOf(application);
 
        if(index == -1) {
            return;
        }
 
        let data = this.state.tableData;
        data[index][event.target.name] = event.target.value.value ? event.target.value.value : event.target.value;
 
        this.setState({
            tableData: data
        });
    }
 
 
 
    render() {
        let adminAlertView = this.viewApplicant();
        return (
            <div>
                <Grid id='candidate-profiles-table-page'>
                    { this.renderDialog(strings.table.confirmDelete, 'To subscribe to this website, please enter your email address here. We will send\n' +
                        'updates occasionally.', this.cancelDelete, this.delete) }
                    <div className='header'>
                        { this.getPageHeader() }
                        <div className='filter-controls'>
 
                            {
                                this.state.showSearch &&
                                <div className={'filter-wrapper'}>
                                    <TextField
                                        label={ strings.table.search }
                                        type="search"
                                        name='search'
                                        value={ this.state.searchData.search }
                                        onChange={ this.searchChanged }
                                        variant={'outlined'}
                                    />
                                    <SearchIcon/>
                                </div>
                            }
 
                            {
                                this.state.showAdd &&
                                this.renderTableControls()
                            }
                        </div>
                    </div>
                    <Paper className={'table-wrapper'} md={12}>
                        { this.renderTable(this.state.tableData) }
                    </Paper>
 
                    <Drawer id='drawer' anchor='right' open={  this.showDrawer() } onClose={ () => this.setPageState(PageState.View) } >
                        <DrawerWrapper onBack={ () => this.setPageState(PageState.View) }>
                            { this.renderDrawerContent() }
                        </DrawerWrapper>
                    </Drawer>
                </Grid>
                {adminAlertView}
            </div>
        );
    }
}
 
function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen,
        setApplicationId: Actions.setApplicationId
    }, dispatch);
}
 
function mapStateToProps({ menuReducers, filterReducers, authReducers, siteDataReducers })
{
    return { menu: menuReducers, filter: filterReducers, user: authReducers.user, siteData: siteDataReducers };
}
 
export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminAlerts)));
