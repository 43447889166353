import isPlainObject from "react-redux/lib/utils/isPlainObject";

export function getDropdownOptions(array, name, label, value = undefined) {
    if(!array){
        return;
    }

    return array.map(item => ({
        value: value ? item[value] : item,
        label: item[label],
        disabled: item.disabled,
        target: {
            name: name,
            value: value ? item[value] : item
        }
    }));

}

export function getDropdownOptionsValue(option, value, name = 'name'){

    return value !== undefined && value == option.value[name] ? value : undefined;
}

export function findDataValue(data, name){
    for(let item in data) {
        if(item == name){
            return data[item] ? data[item].name : '';
        }
    }
}