import React, {Component} from 'react';
import {Dialog, Divider, Slide } from "@material-ui/core/";
import CloseIcon from '@material-ui/icons/Close';
import {Button, IconButton } from "@material-ui/core";
import strings from "../localization";

import FormComponent from "./FormComponent";
import { dateToString } from '../util/DateUtil';

class YotiValidationModal extends FormComponent {

    constructor(props) {
        super(props);

        this.state = {
            data: {}
        }
    }

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="left" ref={ref} {...props} />;
    });

    yes() {
        this.props.yes();
    }

    no() {
        this.props.no();
    }

    render() {
        return (
            <Dialog
                onClose={this.props.close}
                aria-labelledby="customized-dialog-title"
                open={this.props.open}
                TransitionComponent={this.Transition}
                className={'yoti-validation-modal'}>
                    <div className='form-modal-header' onClose={this.props.close}>
                        <div>
                            <h1>{this.props.title}</h1>
                            <IconButton className={'close-button-wrapper'} onClick={this.props.close}>
                                <CloseIcon/>
                            </IconButton>
                        </div>
                        <Divider />
                    </div>

                    <div className={'verify-wrapper'}>
                        <div className={'verify-message'}>
                            { this.props.message }
                        </div>
                        { this.props.yotiValidationData &&
                            <p className='yoti-validation-container'>
                                <p className='labels'>
                                    <p>{strings.application.yotiValidation.firstName}</p>
                                    <p>{strings.application.yotiValidation.surname}</p>
                                    <p>{strings.application.yotiValidation.dateOfBirth}</p>
                                </p>
                                <p className='data'>
                                    <p>{this.props.yotiValidationData.split(',')[0]}</p>
                                    <p>{this.props.yotiValidationData.split(',')[1]}</p>
                                    <p>{dateToString(this.props.yotiValidationData.split(',')[2])}</p>
                                </p>
                            </p>
                        }

                        <div className={'verify-buttons'}>
                            <Button className={'yes'} variant="contained" color="secondary" onClick={() => this.yes()}>
                                { this.props.yesButtonMessage }
                            </Button>
                            <Button className={'no'} variant="contained" color="primary" onClick={() => this.no()}>
                                { this.props.noButtonMessage }

                            </Button>
                        </div>
                    </div>
                </Dialog>
            );
        }
}

export default YotiValidationModal;