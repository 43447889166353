import React from 'react'
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Page from "../../common/Page";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Validators from "../../constants/ValidatorTypes";
import AddCandidatePasswordForm from '../../components/forms/user/AddCandidatePasswordForm';
import { addCandidatePassword } from '../../services/AddCandidateService';
import strings from '../../localization';

class AddCandidatePassword extends Page {

    validationList = {
        password: [ {type: Validators.REQUIRED } ],
        confirmPassword: [ {type: Validators.CONFIRM_PASSWORD } ]
    };

    constructor(props) {
        super(props);

        this.state = {
            data: {
                password: '',
                confirmPassword: '',
                registrationToken: '',
                candidateName: ''
            },
            errors: {},
            redirectUrl: props.location.state ? props.location.state.redirectUrl : '/login'
        };

        let query = this.props.location.search ? new URLSearchParams(this.props.location.search) : undefined;
        this.state.data.registrationToken = query ? query.get('token') : undefined;

        let queryName = this.props.location.search ? new URLSearchParams(this.props.location.search) : undefined;
        this.state.data.candidateName = queryName ? queryName.get('name') : undefined;

        this.props.changeFullScreen(true);

        this.keyPress = this.keyPress.bind(this);
    }

    componentDidMount() {
        if(this.props.auth.user) {
            this.props.history.push('/');
        }
    }

    keyPress(event) {
        if(event.key == 'Enter') {
            this.addCandidatePassword()
        }
    }

    addCandidatePassword() {
        if(!this.validate()) {
            return;
        }

        addCandidatePassword(this.state.data).then(response => {

            if(!response.ok) {
                return;
            }

            this.props.history.push({
                pathname: this.state.redirectUrl
            })
        });
    }

    render() {
        return (
            <div id='add-candidate-password'>
                <h1>{strings.addCandidatePassword.addPassword}</h1>
                <Grid container spacing={9} className={'grid-wrapper'}>
                    <Grid item md={5} className={"add-candidate-password-image"}>
                        <img src={'/images/ras.jpg'} alt={'logo'} />
                    </Grid>
                    <Grid item md={5} className={'add-candidate-password-form-wrapper'}>
                        <Paper className='paper'>
                            <div className={'add-candidate-password-logo'}>
                                <img src={'/images/RAS-HEX.png'} alt={'logo'} />
                            </div>
                            <AddCandidatePasswordForm onSubmit={ () => this.addCandidatePassword() } onChange={ this.changeData }
                                    keyPress={ this.keyPress }
                                    data={ this.state.data } errors={ this.state.errors }
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen,
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, auth: authReducers };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddCandidatePassword));