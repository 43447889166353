import { request } from "../base/HTTP";
import HttpMethod from "../constants/HttpMethod";
import CONFIG from '../config';

export async function getOffer(id) {

    if (!id) {
        return;
    }

    return await request('/api/offer/' + id, {}, HttpMethod.GET);
}

export async function makeOffer(id) {
    const confDiv = CONFIG.currentDivision;
    let currDivision;
    switch (confDiv) {
        case 'ss':
            currDivision = 0;
          break;
        case 'st':
            currDivision = 1;
          break;
        case 'sc':
            currDivision = 2;
          break;
        default:
            currDivision = 1;
          break;
      }
     
      let data = {
        currentDivision: currDivision
      };
    return await request('/api/application/make-offer/' + id, data , HttpMethod.PUT);
}

export async function changeHRApprovalStatus(data) {
    return await request('/api/offer/hrapproval' , data, HttpMethod.POST);
}

export async function sendOffer(id, data) {

    if (!id) {
        return;
    }

    return await request('/api/offer/' + id, transformData(data), HttpMethod.POST);
}

function setDate(inputDate) {
    let date = new Date(inputDate);
    date.setHours(12);
    date.setMinutes(0);

    return date;
}

function transformData(data) {

    return {
        ...data,
        contractedHoursPerMonth: data.contractedHoursPerMonth ? data.contractedHoursPerMonth.name : '',
        hoursPerWeek: data.hoursPerWeek ? data.hoursPerWeek.name : '',
        currency: data.currency ? data.currency.name : '',
        commission: data.commission !== undefined && data.commission.value !== undefined ? data.commission.value : data.commission,
        bonus: data.bonus !== undefined && data.bonus.value !== undefined ? data.bonus.value : data.bonus,
        carAllowance: data.carAllowance !== undefined && data.carAllowance.value !== undefined ? data.carAllowance.value : data.carAllowance,
        healthcare: data.healthcare !== undefined ? data.healthcare.value : false,
        startDate: data.startDate ? setDate(data.startDate) : new Date(Date.now()),
        continuousStartDate: data.continuousStartDate ? setDate(data.continuousStartDate) : new Date(Date.now()),
    }
}