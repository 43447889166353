import * as Actions from '../actions/Actions';

const initialState = {
    doUpdate: false,
    allowUpdate: false
};

const applicationReducers = (state = initialState, action) => {

    switch (action.type) {

        case Actions.UPDATE:
            return {
                ...state,
                doUpdate: action.doUpdate
            };
        case Actions.UPDATE_ALLOW:
            return {
                ...state,
                allowUpdate: action.allowUpdate
            };   
        default: return state;
    }
};

export default applicationReducers;