import { combineReducers } from 'redux';
import authReducers from './AuthReducers';
import siteDataReducers from "./SiteDataReducers";
import menuReducers from "./MenuReducers";
import filterReducers from "./FilterReducers";
import applicationReducers from "./ApplicationReducers";

const appReducers = combineReducers({
    authReducers,
    siteDataReducers,
    menuReducers,
    filterReducers,
    applicationReducers
});

export default appReducers;